<div class="wrapper">
  <div class="block-center account-frame">
    <!-- START panel-->
    <div class="card panel-dark panel-flat">
      <app-account-header></app-account-header>
      <div class="card-body">
        <router-outlet></router-outlet>
      </div>
      <app-account-footer></app-account-footer>
    </div>
  </div>
</div>
