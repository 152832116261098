import {Component, Input, OnInit} from '@angular/core';
import {SafeHtml} from '@angular/platform-browser';
import {ContentService} from '../../../virtual-expo-api';
import {MarkdownService} from 'ngx-markdown';

@Component({
  selector: 'app-content-display',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentDisplayComponent implements OnInit {
  @Input() name: string;
  content: SafeHtml;

  constructor(
    private contentService: ContentService
    , private markdownService: MarkdownService
  ) {
  }

  ngOnInit(): void {
    this.contentService.contentGetContent(this.name)
      .subscribe(value => {
        this.content = this.markdownService.compile(value);;
      });
  }

}
