import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-input-box',
  templateUrl: './input-box.component.html',
  styleUrls: ['./input-box.component.scss']
})
export class InputBoxComponent implements OnInit {
  @Output() sendMessage: EventEmitter<string> = new EventEmitter<string>();
  @Input() modalRef: NgbModalRef;
  message: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  submitForm($event: MouseEvent) {
    $event.preventDefault();

    if (this.message && this.message.trim() !== '') {
      this.modalRef.close(this.message);
      this.message = '';
    }
    this.modalRef.dismiss();
  }
}
