/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ContactProfileDto { 
    type?: string;
    expoId?: string;
    name?: string;
    company?: string;
    email?: string;
    role?: string;
    socialProfiles?: string;
}

/**
 * Namespace for property- and property-value-enumerations of ContactProfileDto.
 */
export namespace ContactProfileDto {
    /**
     * All properties of ContactProfileDto.
     */
    export enum Properties {
        type = 'type',
        expoId = 'expoId',
        name = 'name',
        company = 'company',
        email = 'email',
        role = 'role',
        socialProfiles = 'socialProfiles'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ContactProfileDto.
    */
    export const ModelValidators: {[K in keyof ContactProfileDto]: [string, ValidatorFn][]} = {
        type: [
        ],
        expoId: [
        ],
        name: [
        ],
        company: [
        ],
        email: [
        ],
        role: [
        ],
        socialProfiles: [
        ],
    };

    /**
    * The FormControlFactory for ContactProfileDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ContactProfileDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ContactProfileDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ContactProfileDto = {
            type: null,
            expoId: null,
            name: null,
            company: null,
            email: null,
            role: null,
            socialProfiles: null,
          }
        ) {
            super(model, ContactProfileDto.ModelValidators);
        }
    }

}


