import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-markdown-sample',
  templateUrl: './markdown-sample.component.html',
  styleUrls: ['./markdown-sample.component.scss']
})
export class MarkdownSampleComponent implements OnInit {
  markdown = '# Headers\n' +
    '\n' +
    '# H1\n' +
    '## H2\n' +
    '### H3\n' +
    '#### H4\n' +
    '##### H5\n' +
    '###### H6\n' +
    '\n' +
    'Alternatively, for H1 and H2, an underline-ish style:\n' +
    '\n' +
    'Alt-H1\n' +
    '======\n' +
    '\n' +
    'Alt-H2\n' +
    '------\n' +
    '\n' +
    '\n' +
    '\n' +
    '# Emphasis\n' +
    '\n' +
    'Emphasis, aka italics, with *asterisks* or _underscores_.\n' +
    '\n' +
    'Strong emphasis, aka bold, with **asterisks** or __underscores__.\n' +
    '\n' +
    'Combined emphasis with **asterisks and _underscores_**.\n' +
    '\n' +
    'Strikethrough uses two tildes. ~~Scratch this.~~\n' +
    '\n' +
    '# Lists\n' +
    '\n' +
    '(In this example, leading and trailing spaces are shown with with dots: ⋅)\n' +
    '\n' +
    '1. First ordered list item\n' +
    '2. Another item\n' +
    '  * Unordered sub-list.\n' +
    '1. Actual numbers don\'t matter, just that it\'s a number\n' +
    '  1. Ordered sub-list\n' +
    '4. And another item.\n' +
    '\n' +
    '   You can have properly indented paragraphs within list items. Notice the blank line above,\n' +
    'and the leading spaces (at least one, but we\'ll use three here to also align the raw Markdown).\n' +
    '\n' +
    '   To have a line break without a paragraph, you will need to use two trailing spaces.⋅⋅\n' +
    '   Note that this line is separate, but within the same paragraph.⋅⋅\n' +
    '   (This is contrary to the typical GFM line break behaviour, where trailing spaces are not required.)\n' +
    '\n' +
    '* Unordered list can use asterisks\n' +
    '- Or minuses\n' +
    '+ Or pluses\n' +
    '\n' +
    '\n' +
    '# Links\n' +
    '\n' +
    'There are two ways to create links.\n' +
    '\n' +
    '[I\'m an inline-style link](https://www.google.com)\n' +
    '\n' +
    '[I\'m an inline-style link with title](https://www.google.com "Google\'s Homepage")\n' +
    '\n' +
    '[I\'m a reference-style link][Arbitrary case-insensitive reference text]\n' +
    '\n' +
    // '[I\'m a relative reference to a repository file](../blob/master/LICENSE)\n' +
    // '\n' +
    '[You can use numbers for reference-style link definitions][1]\n' +
    '\n' +
    'Or leave it empty and use the [link text itself].\n' +
    '\n' +
    'URLs and URLs in angle brackets will automatically get turned into links.\n' +
    'http://www.example.com or <http://www.example.com> and sometimes\n' +
    'example.com (but not on Github, for example).\n' +
    '\n' +
    'Some text to show that the reference links can follow later.\n' +
    '\n' +
    '[arbitrary case-insensitive reference text]: https://www.mozilla.org\n' +
    '[1]: http://slashdot.org\n' +
    '[link text itself]: http://www.reddit.com\n' +
    '\n' +
    '\n' +
    '\n' +
    '# Images\n' +
    '\n' +
    'Here\'s our logo (hover to see the title text):\n' +
    '\n' +
    'Inline-style:\n' +
    '![alt text](https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png "Logo Title Text 1")\n' +
    '\n' +
    'Reference-style:\n' +
    '![alt text][logo]\n' +
    '\n' +
    '[logo]: https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png "Logo Title Text 2"\n' +
    '\n' +
    '# Code and Syntax Highlighting\n' +
    '\n' +
    'Inline `code` has `back-ticks around` it.\n' +
    '\n' +
    '```javascript\n' +
    'var s = "JavaScript syntax highlighting";\n' +
    'alert(s);\n' +
    '```\n' +
    '\n' +
    '```python\n' +
    's = "Python syntax highlighting"\n' +
    'print s\n' +
    '```\n' +
    '\n' +
    '```\n' +
    'No language indicated, so no syntax highlighting.\n' +
    'But let\'s throw in a <b>tag</b>.\n' +
    '\n' +
    '```\n' +
    '\n' +
    '\n' +
    '# Blockquotes\n' +
    '\n' +
    '> Blockquotes are very handy in email to emulate reply text.\n' +
    '> This line is part of the same quote.\n' +
    '\n' +
    'Quote break.\n' +
    '\n' +
    '> This is a very long line that will still be quoted properly when it wraps.\n' +
    'Oh boy let\'s keep writing to make sure this is long enough to actually wrap for everyone.\n' +
    'Oh, you can *put* **Markdown** into a blockquote.\n' +
    '\n' +
    '\n' +
    '# Horizontal Rule\n' +
    '\n' +
    'Three or more...\n' +
    '\n' +
    '---\n' +
    '\n' +
    'Hyphens\n' +
    '\n' +
    '***\n' +
    '\n' +
    'Asterisks\n' +
    '\n' +
    '___\n' +
    '\n' +
    'Underscores\n' +
    '\n' +
    '# Tables\n' +
    '\n' +
    'Colons can be used to align columns.\n' +
    '\n' +
    '| Tables        | Are           | Cool  |\n' +
    '| ------------- |:-------------:| -----:|\n' +
    '| col 3 is      | right-aligned | $1600 |\n' +
    '| col 2 is      | centered      |   $12 |\n' +
    '| zebra stripes | are neat      |    $1 |\n' +
    '\n' +
    'There must be at least 3 dashes separating each header cell.\n' +
    'The outer pipes (|) are optional, and you don\'t need to make the\n' +
    'raw Markdown line up prettily. You can also use inline Markdown.\n' +
    '\n' +
    'Markdown | Less | Pretty\n' +
    '--- | --- | ---\n' +
    '*Still* | `renders]` | **nicely**\n' +
    '1 | 2 | 3\n';

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
