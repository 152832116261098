/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { BoothDetailItemDto } from './boothDetailItemDto';


export interface ExpoDetailItemDtoAllOf { 
    textChatEnabled?: boolean;
    videoChatEnabled?: boolean;
    boothCount?: number;
    booths?: Array<BoothDetailItemDto>;
    tagsAvailable?: boolean;
    tagsEnabled?: boolean;
    tagsExhibitorEditable?: boolean;
    boothPresentationEnabled?: boolean;
    tags?: Array<string>;
    timeZone?: string;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoDetailItemDtoAllOf.
 */
export namespace ExpoDetailItemDtoAllOf {
    /**
     * All properties of ExpoDetailItemDtoAllOf.
     */
    export enum Properties {
        textChatEnabled = 'textChatEnabled',
        videoChatEnabled = 'videoChatEnabled',
        boothCount = 'boothCount',
        booths = 'booths',
        tagsAvailable = 'tagsAvailable',
        tagsEnabled = 'tagsEnabled',
        tagsExhibitorEditable = 'tagsExhibitorEditable',
        boothPresentationEnabled = 'boothPresentationEnabled',
        tags = 'tags',
        timeZone = 'timeZone'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoDetailItemDtoAllOf.
    */
    export const ModelValidators: {[K in keyof ExpoDetailItemDtoAllOf]: [string, ValidatorFn][]} = {
        textChatEnabled: [
        ],
        videoChatEnabled: [
        ],
        boothCount: [
        ],
        booths: [
        ],
        tagsAvailable: [
        ],
        tagsEnabled: [
        ],
        tagsExhibitorEditable: [
        ],
        boothPresentationEnabled: [
        ],
        tags: [
        ],
        timeZone: [
        ],
    };

    /**
    * The FormControlFactory for ExpoDetailItemDtoAllOf.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoDetailItemDtoAllOf> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoDetailItemDtoAllOf.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoDetailItemDtoAllOf = {
            textChatEnabled: null,
            videoChatEnabled: null,
            boothCount: null,
            booths: null,
            tagsAvailable: null,
            tagsEnabled: null,
            tagsExhibitorEditable: null,
            boothPresentationEnabled: null,
            tags: null,
            timeZone: null,
          }
        ) {
            super(model, ExpoDetailItemDtoAllOf.ModelValidators);
        }
    }

}


