/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface PwdLessProfileDto { 
    givenName: string;
    termsAccepted: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of PwdLessProfileDto.
 */
export namespace PwdLessProfileDto {
    /**
     * All properties of PwdLessProfileDto.
     */
    export enum Properties {
        givenName = 'givenName',
        termsAccepted = 'termsAccepted'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of PwdLessProfileDto.
    */
    export const ModelValidators: {[K in keyof PwdLessProfileDto]: [string, ValidatorFn][]} = {
        givenName: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(6)],
                ['maxlength', Validators.maxLength(100)],
        ],
        termsAccepted: [
                ['required', Validators.required],
        ],
    };

    /**
    * The FormControlFactory for PwdLessProfileDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<PwdLessProfileDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for PwdLessProfileDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: PwdLessProfileDto = {
            givenName: null,
            termsAccepted: null,
          }
        ) {
            super(model, PwdLessProfileDto.ModelValidators);
        }
    }

}


