<div class="modal-header">
  <h1>Get login token</h1>
</div>
<form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="registerForm" novalidate=""
      (submit)="submitForm($event, valForm.value)">
  <div class="modal-body">
<app-form-string-input [formGroup]="valForm" fieldName="email"></app-form-string-input>
    <div *ngIf="token && token !== ''">
      Login link: <a [href]="token">{{token}}</a>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-block btn-primary mt-lg" type="submit" [disabled]="submitted || !valForm.value">Get token</button>
  </div>
</form>
