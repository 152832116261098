import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NgbDropdown, NgbModal} from '@ng-bootstrap/ng-bootstrap';

const screenfull = require('screenfull');
declare var $: any;

import {UserblockService} from '../sidebar/userblock/userblock.service';
import {SettingsService} from '../../core/settings/settings.service';
import {MenuService} from '../../core/menu/menu.service';
import {AuthService} from '../../core/authService/authService';
import {ConfirmationDialogService} from '../../shared/confirmation-dialog/confirmation-dialog.service';
import {SetPasswordComponent} from '../../routes/pages/set-password/set-password.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout

  authData: any;
  givenName: string;

  isNavSearchVisible: boolean;
  @ViewChild('fsbutton', {static: true}) fsbutton;  // the fullscreen button

  constructor(public menu: MenuService, public userblockService: UserblockService, public settings: SettingsService
    , private router: Router, public auth: AuthService, private confirmationDialogService: ConfirmationDialogService
    , private modalService: NgbModal) {
    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
  }

  ngOnInit() {
    this.isNavSearchVisible = false;

    if (!this.authData) {
      this.authData = this.auth.getTokenData();
    }

    if (this.authData) {
      const nameId = this.authData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
      this.givenName = this.authData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];

      if (nameId === this.givenName) {
        this.router.navigate(['profile']);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    // console.log(stat);
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.layout.offsidebarOpen = !this.settings.layout.offsidebarOpen;
  }

  toggleCollapsedSidebar() {
    this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
  }

  isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  screenFull() {
    return screenfull.isFullscreen;
  }

  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }

  signOut($ev) {
    $ev.preventDefault();
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to log out?', 'Yes, log out!')
      .then((confirmed) => {
        if (confirmed) {
          console.log('signing out');
          this.router.navigate(['pwdless']);
          this.authData = null;
          this.givenName = '';
          console.log('signed out');
        }
      }, reason => {
      });
  }

  setPassword($event: MouseEvent) {
    $event.preventDefault();

    this.modalService.open(SetPasswordComponent, {size: 'sm'});
  }
}
