import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkedIntegrationsComponent } from './linked-integrations/linked-integrations.component';



@NgModule({
  declarations: [LinkedIntegrationsComponent],
  exports: [
    LinkedIntegrationsComponent
  ],
  imports: [
    CommonModule
  ]
})
export class IntegrationsModule { }
