/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CalendarBookedSlotMeta } from './calendarBookedSlotMeta';


export interface CalendarBookedSlotAdminDtoAllOf { 
    userId?: string;
    meta?: CalendarBookedSlotMeta;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarBookedSlotAdminDtoAllOf.
 */
export namespace CalendarBookedSlotAdminDtoAllOf {
    /**
     * All properties of CalendarBookedSlotAdminDtoAllOf.
     */
    export enum Properties {
        userId = 'userId',
        meta = 'meta'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarBookedSlotAdminDtoAllOf.
    */
    export const ModelValidators: {[K in keyof CalendarBookedSlotAdminDtoAllOf]: [string, ValidatorFn][]} = {
        userId: [
        ],
        meta: [
        ],
    };

    /**
    * The FormControlFactory for CalendarBookedSlotAdminDtoAllOf.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarBookedSlotAdminDtoAllOf> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarBookedSlotAdminDtoAllOf.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarBookedSlotAdminDtoAllOf = {
            userId: null,
            meta: null,
          }
        ) {
            super(model, CalendarBookedSlotAdminDtoAllOf.ModelValidators);
        }
    }

}


