<div style="padding: 50px 0">
  <div class="btn btn-sm btn-success" (click)="addIntegration($event)">Add integration</div>
  <table>
    <thead>
    <tr>
      <th>Name</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let integration of usedIntegrations">
      <td>{{ integration.name }}</td>
    </tr>
    </tbody>
  </table>
  <div class="btn btn-sm btn-success" (click)="getRooms($event)">List rooms</div>
  <ul>
    <li *ngFor="let room of rooms" (click)="openRoom($event, room)">
      {{ room.title }} {{ room.id }}
    </li>
  </ul>
  <iframe style="width: 500px; height: 500px;" #iframe
          sandbox="allow-forms allow-popups allow-scripts allow-same-origin" allow="microphone; camera" allowfullscreen="allowfullscreen"
  ></iframe>
</div>
