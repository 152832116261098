/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { BoothDetailItemDto } from '../model/boothDetailItemDto';
import { CalendarEntryBoothDto } from '../model/calendarEntryBoothDto';
import { NotificationMessageDto } from '../model/notificationMessageDto';
import { StreamUpdateDto } from '../model/streamUpdateDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for boothCalendarActivate.
 */
export namespace BoothCalendarActivate {
    /**
     * Parameter map for boothCalendarActivate.
     */
    export interface PartialParamMap {
      entryId: number;
    }

    /**
     * Enumeration of all parameters for boothCalendarActivate.
     */
    export enum Parameters {
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothCalendarActivate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothCalendarActivate.PartialParamMap]?: [string, ValidatorFn][]} = {
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothCalendarAvailable.
 */
export namespace BoothCalendarAvailable {
    /**
     * Parameter map for boothCalendarAvailable.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for boothCalendarAvailable.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothCalendarAvailable
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothCalendarAvailable.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothCalendarDeactivate.
 */
export namespace BoothCalendarDeactivate {
    /**
     * Parameter map for boothCalendarDeactivate.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for boothCalendarDeactivate.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothCalendarDeactivate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothCalendarDeactivate.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothCalendarDelete.
 */
export namespace BoothCalendarDelete {
    /**
     * Parameter map for boothCalendarDelete.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: number;
    }

    /**
     * Enumeration of all parameters for boothCalendarDelete.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothCalendarDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothCalendarDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothCalendarGet.
 */
export namespace BoothCalendarGet {
    /**
     * Parameter map for boothCalendarGet.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: number;
    }

    /**
     * Enumeration of all parameters for boothCalendarGet.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothCalendarGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothCalendarGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothCalendarGetAll.
 */
export namespace BoothCalendarGetAll {
    /**
     * Parameter map for boothCalendarGetAll.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for boothCalendarGetAll.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothCalendarGetAll
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothCalendarGetAll.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothCalendarGetAllExpo.
 */
export namespace BoothCalendarGetAllExpo {
    /**
     * Parameter map for boothCalendarGetAllExpo.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for boothCalendarGetAllExpo.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothCalendarGetAllExpo
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothCalendarGetAllExpo.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothCalendarNotify.
 */
export namespace BoothCalendarNotify {
    /**
     * Parameter map for boothCalendarNotify.
     */
    export interface PartialParamMap {
      entryId: number;
    }

    /**
     * Enumeration of all parameters for boothCalendarNotify.
     */
    export enum Parameters {
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothCalendarNotify
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothCalendarNotify.PartialParamMap]?: [string, ValidatorFn][]} = {
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothCalendarNotifyMessage.
 */
export namespace BoothCalendarNotifyMessage {
    /**
     * Parameter map for boothCalendarNotifyMessage.
     */
    export interface PartialParamMap {
      NotificationMessageDto: NotificationMessageDto;
    }

    /**
     * Enumeration of all parameters for boothCalendarNotifyMessage.
     */
    export enum Parameters {
      NotificationMessageDto = 'NotificationMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothCalendarNotifyMessage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothCalendarNotifyMessage.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothCalendarPost.
 */
export namespace BoothCalendarPost {
    /**
     * Parameter map for boothCalendarPost.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      CalendarEntryBoothDto: CalendarEntryBoothDto;
    }

    /**
     * Enumeration of all parameters for boothCalendarPost.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      CalendarEntryBoothDto = 'CalendarEntryBoothDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothCalendarPost
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothCalendarPost.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothCalendarUpdateStream.
 */
export namespace BoothCalendarUpdateStream {
    /**
     * Parameter map for boothCalendarUpdateStream.
     */
    export interface PartialParamMap {
      entryId: number;
      StreamUpdateDto: StreamUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothCalendarUpdateStream.
     */
    export enum Parameters {
      entryId = 'entryId',
      StreamUpdateDto = 'StreamUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothCalendarUpdateStream
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothCalendarUpdateStream.PartialParamMap]?: [string, ValidatorFn][]} = {
      entryId: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class BoothCalendarService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothCalendarActivateByMap(
    map: BoothCalendarActivate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public boothCalendarActivateByMap(
    map: BoothCalendarActivate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public boothCalendarActivateByMap(
    map: BoothCalendarActivate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public boothCalendarActivateByMap(
    map: BoothCalendarActivate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothCalendarActivate(
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothCalendarActivate(entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public boothCalendarActivate(entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public boothCalendarActivate(entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public boothCalendarActivate(entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling boothCalendarActivate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/BoothCalendar/activate/${encodeURIComponent(String(entryId))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothCalendar_Activate']) {
                this.cancelMap['BoothCalendar_Activate'].next();
            }
            this.cancelMap['BoothCalendar_Activate'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothCalendar_Activate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothCalendar_Activate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothCalendar_Activate', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothCalendar_Activate']) {
            this.cancelMap['BoothCalendar_Activate'].complete();
            delete this.cancelMap['BoothCalendar_Activate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothCalendar_Activate',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothCalendar_Activate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothCalendarAvailableByMap(
    map: BoothCalendarAvailable.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<BoothDetailItemDto>>;
  public boothCalendarAvailableByMap(
    map: BoothCalendarAvailable.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<BoothDetailItemDto>>>;
  public boothCalendarAvailableByMap(
    map: BoothCalendarAvailable.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<BoothDetailItemDto>>>;
  public boothCalendarAvailableByMap(
    map: BoothCalendarAvailable.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothCalendarAvailable(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothCalendarAvailable(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<BoothDetailItemDto>>;
    public boothCalendarAvailable(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<BoothDetailItemDto>>>;
    public boothCalendarAvailable(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<BoothDetailItemDto>>>;
    public boothCalendarAvailable(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothCalendarAvailable.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<BoothDetailItemDto>>(`${this.configuration.basePath}/api/BoothCalendar/available/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothCalendar_Available']) {
                this.cancelMap['BoothCalendar_Available'].next();
            }
            this.cancelMap['BoothCalendar_Available'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothCalendar_Available']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothCalendar_Available']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothCalendar_Available', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothCalendar_Available']) {
            this.cancelMap['BoothCalendar_Available'].complete();
            delete this.cancelMap['BoothCalendar_Available'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothCalendar_Available',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothCalendar_Available')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothCalendarDeactivateByMap(
    map: BoothCalendarDeactivate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public boothCalendarDeactivateByMap(
    map: BoothCalendarDeactivate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public boothCalendarDeactivateByMap(
    map: BoothCalendarDeactivate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public boothCalendarDeactivateByMap(
    map: BoothCalendarDeactivate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothCalendarDeactivate(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothCalendarDeactivate(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public boothCalendarDeactivate(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public boothCalendarDeactivate(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public boothCalendarDeactivate(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothCalendarDeactivate.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling boothCalendarDeactivate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/BoothCalendar/deactivate/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothCalendar_Deactivate']) {
                this.cancelMap['BoothCalendar_Deactivate'].next();
            }
            this.cancelMap['BoothCalendar_Deactivate'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothCalendar_Deactivate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothCalendar_Deactivate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothCalendar_Deactivate', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothCalendar_Deactivate']) {
            this.cancelMap['BoothCalendar_Deactivate'].complete();
            delete this.cancelMap['BoothCalendar_Deactivate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothCalendar_Deactivate',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothCalendar_Deactivate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothCalendarDeleteByMap(
    map: BoothCalendarDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public boothCalendarDeleteByMap(
    map: BoothCalendarDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public boothCalendarDeleteByMap(
    map: BoothCalendarDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public boothCalendarDeleteByMap(
    map: BoothCalendarDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothCalendarDelete(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothCalendarDelete(expoId: string, exhibitorId: string, entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public boothCalendarDelete(expoId: string, exhibitorId: string, entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public boothCalendarDelete(expoId: string, exhibitorId: string, entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public boothCalendarDelete(expoId: string, exhibitorId: string, entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothCalendarDelete.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling boothCalendarDelete.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling boothCalendarDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<boolean>(`${this.configuration.basePath}/api/BoothCalendar/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothCalendar_Delete']) {
                this.cancelMap['BoothCalendar_Delete'].next();
            }
            this.cancelMap['BoothCalendar_Delete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothCalendar_Delete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothCalendar_Delete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothCalendar_Delete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothCalendar_Delete']) {
            this.cancelMap['BoothCalendar_Delete'].complete();
            delete this.cancelMap['BoothCalendar_Delete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothCalendar_Delete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothCalendar_Delete')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothCalendarGetByMap(
    map: BoothCalendarGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarEntryBoothDto>;
  public boothCalendarGetByMap(
    map: BoothCalendarGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryBoothDto>>;
  public boothCalendarGetByMap(
    map: BoothCalendarGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryBoothDto>>;
  public boothCalendarGetByMap(
    map: BoothCalendarGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothCalendarGet(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothCalendarGet(expoId: string, exhibitorId: string, entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarEntryBoothDto>;
    public boothCalendarGet(expoId: string, exhibitorId: string, entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryBoothDto>>;
    public boothCalendarGet(expoId: string, exhibitorId: string, entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryBoothDto>>;
    public boothCalendarGet(expoId: string, exhibitorId: string, entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothCalendarGet.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling boothCalendarGet.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling boothCalendarGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<CalendarEntryBoothDto>(`${this.configuration.basePath}/api/BoothCalendar/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothCalendar_Get']) {
                this.cancelMap['BoothCalendar_Get'].next();
            }
            this.cancelMap['BoothCalendar_Get'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothCalendar_Get']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothCalendar_Get']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothCalendar_Get', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothCalendar_Get']) {
            this.cancelMap['BoothCalendar_Get'].complete();
            delete this.cancelMap['BoothCalendar_Get'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothCalendar_Get',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothCalendar_Get')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothCalendarGetAllByMap(
    map: BoothCalendarGetAll.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<CalendarEntryBoothDto>>;
  public boothCalendarGetAllByMap(
    map: BoothCalendarGetAll.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarEntryBoothDto>>>;
  public boothCalendarGetAllByMap(
    map: BoothCalendarGetAll.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarEntryBoothDto>>>;
  public boothCalendarGetAllByMap(
    map: BoothCalendarGetAll.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothCalendarGetAll(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothCalendarGetAll(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<CalendarEntryBoothDto>>;
    public boothCalendarGetAll(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarEntryBoothDto>>>;
    public boothCalendarGetAll(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarEntryBoothDto>>>;
    public boothCalendarGetAll(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothCalendarGetAll.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling boothCalendarGetAll.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<CalendarEntryBoothDto>>(`${this.configuration.basePath}/api/BoothCalendar/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothCalendar_GetAll']) {
                this.cancelMap['BoothCalendar_GetAll'].next();
            }
            this.cancelMap['BoothCalendar_GetAll'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothCalendar_GetAll']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothCalendar_GetAll']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothCalendar_GetAll', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothCalendar_GetAll']) {
            this.cancelMap['BoothCalendar_GetAll'].complete();
            delete this.cancelMap['BoothCalendar_GetAll'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothCalendar_GetAll',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothCalendar_GetAll')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothCalendarGetAllExpoByMap(
    map: BoothCalendarGetAllExpo.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<CalendarEntryBoothDto>>;
  public boothCalendarGetAllExpoByMap(
    map: BoothCalendarGetAllExpo.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarEntryBoothDto>>>;
  public boothCalendarGetAllExpoByMap(
    map: BoothCalendarGetAllExpo.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarEntryBoothDto>>>;
  public boothCalendarGetAllExpoByMap(
    map: BoothCalendarGetAllExpo.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothCalendarGetAllExpo(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothCalendarGetAllExpo(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<CalendarEntryBoothDto>>;
    public boothCalendarGetAllExpo(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarEntryBoothDto>>>;
    public boothCalendarGetAllExpo(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarEntryBoothDto>>>;
    public boothCalendarGetAllExpo(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothCalendarGetAllExpo.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<CalendarEntryBoothDto>>(`${this.configuration.basePath}/api/BoothCalendar/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothCalendar_GetAllExpo']) {
                this.cancelMap['BoothCalendar_GetAllExpo'].next();
            }
            this.cancelMap['BoothCalendar_GetAllExpo'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothCalendar_GetAllExpo']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothCalendar_GetAllExpo']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothCalendar_GetAllExpo', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothCalendar_GetAllExpo']) {
            this.cancelMap['BoothCalendar_GetAllExpo'].complete();
            delete this.cancelMap['BoothCalendar_GetAllExpo'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothCalendar_GetAllExpo',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothCalendar_GetAllExpo')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothCalendarNotifyByMap(
    map: BoothCalendarNotify.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public boothCalendarNotifyByMap(
    map: BoothCalendarNotify.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public boothCalendarNotifyByMap(
    map: BoothCalendarNotify.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public boothCalendarNotifyByMap(
    map: BoothCalendarNotify.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothCalendarNotify(
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothCalendarNotify(entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public boothCalendarNotify(entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public boothCalendarNotify(entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public boothCalendarNotify(entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling boothCalendarNotify.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/BoothCalendar/notify/${encodeURIComponent(String(entryId))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothCalendar_Notify']) {
                this.cancelMap['BoothCalendar_Notify'].next();
            }
            this.cancelMap['BoothCalendar_Notify'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothCalendar_Notify']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothCalendar_Notify']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothCalendar_Notify', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothCalendar_Notify']) {
            this.cancelMap['BoothCalendar_Notify'].complete();
            delete this.cancelMap['BoothCalendar_Notify'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothCalendar_Notify',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothCalendar_Notify')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothCalendarNotifyMessageByMap(
    map: BoothCalendarNotifyMessage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public boothCalendarNotifyMessageByMap(
    map: BoothCalendarNotifyMessage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public boothCalendarNotifyMessageByMap(
    map: BoothCalendarNotifyMessage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public boothCalendarNotifyMessageByMap(
    map: BoothCalendarNotifyMessage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothCalendarNotifyMessage(
      map.NotificationMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param NotificationMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothCalendarNotifyMessage(NotificationMessageDto: NotificationMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public boothCalendarNotifyMessage(NotificationMessageDto: NotificationMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public boothCalendarNotifyMessage(NotificationMessageDto: NotificationMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public boothCalendarNotifyMessage(NotificationMessageDto: NotificationMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (NotificationMessageDto === null || NotificationMessageDto === undefined) {
            throw new Error('Required parameter NotificationMessageDto was null or undefined when calling boothCalendarNotifyMessage.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/BoothCalendar/notify`,
            NotificationMessageDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothCalendar_NotifyMessage']) {
                this.cancelMap['BoothCalendar_NotifyMessage'].next();
            }
            this.cancelMap['BoothCalendar_NotifyMessage'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothCalendar_NotifyMessage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothCalendar_NotifyMessage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothCalendar_NotifyMessage', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothCalendar_NotifyMessage']) {
            this.cancelMap['BoothCalendar_NotifyMessage'].complete();
            delete this.cancelMap['BoothCalendar_NotifyMessage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothCalendar_NotifyMessage',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothCalendar_NotifyMessage')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothCalendarPostByMap(
    map: BoothCalendarPost.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarEntryBoothDto>;
  public boothCalendarPostByMap(
    map: BoothCalendarPost.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryBoothDto>>;
  public boothCalendarPostByMap(
    map: BoothCalendarPost.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryBoothDto>>;
  public boothCalendarPostByMap(
    map: BoothCalendarPost.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothCalendarPost(
      map.expoId,
      map.exhibitorId,
      map.CalendarEntryBoothDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param CalendarEntryBoothDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothCalendarPost(expoId: string, exhibitorId: string, CalendarEntryBoothDto: CalendarEntryBoothDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarEntryBoothDto>;
    public boothCalendarPost(expoId: string, exhibitorId: string, CalendarEntryBoothDto: CalendarEntryBoothDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryBoothDto>>;
    public boothCalendarPost(expoId: string, exhibitorId: string, CalendarEntryBoothDto: CalendarEntryBoothDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryBoothDto>>;
    public boothCalendarPost(expoId: string, exhibitorId: string, CalendarEntryBoothDto: CalendarEntryBoothDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothCalendarPost.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling boothCalendarPost.');
        }
        if (CalendarEntryBoothDto === null || CalendarEntryBoothDto === undefined) {
            throw new Error('Required parameter CalendarEntryBoothDto was null or undefined when calling boothCalendarPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<CalendarEntryBoothDto>(`${this.configuration.basePath}/api/BoothCalendar/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            CalendarEntryBoothDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothCalendar_Post']) {
                this.cancelMap['BoothCalendar_Post'].next();
            }
            this.cancelMap['BoothCalendar_Post'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothCalendar_Post']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothCalendar_Post']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothCalendar_Post', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothCalendar_Post']) {
            this.cancelMap['BoothCalendar_Post'].complete();
            delete this.cancelMap['BoothCalendar_Post'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothCalendar_Post',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothCalendar_Post')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothCalendarUpdateStreamByMap(
    map: BoothCalendarUpdateStream.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarEntryBoothDto>;
  public boothCalendarUpdateStreamByMap(
    map: BoothCalendarUpdateStream.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryBoothDto>>;
  public boothCalendarUpdateStreamByMap(
    map: BoothCalendarUpdateStream.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryBoothDto>>;
  public boothCalendarUpdateStreamByMap(
    map: BoothCalendarUpdateStream.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothCalendarUpdateStream(
      map.entryId,
      map.StreamUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param entryId 
     * @param StreamUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothCalendarUpdateStream(entryId: number, StreamUpdateDto: StreamUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarEntryBoothDto>;
    public boothCalendarUpdateStream(entryId: number, StreamUpdateDto: StreamUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryBoothDto>>;
    public boothCalendarUpdateStream(entryId: number, StreamUpdateDto: StreamUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryBoothDto>>;
    public boothCalendarUpdateStream(entryId: number, StreamUpdateDto: StreamUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling boothCalendarUpdateStream.');
        }
        if (StreamUpdateDto === null || StreamUpdateDto === undefined) {
            throw new Error('Required parameter StreamUpdateDto was null or undefined when calling boothCalendarUpdateStream.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<CalendarEntryBoothDto>(`${this.configuration.basePath}/api/BoothCalendar/updateStream/${encodeURIComponent(String(entryId))}`,
            StreamUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothCalendar_UpdateStream']) {
                this.cancelMap['BoothCalendar_UpdateStream'].next();
            }
            this.cancelMap['BoothCalendar_UpdateStream'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothCalendar_UpdateStream']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothCalendar_UpdateStream']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothCalendar_UpdateStream', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothCalendar_UpdateStream']) {
            this.cancelMap['BoothCalendar_UpdateStream'].complete();
            delete this.cancelMap['BoothCalendar_UpdateStream'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothCalendar_UpdateStream',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothCalendar_UpdateStream')));
        }
        return handle;
    }

  }

export namespace BoothCalendarService {
  export enum Operations {
    BoothCalendar_Activate = 'BoothCalendar_Activate',
    BoothCalendar_Available = 'BoothCalendar_Available',
    BoothCalendar_Deactivate = 'BoothCalendar_Deactivate',
    BoothCalendar_Delete = 'BoothCalendar_Delete',
    BoothCalendar_Get = 'BoothCalendar_Get',
    BoothCalendar_GetAll = 'BoothCalendar_GetAll',
    BoothCalendar_GetAllExpo = 'BoothCalendar_GetAllExpo',
    BoothCalendar_Notify = 'BoothCalendar_Notify',
    BoothCalendar_NotifyMessage = 'BoothCalendar_NotifyMessage',
    BoothCalendar_Post = 'BoothCalendar_Post',
    BoothCalendar_UpdateStream = 'BoothCalendar_UpdateStream'
  }
}
