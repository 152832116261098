/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { ContactDto } from '../model/contactDto';
import { SortUpdateDto } from '../model/sortUpdateDto';
import { UserListDto } from '../model/userListDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for contactContactSort.
 */
export namespace ContactContactSort {
    /**
     * Parameter map for contactContactSort.
     */
    export interface PartialParamMap {
      /**
       * The sort update.
       */
      SortUpdateDto: SortUpdateDto;
    }

    /**
     * Enumeration of all parameters for contactContactSort.
     */
    export enum Parameters {
      /**
       * The sort update.
       */
      SortUpdateDto = 'SortUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of contactContactSort
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ContactContactSort.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for contactDeleteContact.
 */
export namespace ContactDeleteContact {
    /**
     * Parameter map for contactDeleteContact.
     */
    export interface PartialParamMap {
      exhibitorId: string;
      contactId: string;
    }

    /**
     * Enumeration of all parameters for contactDeleteContact.
     */
    export enum Parameters {
      exhibitorId = 'exhibitorId',
      contactId = 'contactId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of contactDeleteContact
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ContactDeleteContact.PartialParamMap]?: [string, ValidatorFn][]} = {
      exhibitorId: [
              ['required', Validators.required],
      ],
      contactId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for contactDeleteExpoContact.
 */
export namespace ContactDeleteExpoContact {
    /**
     * Parameter map for contactDeleteExpoContact.
     */
    export interface PartialParamMap {
      expoId: string;
      contactId: string;
    }

    /**
     * Enumeration of all parameters for contactDeleteExpoContact.
     */
    export enum Parameters {
      expoId = 'expoId',
      contactId = 'contactId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of contactDeleteExpoContact
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ContactDeleteExpoContact.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      contactId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for contactGetContacts.
 */
export namespace ContactGetContacts {
    /**
     * Parameter map for contactGetContacts.
     */
    export interface PartialParamMap {
      /**
       * The exhibitor identifier.
       */
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for contactGetContacts.
     */
    export enum Parameters {
      /**
       * The exhibitor identifier.
       */
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of contactGetContacts
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ContactGetContacts.PartialParamMap]?: [string, ValidatorFn][]} = {
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for contactGetExpoContacts.
 */
export namespace ContactGetExpoContacts {
    /**
     * Parameter map for contactGetExpoContacts.
     */
    export interface PartialParamMap {
      /**
       * The expo identifier.
       */
      expoId: string;
    }

    /**
     * Enumeration of all parameters for contactGetExpoContacts.
     */
    export enum Parameters {
      /**
       * The expo identifier.
       */
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of contactGetExpoContacts
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ContactGetExpoContacts.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for contactPostContact.
 */
export namespace ContactPostContact {
    /**
     * Parameter map for contactPostContact.
     */
    export interface PartialParamMap {
      exhibitorId: string;
      ContactDto: ContactDto;
    }

    /**
     * Enumeration of all parameters for contactPostContact.
     */
    export enum Parameters {
      exhibitorId = 'exhibitorId',
      ContactDto = 'ContactDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of contactPostContact
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ContactPostContact.PartialParamMap]?: [string, ValidatorFn][]} = {
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for contactPostExpoContact.
 */
export namespace ContactPostExpoContact {
    /**
     * Parameter map for contactPostExpoContact.
     */
    export interface PartialParamMap {
      expoId: string;
      ContactDto: ContactDto;
    }

    /**
     * Enumeration of all parameters for contactPostExpoContact.
     */
    export enum Parameters {
      expoId = 'expoId',
      ContactDto = 'ContactDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of contactPostExpoContact
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ContactPostExpoContact.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for contactSearchContact.
 */
export namespace ContactSearchContact {
    /**
     * Parameter map for contactSearchContact.
     */
    export interface PartialParamMap {
      email: string;
    }

    /**
     * Enumeration of all parameters for contactSearchContact.
     */
    export enum Parameters {
      email = 'email'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of contactSearchContact
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ContactSearchContact.PartialParamMap]?: [string, ValidatorFn][]} = {
      email: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class ContactService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   * Contacts the sort. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public contactContactSortByMap(
    map: ContactContactSort.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ContactDto>>;
  public contactContactSortByMap(
    map: ContactContactSort.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ContactDto>>>;
  public contactContactSortByMap(
    map: ContactContactSort.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ContactDto>>>;
  public contactContactSortByMap(
    map: ContactContactSort.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.contactContactSort(
      map.SortUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Contacts the sort.
     * 
     * @param SortUpdateDto The sort update.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public contactContactSort(SortUpdateDto: SortUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ContactDto>>;
    public contactContactSort(SortUpdateDto: SortUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ContactDto>>>;
    public contactContactSort(SortUpdateDto: SortUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ContactDto>>>;
    public contactContactSort(SortUpdateDto: SortUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (SortUpdateDto === null || SortUpdateDto === undefined) {
            throw new Error('Required parameter SortUpdateDto was null or undefined when calling contactContactSort.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<Array<ContactDto>>(`${this.configuration.basePath}/api/contacts/contactsort`,
            SortUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Contact_ContactSort']) {
                this.cancelMap['Contact_ContactSort'].next();
            }
            this.cancelMap['Contact_ContactSort'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Contact_ContactSort']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Contact_ContactSort']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Contact_ContactSort', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Contact_ContactSort']) {
            this.cancelMap['Contact_ContactSort'].complete();
            delete this.cancelMap['Contact_ContactSort'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Contact_ContactSort',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Contact_ContactSort')));
        }
        return handle;
    }


  /**
   * Delete an exhibitor contact role by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public contactDeleteContactByMap(
    map: ContactDeleteContact.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public contactDeleteContactByMap(
    map: ContactDeleteContact.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public contactDeleteContactByMap(
    map: ContactDeleteContact.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public contactDeleteContactByMap(
    map: ContactDeleteContact.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.contactDeleteContact(
      map.exhibitorId,
      map.contactId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Delete an exhibitor contact role
     * 
     * @param exhibitorId 
     * @param contactId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public contactDeleteContact(exhibitorId: string, contactId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public contactDeleteContact(exhibitorId: string, contactId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public contactDeleteContact(exhibitorId: string, contactId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public contactDeleteContact(exhibitorId: string, contactId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling contactDeleteContact.');
        }
        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling contactDeleteContact.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<boolean>(`${this.configuration.basePath}/api/contacts/${encodeURIComponent(String(exhibitorId))}/${encodeURIComponent(String(contactId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Contact_DeleteContact']) {
                this.cancelMap['Contact_DeleteContact'].next();
            }
            this.cancelMap['Contact_DeleteContact'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Contact_DeleteContact']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Contact_DeleteContact']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Contact_DeleteContact', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Contact_DeleteContact']) {
            this.cancelMap['Contact_DeleteContact'].complete();
            delete this.cancelMap['Contact_DeleteContact'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Contact_DeleteContact',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Contact_DeleteContact')));
        }
        return handle;
    }


  /**
   * Delete an expo contact role by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public contactDeleteExpoContactByMap(
    map: ContactDeleteExpoContact.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public contactDeleteExpoContactByMap(
    map: ContactDeleteExpoContact.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public contactDeleteExpoContactByMap(
    map: ContactDeleteExpoContact.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public contactDeleteExpoContactByMap(
    map: ContactDeleteExpoContact.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.contactDeleteExpoContact(
      map.expoId,
      map.contactId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Delete an expo contact role
     * 
     * @param expoId 
     * @param contactId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public contactDeleteExpoContact(expoId: string, contactId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public contactDeleteExpoContact(expoId: string, contactId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public contactDeleteExpoContact(expoId: string, contactId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public contactDeleteExpoContact(expoId: string, contactId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling contactDeleteExpoContact.');
        }
        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling contactDeleteExpoContact.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<boolean>(`${this.configuration.basePath}/api/contacts/expo/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(contactId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Contact_DeleteExpoContact']) {
                this.cancelMap['Contact_DeleteExpoContact'].next();
            }
            this.cancelMap['Contact_DeleteExpoContact'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Contact_DeleteExpoContact']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Contact_DeleteExpoContact']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Contact_DeleteExpoContact', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Contact_DeleteExpoContact']) {
            this.cancelMap['Contact_DeleteExpoContact'].complete();
            delete this.cancelMap['Contact_DeleteExpoContact'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Contact_DeleteExpoContact',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Contact_DeleteExpoContact')));
        }
        return handle;
    }


  /**
   * Gets the contacts. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public contactGetContactsByMap(
    map: ContactGetContacts.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ContactDto>>;
  public contactGetContactsByMap(
    map: ContactGetContacts.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ContactDto>>>;
  public contactGetContactsByMap(
    map: ContactGetContacts.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ContactDto>>>;
  public contactGetContactsByMap(
    map: ContactGetContacts.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.contactGetContacts(
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the contacts.
     * 
     * @param exhibitorId The exhibitor identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public contactGetContacts(exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ContactDto>>;
    public contactGetContacts(exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ContactDto>>>;
    public contactGetContacts(exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ContactDto>>>;
    public contactGetContacts(exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling contactGetContacts.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ContactDto>>(`${this.configuration.basePath}/api/contacts/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Contact_GetContacts']) {
                this.cancelMap['Contact_GetContacts'].next();
            }
            this.cancelMap['Contact_GetContacts'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Contact_GetContacts']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Contact_GetContacts']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Contact_GetContacts', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Contact_GetContacts']) {
            this.cancelMap['Contact_GetContacts'].complete();
            delete this.cancelMap['Contact_GetContacts'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Contact_GetContacts',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Contact_GetContacts')));
        }
        return handle;
    }


  /**
   * Gets the contacts. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public contactGetExpoContactsByMap(
    map: ContactGetExpoContacts.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ContactDto>>;
  public contactGetExpoContactsByMap(
    map: ContactGetExpoContacts.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ContactDto>>>;
  public contactGetExpoContactsByMap(
    map: ContactGetExpoContacts.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ContactDto>>>;
  public contactGetExpoContactsByMap(
    map: ContactGetExpoContacts.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.contactGetExpoContacts(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the contacts.
     * 
     * @param expoId The expo identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public contactGetExpoContacts(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ContactDto>>;
    public contactGetExpoContacts(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ContactDto>>>;
    public contactGetExpoContacts(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ContactDto>>>;
    public contactGetExpoContacts(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling contactGetExpoContacts.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ContactDto>>(`${this.configuration.basePath}/api/contacts/expo/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Contact_GetExpoContacts']) {
                this.cancelMap['Contact_GetExpoContacts'].next();
            }
            this.cancelMap['Contact_GetExpoContacts'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Contact_GetExpoContacts']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Contact_GetExpoContacts']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Contact_GetExpoContacts', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Contact_GetExpoContacts']) {
            this.cancelMap['Contact_GetExpoContacts'].complete();
            delete this.cancelMap['Contact_GetExpoContacts'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Contact_GetExpoContacts',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Contact_GetExpoContacts')));
        }
        return handle;
    }


  /**
   * Add an exhibitor contact by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public contactPostContactByMap(
    map: ContactPostContact.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ContactDto>;
  public contactPostContactByMap(
    map: ContactPostContact.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ContactDto>>;
  public contactPostContactByMap(
    map: ContactPostContact.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ContactDto>>;
  public contactPostContactByMap(
    map: ContactPostContact.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.contactPostContact(
      map.exhibitorId,
      map.ContactDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Add an exhibitor contact
     * 
     * @param exhibitorId 
     * @param ContactDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public contactPostContact(exhibitorId: string, ContactDto: ContactDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ContactDto>;
    public contactPostContact(exhibitorId: string, ContactDto: ContactDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ContactDto>>;
    public contactPostContact(exhibitorId: string, ContactDto: ContactDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ContactDto>>;
    public contactPostContact(exhibitorId: string, ContactDto: ContactDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling contactPostContact.');
        }
        if (ContactDto === null || ContactDto === undefined) {
            throw new Error('Required parameter ContactDto was null or undefined when calling contactPostContact.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<ContactDto>(`${this.configuration.basePath}/api/contacts/${encodeURIComponent(String(exhibitorId))}`,
            ContactDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Contact_PostContact']) {
                this.cancelMap['Contact_PostContact'].next();
            }
            this.cancelMap['Contact_PostContact'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Contact_PostContact']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Contact_PostContact']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Contact_PostContact', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Contact_PostContact']) {
            this.cancelMap['Contact_PostContact'].complete();
            delete this.cancelMap['Contact_PostContact'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Contact_PostContact',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Contact_PostContact')));
        }
        return handle;
    }


  /**
   * Add an expo contact by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public contactPostExpoContactByMap(
    map: ContactPostExpoContact.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ContactDto>;
  public contactPostExpoContactByMap(
    map: ContactPostExpoContact.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ContactDto>>;
  public contactPostExpoContactByMap(
    map: ContactPostExpoContact.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ContactDto>>;
  public contactPostExpoContactByMap(
    map: ContactPostExpoContact.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.contactPostExpoContact(
      map.expoId,
      map.ContactDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Add an expo contact
     * 
     * @param expoId 
     * @param ContactDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public contactPostExpoContact(expoId: string, ContactDto: ContactDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ContactDto>;
    public contactPostExpoContact(expoId: string, ContactDto: ContactDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ContactDto>>;
    public contactPostExpoContact(expoId: string, ContactDto: ContactDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ContactDto>>;
    public contactPostExpoContact(expoId: string, ContactDto: ContactDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling contactPostExpoContact.');
        }
        if (ContactDto === null || ContactDto === undefined) {
            throw new Error('Required parameter ContactDto was null or undefined when calling contactPostExpoContact.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<ContactDto>(`${this.configuration.basePath}/api/contacts/expo/${encodeURIComponent(String(expoId))}`,
            ContactDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Contact_PostExpoContact']) {
                this.cancelMap['Contact_PostExpoContact'].next();
            }
            this.cancelMap['Contact_PostExpoContact'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Contact_PostExpoContact']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Contact_PostExpoContact']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Contact_PostExpoContact', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Contact_PostExpoContact']) {
            this.cancelMap['Contact_PostExpoContact'].complete();
            delete this.cancelMap['Contact_PostExpoContact'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Contact_PostExpoContact',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Contact_PostExpoContact')));
        }
        return handle;
    }


  /**
   * Search for the contact with the given email address by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public contactSearchContactByMap(
    map: ContactSearchContact.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<UserListDto>;
  public contactSearchContactByMap(
    map: ContactSearchContact.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<UserListDto>>;
  public contactSearchContactByMap(
    map: ContactSearchContact.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<UserListDto>>;
  public contactSearchContactByMap(
    map: ContactSearchContact.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.contactSearchContact(
      map.email,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Search for the contact with the given email address
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public contactSearchContact(email: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<UserListDto>;
    public contactSearchContact(email: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<UserListDto>>;
    public contactSearchContact(email: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<UserListDto>>;
    public contactSearchContact(email: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling contactSearchContact.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<UserListDto>(`${this.configuration.basePath}/api/contacts/search/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Contact_SearchContact']) {
                this.cancelMap['Contact_SearchContact'].next();
            }
            this.cancelMap['Contact_SearchContact'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Contact_SearchContact']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Contact_SearchContact']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Contact_SearchContact', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Contact_SearchContact']) {
            this.cancelMap['Contact_SearchContact'].complete();
            delete this.cancelMap['Contact_SearchContact'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Contact_SearchContact',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Contact_SearchContact')));
        }
        return handle;
    }

  }

export namespace ContactService {
  export enum Operations {
    Contact_ContactSort = 'Contact_ContactSort',
    Contact_DeleteContact = 'Contact_DeleteContact',
    Contact_DeleteExpoContact = 'Contact_DeleteExpoContact',
    Contact_GetContacts = 'Contact_GetContacts',
    Contact_GetExpoContacts = 'Contact_GetExpoContacts',
    Contact_PostContact = 'Contact_PostContact',
    Contact_PostExpoContact = 'Contact_PostExpoContact',
    Contact_SearchContact = 'Contact_SearchContact'
  }
}
