/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ApplicationUser } from './applicationUser';
import { Expo } from './expo';
import { CalendarBookedSlot } from './calendarBookedSlot';


export interface CalendarAvailability { 
    rowId?: number;
    expoId?: string;
    expo?: Expo;
    userId?: string;
    user?: ApplicationUser;
    start?: Date;
    end?: Date;
    interval?: number;
    comment?: string;
    active?: boolean;
    booked?: Array<CalendarBookedSlot>;
    deleted?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarAvailability.
 */
export namespace CalendarAvailability {
    /**
     * All properties of CalendarAvailability.
     */
    export enum Properties {
        rowId = 'rowId',
        expoId = 'expoId',
        expo = 'expo',
        userId = 'userId',
        user = 'user',
        start = 'start',
        end = 'end',
        interval = 'interval',
        comment = 'comment',
        active = 'active',
        booked = 'booked',
        deleted = 'deleted'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarAvailability.
    */
    export const ModelValidators: {[K in keyof CalendarAvailability]: [string, ValidatorFn][]} = {
        rowId: [
        ],
        expoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        expo: [
        ],
        userId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        user: [
        ],
        start: [
        ],
        end: [
        ],
        interval: [
        ],
        comment: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(5000)],
        ],
        active: [
        ],
        booked: [
        ],
        deleted: [
        ],
    };

    /**
    * The FormControlFactory for CalendarAvailability.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarAvailability> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarAvailability.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarAvailability = {
            rowId: null,
            expoId: null,
            expo: null,
            userId: null,
            user: null,
            start: null,
            end: null,
            interval: null,
            comment: null,
            active: null,
            booked: null,
            deleted: null,
          }
        ) {
            super(model, CalendarAvailability.ModelValidators);
        }
    }

}


