import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CalendarService,
  CalendarEntryStageDto,
  ExhibitorReferenceDto,
  ExhibitorService,
  ExpoListDto,
  NotificationMessageDto,
  StageDto,
  StageService,
  StreamUpdateDto,
  InventoryItemType,
  StageMode,
  FeaturedMode,
  DateMode,
  StreamType
} from '../../../virtual-expo-api';
import {NgbDateStruct, NgbModal, NgbModalRef, NgbTimeStruct, NgbDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {KeyValue} from '@angular/common';
import {CalendarSocialComponent} from '../calendar-edit-social/calendar-edit-social.component';
import {ToasterService} from '../../../core/toaster.service';
import {ConfirmationDialogService} from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import {InputBoxComponent} from '../input-box/input-box.component';
import {LinkHandlerService} from '../../../core/link-handler.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {NgAddToCalendarService} from '../../../modules/ngAddToCalendar/service/ng-add-to-calendar.service';
import {CalendarTypeEnum} from '../../../modules/ngAddToCalendar/model/calendar-type.enum';
import {ICalendarEvent} from '@trademe/ng-add-to-calendar';
import {TypedFormGroup} from '../../../virtual-expo-api/formgroup';

const moment = require('moment-timezone');

@Component({
  selector: 'app-cal-stage-edit',
  templateUrl: './calendar-edit.component.html',
  styleUrls: ['./calendar-edit.component.scss']
})
export class CalendarStageEditComponent implements OnInit {
  @Input() stage: StageDto;
  @Input() expo: ExpoListDto;
  @Input() stages: Array<StageDto>;
  @Output() calendarEdit: EventEmitter<CalendarEntryStageDto> = new EventEmitter<CalendarEntryStageDto>();
  @Output() calendarDelete: EventEmitter<CalendarEntryStageDto> = new EventEmitter<CalendarEntryStageDto>();
  @Output() stageUpdate: EventEmitter<StageDto> = new EventEmitter<StageDto>();

  calendarItem: CalendarEntryStageDto;

  modalRef: NgbModalRef;
  valForm: TypedFormGroup<CalendarEntryStageDto>;
  errorMessage: any = '';

  startDate: NgbDateStruct;
  startTime: NgbTimeStruct;

  properties = CalendarEntryStageDto.Properties;
  errorMap = {
    'prefix.value.required': 'Value is required',
  };
  exhibitors: Array<ExhibitorReferenceDto>;
  selectedExhibitor: ExhibitorReferenceDto;
  saving: boolean;
  loaded: boolean;

  stageLink: string;
  downloadICal: SafeUrl;
  downloadGoogle: SafeUrl;
  downloadOutlook: SafeUrl;
  downloadOutlookLive: SafeUrl;
  currentStage: StageDto;
  isLoaded = false;

  dateMode: boolean;
  featured: boolean;
  availableExhibitors: Array<ExhibitorReferenceDto>;

  constructor(
    private sanitizer: DomSanitizer
    , private addToCalendarService: NgAddToCalendarService
    , private calendarService: CalendarService
    , private alertService: ToasterService
    , private modalService: NgbModal
    , private confirmationDialogService: ConfirmationDialogService
    , private exhibitorService: ExhibitorService
    , private stageService: StageService
    , private linkHandlerService: LinkHandlerService
  ) {
  }

  ngOnInit() {
    if (!this.exhibitors) {
      this.exhibitorService.exhibitorGetExhibitorsByExpo(this.expo.id)
        .subscribe(value => {
          this.exhibitors = new Array<ExhibitorReferenceDto>();
          value.forEach(value1 => {
            if (!value1.hidden) {
              this.exhibitors.push(value1);
            }
          });
          this.initForm();
        });
    } else {
      this.initForm();
    }
  }

  initForm() {
    this.stageLink = this.linkHandlerService.construct('stage', this.expo, this.stage);

    this.stages.sort((a, b) => a.sequence - b.sequence);

    let startDate = new Date();
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours() + 1, 0, 0);
    const emptyItem = {
      stageId: this.stages[0].id,
      duration: 15,
      start: startDate,
      label: '',
      description: '',
      useProfile: false,
      streamType: StreamType.NUMBER_0,
      stageMode: StageMode.NUMBER_0,
      vodType: InventoryItemType.NUMBER_0,
      featured: FeaturedMode.NUMBER_0,
      dateMode: DateMode.NUMBER_0
    };

    if (!this.calendarItem) {
      this.calendarItem = emptyItem;
    } else {
      this.calendarItem = {...emptyItem, ...this.calendarItem};
    }

    const dt = moment.tz(this.calendarItem.start, this.expo.timeZone).toDate();

    this.startDate = {
      day: dt.getDate(),
      month: dt.getMonth() + 1,
      year: dt.getFullYear()
    };

    this.startTime = {
      hour: dt.getHours(),
      minute: dt.getMinutes(),
      second: 0
    };

    if (!this.calendarItem.exhibitors) {
      this.calendarItem.exhibitors = new Array<ExhibitorReferenceDto>();
    }

    const factory = new CalendarEntryStageDto.FormControlFactory(this.calendarItem);
    this.valForm = new TypedFormGroup<CalendarEntryStageDto>({
        label: factory.createFormControl<string>('label'),
        description: factory.createFormControl<string>('description'),
        speakerEmail: factory.createFormControl<string>('speakerEmail'),
        duration: factory.createFormControl<number>('duration'),
        start: factory.createFormControl<Date>('start'),
        stageId: factory.createFormControl<string>('stageId'),
        useProfile: factory.createFormControl<string>('useProfile'),
        speakerName: factory.createFormControl<string>('speakerName'),
        speakerCompany: factory.createFormControl<string>('speakerCompany'),
        speakerDescription: factory.createFormControl<string>('speakerDescription'),
        speakerImage: factory.createFormControl<string>('speakerImage'),
        speakerRole: factory.createFormControl<string>('speakerRole'),
        streamType: factory.createFormControl<number>('streamType'),
        stream: factory.createFormControl<number>('stream'),
        streamAdditional: factory.createFormControl<number>('streamAdditional'),
        streamAdditional2: factory.createFormControl<number>('streamAdditional2'),
        showSpeaker: factory.createFormControl<boolean>('showSpeaker'),
        hidden: factory.createFormControl<boolean>('hidden'),
        stageMode: factory.createFormControl<number>('stageMode'),
        exhibitors: factory.createFormControl('exhibitors'),
        vodType: factory.createFormControl('vodType'),
        vodSource: factory.createFormControl('vodSource'),
        vodSourceAdditional: factory.createFormControl('vodSourceAdditional'),
        vodComment: factory.createFormControl('vodComment'),
        featured: factory.createFormControl('featured'),
        dateMode: factory.createFormControl('dateMode'),
      },
      {updateOn: 'blur'});

    this.featured = this.calendarItem.featured > 0;
    this.dateMode = this.calendarItem.dateMode > 0;

    if (this.calendarItem && this.calendarItem.id > 0) {
      const newEvent: ICalendarEvent = {
        // Event title
        title: this.calendarItem.label,
        // Event start date
        start: new Date(this.calendarItem.start),
        // Event duration (IN MINUTES)
        duration: this.calendarItem.duration,
        // If an end time is set, this will take precedence over duration (optional)
        // end: new Date('June 15, 2013 23:00'),
        // Event Address (optional)
        // address: this.getStageLink(this.entry),
        // Event Description (optional)
        description: this.calendarItem.description,
        url: this.getStageLink(this.calendarItem),
      };

      this.downloadICal = this.sanitizer.bypassSecurityTrustUrl(
        this.addToCalendarService.getHrefFor(CalendarTypeEnum.iCalendar, newEvent)
      );
      this.downloadGoogle = this.sanitizer.bypassSecurityTrustUrl(
        this.addToCalendarService.getHrefFor(CalendarTypeEnum.google, newEvent)
      );
      this.downloadOutlook = this.sanitizer.bypassSecurityTrustUrl(
        this.addToCalendarService.getHrefFor(CalendarTypeEnum.outlook, newEvent)
      );
      this.downloadOutlookLive = this.sanitizer.bypassSecurityTrustUrl(
        this.addToCalendarService.getHrefFor(CalendarTypeEnum.outlookLive, newEvent)
      );
    }

    this.currentStage = null;
    this.stages.forEach(value => {
      if (value.id === this.calendarItem.stageId) {
        this.currentStage = value;
      }
    });

    this.reSequence();

    this.valForm.reset(this.calendarItem);

    this.isLoaded = true;
  }

  private getStageLink(entry: CalendarEntryStageDto): string {
    const link = `/stage/${this.linkHandlerService.prepare(this.expo.name, this.expo.shortKey)}/${this.linkHandlerService.prepare(entry.stageName, entry.stageShortKey)}/${entry.id}`;
    return link;
  }

  submitForm($ev: MouseEvent) {
    $ev.preventDefault();
    this.saving = true;

    for (const c in this.valForm.controls) {
      if (c) {
        this.valForm.controls[c].markAsTouched();
      }
    }

    if (this.valForm.valid) {
      const saveData: CalendarEntryStageDto = {...this.calendarItem, ...this.valForm.value};

      saveData.hidden = saveData.hidden || false;
      saveData.showSpeaker = saveData.showSpeaker || false;

      if (saveData.stream) {
        saveData.stream = saveData.stream.replace(/ /g, '');
      }

      if (saveData.streamAdditional) {
        saveData.streamAdditional = saveData.streamAdditional.replace(/ /g, '');
      }

      if (saveData.streamAdditional2) {
        saveData.streamAdditional2 = saveData.streamAdditional2.replace(/ /g, '');
      }

      if (saveData.vodSource) {
        saveData.vodSource = saveData.vodSource.replace(/ /g, '');
      }

      if (saveData.vodSourceAdditional) {
        saveData.vodSourceAdditional = saveData.vodSourceAdditional.replace(/ /g, '');
      }

      saveData.start = moment.tz({
        year: this.startDate.year,
        month: this.startDate.month - 1,
        day: this.startDate.day,
        hour: this.startTime.hour,
        minute: this.startTime.minute
      }, this.expo.timeZone).toDate();

      this.calendarService.calendarPost(saveData)
        .subscribe(value => {
          this.saving = false;
          this.alertService.saved();
          this.calendarEdit.emit(value);
          this.modalRef.close();
        }, error => {
          this.saving = false;
          this.errorMessage = error;
        });
    } else {
      this.saving = false;
      this.errorMessage = this.valForm.errors;
    }
  }

  cancelEditor($event) {
    $event.preventDefault();
    this.modalRef.close();
  }

  onImageAdded($event: string) {
    this.calendarItem.logoRaw = $event;
  }

  onBackgroundAdded($event: string) {
    this.calendarItem.background = $event;
  }

  onSpeakerImageAdded($event: string) {
    this.calendarItem.speakerImageRaw = $event;
  }

  addProfile($event: MouseEvent) {
    $event.preventDefault();

    this.modalRef = this.modalService.open(CalendarSocialComponent);
    const modalComponent = this.modalRef.componentInstance as CalendarSocialComponent;
    modalComponent.modalRef = this.modalRef;
    modalComponent.network = '';
    modalComponent.value = '';
    modalComponent.networkUpdated.subscribe((data: { oldNetwork: string, network: string, value: string }) => {
      if (data.oldNetwork && data.oldNetwork !== '' && data.oldNetwork !== data.network) {
        delete this.calendarItem.speakerSocialProfiles[data.oldNetwork];
      }
      this.calendarItem.speakerSocialProfiles[data.network] = data.value;
    });
  }

  editSocial($event: MouseEvent, item: KeyValue<string, string>) {
    $event.preventDefault();

    this.modalRef = this.modalService.open(CalendarSocialComponent);
    const modalComponent = this.modalRef.componentInstance as CalendarSocialComponent;
    modalComponent.modalRef = this.modalRef;
    modalComponent.network = item.key;
    modalComponent.value = item.value;
    modalComponent.networkUpdated.subscribe((data: { oldNetwork: string, network: string, value: string }) => {
      if (data.oldNetwork && data.oldNetwork !== '' && data.oldNetwork !== data.network) {
        delete this.calendarItem.speakerSocialProfiles[data.oldNetwork];
      }
      this.calendarItem.speakerSocialProfiles[data.network] = data.value;
    });
  }

  removeSocial($event: MouseEvent, item: KeyValue<string, string>) {
    $event.preventDefault();

    this.confirmationDialogService.confirm('Delete social', 'Really delete social network info?')
      .then(value => {
        if (value) {
          delete this.calendarItem.speakerSocialProfiles[item.key];
        }
      }, () => {
      });
  }

  deleteEntry($event: MouseEvent) {
    $event.preventDefault();

    this.confirmationDialogService.confirm('Delete entry', 'Really delete this calendar entry?')
      .then(value => {
        if (value) {
          this.calendarService.calendarDelete(this.calendarItem.id)
            .subscribe(() => {
              this.alertService.deleted();
              this.calendarDelete.emit(this.calendarItem);
              this.modalRef.close();
            }, error => {
              this.errorMessage = error;
            });
        }
      }, () => {
      });
  }

  reSequence() {
    let seq = 0;

    this.availableExhibitors = new Array<ExhibitorReferenceDto>();

    const exist: Array<string> = new Array<string>();
    this.calendarItem.exhibitors.forEach((value) => {
      value.sequence = ++seq;
      exist.push(value.id);
    });

    this.exhibitors.forEach(value => {
      if (exist.indexOf(value.id) < 0) {
        this.availableExhibitors.push(value);
      }
    });
  }

  removeExhibitor($event: MouseEvent, exhi: ExhibitorReferenceDto) {
    $event.preventDefault();
    const index = this.calendarItem.exhibitors.indexOf(exhi);
    if (index >= 0) {
      this.calendarItem.exhibitors.splice(index, 1);

      this.reSequence();
    }
  }

  addExhibitor($event: MouseEvent, selectedExhibitor: ExhibitorReferenceDto) {
    $event.preventDefault();

    if (!this.calendarItem.exhibitors) {
      this.calendarItem.exhibitors = new Array<ExhibitorReferenceDto>();
    }

    this.calendarItem.exhibitors.push(selectedExhibitor);

    this.reSequence();

    if (this.availableExhibitors.length > 0) {
      this.selectedExhibitor = this.availableExhibitors[0];
    } else {
      this.selectedExhibitor = null;
    }
  }

  sortUpExhibitor($event: MouseEvent, exhibitor: ExhibitorReferenceDto) {
    $event.preventDefault();
    const index = this.calendarItem.exhibitors.indexOf(exhibitor);
    this.calendarItem.exhibitors.splice(index, 1);
    this.calendarItem.exhibitors.splice(index - 1, 0, exhibitor);
    this.reSequence();
  }

  sortDownExhibitor($event: MouseEvent, exhibitor: ExhibitorReferenceDto) {
    $event.preventDefault();
    const index = this.calendarItem.exhibitors.indexOf(exhibitor);
    this.calendarItem.exhibitors.splice(index, 1);
    this.calendarItem.exhibitors.splice(index + 1, 0, exhibitor);
    this.reSequence();
  }

  sendAnnouncement($event: MouseEvent) {
    $event.preventDefault();

    this.stageService.stageNotify(this.calendarItem.id)
      .subscribe(() => {
        this.alertService.success('Announcement sent');
      });
  }

  sendCustomAnnouncement($event: MouseEvent) {
    $event.preventDefault();

    const modalRef = this.modalService.open(InputBoxComponent);
    const modalComponent = modalRef.componentInstance as InputBoxComponent;
    modalComponent.modalRef = modalRef;
    // const instance = <InputBoxComponent>modalRef.componentInstance;
    modalRef.result.then((result) => {
      const saveData: NotificationMessageDto = {
        entryId: this.calendarItem.id,
        message: result
      };
      this.stageService.stageNotifyMessage(saveData)
        .subscribe(() => {
          this.alertService.success('Announcement sent');
        });
    }, () => {
    });
  }

  activeEvent($event: MouseEvent) {
    $event.preventDefault();

    this.stageService.stageActivate(this.calendarItem.id)
      .subscribe(() => {
        this.stage.current = this.calendarItem.id;
        this.stageUpdate.emit(this.stage);
        this.alertService.success('Activated');
      });
  }

  deactivateEvent($event: MouseEvent) {
    $event.preventDefault();

    this.stageService.stageDeactivate(this.calendarItem.stageId)
      .subscribe(() => {
        this.stage.current = null;
        this.stageUpdate.emit(this.stage);
        this.alertService.warn('Deactivated');
      });
  }

  changeCurrentStage($event: Event) {
    this.currentStage = null;
    this.stages.forEach(value => {
      if (value.id === ($event.currentTarget as HTMLInputElement).value) {
        this.currentStage = value;
      }
    });
  }

  updateEvent($event: MouseEvent) {
    $event.preventDefault();

    const streamData: StreamUpdateDto = this.valForm.value;

    this.stageService.stageUpdateStream(this.calendarItem.id, streamData)
      .subscribe(() => {
        this.stage.current = this.calendarItem.id;
        this.stageUpdate.emit(this.stage);
        this.alertService.success('Activated');
      });
  }

  changeDateMode($event: boolean) {
    this.valForm.get('dateMode').setValue($event ? 1 : 0);
  }

  changeFeatured($event: boolean) {
    this.valForm.get('featured').setValue($event ? 1 : 0);
  }
}
