<div>
  <label [for]="fieldName" *ngIf="label && label.trim() !== ''">{{ label }}
    <app-help *ngIf="help && help.trim() !== ''"
              [details]="help"></app-help>
  </label>
  <input [(colorPicker)]="dataSource[fieldName]" [style.background]="dataSource[fieldName]"
         [cpOKButton]="true" [cpCancelButton]="true" cpOKButtonClass="btn btn-success"
         [cpSaveClickOutside]="false" [cpCloseClickOutside]="true" [cpAlphaChannel]='alphaChannel'
         (colorPickerChange)="updateFrame($event)" cpPosition="bottom" cpDialogDisplay="popup"
         cpOutputFormat='hex'
         [id]="fieldName" [name]="[fieldName]"/>
  <app-help *ngIf="(!label || label.trim() === '') && (help && help.trim() !== '')"
            [details]="help"></app-help>
</div>
