/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface IntegrationStartDto { 
    id?: string;
    expoId?: string;
    exhibitorId?: string;
    server?: string;
}

/**
 * Namespace for property- and property-value-enumerations of IntegrationStartDto.
 */
export namespace IntegrationStartDto {
    /**
     * All properties of IntegrationStartDto.
     */
    export enum Properties {
        id = 'id',
        expoId = 'expoId',
        exhibitorId = 'exhibitorId',
        server = 'server'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of IntegrationStartDto.
    */
    export const ModelValidators: {[K in keyof IntegrationStartDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        expoId: [
        ],
        exhibitorId: [
        ],
        server: [
        ],
    };

    /**
    * The FormControlFactory for IntegrationStartDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<IntegrationStartDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for IntegrationStartDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: IntegrationStartDto = {
            id: null,
            expoId: null,
            exhibitorId: null,
            server: null,
          }
        ) {
            super(model, IntegrationStartDto.ModelValidators);
        }
    }

}


