/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { SchedulingService } from './schedulingService';
import { ExpoListDto } from './expoListDto';


export interface ExhibitorDto { 
    id?: string;
    ownerId?: string;
    name: string;
    company?: string;
    label?: string;
    description?: string;
    descriptionLong?: string;
    category?: number;
    managerId?: string;
    managerName?: string;
    managerChangeEmail?: string;
    website?: string;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    backgroundImage?: string;
    sequence?: number;
    schedulingService?: SchedulingService;
    schedulingKey?: string;
    schedulingActive?: boolean;
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    chatWelcomeMessage?: string;
    chatEnabled?: boolean;
    hidden?: boolean;
    expos?: Array<ExpoListDto>;
    shortLabel?: string;
    exhibitorGroupId?: number;
    tags?: Array<string>;
}

/**
 * Namespace for property- and property-value-enumerations of ExhibitorDto.
 */
export namespace ExhibitorDto {
    /**
     * All properties of ExhibitorDto.
     */
    export enum Properties {
        id = 'id',
        ownerId = 'ownerId',
        name = 'name',
        company = 'company',
        label = 'label',
        description = 'description',
        descriptionLong = 'descriptionLong',
        category = 'category',
        managerId = 'managerId',
        managerName = 'managerName',
        managerChangeEmail = 'managerChangeEmail',
        website = 'website',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        backgroundImage = 'backgroundImage',
        sequence = 'sequence',
        schedulingService = 'schedulingService',
        schedulingKey = 'schedulingKey',
        schedulingActive = 'schedulingActive',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        chatWelcomeMessage = 'chatWelcomeMessage',
        chatEnabled = 'chatEnabled',
        hidden = 'hidden',
        expos = 'expos',
        shortLabel = 'shortLabel',
        exhibitorGroupId = 'exhibitorGroupId',
        tags = 'tags'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExhibitorDto.
    */
    export const ModelValidators: {[K in keyof ExhibitorDto]: [string, ValidatorFn][]} = {
        id: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        ownerId: [
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        company: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        label: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        description: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        descriptionLong: [
        ],
        category: [
        ],
        managerId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        managerName: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        managerChangeEmail: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        website: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logo: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        backgroundImage: [
        ],
        sequence: [
        ],
        schedulingService: [
        ],
        schedulingKey: [
        ],
        schedulingActive: [
        ],
        googleAnalyticsId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        matomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        chatWelcomeMessage: [
        ],
        chatEnabled: [
        ],
        hidden: [
        ],
        expos: [
        ],
        shortLabel: [
        ],
        exhibitorGroupId: [
        ],
        tags: [
        ],
    };

    /**
    * The FormControlFactory for ExhibitorDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExhibitorDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExhibitorDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExhibitorDto = {
            id: null,
            ownerId: null,
            name: null,
            company: null,
            label: null,
            description: null,
            descriptionLong: null,
            category: null,
            managerId: null,
            managerName: null,
            managerChangeEmail: null,
            website: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            backgroundImage: null,
            sequence: null,
            schedulingService: null,
            schedulingKey: null,
            schedulingActive: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            chatWelcomeMessage: null,
            chatEnabled: null,
            hidden: null,
            expos: null,
            shortLabel: null,
            exhibitorGroupId: null,
            tags: null,
          }
        ) {
            super(model, ExhibitorDto.ModelValidators);
        }
    }

}


