/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ExpoListDto { 
    id?: string;
    name?: string;
    description?: string;
    descriptionLong?: string;
    dateStart?: Date;
    dateEnd?: Date;
    shortKey?: string;
    timeZone?: string;
    rowVersion?: number;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoListDto.
 */
export namespace ExpoListDto {
    /**
     * All properties of ExpoListDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        description = 'description',
        descriptionLong = 'descriptionLong',
        dateStart = 'dateStart',
        dateEnd = 'dateEnd',
        shortKey = 'shortKey',
        timeZone = 'timeZone',
        rowVersion = 'rowVersion'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoListDto.
    */
    export const ModelValidators: {[K in keyof ExpoListDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
        ],
        description: [
        ],
        descriptionLong: [
        ],
        dateStart: [
        ],
        dateEnd: [
        ],
        shortKey: [
        ],
        timeZone: [
        ],
        rowVersion: [
        ],
    };

    /**
    * The FormControlFactory for ExpoListDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoListDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoListDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoListDto = {
            id: null,
            name: null,
            description: null,
            descriptionLong: null,
            dateStart: null,
            dateEnd: null,
            shortKey: null,
            timeZone: null,
            rowVersion: null,
          }
        ) {
            super(model, ExpoListDto.ModelValidators);
        }
    }

}


