import { Injectable } from '@angular/core';
import {MenuItem} from '../../objects/menu-item';

@Injectable()
export class MenuService {

    menuItems: Array<MenuItem>;

    constructor() {
        this.menuItems = [];
    }

    addMenu(items: Array<MenuItem>) {
        items.forEach((item) => {
            this.menuItems.push(item);
        });
    }

    getMenu() {
        return this.menuItems;
    }

}
