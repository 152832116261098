import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountService } from './api/account.service';
import { BoothService } from './api/booth.service';
import { BoothAvailabilityService } from './api/boothAvailability.service';
import { BoothCalendarService } from './api/boothCalendar.service';
import { CalendarService } from './api/calendar.service';
import { ChatService } from './api/chat.service';
import { ContactService } from './api/contact.service';
import { ContentService } from './api/content.service';
import { ExhibitorService } from './api/exhibitor.service';
import { ExpoService } from './api/expo.service';
import { OauthClientService } from './api/oauthClient.service';
import { PlaceService } from './api/place.service';
import { ReportService } from './api/report.service';
import { StageService } from './api/stage.service';
import { StatisticService } from './api/statistic.service';
import { StorageService } from './api/storage.service';
import { TalkBookingService } from './api/talkBooking.service';
import { TicketService } from './api/ticket.service';
import { VisitorService } from './api/visitor.service';
import { WebService } from './api/web.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountService,
    BoothService,
    BoothAvailabilityService,
    BoothCalendarService,
    CalendarService,
    ChatService,
    ContactService,
    ContentService,
    ExhibitorService,
    ExpoService,
    OauthClientService,
    PlaceService,
    ReportService,
    StageService,
    StatisticService,
    StorageService,
    TalkBookingService,
    TicketService,
    VisitorService,
    WebService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: (...args: any[]) => Configuration, deps?: any[]): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory, deps } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
