/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type SchedulingService = 0 | 10;

export const SchedulingService = {
    NUMBER_0: 0 as SchedulingService,
    NUMBER_10: 10 as SchedulingService
};

