import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {StockMediaDto} from '../../../virtual-expo-api';
import {ImageUrlService} from '../../../core/image-url.service';
import {ConfirmationDialogService} from '../../../shared/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-stock-or-custom-image',
  templateUrl: './stock-or-custom-image.component.html',
  styleUrls: ['./stock-or-custom-image.component.scss']
})
export class StockOrCustomImageComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() imageUrl: string;
  @Input() imageId: string;
  @Input() width: number;
  @Input() height: number;
  @Input() allowClear: boolean;
  @Input() defaultEmpty: boolean;

  @Input() ar: string;

  @Input() stockMedia: Array<StockMediaDto>;

  @Input() tooltip: string;

  @Output() imageUploaded: EventEmitter<string> = new EventEmitter<string>();
  customArt: any;
  originalImage: string;
  stockImage: StockMediaDto;
  selectedStock: StockMediaDto;

  display: { url: string };

  constructor(
    private imageUrlService: ImageUrlService
    , private confirmService: ConfirmationDialogService
  ) {
  }

  ngOnInit(): void {
    this.originalImage = this.imageId;
    this.updateDisplay();

    this.checkCustomArt();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stockMedia && !changes.stockMedia.previousValue) {
      this.checkCustomArt();
    }
  }

  updateDisplay() {
    // debugger;
    if (this.imageId && this.imageId !== '' && this.imageId !== 'DELETED') {
      this.display = {url: this.imageUrlService.getImage(this.imageId)};
    } else {
      this.display = null;
      this.customArt = false;
    }
  }

  checkCustomArt() {
    if (!this.imageId || this.imageId === '' || this.imageId === 'DELETED') {
      if (this.stockMedia && this.stockMedia.length > 0 && !this.defaultEmpty) {
        this.imageId = this.stockMedia[0].filename;
      }
      this.customArt = false;
    } else {
      this.customArt = true;
    }
  }

  onImageUploaded($event: string) {
    if ($event === 'DELETED') {
      this.customArt = false;
      this.display = null;
      this.imageId = 'DELETED';
    }
    this.updateDisplay();
    this.imageUploaded.emit($event);
  }

  changeCustom($event: boolean) {
    this.customArt = $event;

    if (this.customArt) {
      this.imageId = this.originalImage;
      this.imageUploaded.emit(this.originalImage);
    } else {
      this.imageId = '';
      this.imageUploaded.emit('');
    }
    this.updateDisplay();
  }

  updateImage($event: StockMediaDto) {
    this.stockImage = $event;
    // for (let i = 0; i < this.stockMedia.length; i++) {
    //   if (this.stockMedia[i] === this.selectedStock) {
    //     this.stockImage = this.stockMedia[i];
    //     break;
    //   }
    // }
    // debugger;
    if (this.stockImage && this.stockImage.filename !== '') {
      this.imageId = this.stockImage.filename;
      this.imageUploaded.emit(this.imageId);
    } else {
      this.imageId = '';
      this.imageUploaded.emit(this.imageId);
    }
    this.updateDisplay();
  }

  useCustom($event: MouseEvent) {

  }

  clear($event: MouseEvent) {
    $event.preventDefault();
    this.confirmService.confirm('Clear image', 'Really clear image?')
      .then(value => {
        if (value) {
          this.imageId = '';
          this.imageUploaded.emit(this.imageId);
        }
      }, reason => {});
  }
}
