/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { Exhibitor } from './exhibitor';
import { Stage } from './stage';


export interface StageExhibitor { 
    stageId?: string;
    stage?: Stage;
    exhibitorId?: string;
    exhibitor?: Exhibitor;
    sequence?: number;
}

/**
 * Namespace for property- and property-value-enumerations of StageExhibitor.
 */
export namespace StageExhibitor {
    /**
     * All properties of StageExhibitor.
     */
    export enum Properties {
        stageId = 'stageId',
        stage = 'stage',
        exhibitorId = 'exhibitorId',
        exhibitor = 'exhibitor',
        sequence = 'sequence'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of StageExhibitor.
    */
    export const ModelValidators: {[K in keyof StageExhibitor]: [string, ValidatorFn][]} = {
        stageId: [
        ],
        stage: [
        ],
        exhibitorId: [
        ],
        exhibitor: [
        ],
        sequence: [
        ],
    };

    /**
    * The FormControlFactory for StageExhibitor.
    */
    export class FormControlFactory extends BaseFormControlFactory<StageExhibitor> {

        /**
         * Constructor.
         *
         * @param model An existing model for StageExhibitor.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: StageExhibitor = {
            stageId: null,
            stage: null,
            exhibitorId: null,
            exhibitor: null,
            sequence: null,
          }
        ) {
            super(model, StageExhibitor.ModelValidators);
        }
    }

}


