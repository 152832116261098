/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { InventoryItemType } from './inventoryItemType';


export interface InventoryItemDto { 
    type?: string;
    expoId?: string;
    exhibitorId?: string;
    description?: string;
    image?: string;
    inventoryType?: InventoryItemType;
    itemId?: string;
    link?: string;
    source?: string;
}

/**
 * Namespace for property- and property-value-enumerations of InventoryItemDto.
 */
export namespace InventoryItemDto {
    /**
     * All properties of InventoryItemDto.
     */
    export enum Properties {
        type = 'type',
        expoId = 'expoId',
        exhibitorId = 'exhibitorId',
        description = 'description',
        image = 'image',
        inventoryType = 'inventoryType',
        itemId = 'itemId',
        link = 'link',
        source = 'source'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of InventoryItemDto.
    */
    export const ModelValidators: {[K in keyof InventoryItemDto]: [string, ValidatorFn][]} = {
        type: [
        ],
        expoId: [
        ],
        exhibitorId: [
        ],
        description: [
        ],
        image: [
        ],
        inventoryType: [
        ],
        itemId: [
        ],
        link: [
        ],
        source: [
        ],
    };

    /**
    * The FormControlFactory for InventoryItemDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<InventoryItemDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for InventoryItemDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: InventoryItemDto = {
            type: null,
            expoId: null,
            exhibitorId: null,
            description: null,
            image: null,
            inventoryType: null,
            itemId: null,
            link: null,
            source: null,
          }
        ) {
            super(model, InventoryItemDto.ModelValidators);
        }
    }

}


