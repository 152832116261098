import { Component, OnInit } from '@angular/core';
import {MarkdownSampleComponent} from '../markdown-sample/markdown-sample.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-markdown-sample-message',
  templateUrl: './markdown-sample-message.component.html',
  styleUrls: ['./markdown-sample-message.component.scss']
})
export class MarkdownSampleMessageComponent implements OnInit {
  modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  showMarkdownSample($event: MouseEvent) {
    $event.preventDefault();
    this.modalRef = this.modalService.open(MarkdownSampleComponent, {size: 'lg'});
  }
}
