import {Component, OnInit} from '@angular/core';
import {SettingsService} from '../../../core/settings/settings.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../../core/authService/authService';
import {AccountService, PwdLessProfileDto} from '../../../virtual-expo-api';
import {TypedFormGroup} from '../../../virtual-expo-api/formgroup';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  submitted: boolean;
  registered = false;

  profile: PwdLessProfileDto;

  valForm: FormGroup;
  errorMessage: { description: string }[] = [];

  constructor(public settings: SettingsService, fb: FormBuilder, private router: Router, private auth: AuthService
    , private accountService: AccountService
  ) {
  }

  ngOnInit() {
    const formControlFactory = new PwdLessProfileDto.FormControlFactory(this.profile);
    this.valForm = new TypedFormGroup<PwdLessProfileDto>({
      givenName: formControlFactory.createFormControl<string>('givenName'),
      termsAccepted: formControlFactory.createFormControl<Boolean>('termsAccepted')
    });

    const authData = this.auth.getTokenData();

    const nameId = authData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
    const givenName = authData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];

    if (givenName !== nameId) {
      this.valForm.reset({givenName: givenName, termsAccepted: true});
      this.registered = true;
    }
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (!this.valForm.value['termsAccepted']) {
      this.errorMessage.push({description: 'You have to accept our terms of service before using the management system'});
    } else {
      if (this.valForm.valid) {
        this.auth.updatedPwdLessProfile(this.valForm.value)
          .subscribe(value1 => {
            this.submitted = true;
            this.router.navigate(['/']);
          }, error => {
            this.errorMessage = error;
            console.log(error);
          });
      }
    }
  }
}
