<div class="card bg-default pad1">
  <div class="card-header">
    <button class="btn btn-xs btn-danger float-left" (click)="clear($event)" *ngIf="allowClear">Clear</button>
    <span>
      {{ label }}
      <app-help [title]="'Image upload'" [details]="tooltip"></app-help>
    </span>
    <label [for]="instanceId + '_fileupload'" class="btn btn-primary btn-xs float-right">
      Upload file
    </label>
  </div>
  <div [style]="aspectStyle">
    <div>
      <img [src]="imageUrl" *ngIf="imageUrl && imageUrl !== ''" style="max-width: 100%;">
    </div>
  </div>
  <canvas #paintCanvas [id]="instanceId + '_canvas'" width="{{ width }}" height="{{ height }}" style="display: none;">
    If you see this, your browser does not support HTML5 canvas and you need a newer one.
  </canvas>
  <br/>
  <input type="file" [id]="instanceId + '_fileupload'" #fileInput style="display: none" (change)="startRead(this)"
         accept="image/*"/>
</div>
