import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-form-text-input',
  templateUrl: './form-text-input.component.html',
  styleUrls: ['./form-text-input.component.scss']
})
export class FormTextInputComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() fieldName: string;

  @Input() label: string = '';
  @Input() help: string = '';

  @Input() placeholder: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
