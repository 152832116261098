/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ApplicationUser } from './applicationUser';
import { ExhibitorContactMode } from './exhibitorContactMode';
import { SchedulingService } from './schedulingService';
import { ExpoExhibitor } from './expoExhibitor';
import { ExhibitorContact } from './exhibitorContact';


export interface Exhibitor { 
    id: string;
    shortKey?: string;
    owner: ApplicationUser;
    ownerId?: string;
    name: string;
    company?: string;
    phoneNumber?: string;
    address?: string;
    email?: string;
    description?: string;
    descriptionLong?: string;
    socialProfiles?: string;
    label?: string;
    shortLabel?: string;
    showContact?: boolean;
    manager?: ApplicationUser;
    managerId?: string;
    website?: string;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    avatar?: string;
    schedulingService?: SchedulingService;
    schedulingKey?: string;
    schedulingActive?: boolean;
    expos?: Array<ExpoExhibitor>;
    contacts?: Array<ExhibitorContact>;
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    chatWelcomeMessage?: string;
    deleted?: boolean;
    hideAvatar?: boolean;
    groupChatEnabled?: boolean;
    singleChatEnabled?: boolean;
    singleChatWelcomeMessage?: string;
    internalContactMail?: string;
    publicContactMail?: string;
    contactMode?: ExhibitorContactMode;
    tags?: string;
    rowVersion?: number;
    uploadTs?: Date;
}

/**
 * Namespace for property- and property-value-enumerations of Exhibitor.
 */
export namespace Exhibitor {
    /**
     * All properties of Exhibitor.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        owner = 'owner',
        ownerId = 'ownerId',
        name = 'name',
        company = 'company',
        phoneNumber = 'phoneNumber',
        address = 'address',
        email = 'email',
        description = 'description',
        descriptionLong = 'descriptionLong',
        socialProfiles = 'socialProfiles',
        label = 'label',
        shortLabel = 'shortLabel',
        showContact = 'showContact',
        manager = 'manager',
        managerId = 'managerId',
        website = 'website',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        avatar = 'avatar',
        schedulingService = 'schedulingService',
        schedulingKey = 'schedulingKey',
        schedulingActive = 'schedulingActive',
        expos = 'expos',
        contacts = 'contacts',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        chatWelcomeMessage = 'chatWelcomeMessage',
        deleted = 'deleted',
        hideAvatar = 'hideAvatar',
        groupChatEnabled = 'groupChatEnabled',
        singleChatEnabled = 'singleChatEnabled',
        singleChatWelcomeMessage = 'singleChatWelcomeMessage',
        internalContactMail = 'internalContactMail',
        publicContactMail = 'publicContactMail',
        contactMode = 'contactMode',
        tags = 'tags',
        rowVersion = 'rowVersion',
        uploadTs = 'uploadTs'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of Exhibitor.
    */
    export const ModelValidators: {[K in keyof Exhibitor]: [string, ValidatorFn][]} = {
        id: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        shortKey: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(10)],
        ],
        owner: [
                ['required', Validators.required],
        ],
        ownerId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        company: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        phoneNumber: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        address: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        email: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        description: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        descriptionLong: [
        ],
        socialProfiles: [
        ],
        label: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        shortLabel: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        showContact: [
        ],
        manager: [
        ],
        managerId: [
        ],
        website: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logo: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        logoSquare: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logoWide: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        avatar: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        schedulingService: [
        ],
        schedulingKey: [
        ],
        schedulingActive: [
        ],
        expos: [
        ],
        contacts: [
        ],
        googleAnalyticsId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        matomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        chatWelcomeMessage: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        deleted: [
        ],
        hideAvatar: [
        ],
        groupChatEnabled: [
        ],
        singleChatEnabled: [
        ],
        singleChatWelcomeMessage: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        internalContactMail: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        publicContactMail: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        contactMode: [
        ],
        tags: [
        ],
        rowVersion: [
        ],
        uploadTs: [
        ],
    };

    /**
    * The FormControlFactory for Exhibitor.
    */
    export class FormControlFactory extends BaseFormControlFactory<Exhibitor> {

        /**
         * Constructor.
         *
         * @param model An existing model for Exhibitor.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: Exhibitor = {
            id: null,
            shortKey: null,
            owner: null,
            ownerId: null,
            name: null,
            company: null,
            phoneNumber: null,
            address: null,
            email: null,
            description: null,
            descriptionLong: null,
            socialProfiles: null,
            label: null,
            shortLabel: null,
            showContact: null,
            manager: null,
            managerId: null,
            website: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            avatar: null,
            schedulingService: null,
            schedulingKey: null,
            schedulingActive: null,
            expos: null,
            contacts: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            chatWelcomeMessage: null,
            deleted: null,
            hideAvatar: null,
            groupChatEnabled: null,
            singleChatEnabled: null,
            singleChatWelcomeMessage: null,
            internalContactMail: null,
            publicContactMail: null,
            contactMode: null,
            tags: null,
            rowVersion: null,
            uploadTs: null,
          }
        ) {
            super(model, Exhibitor.ModelValidators);
        }
    }

}


