/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ExpoBagDto } from './expoBagDto';
import { VisitorProfileDto } from './visitorProfileDto';


export interface VisitorProfileItem { 
    userProfile?: VisitorProfileDto;
    expos?: Array<ExpoBagDto>;
}

/**
 * Namespace for property- and property-value-enumerations of VisitorProfileItem.
 */
export namespace VisitorProfileItem {
    /**
     * All properties of VisitorProfileItem.
     */
    export enum Properties {
        userProfile = 'userProfile',
        expos = 'expos'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of VisitorProfileItem.
    */
    export const ModelValidators: {[K in keyof VisitorProfileItem]: [string, ValidatorFn][]} = {
        userProfile: [
        ],
        expos: [
        ],
    };

    /**
    * The FormControlFactory for VisitorProfileItem.
    */
    export class FormControlFactory extends BaseFormControlFactory<VisitorProfileItem> {

        /**
         * Constructor.
         *
         * @param model An existing model for VisitorProfileItem.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: VisitorProfileItem = {
            userProfile: null,
            expos: null,
          }
        ) {
            super(model, VisitorProfileItem.ModelValidators);
        }
    }

}


