<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [innerHTML]="message" *ngIf="!getReason">
</div>
<div class="modal-body" *ngIf="getReason">
  <div [innerHTML]="message"></div>
  <input [(ngModel)]="reason" style="width: 100%;">
</div>
<div class="modal-footer" style="justify-content: space-between;">
  <button type="button" class="btn btn-danger" (click)="decline()" *ngIf="btnCancelText && btnCancelText !== ''">{{ btnCancelText }}</button>
  <button type="button" class="btn btn-success" (click)="accept()" *ngIf="btnOkText && btnOkText !== ''">{{ btnOkText }}</button>
</div>
