/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ExhibitorSelfUpdateDto { 
    id?: string;
    name: string;
    company?: string;
    label?: string;
    shortLabel?: string;
    description?: string;
    descriptionLong?: string;
    website?: string;
    displays?: object;
    links?: object;
    panels?: object;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    backgroundImage?: string;
    avatar?: string;
    phoneNumber?: string;
    address?: string;
    email?: string;
    socialProfiles?: { [key: string]: string; };
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    chatWelcomeMessage?: string;
    tags?: Array<string>;
    rowVersion?: number;
}

/**
 * Namespace for property- and property-value-enumerations of ExhibitorSelfUpdateDto.
 */
export namespace ExhibitorSelfUpdateDto {
    /**
     * All properties of ExhibitorSelfUpdateDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        company = 'company',
        label = 'label',
        shortLabel = 'shortLabel',
        description = 'description',
        descriptionLong = 'descriptionLong',
        website = 'website',
        displays = 'displays',
        links = 'links',
        panels = 'panels',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        backgroundImage = 'backgroundImage',
        avatar = 'avatar',
        phoneNumber = 'phoneNumber',
        address = 'address',
        email = 'email',
        socialProfiles = 'socialProfiles',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        chatWelcomeMessage = 'chatWelcomeMessage',
        tags = 'tags',
        rowVersion = 'rowVersion'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExhibitorSelfUpdateDto.
    */
    export const ModelValidators: {[K in keyof ExhibitorSelfUpdateDto]: [string, ValidatorFn][]} = {
        id: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        company: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        label: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        shortLabel: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        description: [
        ],
        descriptionLong: [
        ],
        website: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        displays: [
        ],
        links: [
        ],
        panels: [
        ],
        logo: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        backgroundImage: [
        ],
        avatar: [
        ],
        phoneNumber: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        address: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        email: [
        ],
        socialProfiles: [
        ],
        googleAnalyticsId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        matomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        chatWelcomeMessage: [
        ],
        tags: [
        ],
        rowVersion: [
        ],
    };

    /**
    * The FormControlFactory for ExhibitorSelfUpdateDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExhibitorSelfUpdateDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExhibitorSelfUpdateDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExhibitorSelfUpdateDto = {
            id: null,
            name: null,
            company: null,
            label: null,
            shortLabel: null,
            description: null,
            descriptionLong: null,
            website: null,
            displays: null,
            links: null,
            panels: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            backgroundImage: null,
            avatar: null,
            phoneNumber: null,
            address: null,
            email: null,
            socialProfiles: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            chatWelcomeMessage: null,
            tags: null,
            rowVersion: null,
          }
        ) {
            super(model, ExhibitorSelfUpdateDto.ModelValidators);
        }
    }

}


