/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { IntegrationDto } from './integrationDto';


export interface IntegrationPartnerDto { 
    id: string;
    name: string;
    integrations?: Array<IntegrationDto>;
}

/**
 * Namespace for property- and property-value-enumerations of IntegrationPartnerDto.
 */
export namespace IntegrationPartnerDto {
    /**
     * All properties of IntegrationPartnerDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        integrations = 'integrations'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of IntegrationPartnerDto.
    */
    export const ModelValidators: {[K in keyof IntegrationPartnerDto]: [string, ValidatorFn][]} = {
        id: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(1)],
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(1)],
        ],
        integrations: [
        ],
    };

    /**
    * The FormControlFactory for IntegrationPartnerDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<IntegrationPartnerDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for IntegrationPartnerDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: IntegrationPartnerDto = {
            id: null,
            name: null,
            integrations: null,
          }
        ) {
            super(model, IntegrationPartnerDto.ModelValidators);
        }
    }

}


