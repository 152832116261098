/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CalendarAvailability } from './calendarAvailability';
import { Expo } from './expo';
import { UserProfile } from './userProfile';
import { Exhibitor } from './exhibitor';


export interface ApplicationUserAllOf { 
    sinespaceUser?: string;
    sinespaceUserId?: number;
    displayName?: string;
    isManager?: boolean;
    exhibitors?: Array<Exhibitor>;
    expos?: Array<Expo>;
    profile?: UserProfile;
    availabilities?: Array<CalendarAvailability>;
}

/**
 * Namespace for property- and property-value-enumerations of ApplicationUserAllOf.
 */
export namespace ApplicationUserAllOf {
    /**
     * All properties of ApplicationUserAllOf.
     */
    export enum Properties {
        sinespaceUser = 'sinespaceUser',
        sinespaceUserId = 'sinespaceUserId',
        displayName = 'displayName',
        isManager = 'isManager',
        exhibitors = 'exhibitors',
        expos = 'expos',
        profile = 'profile',
        availabilities = 'availabilities'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ApplicationUserAllOf.
    */
    export const ModelValidators: {[K in keyof ApplicationUserAllOf]: [string, ValidatorFn][]} = {
        sinespaceUser: [
        ],
        sinespaceUserId: [
        ],
        displayName: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        isManager: [
        ],
        exhibitors: [
        ],
        expos: [
        ],
        profile: [
        ],
        availabilities: [
        ],
    };

    /**
    * The FormControlFactory for ApplicationUserAllOf.
    */
    export class FormControlFactory extends BaseFormControlFactory<ApplicationUserAllOf> {

        /**
         * Constructor.
         *
         * @param model An existing model for ApplicationUserAllOf.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ApplicationUserAllOf = {
            sinespaceUser: null,
            sinespaceUserId: null,
            displayName: null,
            isManager: null,
            exhibitors: null,
            expos: null,
            profile: null,
            availabilities: null,
          }
        ) {
            super(model, ApplicationUserAllOf.ModelValidators);
        }
    }

}


