<div class="modal-header">
  <h4 class="modal-title float-left" *ngIf="calendarItem.id > 0">
    Edit stage presentation
  </h4>
  <h4 class="modal-title float-left" *ngIf="!(calendarItem.id > 0)">
    Create stage presentation
  </h4>
  <div class="btn-group-xs action-button-group" style="margin-left: 8em;" *ngIf="calendarItem.id > 0">
    <button class="btn btn-success" (click)="activeEvent($event)"
            *ngIf="stage && stage.current !== calendarItem.id">Activate
    </button>
    <button class="btn btn-success" (click)="updateEvent($event)"
            *ngIf="stage && stage.current === calendarItem.id">Change stream
    </button>
    <button class="btn btn-warning" (click)="deactivateEvent($event) "
            *ngIf="stage && stage.current === calendarItem.id">Deactivate
    </button>
    <button class="btn btn-default-outline" style="width: 12em;" disabled
            *ngIf="stage && stage.current !== calendarItem.id">Deactivate
    </button>
    <button class="btn btn-primary" (click)="sendAnnouncement($event)">Send announcement</button>
    <button class="btn btn-secondary" (click)="sendCustomAnnouncement($event)">Custom
      announcement
    </button>
    <a class="btn btn-outline-success" [href]="stageLink" target="_blank" rel="noopener">Open stage</a>
  </div>
  <button type="button" class="close float-right" aria-label="Close" (click)="modalRef.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="expoForm" novalidate=""
        *ngIf="calendarItem && isLoaded">
    <div class="row">
      <div class="col-6">
        <div class="form-group has-feedback">
          <label for="label">Label *</label>
          <input class="form-control" id="label" type="text" name="label" placeholder="Enter label"
                 autocomplete="off"
                 [required]="valForm.isValidatorRegistered(properties.label, 'required')"
                 formControlName="label"/>
          <app-control-validation-results [validatedControl]="properties.label"
                                          [formGroup]="valForm"></app-control-validation-results>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group has-feedback">
              <label for="stageId">Stage</label>
              <select class="form-control" id="stageId" name="stageId" formControlName="stageId" style="width: 100%;"
                      (change)="changeCurrentStage($event)"
                      [required]="valForm.isValidatorRegistered(properties.stageId, 'required')">
                <option [value]="oneStage.id" [selected]="oneStage.id === valForm.value.stageId"
                        *ngFor="let oneStage of stages | sequenceSort" [innerText]="oneStage.name"></option>
              </select>
              <app-control-validation-results [validatedControl]="properties.stageId"
                                              [formGroup]="valForm"></app-control-validation-results>
            </div>
          </div>
          <div class="col-6" *ngIf="currentStage && currentStage.stageMode < 100">
            <div class="form-group has-feedback">
              <label for="stageMode">Stage mode</label>
              <select class="form-control" id="stageMode" name="stageMode" formControlName="stageMode"
                      [required]="valForm.isValidatorRegistered(properties.stageMode, 'required')">
                <option value="0">Big screen, show all exhibitors</option>
                <option value="1">Smaller screen, show all exhibitors</option>
                <option value="10">Big screen, show only specified exhibitors</option>
                <option value="11">Smaller screen, show only specified exhibitors</option>
              </select>
              <app-control-validation-results [validatedControl]="properties.stageMode"
                                              [formGroup]="valForm"></app-control-validation-results>
            </div>
          </div>
        </div>
        <app-form-markdown-input [formGroup]="valForm" fieldName="description" label="Description"
                                 [class]="'item-group'"
                                 placeholder="Enter description"
        ></app-form-markdown-input>
        <!--        <div class="form-group has-feedback">-->
        <!--          <label for="description">Description *</label>-->
        <!--          <textarea class="form-control" id="description"-->
        <!--                    placeholder="Enter description" formControlName="description"-->
        <!--                    [required]="valForm.isValidatorRegistered(properties.description, 'required')"-->
        <!--          >-->
        <!--          </textarea>-->
        <!--          <app-control-validation-results [validatedControl]="properties.description"-->
        <!--                                          [formGroup]="valForm"></app-control-validation-results>-->
        <!--        </div>-->
        <div class="row">
          <div class="col-6">
            <div class="form-group has-feedback">
              <label for="streamType">Stream type</label>
              <select class="form-control" id="streamType" name="streamType" formControlName="streamType"
                      [required]="valForm.isValidatorRegistered(properties.streamType, 'required')">
                <option value="0">none</option>
                <option value="1">Youtube</option>
                <option value="2">Youtube live stream</option>
                <option value="3">Zoom</option>
                <option value="11">Vimeo playlist</option>
                <option value="12">Vimeo live stream</option>
                <option value="20">Cisco WebEx Meeting</option>
                <option value="21">Website</option>
              </select>
              <app-control-validation-results [validatedControl]="properties.streamType"
                                              [formGroup]="valForm"></app-control-validation-results>
            </div>
          </div>
          <div class="col-6" *ngIf="valForm.value && valForm.value.streamType && valForm.value.streamType.toString() !== '21'">
            <div class="form-group has-feedback">
              <label for="stream">Stream Id</label>
              <input class="form-control" id="stream" type="text" name="stream" placeholder="Enter stream"
                     prevent-spaces
                     autocomplete="off"
                     [required]="valForm.isValidatorRegistered(properties.stream, 'required')"
                     formControlName="stream"/>
              <app-control-validation-results [validatedControl]="properties.stream"
                                              [formGroup]="valForm"></app-control-validation-results>
            </div>
          </div>
          <div class="col-6" *ngIf="valForm.value && valForm.value.streamType && valForm.value.streamType.toString() === '21'">
            <app-form-string-input label="Website url" placeholder="Enter website url"
                                   [formGroup]="valForm" fieldName="stream"></app-form-string-input>
          </div>
          <div class="col-6" *ngIf="valForm.value && valForm.value.streamType && valForm.value.streamType.toString() === '12'">
            <app-form-string-input label="Event Id" placeholder="Enter video event id"
                                   [formGroup]="valForm" fieldName="streamAdditional"></app-form-string-input>
          </div>
          <ng-container
            *ngIf="valForm.value && valForm.value.streamType && valForm.value.streamType.toString() === '3'">
            <div class="col-6">
              <div class="form-group has-feedback">
                <label for="streamAdditional2">Cleartext zoom password</label>
                <input class="form-control" id="streamAdditional2" type="text" name="streamAdditional2" prevent-spaces
                       placeholder="Zoom password clear text"
                       autocomplete="off"
                       [required]="valForm.isValidatorRegistered(properties.streamAdditional2, 'required')"
                       formControlName="streamAdditional2"/>
                <app-control-validation-results [validatedControl]="properties.streamAdditional2"
                                                [formGroup]="valForm"></app-control-validation-results>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group has-feedback">
                <label for="streamAdditional">Encrypted Zoom password</label>
                <input class="form-control" id="streamAdditional" type="text" name="streamAdditional" prevent-spaces
                       placeholder="Zoom password encrypted"
                       autocomplete="off"
                       [required]="valForm.isValidatorRegistered(properties.streamAdditional, 'required')"
                       formControlName="streamAdditional"/>
                <app-control-validation-results [validatedControl]="properties.streamAdditional"
                                                [formGroup]="valForm"></app-control-validation-results>
              </div>
              needed to open external Zoom client
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-6">
            <div class="form-group has-feedback float-right">
              <ui-switch [(ngModel)]="dateMode" [ngModelOptions]="{standalone: true}"
                         checkedLabel="No date" uncheckedLabel="Has date"
                         defaultBgColor="green" uncheckedTextColor="white"
                         (change)="changeDateMode($event)"
                         checkedTextColor="black" color="yellow">
              </ui-switch>
              <app-help
                details="Calendar entries without dates will show up before all other calendar entries."></app-help>
            </div>
          </div>
          <div class="col-6">
            <div
              [ngClass]="{'entry-hidden': valForm.value.hidden, 'exhibition-inactive': !valForm.value.hidden}">
              <div class="form-group has-feedback float-right">
                <ui-switch formControlName="hidden" checkedLabel="Hidden entry" uncheckedLabel="Visible entry"
                           defaultBgColor="green" uncheckedTextColor="white"
                           checkedTextColor="white" color="red">
                </ui-switch>
                <app-help
                  details="Calendar entries can be hidden so they can e.g.be used to store intermission streams before, after, or between talks."></app-help>
              </div>
            </div>
            <!--            <div class="form-group has-feedback">-->
            <!--              <ui-switch [formControlName]="properties.showSpeaker" id="showSpeaker" checkedLabel="Show speaker" uncheckedLabel="Hide speaker"></ui-switch>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="row" *ngIf="valForm.value.dateMode === 0">
          <div class="col-7">
            <div class="form-group has-feedback">
              <label for="startDate">Stage presentation start time</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i class="fa fa-edit" (click)="d.toggle()"></i>
                  </div>
                </div>
                <input class="form-control" id="startDate" type="text" name="startDate" readonly="readonly"
                       placeholder="Enter start date" autocomplete="off" ngbDatepicker #d="ngbDatepicker"
                       (click)="d.toggle()"
                       [(ngModel)]="startDate" [ngModelOptions]="{standalone: true}"/>
              </div>
            </div>
            <div class="form-group has-feedback">
              <label [for]="properties.duration">Duration</label> &nbsp;
              <input type="number" [formControlName]="properties.duration" [id]="properties.duration"
                     style="width: 5em;">
              &nbsp; min.
            </div>
          </div>
          <div class="col-5">
            <ngb-timepicker #d [(ngModel)]="startTime" [ngModelOptions]="{standalone: true}" [hourStep]="1"
                            [minuteStep]="15"></ngb-timepicker>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <app-upload [label]="'Calendar image'" [imageUrl]="calendarItem.logo" [imageId]="calendarItem.logo"
                        dynamic="true" allowClear="true"
                        (imageUploaded)="onImageAdded($event)" [width]="512" [height]="512"></app-upload>
          </div>
          <div class="col-6">
            <app-upload [label]="'Stage background'" [imageUrl]="calendarItem.background"
                        [imageId]="calendarItem.background" ar="1380/1100"
                        tooltip="Stage background image, should be 1380 pixel wide by 1100 pixel high."
                        dynamic="true" allowClear="true"
                        (imageUploaded)="onBackgroundAdded($event)" [width]="1380" [height]="1100"></app-upload>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group has-feedback">
              <label for="vodType">VoD type</label>
              <select class="form-control" id="vodType" name="vodType" formControlName="vodType"
                      [required]="valForm.isValidatorRegistered(properties.vodType, 'required')">
                <option value="0">none</option>
                <option value="1">Youtube</option>
                <!--                <option value="2">Vimeo</option>-->
                <!--                <option value="3">Cloudflare Streams</option>-->
              </select>
              <app-control-validation-results [validatedControl]="properties.vodType"
                                              [formGroup]="valForm"></app-control-validation-results>
            </div>
          </div>
          <div class="col-4" *ngIf="valForm.value.vodType && valForm.value.vodType.toString() !== '0'">
            <div class="form-group has-feedback">
              <label for="vodSource">Source Id</label>
              <input class="form-control" id="vodSource" type="text" name="vodSource" placeholder="Enter source id"
                     autocomplete="off" prevent-spaces
                     [required]="valForm.isValidatorRegistered(properties.vodSource, 'required')"
                     formControlName="vodSource"/>
              <app-control-validation-results [validatedControl]="properties.vodSource"
                                              [formGroup]="valForm"></app-control-validation-results>
            </div>
          </div>
        </div>
        <ng-container *ngIf="valForm.value.stageMode >= 10 && currentStage.stageMode < 100">
          <h3>Linked exhibitors</h3>
          <table style="width: 100%">
            <thead>
            <tr>
              <th>Name</th>
              <th></th>
              <th>Sequence</th>
              <th></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let exhi of valForm.value.exhibitors">
              <td>{{ exhi.name }}</td>
              <td style="width: 25px;">
                <button class="btn btn-xs" (click)="sortUpExhibitor($event, exhi)" *ngIf="exhi.sequence > 1"><i
                  class="fa fa-angle-up"></i></button>
              </td>
              <td style="width: 50px; text-align: right;">{{ exhi.sequence }}</td>
              <td style="width: 25px;">
                <button class="btn btn-xs" (click)="sortDownExhibitor($event, exhi)"
                        *ngIf="exhi.sequence < calendarItem.exhibitors.length"><i
                  class="fa fa-angle-down"></i>
                </button>
              </td>
              <td style="text-align: center; width: 3em;"><i class="fa fa-minus"
                                                             (click)="removeExhibitor($event, exhi)"></i></td>
            </tr>
            <tr>
              <td colspan="4">
                <select class="form-control" [(ngModel)]="selectedExhibitor" [ngModelOptions]="{standalone: true}">
                  <option [ngValue]="newEx" *ngFor="let newEx of availableExhibitors">{{ newEx.name }}</option>
                </select>
              </td>
              <td>
                <button (click)="addExhibitor($event, selectedExhibitor)" [disabled]="!selectedExhibitor"><i
                  class="fa fa-plus"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
    </div>
    <div class="row mt-3" *ngIf="valForm.value.showSpeaker">
      <div class="col-9">
        <div class="form-group has-feedback">
          <ui-switch [formControlName]="properties.useProfile" id="useProfile" checkedLabel="Use speakers user profile"
                     uncheckedLabel="Speaker details"></ui-switch>
        </div>
        <ng-container *ngIf="valForm.value.useProfile">
          <div class="form-group has-feedback">
            <label for="speakerEmail" *ngIf="calendarItem.speakerName && calendarItem.speakerName !== ''">Change
              speaker?
              Currently, its {{ calendarItem.speakerName }}. Enter Email to
              change.</label>
            <label for="speakerEmail" *ngIf="!calendarItem.speakerName || calendarItem.speakerName === ''">Enter
              speaker's
              email to set.</label>
            <input class="form-control" id="speakerEmail" type="text" name="speakerEmail"
                   placeholder="Enter speaker email" autocomplete="off"
                   [formControlName]="properties.speakerEmail"/>
            <app-control-validation-results [validatedControl]="properties.speakerEmail"
                                            [formGroup]="valForm"></app-control-validation-results>
          </div>
        </ng-container>
        <ng-container *ngIf="!valForm.value.useProfile">
          <div class="row">
            <div class="col-12">
              <div class="form-group has-feedback">
                <label [for]="properties.speakerName">Speaker name</label>
                <input class="form-control" [id]="properties.speakerName" type="text" [name]="properties.speakerName"
                       placeholder="Enter speaker's name" autocomplete="off"
                       [formControlName]="properties.speakerName"/>
                <app-control-validation-results [validatedControl]="properties.speakerName"
                                                [formGroup]="valForm"></app-control-validation-results>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group has-feedback">
                <label [for]="properties.speakerCompany">Speaker company</label>
                <input class="form-control" [id]="properties.speakerCompany" type="text"
                       [name]="properties.speakerCompany"
                       placeholder="Enter speaker's company" autocomplete="off"
                       [formControlName]="properties.speakerCompany"/>
                <app-control-validation-results [validatedControl]="properties.speakerCompany"
                                                [formGroup]="valForm"></app-control-validation-results>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group has-feedback">
                <label [for]="properties.speakerRole">Speaker role</label>
                <input class="form-control" [id]="properties.speakerRole" type="text" [name]="properties.speakerRole"
                       placeholder="Enter speaker's role" autocomplete="off"
                       [formControlName]="properties.speakerRole"/>
                <app-control-validation-results [validatedControl]="properties.speakerRole"
                                                [formGroup]="valForm"></app-control-validation-results>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group has-feedback">
                <label [for]="properties.speakerDescription">Description</label>
                <textarea class="form-control" [id]="properties.speakerDescription" type="text"
                          [name]="properties.speakerDescription"
                          placeholder="Enter description" autocomplete="off" rows="5" wrap="hard"
                          [formControlName]="properties.speakerDescription">

            </textarea>
                <app-control-validation-results [validatedControl]="properties.speakerDescription"
                                                [formGroup]="valForm"></app-control-validation-results>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <label style="width: 100%;">Social networks
                <button class="btn btn-xs btn-primary float-right" (click)="addProfile($event)">Add</button>
              </label>
              <div class="row social" *ngFor="let item of calendarItem.speakerSocialProfiles | keyvalue">
                <div class="col-sm-4" [translate]="'profile.' + item.key"></div>
                <div class="col-sm-4">
                  {{ item.value }}
                </div>
                <div class="col-sm-4">
                  <div class="float-right">
                    <i class="btn btn-xs btn-success fa fa-edit" (click)="editSocial($event, item)"> </i> &nbsp;
                    <i class="btn btn-xs btn-danger fa fa-trash" (click)="removeSocial($event, item)"> </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-3">
        <app-upload [label]="'Speaker image'" [imageUrl]="calendarItem.speakerImage"
                    [imageId]="calendarItem.speakerImage" allowClear="true"
                    dynamic="true" *ngIf="!valForm.value.useProfile"
                    (imageUploaded)="onSpeakerImageAdded($event)" [width]="512" [height]="512"></app-upload>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="container-fluid">
    <div class="row" *ngIf="calendarItem && isLoaded">
      <div class="col-md-2">
        <button class="btn btn-warning" (click)="cancelEditor($event)">Cancel</button>
      </div>
      <div class="col-md-2">
        <button class="btn btn-danger" (click)="deleteEntry($event)">Delete</button>
      </div>
      <div class="col-md-4">
        <div class="calendar-download" *ngIf="calendarItem.id > 0">
          {{ 'calendar.download.label' | translate }}
          <a [href]="downloadICal" title="ICal" target="_blank">
            <button class="btn btn-xs btn-outline-info mr-1">
              ICal
            </button>
          </a>
          <a [href]="downloadGoogle" title="ICS" target="_blank">
            <button class="btn btn-xs btn-outline-info mr-1">
              Google
            </button>
          </a>
          <a [href]="downloadOutlook" title="Outlook" target="_blank">
            <button class="btn btn-xs btn-outline-info">
              Outlook
            </button>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <button class="btn btn-success float-right" (click)="submitForm($event)"
                [disabled]="saving || !valForm.valid" [title]="!valForm.valid ? 'Invalid / missing data' : ''"
                *ngIf="!calendarItem.id || calendarItem.id === 0">Save
        </button>
        <button class="btn btn-success float-right" (click)="submitForm($event)"
                [disabled]="saving || !valForm.valid" [title]="!valForm.valid ? 'Invalid / missing data' : ''"
                *ngIf="calendarItem.id && calendarItem.id !== 0">Update
        </button>
      </div>
    </div>
  </div>
</div>
