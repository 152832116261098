<div class="modal-header">
  <h4 class="modal-title float-left">Navigate away ?</h4>
  <button type="button" class="close float-right" aria-label="Close" (click)="modalRef.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [innerHTML]="message">
</div>
<div class="modal-footer" style="justify-content: space-between;">
  <button type="button" class="btn btn-danger" (click)="onConfirm()">Yes</button>
  <button type="button" class="btn btn-success" (click)="onCancel()">No</button>
</div>
