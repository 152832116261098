import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-form-string-input',
  templateUrl: './form-string-input.component.html',
  styleUrls: ['./form-string-input.component.scss']
})
export class FormStringInputComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() model: any;
  @Input() fieldName: string;

  @Input() label = '';
  @Input() subLabel = '';
  @Input() help = '';

  @Input() placeholder = '';

  @Output() change: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() { }

  ngOnInit(): void {
  }

  onChange($event: Event) {
    this.change.emit($event);
  }
}
