/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for statisticAreaStatDaily.
 */
export namespace StatisticAreaStatDaily {
    /**
     * Parameter map for statisticAreaStatDaily.
     */
    export interface PartialParamMap {
      regionId: number;
      areaId: string;
      start: Date;
      end: Date;
    }

    /**
     * Enumeration of all parameters for statisticAreaStatDaily.
     */
    export enum Parameters {
      regionId = 'regionId',
      areaId = 'areaId',
      start = 'start',
      end = 'end'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of statisticAreaStatDaily
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StatisticAreaStatDaily.PartialParamMap]?: [string, ValidatorFn][]} = {
      regionId: [
              ['required', Validators.required],
      ],
      areaId: [
              ['required', Validators.required],
      ],
      start: [
              ['required', Validators.required],
      ],
      end: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for statisticAreaStatHourly.
 */
export namespace StatisticAreaStatHourly {
    /**
     * Parameter map for statisticAreaStatHourly.
     */
    export interface PartialParamMap {
      regionId: number;
      areaId: string;
      start: Date;
      end: Date;
    }

    /**
     * Enumeration of all parameters for statisticAreaStatHourly.
     */
    export enum Parameters {
      regionId = 'regionId',
      areaId = 'areaId',
      start = 'start',
      end = 'end'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of statisticAreaStatHourly
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StatisticAreaStatHourly.PartialParamMap]?: [string, ValidatorFn][]} = {
      regionId: [
              ['required', Validators.required],
      ],
      areaId: [
              ['required', Validators.required],
      ],
      start: [
              ['required', Validators.required],
      ],
      end: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for statisticAreaVisitors.
 */
export namespace StatisticAreaVisitors {
    /**
     * Parameter map for statisticAreaVisitors.
     */
    export interface PartialParamMap {
      regionId: number;
      areaId: string;
      start: Date;
      end: Date;
    }

    /**
     * Enumeration of all parameters for statisticAreaVisitors.
     */
    export enum Parameters {
      regionId = 'regionId',
      areaId = 'areaId',
      start = 'start',
      end = 'end'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of statisticAreaVisitors
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StatisticAreaVisitors.PartialParamMap]?: [string, ValidatorFn][]} = {
      regionId: [
              ['required', Validators.required],
      ],
      areaId: [
              ['required', Validators.required],
      ],
      start: [
              ['required', Validators.required],
      ],
      end: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for statisticRegionStatDaily.
 */
export namespace StatisticRegionStatDaily {
    /**
     * Parameter map for statisticRegionStatDaily.
     */
    export interface PartialParamMap {
      regionId: number;
      start: Date;
      end: Date;
    }

    /**
     * Enumeration of all parameters for statisticRegionStatDaily.
     */
    export enum Parameters {
      regionId = 'regionId',
      start = 'start',
      end = 'end'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of statisticRegionStatDaily
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StatisticRegionStatDaily.PartialParamMap]?: [string, ValidatorFn][]} = {
      regionId: [
              ['required', Validators.required],
      ],
      start: [
              ['required', Validators.required],
      ],
      end: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for statisticRegionStatHourly.
 */
export namespace StatisticRegionStatHourly {
    /**
     * Parameter map for statisticRegionStatHourly.
     */
    export interface PartialParamMap {
      regionId: number;
      start: Date;
      end: Date;
    }

    /**
     * Enumeration of all parameters for statisticRegionStatHourly.
     */
    export enum Parameters {
      regionId = 'regionId',
      start = 'start',
      end = 'end'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of statisticRegionStatHourly
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StatisticRegionStatHourly.PartialParamMap]?: [string, ValidatorFn][]} = {
      regionId: [
              ['required', Validators.required],
      ],
      start: [
              ['required', Validators.required],
      ],
      end: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for statisticRegionVisitors.
 */
export namespace StatisticRegionVisitors {
    /**
     * Parameter map for statisticRegionVisitors.
     */
    export interface PartialParamMap {
      regionId: number;
      start: Date;
      end: Date;
    }

    /**
     * Enumeration of all parameters for statisticRegionVisitors.
     */
    export enum Parameters {
      regionId = 'regionId',
      start = 'start',
      end = 'end'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of statisticRegionVisitors
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StatisticRegionVisitors.PartialParamMap]?: [string, ValidatorFn][]} = {
      regionId: [
              ['required', Validators.required],
      ],
      start: [
              ['required', Validators.required],
      ],
      end: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for statisticTrackArea.
 */
export namespace StatisticTrackArea {
    /**
     * Parameter map for statisticTrackArea.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for statisticTrackArea.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of statisticTrackArea
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StatisticTrackArea.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for statisticTrackRegion.
 */
export namespace StatisticTrackRegion {
    /**
     * Parameter map for statisticTrackRegion.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for statisticTrackRegion.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of statisticTrackRegion
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StatisticTrackRegion.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}



@Injectable({
  providedIn: 'root'
})
export class StatisticService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public statisticAreaStatDailyByMap(
    map: StatisticAreaStatDaily.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public statisticAreaStatDailyByMap(
    map: StatisticAreaStatDaily.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public statisticAreaStatDailyByMap(
    map: StatisticAreaStatDaily.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public statisticAreaStatDailyByMap(
    map: StatisticAreaStatDaily.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.statisticAreaStatDaily(
      map.regionId,
      map.areaId,
      map.start,
      map.end,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param regionId 
     * @param areaId 
     * @param start 
     * @param end 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public statisticAreaStatDaily(regionId: number, areaId: string, start: Date, end: Date, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public statisticAreaStatDaily(regionId: number, areaId: string, start: Date, end: Date, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public statisticAreaStatDaily(regionId: number, areaId: string, start: Date, end: Date, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public statisticAreaStatDaily(regionId: number, areaId: string, start: Date, end: Date, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (regionId === null || regionId === undefined) {
            throw new Error('Required parameter regionId was null or undefined when calling statisticAreaStatDaily.');
        }
        if (areaId === null || areaId === undefined) {
            throw new Error('Required parameter areaId was null or undefined when calling statisticAreaStatDaily.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling statisticAreaStatDaily.');
        }
        if (end === null || end === undefined) {
            throw new Error('Required parameter end was null or undefined when calling statisticAreaStatDaily.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/Statistic/area/daily/${encodeURIComponent(String(regionId))}/${encodeURIComponent(String(areaId))}/${encodeURIComponent(String(start.toISOString()))}/${encodeURIComponent(String(end.toISOString()))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Statistic_AreaStatDaily']) {
                this.cancelMap['Statistic_AreaStatDaily'].next();
            }
            this.cancelMap['Statistic_AreaStatDaily'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Statistic_AreaStatDaily']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Statistic_AreaStatDaily']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Statistic_AreaStatDaily', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Statistic_AreaStatDaily']) {
            this.cancelMap['Statistic_AreaStatDaily'].complete();
            delete this.cancelMap['Statistic_AreaStatDaily'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Statistic_AreaStatDaily',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Statistic_AreaStatDaily')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public statisticAreaStatHourlyByMap(
    map: StatisticAreaStatHourly.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public statisticAreaStatHourlyByMap(
    map: StatisticAreaStatHourly.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public statisticAreaStatHourlyByMap(
    map: StatisticAreaStatHourly.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public statisticAreaStatHourlyByMap(
    map: StatisticAreaStatHourly.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.statisticAreaStatHourly(
      map.regionId,
      map.areaId,
      map.start,
      map.end,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param regionId 
     * @param areaId 
     * @param start 
     * @param end 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public statisticAreaStatHourly(regionId: number, areaId: string, start: Date, end: Date, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public statisticAreaStatHourly(regionId: number, areaId: string, start: Date, end: Date, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public statisticAreaStatHourly(regionId: number, areaId: string, start: Date, end: Date, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public statisticAreaStatHourly(regionId: number, areaId: string, start: Date, end: Date, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (regionId === null || regionId === undefined) {
            throw new Error('Required parameter regionId was null or undefined when calling statisticAreaStatHourly.');
        }
        if (areaId === null || areaId === undefined) {
            throw new Error('Required parameter areaId was null or undefined when calling statisticAreaStatHourly.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling statisticAreaStatHourly.');
        }
        if (end === null || end === undefined) {
            throw new Error('Required parameter end was null or undefined when calling statisticAreaStatHourly.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/Statistic/area/hourly/${encodeURIComponent(String(regionId))}/${encodeURIComponent(String(areaId))}/${encodeURIComponent(String(start.toISOString()))}/${encodeURIComponent(String(end.toISOString()))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Statistic_AreaStatHourly']) {
                this.cancelMap['Statistic_AreaStatHourly'].next();
            }
            this.cancelMap['Statistic_AreaStatHourly'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Statistic_AreaStatHourly']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Statistic_AreaStatHourly']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Statistic_AreaStatHourly', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Statistic_AreaStatHourly']) {
            this.cancelMap['Statistic_AreaStatHourly'].complete();
            delete this.cancelMap['Statistic_AreaStatHourly'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Statistic_AreaStatHourly',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Statistic_AreaStatHourly')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public statisticAreaVisitorsByMap(
    map: StatisticAreaVisitors.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public statisticAreaVisitorsByMap(
    map: StatisticAreaVisitors.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public statisticAreaVisitorsByMap(
    map: StatisticAreaVisitors.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public statisticAreaVisitorsByMap(
    map: StatisticAreaVisitors.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.statisticAreaVisitors(
      map.regionId,
      map.areaId,
      map.start,
      map.end,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param regionId 
     * @param areaId 
     * @param start 
     * @param end 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public statisticAreaVisitors(regionId: number, areaId: string, start: Date, end: Date, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public statisticAreaVisitors(regionId: number, areaId: string, start: Date, end: Date, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public statisticAreaVisitors(regionId: number, areaId: string, start: Date, end: Date, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public statisticAreaVisitors(regionId: number, areaId: string, start: Date, end: Date, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (regionId === null || regionId === undefined) {
            throw new Error('Required parameter regionId was null or undefined when calling statisticAreaVisitors.');
        }
        if (areaId === null || areaId === undefined) {
            throw new Error('Required parameter areaId was null or undefined when calling statisticAreaVisitors.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling statisticAreaVisitors.');
        }
        if (end === null || end === undefined) {
            throw new Error('Required parameter end was null or undefined when calling statisticAreaVisitors.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/Statistic/area/visitors/${encodeURIComponent(String(regionId))}/${encodeURIComponent(String(areaId))}/${encodeURIComponent(String(start.toISOString()))}/${encodeURIComponent(String(end.toISOString()))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Statistic_AreaVisitors']) {
                this.cancelMap['Statistic_AreaVisitors'].next();
            }
            this.cancelMap['Statistic_AreaVisitors'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Statistic_AreaVisitors']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Statistic_AreaVisitors']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Statistic_AreaVisitors', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Statistic_AreaVisitors']) {
            this.cancelMap['Statistic_AreaVisitors'].complete();
            delete this.cancelMap['Statistic_AreaVisitors'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Statistic_AreaVisitors',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Statistic_AreaVisitors')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public statisticRegionStatDailyByMap(
    map: StatisticRegionStatDaily.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public statisticRegionStatDailyByMap(
    map: StatisticRegionStatDaily.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public statisticRegionStatDailyByMap(
    map: StatisticRegionStatDaily.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public statisticRegionStatDailyByMap(
    map: StatisticRegionStatDaily.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.statisticRegionStatDaily(
      map.regionId,
      map.start,
      map.end,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param regionId 
     * @param start 
     * @param end 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public statisticRegionStatDaily(regionId: number, start: Date, end: Date, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public statisticRegionStatDaily(regionId: number, start: Date, end: Date, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public statisticRegionStatDaily(regionId: number, start: Date, end: Date, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public statisticRegionStatDaily(regionId: number, start: Date, end: Date, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (regionId === null || regionId === undefined) {
            throw new Error('Required parameter regionId was null or undefined when calling statisticRegionStatDaily.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling statisticRegionStatDaily.');
        }
        if (end === null || end === undefined) {
            throw new Error('Required parameter end was null or undefined when calling statisticRegionStatDaily.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/Statistic/region/daily/${encodeURIComponent(String(regionId))}/${encodeURIComponent(String(start.toISOString()))}/${encodeURIComponent(String(end.toISOString()))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Statistic_RegionStatDaily']) {
                this.cancelMap['Statistic_RegionStatDaily'].next();
            }
            this.cancelMap['Statistic_RegionStatDaily'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Statistic_RegionStatDaily']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Statistic_RegionStatDaily']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Statistic_RegionStatDaily', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Statistic_RegionStatDaily']) {
            this.cancelMap['Statistic_RegionStatDaily'].complete();
            delete this.cancelMap['Statistic_RegionStatDaily'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Statistic_RegionStatDaily',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Statistic_RegionStatDaily')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public statisticRegionStatHourlyByMap(
    map: StatisticRegionStatHourly.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public statisticRegionStatHourlyByMap(
    map: StatisticRegionStatHourly.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public statisticRegionStatHourlyByMap(
    map: StatisticRegionStatHourly.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public statisticRegionStatHourlyByMap(
    map: StatisticRegionStatHourly.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.statisticRegionStatHourly(
      map.regionId,
      map.start,
      map.end,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param regionId 
     * @param start 
     * @param end 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public statisticRegionStatHourly(regionId: number, start: Date, end: Date, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public statisticRegionStatHourly(regionId: number, start: Date, end: Date, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public statisticRegionStatHourly(regionId: number, start: Date, end: Date, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public statisticRegionStatHourly(regionId: number, start: Date, end: Date, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (regionId === null || regionId === undefined) {
            throw new Error('Required parameter regionId was null or undefined when calling statisticRegionStatHourly.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling statisticRegionStatHourly.');
        }
        if (end === null || end === undefined) {
            throw new Error('Required parameter end was null or undefined when calling statisticRegionStatHourly.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/Statistic/region/hourly/${encodeURIComponent(String(regionId))}/${encodeURIComponent(String(start.toISOString()))}/${encodeURIComponent(String(end.toISOString()))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Statistic_RegionStatHourly']) {
                this.cancelMap['Statistic_RegionStatHourly'].next();
            }
            this.cancelMap['Statistic_RegionStatHourly'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Statistic_RegionStatHourly']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Statistic_RegionStatHourly']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Statistic_RegionStatHourly', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Statistic_RegionStatHourly']) {
            this.cancelMap['Statistic_RegionStatHourly'].complete();
            delete this.cancelMap['Statistic_RegionStatHourly'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Statistic_RegionStatHourly',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Statistic_RegionStatHourly')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public statisticRegionVisitorsByMap(
    map: StatisticRegionVisitors.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public statisticRegionVisitorsByMap(
    map: StatisticRegionVisitors.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public statisticRegionVisitorsByMap(
    map: StatisticRegionVisitors.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public statisticRegionVisitorsByMap(
    map: StatisticRegionVisitors.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.statisticRegionVisitors(
      map.regionId,
      map.start,
      map.end,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param regionId 
     * @param start 
     * @param end 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public statisticRegionVisitors(regionId: number, start: Date, end: Date, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public statisticRegionVisitors(regionId: number, start: Date, end: Date, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public statisticRegionVisitors(regionId: number, start: Date, end: Date, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public statisticRegionVisitors(regionId: number, start: Date, end: Date, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (regionId === null || regionId === undefined) {
            throw new Error('Required parameter regionId was null or undefined when calling statisticRegionVisitors.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling statisticRegionVisitors.');
        }
        if (end === null || end === undefined) {
            throw new Error('Required parameter end was null or undefined when calling statisticRegionVisitors.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/Statistic/region/visitors/${encodeURIComponent(String(regionId))}/${encodeURIComponent(String(start.toISOString()))}/${encodeURIComponent(String(end.toISOString()))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Statistic_RegionVisitors']) {
                this.cancelMap['Statistic_RegionVisitors'].next();
            }
            this.cancelMap['Statistic_RegionVisitors'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Statistic_RegionVisitors']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Statistic_RegionVisitors']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Statistic_RegionVisitors', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Statistic_RegionVisitors']) {
            this.cancelMap['Statistic_RegionVisitors'].complete();
            delete this.cancelMap['Statistic_RegionVisitors'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Statistic_RegionVisitors',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Statistic_RegionVisitors')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public statisticTrackAreaByMap(
    map: StatisticTrackArea.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public statisticTrackAreaByMap(
    map: StatisticTrackArea.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public statisticTrackAreaByMap(
    map: StatisticTrackArea.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public statisticTrackAreaByMap(
    map: StatisticTrackArea.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.statisticTrackArea(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public statisticTrackArea(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public statisticTrackArea(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public statisticTrackArea(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public statisticTrackArea(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/Statistic/area/track`,
            null,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Statistic_TrackArea']) {
                this.cancelMap['Statistic_TrackArea'].next();
            }
            this.cancelMap['Statistic_TrackArea'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Statistic_TrackArea']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Statistic_TrackArea']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Statistic_TrackArea', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Statistic_TrackArea']) {
            this.cancelMap['Statistic_TrackArea'].complete();
            delete this.cancelMap['Statistic_TrackArea'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Statistic_TrackArea',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Statistic_TrackArea')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public statisticTrackRegionByMap(
    map: StatisticTrackRegion.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public statisticTrackRegionByMap(
    map: StatisticTrackRegion.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public statisticTrackRegionByMap(
    map: StatisticTrackRegion.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public statisticTrackRegionByMap(
    map: StatisticTrackRegion.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.statisticTrackRegion(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public statisticTrackRegion(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public statisticTrackRegion(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public statisticTrackRegion(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public statisticTrackRegion(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/Statistic/region/track`,
            null,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Statistic_TrackRegion']) {
                this.cancelMap['Statistic_TrackRegion'].next();
            }
            this.cancelMap['Statistic_TrackRegion'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Statistic_TrackRegion']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Statistic_TrackRegion']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Statistic_TrackRegion', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Statistic_TrackRegion']) {
            this.cancelMap['Statistic_TrackRegion'].complete();
            delete this.cancelMap['Statistic_TrackRegion'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Statistic_TrackRegion',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Statistic_TrackRegion')));
        }
        return handle;
    }

  }

export namespace StatisticService {
  export enum Operations {
    Statistic_AreaStatDaily = 'Statistic_AreaStatDaily',
    Statistic_AreaStatHourly = 'Statistic_AreaStatHourly',
    Statistic_AreaVisitors = 'Statistic_AreaVisitors',
    Statistic_RegionStatDaily = 'Statistic_RegionStatDaily',
    Statistic_RegionStatHourly = 'Statistic_RegionStatHourly',
    Statistic_RegionVisitors = 'Statistic_RegionVisitors',
    Statistic_TrackArea = 'Statistic_TrackArea',
    Statistic_TrackRegion = 'Statistic_TrackRegion'
  }
}
