import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../core/authService/authService';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {
  error = false;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {
  }

  ngOnInit(): void {
    const email = this.route.snapshot.paramMap.get('email');
    const token = this.route.snapshot.paramMap.get('token');
    // debugger;
    this.authService.validatePwdLess(email, token)
      .subscribe(value => {
        const authData = this.authService.getTokenData();
        const nameId = authData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
        const givenName = authData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
        if (nameId === givenName) {
          this.router.navigate(['profile']);
        } else {
          this.router.navigate(['/']);
        }
      }, error => {
        this.error = true;
        // console.log(error);
      });
  }

}
