/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ContactDto { 
    exhibitorId?: string;
    userId?: string;
    responsibility?: string;
    chatUser?: boolean;
    isManager?: boolean;
    showOnWebsite?: boolean;
    showPhone?: boolean;
    showAddress?: boolean;
    showImage?: boolean;
    sequence?: number;
    name: string;
    email?: string;
}

/**
 * Namespace for property- and property-value-enumerations of ContactDto.
 */
export namespace ContactDto {
    /**
     * All properties of ContactDto.
     */
    export enum Properties {
        exhibitorId = 'exhibitorId',
        userId = 'userId',
        responsibility = 'responsibility',
        chatUser = 'chatUser',
        isManager = 'isManager',
        showOnWebsite = 'showOnWebsite',
        showPhone = 'showPhone',
        showAddress = 'showAddress',
        showImage = 'showImage',
        sequence = 'sequence',
        name = 'name',
        email = 'email'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ContactDto.
    */
    export const ModelValidators: {[K in keyof ContactDto]: [string, ValidatorFn][]} = {
        exhibitorId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        userId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        responsibility: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        chatUser: [
        ],
        isManager: [
        ],
        showOnWebsite: [
        ],
        showPhone: [
        ],
        showAddress: [
        ],
        showImage: [
        ],
        sequence: [
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(5)],
                ['maxlength', Validators.maxLength(100)],
        ],
        email: [
                ['minlength', Validators.minLength(5)],
                ['maxlength', Validators.maxLength(50)],
        ],
    };

    /**
    * The FormControlFactory for ContactDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ContactDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ContactDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ContactDto = {
            exhibitorId: null,
            userId: null,
            responsibility: null,
            chatUser: null,
            isManager: null,
            showOnWebsite: null,
            showPhone: null,
            showAddress: null,
            showImage: null,
            sequence: null,
            name: null,
            email: null,
          }
        ) {
            super(model, ContactDto.ModelValidators);
        }
    }

}


