<div class="card bg-default pad1">
  <div class="card-header">
    <span class="float-right" *ngIf="documentUrl && documentUrl !== ''">
      <a [href]="documentUrl" target="_blank" download="file.pdf"><i class="fa fa-link"></i> </a>
    </span>
    {{ label }}
    <span class="text-muted ml-3" style="font-size: 0.8em;">Max size: {{ maxFileSize }}MB</span>
  </div>
  <div class="card-body">
    <!-- minimal setup -->
    <label [for]="instanceId + '_fileupload'" class="btn btn-warning btn-block">
      Choose file
    </label>
    <input type="file" [id]="instanceId + '_fileupload'" #fileInput style="display: none" (change)="startRead(this)"
           accept="application/pdf"/>
  </div>
</div>
