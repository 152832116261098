<div>
  <p>Booth sizes</p>
  <div class="row">
    <ng-container *ngFor="let current of availableSizes">
      <div class="col-6" *ngIf="has3d || current.size != 11" [ngClass]="{'bg-warning': current.size == 11}">
        <app-form-toggle fieldName="selected" [fieldSource]="current" (change)="updatedSize(current)"
                         [uncheckedLabel]="current.label"
                         [checkedLabel]="current.label"
        ></app-form-toggle>
      </div>

<!--      <div class="col-sm-4" *ngIf="has3d || current.size != 11" [ngClass]="{'bg-warning': current.size == 11}" style="padding: 0.2em;">-->
<!--        <ui-switch [checked]="current.selected" (change)="updatedSize(current)"></ui-switch>-->
<!--        {{ current.label }}<br/>-->
<!--      </div>-->
    </ng-container>
  </div>
</div>
