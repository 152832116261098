/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface WebexRoomDto { 
    id?: string;
    title?: string;
    type?: string;
}

/**
 * Namespace for property- and property-value-enumerations of WebexRoomDto.
 */
export namespace WebexRoomDto {
    /**
     * All properties of WebexRoomDto.
     */
    export enum Properties {
        id = 'id',
        title = 'title',
        type = 'type'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of WebexRoomDto.
    */
    export const ModelValidators: {[K in keyof WebexRoomDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        title: [
        ],
        type: [
        ],
    };

    /**
    * The FormControlFactory for WebexRoomDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<WebexRoomDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for WebexRoomDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: WebexRoomDto = {
            id: null,
            title: null,
            type: null,
          }
        ) {
            super(model, WebexRoomDto.ModelValidators);
        }
    }

}


