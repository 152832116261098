<div class="card">
  <div class="card-header">
    {{label}}
    <app-help [title]="'Image upload'" [details]="tooltip"></app-help>
  </div>
  <div class="card-body">
    <div class="form-group has-feedback">
      <label for="background" style="width: 100%">Change to preset
        <button class="btn btn-primary btn-xs float-right" (click)="customArt = !customArt; $event.preventDefault();"
                *ngIf="!customArt">Upload
        </button>
        <button class="btn btn-warning btn-xs float-right" (click)="customArt = !customArt; $event.preventDefault();"
                *ngIf="customArt">Change
        </button>
        <button class="btn btn-xs btn-danger float-right" (click)="clear($event)" *ngIf="allowClear" style="margin-right: 1em;">Clear</button>
      </label>
      <select class="form-control" name="background" id="background" *ngIf="!customArt" (ngModelChange)="updateImage($event)"
              [(ngModel)]="selectedStock">
        <option [ngValue]="null">Default</option>
        <option [ngValue]="stock" *ngFor="let stock of stockMedia">{{ stock.name }}</option>
      </select>
    </div>

    <img [src]="display.url" class="img img-fluid" *ngIf="!customArt && display"/>

    <app-upload #background [label]="label" [imageUrl]="imageUrl" *ngIf="customArt" [ar]="ar"
                [imageId]="imageId" dynamic="true" [tooltip]="tooltip" [allowClear]="false"
                (imageUploaded)="onImageUploaded($event)" [width]="width" [height]="height"
    ></app-upload>
  </div>
</div>
