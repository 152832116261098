/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { IdentityUserOfString } from './identityUserOfString';


export interface IdentityUser extends IdentityUserOfString { 
}

/**
 * Namespace for property- and property-value-enumerations of IdentityUser.
 */
export namespace IdentityUser {
    /**
     * All properties of IdentityUser.
     */
    export enum Properties {
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of IdentityUser.
    */
    export const ModelValidators: {[K in keyof IdentityUser]: [string, ValidatorFn][]} = {
    };

    /**
    * The FormControlFactory for IdentityUser.
    */
    export class FormControlFactory extends BaseFormControlFactory<IdentityUser> {

        /**
         * Constructor.
         *
         * @param model An existing model for IdentityUser.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: IdentityUser = {
          }
        ) {
            super(model, IdentityUser.ModelValidators);
        }
    }

}


