// https://medium.com/@aakashbumiya/auto-reload-for-clients-after-deploy-with-angular-7-production-build-bdc45be9b2bd

import {ApplicationRef, Injectable} from '@angular/core';
import {HttpClient, HttpBackend} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {first} from 'rxjs/operators';
import {concat, interval} from 'rxjs';

@Injectable()
export class VersionCheckService {
  private httpClient: HttpClient;

  currentBuildDate: Date;

  constructor(private handler: HttpBackend, private appRef: ApplicationRef) {
    this.httpClient = new HttpClient(handler);

    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    // const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHours$ = interval(30 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => {
      const url = `/assets/version.json?ts=${new Date().getTime()}`;
      this.httpClient.get(url, {responseType: 'text'})
        .subscribe(
          (response: any) => {
            const ts = response.trim();
            const pattern = `timeStamp: '${environment.timeStamp}'`;
            if (ts !== pattern) {
              alert('A new version of the website is available, reloading');
              document.location.reload();
            }
          },
          (err) => {
            // console.error(err, 'Could not get version');
          }
        );
    });
  }
}
