<div class="form-group has-feedback" [formGroup]="formGroup">
  <label [for]="fieldName">{{ label }}
    <app-help *ngIf="help && help.trim() !== ''"
              [details]="help"></app-help>
  </label>
  <textarea class="form-control"  [id]="fieldName" [name]="fieldName"
            [placeholder]="placeholder"
            [formControlName]="fieldName"></textarea>
  <app-control-validation-results [formGroup]="formGroup"
                                  [validatedControl]="fieldName"></app-control-validation-results>
</div>
