import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {KeyValue} from '@angular/common';

@Component({
  selector: 'app-control-validation-results',
  templateUrl: './control-validation-results.component.html',
  styleUrls: ['./control-validation-results.component.scss']
})
export class ControlValidationResultsComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() validatedControl: string;
  @Input() customErrorMap: { [key: string]: (value: any) => string };
  @Input() customErrors: Array<string>;

  control: AbstractControl;

  errorMap: { [key: string]: (value: any) => string } = {
    'required': (value) => 'Value is required',
    'minlength': (value) => `Min. ${value.requiredLength} characters required, only ${value.actualLength} entered`,
    'maxlength': (value) => `Max. ${value.requiredLength} characters allowed, ${value.actualLength} entered`,
    'email': (value) => `Valid email address required`,
    'pattern': (value) => 'Input should be 6-16 characters long, contain at least one upper case and one lower case ' +
      'character, one number and one special character <span style="font-weight: 900">! " # $ % & \\ \' ( ) * + , - . /',
    'mustMatch': (value) => 'Values must match each other'
  };

  constructor() {
  }

  ngOnInit(): void {
    this.control = this.formGroup.controls[this.validatedControl];
  }

  getError(error: KeyValue<string, any>): string {
    if (this.customErrorMap && this.customErrorMap[error.key]) {
      return this.errorMap[error.key](error.value);
    }
    if (this.errorMap[error.key]) {
      return this.errorMap[error.key](error.value);
    }
    return JSON.stringify(error);
  }
}
