/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ExpoUpdateDtoAllOf { 
    ownerEmail?: string;
    ownerChangeEmail?: string;
    expoConsultantAdd?: string;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoUpdateDtoAllOf.
 */
export namespace ExpoUpdateDtoAllOf {
    /**
     * All properties of ExpoUpdateDtoAllOf.
     */
    export enum Properties {
        ownerEmail = 'ownerEmail',
        ownerChangeEmail = 'ownerChangeEmail',
        expoConsultantAdd = 'expoConsultantAdd'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoUpdateDtoAllOf.
    */
    export const ModelValidators: {[K in keyof ExpoUpdateDtoAllOf]: [string, ValidatorFn][]} = {
        ownerEmail: [
        ],
        ownerChangeEmail: [
        ],
        expoConsultantAdd: [
        ],
    };

    /**
    * The FormControlFactory for ExpoUpdateDtoAllOf.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoUpdateDtoAllOf> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoUpdateDtoAllOf.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoUpdateDtoAllOf = {
            ownerEmail: null,
            ownerChangeEmail: null,
            expoConsultantAdd: null,
          }
        ) {
            super(model, ExpoUpdateDtoAllOf.ModelValidators);
        }
    }

}


