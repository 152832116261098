/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface StorageDto { 
    data?: string;
}

/**
 * Namespace for property- and property-value-enumerations of StorageDto.
 */
export namespace StorageDto {
    /**
     * All properties of StorageDto.
     */
    export enum Properties {
        data = 'data'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of StorageDto.
    */
    export const ModelValidators: {[K in keyof StorageDto]: [string, ValidatorFn][]} = {
        data: [
        ],
    };

    /**
    * The FormControlFactory for StorageDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<StorageDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for StorageDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: StorageDto = {
            data: null,
          }
        ) {
            super(model, StorageDto.ModelValidators);
        }
    }

}


