/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CalendarEntryStageDto } from './calendarEntryStageDto';
import { StageDto } from './stageDto';


export interface StageEventsDto { 
    stage?: StageDto;
    calendar?: Array<CalendarEntryStageDto>;
}

/**
 * Namespace for property- and property-value-enumerations of StageEventsDto.
 */
export namespace StageEventsDto {
    /**
     * All properties of StageEventsDto.
     */
    export enum Properties {
        stage = 'stage',
        calendar = 'calendar'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of StageEventsDto.
    */
    export const ModelValidators: {[K in keyof StageEventsDto]: [string, ValidatorFn][]} = {
        stage: [
        ],
        calendar: [
        ],
    };

    /**
    * The FormControlFactory for StageEventsDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<StageEventsDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for StageEventsDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: StageEventsDto = {
            stage: null,
            calendar: null,
          }
        ) {
            super(model, StageEventsDto.ModelValidators);
        }
    }

}


