<div class="modal-header">
  <div style="width: 100%;">
    <div class="float-right">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <h4 class="modal-title">
      Oauth Integration
    </h4>
  </div>
</div>
<div class="modal-body">
  <p>auth-client works!</p>
  <iframe style="width: 500px; height: 500px;" #iframe></iframe>
  <p>End frame</p>
</div>
<div class="modal-footer" style="justify-content: space-between;">
<!--  <button class="btn btn-danger" (click)="modalRef.dismiss()">Cancel-->
<!--  </button>-->
<!--  <button class="btn btn-success" (click)="submitForm($event)"-->
<!--          [attr.disabled]="saving || !valForm.valid ? 'disabled' : null"-->
<!--          *ngIf="availability.rowId && availability.rowId !== 0">Update-->
<!--  </button>-->
</div>
