<ng-container *ngIf="!submitted">
  <h2 class="text-center pv">User profile</h2>
  <p *ngIf="!registered">
    Please fill out the following form to complete your registration:
  </p>
  <p *ngIf="registered">
    Please fill out to change your displayed name:
  </p>
  <form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="loginForm" novalidate=""
        (submit)="submitForm($event, valForm.value)">
    <div class="form-group has-feedback">
      <div class="input-group">
      <input class="form-control" id="exampleInputEmail1" type="text" name="givenName" placeholder="Your chosen display name"
             autocomplete="givenName" formControlName="givenName" required=""/>
        <div class="input-group-append">
          <span class="input-group-text" id="emailIcon"><i class="fa fa-envelope"></i></span>
        </div>
      </div>
      <span class="text-danger"
            *ngIf="valForm.controls['givenName'].hasError('required') && (valForm.controls['givenName'].dirty || valForm.controls['givenName'].touched)">This field is required</span>
      <span class="text-danger"
            *ngIf="valForm.controls['givenName'].hasError('minlength') && (valForm.controls['givenName'].dirty || valForm.controls['givenName'].touched)"
            [translate]="'profile.fieldLength'" [translateParams]="{min: 6, max: 100}"></span>
      <span class="text-danger"
            *ngIf="valForm.controls['givenName'].hasError('maxlength') && (valForm.controls['givenName'].dirty || valForm.controls['givenName'].touched)"
            [translate]="'profile.fieldLength'" [translateParams]="{min: 6, max: 100}"></span>
    </div>
    <div class="clearfix">
      <div class="checkbox c-checkbox float-left mt0">
        <label>
          <input type="checkbox" required="" name="account_agreed" formControlName="termsAccepted"/>
          <span class="fa fa-check"></span>I agree with the <a href="https://virtualexpo.info/tos" target="_blank" >terms of service</a>
        </label>
      </div>
    </div>
    <span class="text-danger"
          *ngIf="valForm.controls['termsAccepted'].hasError('required') && (valForm.controls['termsAccepted'].dirty || valForm.controls['termsAccepted'].touched)">You must agree the terms</span>
    <div class="clearfix" *ngIf="errorMessage && errorMessage.length > 0">
      <ul>
        <li class="text-danger" *ngFor="let error of errorMessage">{{ error.description }}</li>
      </ul>
    </div>
    <button class="btn btn-block btn-primary mt-lg" type="submit">Update</button>
    <p style="padding-top: 2em;">
      Here, you can find our  <a href="https://virtualexpo.info/tos" target="_blank" >terms of service</a><br/>
      and our <a href="https://virtualexpo.info/privacy" target="_blank" >privacy information</a>
    </p>
  </form>
</ng-container>
<ng-container *ngIf="submitted">
  <h2 class="text-center pv">Thank you.</h2>
  <p>
    I will now take you to the management system.
  </p>
</ng-container>
