/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface CalendarAvailabilityDto { 
    rowId?: number;
    start?: Date;
    end?: Date;
    interval?: number;
    comment?: string;
    active?: boolean;
    userId?: string;
    name?: string;
    bookedSlotCount?: number;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarAvailabilityDto.
 */
export namespace CalendarAvailabilityDto {
    /**
     * All properties of CalendarAvailabilityDto.
     */
    export enum Properties {
        rowId = 'rowId',
        start = 'start',
        end = 'end',
        interval = 'interval',
        comment = 'comment',
        active = 'active',
        userId = 'userId',
        name = 'name',
        bookedSlotCount = 'bookedSlotCount'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarAvailabilityDto.
    */
    export const ModelValidators: {[K in keyof CalendarAvailabilityDto]: [string, ValidatorFn][]} = {
        rowId: [
        ],
        start: [
        ],
        end: [
        ],
        interval: [
        ],
        comment: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(5000)],
        ],
        active: [
        ],
        userId: [
        ],
        name: [
        ],
        bookedSlotCount: [
        ],
    };

    /**
    * The FormControlFactory for CalendarAvailabilityDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarAvailabilityDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarAvailabilityDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarAvailabilityDto = {
            rowId: null,
            start: null,
            end: null,
            interval: null,
            comment: null,
            active: null,
            userId: null,
            name: null,
            bookedSlotCount: null,
          }
        ) {
            super(model, CalendarAvailabilityDto.ModelValidators);
        }
    }

}


