import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() getReason: boolean;

  reason: string;

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  public decline() {
    if (!this.getReason) {
      this.activeModal.close(false);
    } else {
      this.activeModal.close({result: false});
    }
  }

  public accept() {
    if (!this.getReason) {
      this.activeModal.close(true);
    } else {
      this.activeModal.close({result: true, reason: this.reason});
    }
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
