/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { InventoryItemType } from './inventoryItemType';


export interface ExhibitorDisplayUpdateDto { 
    expoId?: string;
    exhibitorId?: string;
    id?: string;
    sequence?: number;
    image?: string;
    source?: string;
    sourceAdditional?: string;
    link?: string;
    description?: string;
    descriptionLong?: string;
    displayType?: InventoryItemType;
    imageRaw?: string;
    sourceRaw?: string;
    inActive?: boolean;
    rowVersion?: number;
}

/**
 * Namespace for property- and property-value-enumerations of ExhibitorDisplayUpdateDto.
 */
export namespace ExhibitorDisplayUpdateDto {
    /**
     * All properties of ExhibitorDisplayUpdateDto.
     */
    export enum Properties {
        expoId = 'expoId',
        exhibitorId = 'exhibitorId',
        id = 'id',
        sequence = 'sequence',
        image = 'image',
        source = 'source',
        sourceAdditional = 'sourceAdditional',
        link = 'link',
        description = 'description',
        descriptionLong = 'descriptionLong',
        displayType = 'displayType',
        imageRaw = 'imageRaw',
        sourceRaw = 'sourceRaw',
        inActive = 'inActive',
        rowVersion = 'rowVersion'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExhibitorDisplayUpdateDto.
    */
    export const ModelValidators: {[K in keyof ExhibitorDisplayUpdateDto]: [string, ValidatorFn][]} = {
        expoId: [
        ],
        exhibitorId: [
        ],
        id: [
        ],
        sequence: [
        ],
        image: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        source: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        sourceAdditional: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        link: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        description: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        descriptionLong: [
        ],
        displayType: [
        ],
        imageRaw: [
        ],
        sourceRaw: [
        ],
        inActive: [
        ],
        rowVersion: [
        ],
    };

    /**
    * The FormControlFactory for ExhibitorDisplayUpdateDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExhibitorDisplayUpdateDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExhibitorDisplayUpdateDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExhibitorDisplayUpdateDto = {
            expoId: null,
            exhibitorId: null,
            id: null,
            sequence: null,
            image: null,
            source: null,
            sourceAdditional: null,
            link: null,
            description: null,
            descriptionLong: null,
            displayType: null,
            imageRaw: null,
            sourceRaw: null,
            inActive: null,
            rowVersion: null,
          }
        ) {
            super(model, ExhibitorDisplayUpdateDto.ModelValidators);
        }
    }

}


