import {Component, ElementRef, Input, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {IntegrationStartDto, OauthClientService} from '../../../virtual-expo-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {SettingsService} from '../../../core/settings/settings.service';

@Component({
  selector: 'app-auth-client',
  templateUrl: './auth-client.component.html',
  styleUrls: ['./auth-client.component.scss']
})
export class AuthClientComponent implements OnInit {
  @Input() exhibitorId: string;
  @Input() expoId: string;
  @Input() integrationId: string;
  @Input() callbackUrl: string;

  authFrameSource: string;

  @ViewChild('iframe', {static: false}) iframe: ElementRef;

  constructor(
    private sanitizer: DomSanitizer
    , private settingsService: SettingsService
    , private oauthService: OauthClientService
    , public modal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    const intTest: IntegrationStartDto = {
      exhibitorId: this.exhibitorId,
      expoId: this.expoId,
      id: this.integrationId,
      server: this.settingsService.getAppSetting('baseUrl')
    };
    this.oauthService.oauthClientStartIntegration(intTest)
      .subscribe(value => {
        const element = this.iframe.nativeElement;
        element.src = value;
        // this.authFrameSource = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, value);
      });
  }
}
