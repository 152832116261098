/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ExhibitorBagDto { 
    id?: string;
    name?: string;
    company?: string;
    label?: string;
    website?: string;
    logo?: string;
}

/**
 * Namespace for property- and property-value-enumerations of ExhibitorBagDto.
 */
export namespace ExhibitorBagDto {
    /**
     * All properties of ExhibitorBagDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        company = 'company',
        label = 'label',
        website = 'website',
        logo = 'logo'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExhibitorBagDto.
    */
    export const ModelValidators: {[K in keyof ExhibitorBagDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
        ],
        company: [
        ],
        label: [
        ],
        website: [
        ],
        logo: [
        ],
    };

    /**
    * The FormControlFactory for ExhibitorBagDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExhibitorBagDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExhibitorBagDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExhibitorBagDto = {
            id: null,
            name: null,
            company: null,
            label: null,
            website: null,
            logo: null,
          }
        ) {
            super(model, ExhibitorBagDto.ModelValidators);
        }
    }

}


