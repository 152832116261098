/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { StreamType } from './streamType';
import { ExhibitorReferenceDto } from './exhibitorReferenceDto';
import { CalendarEntryType } from './calendarEntryType';
import { CalendarEntrySessionMode } from './calendarEntrySessionMode';
import { CalendarEntrySessionFillMode } from './calendarEntrySessionFillMode';
import { StageMode } from './stageMode';
import { CalendarEntrySessionDataDto } from './calendarEntrySessionDataDto';


export interface CalendarEntrySessionDto { 
    id: number;
    expoId: string;
    label: string;
    entryType?: CalendarEntryType;
    description?: string;
    greeting?: string;
    start?: Date;
    duration?: number;
    logo?: string;
    logoRaw?: string;
    hidden?: boolean;
    background?: string;
    streamType?: StreamType;
    stageMode?: StageMode;
    exhibitors?: Array<ExhibitorReferenceDto>;
    sessionMode?: CalendarEntrySessionMode;
    fillMode?: CalendarEntrySessionFillMode;
    maxSessionSize?: number;
    exhibitorId?: string;
    sessions?: Array<CalendarEntrySessionDataDto>;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarEntrySessionDto.
 */
export namespace CalendarEntrySessionDto {
    /**
     * All properties of CalendarEntrySessionDto.
     */
    export enum Properties {
        id = 'id',
        expoId = 'expoId',
        label = 'label',
        entryType = 'entryType',
        description = 'description',
        greeting = 'greeting',
        start = 'start',
        duration = 'duration',
        logo = 'logo',
        logoRaw = 'logoRaw',
        hidden = 'hidden',
        background = 'background',
        streamType = 'streamType',
        stageMode = 'stageMode',
        exhibitors = 'exhibitors',
        sessionMode = 'sessionMode',
        fillMode = 'fillMode',
        maxSessionSize = 'maxSessionSize',
        exhibitorId = 'exhibitorId',
        sessions = 'sessions'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarEntrySessionDto.
    */
    export const ModelValidators: {[K in keyof CalendarEntrySessionDto]: [string, ValidatorFn][]} = {
        id: [
                ['required', Validators.required],
        ],
        expoId: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        label: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        entryType: [
        ],
        description: [
        ],
        greeting: [
        ],
        start: [
        ],
        duration: [
        ],
        logo: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logoRaw: [
        ],
        hidden: [
        ],
        background: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        streamType: [
        ],
        stageMode: [
        ],
        exhibitors: [
        ],
        sessionMode: [
        ],
        fillMode: [
        ],
        maxSessionSize: [
        ],
        exhibitorId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        sessions: [
        ],
    };

    /**
    * The FormControlFactory for CalendarEntrySessionDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarEntrySessionDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarEntrySessionDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarEntrySessionDto = {
            id: null,
            expoId: null,
            label: null,
            entryType: null,
            description: null,
            greeting: null,
            start: null,
            duration: null,
            logo: null,
            logoRaw: null,
            hidden: null,
            background: null,
            streamType: null,
            stageMode: null,
            exhibitors: null,
            sessionMode: null,
            fillMode: null,
            maxSessionSize: null,
            exhibitorId: null,
            sessions: null,
          }
        ) {
            super(model, CalendarEntrySessionDto.ModelValidators);
        }
    }

}


