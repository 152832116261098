/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface InfoSlideUpdateDtoAllOf { 
    imageRaw?: string;
    exhibitorId?: string;
    expoId?: string;
}

/**
 * Namespace for property- and property-value-enumerations of InfoSlideUpdateDtoAllOf.
 */
export namespace InfoSlideUpdateDtoAllOf {
    /**
     * All properties of InfoSlideUpdateDtoAllOf.
     */
    export enum Properties {
        imageRaw = 'imageRaw',
        exhibitorId = 'exhibitorId',
        expoId = 'expoId'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of InfoSlideUpdateDtoAllOf.
    */
    export const ModelValidators: {[K in keyof InfoSlideUpdateDtoAllOf]: [string, ValidatorFn][]} = {
        imageRaw: [
        ],
        exhibitorId: [
        ],
        expoId: [
        ],
    };

    /**
    * The FormControlFactory for InfoSlideUpdateDtoAllOf.
    */
    export class FormControlFactory extends BaseFormControlFactory<InfoSlideUpdateDtoAllOf> {

        /**
         * Constructor.
         *
         * @param model An existing model for InfoSlideUpdateDtoAllOf.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: InfoSlideUpdateDtoAllOf = {
            imageRaw: null,
            exhibitorId: null,
            expoId: null,
          }
        ) {
            super(model, InfoSlideUpdateDtoAllOf.ModelValidators);
        }
    }

}


