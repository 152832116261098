<div class="modal-header">
    <h4 class="modal-title">Markdown sample</h4>
    <button type="button" class="float-right close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
  <div>
    Markdown is a simplified and more secure way of formatting text on a website without having to know HTML tags.
  </div>
  <div class="row">
    <div class="col-sm-6">
      <textarea id="markdownSample" [(ngModel)]="markdown"
                style="overflow-y:auto; height: 100vh; width: 100%;"></textarea>
    </div>
    <div class="col-sm-6">
      <markdown [data]="markdown"></markdown>
    </div>
  </div>
</div>
