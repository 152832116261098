<!-- START Sidebar (left)-->
<div class="aside-inner">
  <nav class="sidebar" sidebar-anyclick-close="" [class.show-scrollbar]="settings.getLayoutSetting('asideScrollbar')">
    <!-- START sidebar nav-->
    <ul class="sidebar-nav" *ngIf="menuItems">

      <!-- START user info-->
      <!--li class="has-user-block">
          <app-userblock></app-userblock>
      </li-->
      <!-- END user info-->

      <ng-container *ngFor='let item of menuItems; let i = index'>
        <ng-container *ngIf="item && !item.hidden && hasRole(item.role)">
          <!-- menu heading -->
          <li>
            <hr *ngIf="item.heading && i > 0"/>
          </li>
          <li><span *ngIf="item.heading"
                    [ngClass]="{'nav-heading': item.heading}">{{(item.translate | translate) || item.text}}</span></li>
          <li *ngIf="!item.heading" [routerLinkActive]="['active']">
            <!-- external links -->
            <a *ngIf="!item.heading && !item.submenu && item.elink && hasRole(item.role)" [attr.target]="item.target"
               [attr.href]="item.elink" title="{{item.text}}">
          <span class="float-right" *ngIf="item.alert"
                [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
              <em class="fas {{item.icon}}" *ngIf="item.icon"></em>
              <span>{{(item.translate | translate) || item.text}}</span>
            </a>
            <!-- single menu item -->
            <a *ngIf="!item.heading && !item.submenu && !item.elink && hasRole(item.role)" [routerLink]="item.link"
               [attr.route]="item.link" title="{{item.text}}"
               (click)="toggleSubmenuClick($event, item)" (mouseenter)="toggleSubmenuHover($event)">
          <span class="float-right" *ngIf="item.alert"
                [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
              <em class="fas {{item.icon}}" *ngIf="item.icon"></em>
              <span>{{(item.translate | translate) || item.text}}</span>
            </a>
            <!-- has submenu -->
            <a *ngIf="!item.heading && item.submenu && hasRole(item.role)" title="{{item.text}}"
               (click)="toggleSubmenuClick($event, item)" (mouseenter)="toggleSubmenuHover($event)">
          <span class="float-right" *ngIf="item.alert"
                [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
              <em class="fas {{item.icon}}" *ngIf="item.icon"></em>
              <span>{{(item.translate | translate) || item.text}}</span>
            </a>
            <!-- SUBLEVEL -->
            <ul *ngIf="item.submenu && hasRole(item.role)" class="nav sidebar-subnav" [routerLinkActive]="['opening']"
                [ngClass]="{'opening': item.opened}">
              <li class="sidebar-subnav-header">{{(item.translate | translate) || item.text}}</li>
              <li *ngFor='let subitem of item.submenu' [routerLinkActive]="['active']">
                <ng-container *ngIf="subitem">
                  <!-- sublevel: external links -->
                  <a *ngIf="!subitem.heading && !subitem.submenu && subitem.elink" [attr.target]="subitem.target"
                     [attr.href]="subitem.elink" title="{{subitem.text}}">
              <span class="float-right" *ngIf="subitem.alert"
                    [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                    <em class="fas {{subitem.icon}}" *ngIf="subitem.icon"></em>
                    <span>{{(subitem.translate | translate) || subitem.text}}</span>
                  </a>
                  <!-- sublevel: passwort popup  -->
                  <a *ngIf="subitem.link === 'setPassword'" href="#" (click)="setPassword($event)" [attr.route]="subitem.link"
                     title="{{subitem.text}}">
              <span class="float-right" *ngIf="subitem.alert"
                    [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                    <em class="fas {{subitem.icon}}" *ngIf="subitem.icon"></em>
                    <span>{{(subitem.translate | translate) || subitem.text}}</span>
                  </a>
                  <a *ngIf="subitem.link === 'setUserPassword'" href="#" (click)="setUserPassword($event)"
                     [attr.route]="subitem.link"
                     title="{{subitem.text}}">
              <span class="float-right" *ngIf="subitem.alert"
                    [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                    <em class="fas {{subitem.icon}}" *ngIf="subitem.icon"></em>
                    <span>{{(subitem.translate | translate) || subitem.text}}</span>
                  </a>
                  <a *ngIf="subitem.link === 'getUserToken'" href="#" (click)="getUserToken($event)"
                     [attr.route]="subitem.link"
                     title="{{subitem.text}}">
              <span class="float-right" *ngIf="subitem.alert"
                    [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                    <em class="fas {{subitem.icon}}" *ngIf="subitem.icon"></em>
                    <span>{{(subitem.translate | translate) || subitem.text}}</span>
                  </a>
                  <!-- sublevel: single menu item  -->
                  <a *ngIf="!subitem.submenu && !subitem.elink && subitem.link !== 'setPassword' && subitem.link !== 'setUserPassword' && subitem.link !== 'getUserToken'"
                     [routerLink]="subitem.link" [attr.route]="subitem.link"
                     title="{{subitem.text}}">
              <span class="float-right" *ngIf="subitem.alert"
                    [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                    <em class="fas {{subitem.icon}}" *ngIf="subitem.icon"></em>
                    <span>{{(subitem.translate | translate) || subitem.text}}</span>
                  </a>
                  <!-- sublevel: has submenu -->
                  <a *ngIf="subitem.submenu" title="{{subitem.text}}"
                     (click)="toggleSubmenuClick($event, subitem)" (mouseenter)="toggleSubmenuHover($event)">
              <span class="float-right" *ngIf="subitem.alert"
                    [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                    <em class="fas {{subitem.icon}}" *ngIf="subitem.icon"></em>
                    <span>{{(subitem.translate | translate) || subitem.text}}</span>
                  </a>
                  <!-- SUBLEVEL 2 -->
                  <ul *ngIf="subitem.submenu" class="nav sidebar-subnav level2" [routerLinkActive]="['opening']"
                      [ngClass]="{'opening': subitem.opened}">
                    <li *ngFor='let subitem2 of subitem.submenu' [routerLinkActive]="['active']">
                      <!-- sublevel 2: single menu item  -->
                      <a *ngIf="!subitem2.submenu" [routerLink]="subitem2.link" [attr.route]="subitem2.link"
                         title="{{subitem2.text}}">
                  <span class="float-right" *ngIf="subitem2.alert"
                        [ngClass]="subitem2.label || 'label label-success'">{{subitem2.alert}}</span>
                        <em class="fas {{subitem2.icon}}" *ngIf="subitem2.icon"></em>
                        <span>{{(subitem2.translate | translate) || subitem2.text}}</span>
                      </a>
                      <!-- sublevel2: has submenu -->
                      <a *ngIf="subitem2.submenu" title="{{subitem2.text}}"
                         (click)="toggleSubmenuClick($event, subitem2)" (mouseenter)="toggleSubmenuHover($event)">
                  <span class="float-right" *ngIf="subitem2.alert"
                        [ngClass]="subitem2.label || 'label label-success'">{{subitem2.alert}}</span>
                        <em class="fas {{subitem2.icon}}" *ngIf="subitem2.icon"></em>
                        <span>{{(subitem2.translate | translate) || subitem2.text}}</span>
                      </a>
                      <!-- SUBLEVEL 3 -->
                      <ul *ngIf="subitem2.submenu" class="nav sidebar-subnav level3" [routerLinkActive]="['opening']"
                          [ngClass]="{'opening': subitem2.opened}">
                        <li *ngFor='let subitem3 of subitem2.submenu' [routerLinkActive]="['active']">
                          <!-- sublevel 2: single menu item  -->
                          <a *ngIf="!subitem3.submenu" [routerLink]="subitem3.link" [attr.route]="subitem3.link"
                             title="{{subitem3.text}}">
                      <span class="float-right" *ngIf="subitem3.alert"
                            [ngClass]="subitem3.label || 'label label-success'">{{subitem3.alert}}</span>
                            <em class="fas {{subitem3.icon}}" *ngIf="subitem3.icon"></em>
                            <span>{{(subitem3.translate | translate) || subitem3.text}}</span>
                          </a>
                          <!-- sublevel3: has submenu -->
                          <a *ngIf="subitem3.submenu" title="{{subitem3.text}}"
                             (click)="toggleSubmenuClick($event, subitem3)" (mouseenter)="toggleSubmenuHover($event)">
                      <span class="float-right" *ngIf="subitem3.alert"
                            [ngClass]="subitem3.label || 'label label-success'">{{subitem3.alert}}</span>
                            <em class="fas {{subitem3.icon}}" *ngIf="subitem3.icon"></em>
                            <span>{{(subitem3.translate | translate) || subitem3.text}}</span>
                          </a>
                          <!-- SUBLEVEL 4 -->
                          <ul *ngIf="subitem3.submenu" class="nav sidebar-subnav level3"
                              [routerLinkActive]="['opening']"
                              [ngClass]="{'opening': subitem3.opened}">
                            <li *ngFor='let subitem4 of subitem3.submenu' [routerLinkActive]="['active']">
                              <!-- sublevel 2: single menu item  -->
                              <a *ngIf="!subitem4.submenu" [routerLink]="subitem4.link" [attr.route]="subitem4.link"
                                 title="{{subitem4.text}}">
                          <span class="float-right" *ngIf="subitem4.alert"
                                [ngClass]="subitem4.label || 'label label-success'">{{subitem4.alert}}</span>
                                <em class="fas {{subitem4.icon}}" *ngIf="subitem4.icon"></em>
                                <span>{{(subitem4.translate | translate) || subitem4.text}}</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    <li>
                  </ul>
                </ng-container>
              <li>
            </ul>
          </li>
        </ng-container>
      </ng-container>

    </ul>
    <!-- END sidebar nav-->

  </nav>
</div>
<!-- END Sidebar (left)-->
