<div class="modal-header">
  <h4 class="modal-title float-left">Send message</h4>
  <button type="button" class="close float-right" aria-label="Close" (click)="modalRef.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="form-validate mb-lg" role="form" name="expoForm" novalidate="">
  <div class="form-group has-feedback">
    <label for="message">Please enter your message</label>
    <input class="form-control" id="message" type="text" name="message" placeholder="Enter message" autocomplete="off"
           [(ngModel)]="message"/>
  </div>
  </form>
</div>
<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <button class="btn btn-block btn-danger" (click)="modalRef.dismiss()">Cancel</button>
      </div>
      <div class="col-md-3">
        <button class="btn btn-block btn-success" (click)="submitForm($event)">Update</button>
      </div>
    </div>
  </div>
</div>
