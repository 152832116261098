import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sequenceSort'
})
export class SequenceSortPipe implements PipeTransform {

  transform(values: any[], args?: any): any {
    return values.sort((a, b) => a.sequence - b.sequence);
  }
}
