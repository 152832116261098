/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { LoginDto } from '../model/loginDto';
import { LoginResultDto } from '../model/loginResultDto';
import { PasswordSetDto } from '../model/passwordSetDto';
import { PwdLessCallbackDto } from '../model/pwdLessCallbackDto';
import { PwdLessLoginDto } from '../model/pwdLessLoginDto';
import { PwdLessProfileDto } from '../model/pwdLessProfileDto';
import { TokenGetDto } from '../model/tokenGetDto';
import { UserPasswordSetDto } from '../model/userPasswordSetDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for accountGetUserToken.
 */
export namespace AccountGetUserToken {
    /**
     * Parameter map for accountGetUserToken.
     */
    export interface PartialParamMap {
      TokenGetDto: TokenGetDto;
    }

    /**
     * Enumeration of all parameters for accountGetUserToken.
     */
    export enum Parameters {
      TokenGetDto = 'TokenGetDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountGetUserToken
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountGetUserToken.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountLogin.
 */
export namespace AccountLogin {
    /**
     * Parameter map for accountLogin.
     */
    export interface PartialParamMap {
      /**
       * The model.
       */
      LoginDto: LoginDto;
    }

    /**
     * Enumeration of all parameters for accountLogin.
     */
    export enum Parameters {
      /**
       * The model.
       */
      LoginDto = 'LoginDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountLogin
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountLogin.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountPwdLessCallback.
 */
export namespace AccountPwdLessCallback {
    /**
     * Parameter map for accountPwdLessCallback.
     */
    export interface PartialParamMap {
      PwdLessCallbackDto: PwdLessCallbackDto;
    }

    /**
     * Enumeration of all parameters for accountPwdLessCallback.
     */
    export enum Parameters {
      PwdLessCallbackDto = 'PwdLessCallbackDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountPwdLessCallback
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountPwdLessCallback.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountPwdLessProfile.
 */
export namespace AccountPwdLessProfile {
    /**
     * Parameter map for accountPwdLessProfile.
     */
    export interface PartialParamMap {
      PwdLessProfileDto: PwdLessProfileDto;
    }

    /**
     * Enumeration of all parameters for accountPwdLessProfile.
     */
    export enum Parameters {
      PwdLessProfileDto = 'PwdLessProfileDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountPwdLessProfile
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountPwdLessProfile.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountPwdLessStart.
 */
export namespace AccountPwdLessStart {
    /**
     * Parameter map for accountPwdLessStart.
     */
    export interface PartialParamMap {
      PwdLessLoginDto: PwdLessLoginDto;
    }

    /**
     * Enumeration of all parameters for accountPwdLessStart.
     */
    export enum Parameters {
      PwdLessLoginDto = 'PwdLessLoginDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountPwdLessStart
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountPwdLessStart.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountPwdLessTotpCallback.
 */
export namespace AccountPwdLessTotpCallback {
    /**
     * Parameter map for accountPwdLessTotpCallback.
     */
    export interface PartialParamMap {
      PwdLessCallbackDto: PwdLessCallbackDto;
    }

    /**
     * Enumeration of all parameters for accountPwdLessTotpCallback.
     */
    export enum Parameters {
      PwdLessCallbackDto = 'PwdLessCallbackDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountPwdLessTotpCallback
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountPwdLessTotpCallback.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountRefresh.
 */
export namespace AccountRefresh {
    /**
     * Parameter map for accountRefresh.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for accountRefresh.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountRefresh
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountRefresh.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountSetPassword.
 */
export namespace AccountSetPassword {
    /**
     * Parameter map for accountSetPassword.
     */
    export interface PartialParamMap {
      PasswordSetDto: PasswordSetDto;
    }

    /**
     * Enumeration of all parameters for accountSetPassword.
     */
    export enum Parameters {
      PasswordSetDto = 'PasswordSetDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountSetPassword
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountSetPassword.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for accountSetUserPassword.
 */
export namespace AccountSetUserPassword {
    /**
     * Parameter map for accountSetUserPassword.
     */
    export interface PartialParamMap {
      UserPasswordSetDto: UserPasswordSetDto;
    }

    /**
     * Enumeration of all parameters for accountSetUserPassword.
     */
    export enum Parameters {
      UserPasswordSetDto = 'UserPasswordSetDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of accountSetUserPassword
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof AccountSetUserPassword.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}



@Injectable({
  providedIn: 'root'
})
export class AccountService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountGetUserTokenByMap(
    map: AccountGetUserToken.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public accountGetUserTokenByMap(
    map: AccountGetUserToken.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public accountGetUserTokenByMap(
    map: AccountGetUserToken.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public accountGetUserTokenByMap(
    map: AccountGetUserToken.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountGetUserToken(
      map.TokenGetDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param TokenGetDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountGetUserToken(TokenGetDto: TokenGetDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public accountGetUserToken(TokenGetDto: TokenGetDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public accountGetUserToken(TokenGetDto: TokenGetDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public accountGetUserToken(TokenGetDto: TokenGetDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (TokenGetDto === null || TokenGetDto === undefined) {
            throw new Error('Required parameter TokenGetDto was null or undefined when calling accountGetUserToken.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<string>(`${this.configuration.basePath}/api/Account/manage/gettoken`,
            TokenGetDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_GetUserToken']) {
                this.cancelMap['Account_GetUserToken'].next();
            }
            this.cancelMap['Account_GetUserToken'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_GetUserToken']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_GetUserToken']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_GetUserToken', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_GetUserToken']) {
            this.cancelMap['Account_GetUserToken'].complete();
            delete this.cancelMap['Account_GetUserToken'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_GetUserToken',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_GetUserToken')));
        }
        return handle;
    }


  /**
   * Account login by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountLoginByMap(
    map: AccountLogin.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
  public accountLoginByMap(
    map: AccountLogin.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
  public accountLoginByMap(
    map: AccountLogin.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
  public accountLoginByMap(
    map: AccountLogin.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountLogin(
      map.LoginDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Account login
     * 
     * @param LoginDto The model.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountLogin(LoginDto: LoginDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
    public accountLogin(LoginDto: LoginDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
    public accountLogin(LoginDto: LoginDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
    public accountLogin(LoginDto: LoginDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (LoginDto === null || LoginDto === undefined) {
            throw new Error('Required parameter LoginDto was null or undefined when calling accountLogin.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<LoginResultDto>(`${this.configuration.basePath}/api/Account/login`,
            LoginDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_Login']) {
                this.cancelMap['Account_Login'].next();
            }
            this.cancelMap['Account_Login'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_Login']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_Login']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_Login', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_Login']) {
            this.cancelMap['Account_Login'].complete();
            delete this.cancelMap['Account_Login'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_Login',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_Login')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountPwdLessCallbackByMap(
    map: AccountPwdLessCallback.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
  public accountPwdLessCallbackByMap(
    map: AccountPwdLessCallback.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
  public accountPwdLessCallbackByMap(
    map: AccountPwdLessCallback.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
  public accountPwdLessCallbackByMap(
    map: AccountPwdLessCallback.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountPwdLessCallback(
      map.PwdLessCallbackDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param PwdLessCallbackDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountPwdLessCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
    public accountPwdLessCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
    public accountPwdLessCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
    public accountPwdLessCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (PwdLessCallbackDto === null || PwdLessCallbackDto === undefined) {
            throw new Error('Required parameter PwdLessCallbackDto was null or undefined when calling accountPwdLessCallback.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<LoginResultDto>(`${this.configuration.basePath}/api/Account/pwdless/callback`,
            PwdLessCallbackDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_PwdLessCallback']) {
                this.cancelMap['Account_PwdLessCallback'].next();
            }
            this.cancelMap['Account_PwdLessCallback'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_PwdLessCallback']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_PwdLessCallback']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_PwdLessCallback', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_PwdLessCallback']) {
            this.cancelMap['Account_PwdLessCallback'].complete();
            delete this.cancelMap['Account_PwdLessCallback'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_PwdLessCallback',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_PwdLessCallback')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountPwdLessProfileByMap(
    map: AccountPwdLessProfile.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
  public accountPwdLessProfileByMap(
    map: AccountPwdLessProfile.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
  public accountPwdLessProfileByMap(
    map: AccountPwdLessProfile.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
  public accountPwdLessProfileByMap(
    map: AccountPwdLessProfile.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountPwdLessProfile(
      map.PwdLessProfileDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param PwdLessProfileDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountPwdLessProfile(PwdLessProfileDto: PwdLessProfileDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
    public accountPwdLessProfile(PwdLessProfileDto: PwdLessProfileDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
    public accountPwdLessProfile(PwdLessProfileDto: PwdLessProfileDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
    public accountPwdLessProfile(PwdLessProfileDto: PwdLessProfileDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (PwdLessProfileDto === null || PwdLessProfileDto === undefined) {
            throw new Error('Required parameter PwdLessProfileDto was null or undefined when calling accountPwdLessProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<LoginResultDto>(`${this.configuration.basePath}/api/Account/pwdless/profile`,
            PwdLessProfileDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_PwdLessProfile']) {
                this.cancelMap['Account_PwdLessProfile'].next();
            }
            this.cancelMap['Account_PwdLessProfile'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_PwdLessProfile']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_PwdLessProfile']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_PwdLessProfile', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_PwdLessProfile']) {
            this.cancelMap['Account_PwdLessProfile'].complete();
            delete this.cancelMap['Account_PwdLessProfile'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_PwdLessProfile',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_PwdLessProfile')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountPwdLessStartByMap(
    map: AccountPwdLessStart.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public accountPwdLessStartByMap(
    map: AccountPwdLessStart.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public accountPwdLessStartByMap(
    map: AccountPwdLessStart.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public accountPwdLessStartByMap(
    map: AccountPwdLessStart.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountPwdLessStart(
      map.PwdLessLoginDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param PwdLessLoginDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountPwdLessStart(PwdLessLoginDto: PwdLessLoginDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public accountPwdLessStart(PwdLessLoginDto: PwdLessLoginDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public accountPwdLessStart(PwdLessLoginDto: PwdLessLoginDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public accountPwdLessStart(PwdLessLoginDto: PwdLessLoginDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (PwdLessLoginDto === null || PwdLessLoginDto === undefined) {
            throw new Error('Required parameter PwdLessLoginDto was null or undefined when calling accountPwdLessStart.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/Account/pwdless`,
            PwdLessLoginDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_PwdLessStart']) {
                this.cancelMap['Account_PwdLessStart'].next();
            }
            this.cancelMap['Account_PwdLessStart'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_PwdLessStart']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_PwdLessStart']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_PwdLessStart', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_PwdLessStart']) {
            this.cancelMap['Account_PwdLessStart'].complete();
            delete this.cancelMap['Account_PwdLessStart'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_PwdLessStart',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_PwdLessStart')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountPwdLessTotpCallbackByMap(
    map: AccountPwdLessTotpCallback.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
  public accountPwdLessTotpCallbackByMap(
    map: AccountPwdLessTotpCallback.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
  public accountPwdLessTotpCallbackByMap(
    map: AccountPwdLessTotpCallback.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
  public accountPwdLessTotpCallbackByMap(
    map: AccountPwdLessTotpCallback.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountPwdLessTotpCallback(
      map.PwdLessCallbackDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param PwdLessCallbackDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountPwdLessTotpCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
    public accountPwdLessTotpCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
    public accountPwdLessTotpCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
    public accountPwdLessTotpCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (PwdLessCallbackDto === null || PwdLessCallbackDto === undefined) {
            throw new Error('Required parameter PwdLessCallbackDto was null or undefined when calling accountPwdLessTotpCallback.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<LoginResultDto>(`${this.configuration.basePath}/api/Account/pwdless/totpcallback`,
            PwdLessCallbackDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_PwdLessTotpCallback']) {
                this.cancelMap['Account_PwdLessTotpCallback'].next();
            }
            this.cancelMap['Account_PwdLessTotpCallback'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_PwdLessTotpCallback']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_PwdLessTotpCallback']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_PwdLessTotpCallback', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_PwdLessTotpCallback']) {
            this.cancelMap['Account_PwdLessTotpCallback'].complete();
            delete this.cancelMap['Account_PwdLessTotpCallback'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_PwdLessTotpCallback',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_PwdLessTotpCallback')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountRefreshByMap(
    map: AccountRefresh.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
  public accountRefreshByMap(
    map: AccountRefresh.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
  public accountRefreshByMap(
    map: AccountRefresh.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
  public accountRefreshByMap(
    map: AccountRefresh.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountRefresh(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountRefresh(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
    public accountRefresh(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
    public accountRefresh(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
    public accountRefresh(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post<LoginResultDto>(`${this.configuration.basePath}/api/Account/refresh`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_Refresh']) {
                this.cancelMap['Account_Refresh'].next();
            }
            this.cancelMap['Account_Refresh'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_Refresh']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_Refresh']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_Refresh', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_Refresh']) {
            this.cancelMap['Account_Refresh'].complete();
            delete this.cancelMap['Account_Refresh'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_Refresh',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_Refresh')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountSetPasswordByMap(
    map: AccountSetPassword.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
  public accountSetPasswordByMap(
    map: AccountSetPassword.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
  public accountSetPasswordByMap(
    map: AccountSetPassword.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
  public accountSetPasswordByMap(
    map: AccountSetPassword.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountSetPassword(
      map.PasswordSetDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param PasswordSetDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountSetPassword(PasswordSetDto: PasswordSetDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
    public accountSetPassword(PasswordSetDto: PasswordSetDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
    public accountSetPassword(PasswordSetDto: PasswordSetDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
    public accountSetPassword(PasswordSetDto: PasswordSetDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (PasswordSetDto === null || PasswordSetDto === undefined) {
            throw new Error('Required parameter PasswordSetDto was null or undefined when calling accountSetPassword.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<LoginResultDto>(`${this.configuration.basePath}/api/Account/pwdless/setpassword`,
            PasswordSetDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_SetPassword']) {
                this.cancelMap['Account_SetPassword'].next();
            }
            this.cancelMap['Account_SetPassword'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_SetPassword']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_SetPassword']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_SetPassword', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_SetPassword']) {
            this.cancelMap['Account_SetPassword'].complete();
            delete this.cancelMap['Account_SetPassword'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_SetPassword',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_SetPassword')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public accountSetUserPasswordByMap(
    map: AccountSetUserPassword.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public accountSetUserPasswordByMap(
    map: AccountSetUserPassword.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public accountSetUserPasswordByMap(
    map: AccountSetUserPassword.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public accountSetUserPasswordByMap(
    map: AccountSetUserPassword.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.accountSetUserPassword(
      map.UserPasswordSetDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param UserPasswordSetDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public accountSetUserPassword(UserPasswordSetDto: UserPasswordSetDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public accountSetUserPassword(UserPasswordSetDto: UserPasswordSetDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public accountSetUserPassword(UserPasswordSetDto: UserPasswordSetDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public accountSetUserPassword(UserPasswordSetDto: UserPasswordSetDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (UserPasswordSetDto === null || UserPasswordSetDto === undefined) {
            throw new Error('Required parameter UserPasswordSetDto was null or undefined when calling accountSetUserPassword.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/Account/manage/setuserpassword`,
            UserPasswordSetDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Account_SetUserPassword']) {
                this.cancelMap['Account_SetUserPassword'].next();
            }
            this.cancelMap['Account_SetUserPassword'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Account_SetUserPassword']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Account_SetUserPassword']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Account_SetUserPassword', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Account_SetUserPassword']) {
            this.cancelMap['Account_SetUserPassword'].complete();
            delete this.cancelMap['Account_SetUserPassword'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Account_SetUserPassword',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Account_SetUserPassword')));
        }
        return handle;
    }

  }

export namespace AccountService {
  export enum Operations {
    Account_GetUserToken = 'Account_GetUserToken',
    Account_Login = 'Account_Login',
    Account_PwdLessCallback = 'Account_PwdLessCallback',
    Account_PwdLessProfile = 'Account_PwdLessProfile',
    Account_PwdLessStart = 'Account_PwdLessStart',
    Account_PwdLessTotpCallback = 'Account_PwdLessTotpCallback',
    Account_Refresh = 'Account_Refresh',
    Account_SetPassword = 'Account_SetPassword',
    Account_SetUserPassword = 'Account_SetUserPassword'
  }
}
