<div class="modal-header">
  <h1>Set a user password</h1>
</div>
<form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="registerForm" novalidate=""
      (submit)="submitForm($event, valForm.value)">
  <div class="modal-body">
    <div>
      <p>
        Force set password for a given email address
      </p>
      <app-form-string-input [formGroup]="valForm" fieldName="email"></app-form-string-input>
      <app-form-string-input [formGroup]="valForm" fieldName="password"></app-form-string-input>
      <app-form-string-input [formGroup]="valForm" fieldName="confirmPassword"></app-form-string-input>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-block btn-primary mt-lg" type="submit" [disabled]="submitted || !valForm.value">Update
      password
    </button>
  </div>
</form>
