import {Injectable} from '@angular/core';
import {MarkdownService} from 'ngx-markdown';
import {ImageUrlService} from './image-url.service';

@Injectable({
  providedIn: 'root'
})
export class CanvasRendererService {
  renderCache: { [p: string]: string } = {};

  constructor(
    private markdownService: MarkdownService
    , private imageUrlService: ImageUrlService
  ) {
  }


  private render_html_to_canvas(html: string, ctx: HTMLCanvasElement, x: number, y: number
    , width: number, height: number, style: string = ''): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const data = 'data:image/svg+xml;charset=utf-8,' + '<svg xmlns="http://www.w3.org/2000/svg" width="' + width + '" height="' + height + '">' +
        '<foreignObject width="100%" height="100%">' +
        this.html_to_xml(html, style) +
        '</foreignObject>' +
        '</svg>';

      const img = document.createElement('img');
      const self = this;
      img.onload = function () {
        ctx.getContext('2d').drawImage(img, x, y);
        try {
          const result = ctx.toDataURL('PNG');
          resolve(result);
        } catch (e) {
        }
      };
      img.onabort = function (ev) {
        ctx.parentNode.removeChild(ctx);
        reject(ev);
      };
      img.onerror = function (ev) {
        ctx.parentNode.removeChild(ctx);
        reject(ev);
      };
      img.src = data;
    });
  }

  private html_to_xml(html: string, style: string) {
    const doc = document.implementation.createHTMLDocument('');
    if (style && style !== '') {
      // doc.write(`<style>.inner { ${style} }</style>`);
      doc.write(`<style>.inner {`);
      style.split(';').forEach(value => {
        doc.write(value.trim() + ';');
      });
      // doc.write(`<style>.inner { ${style} }</style>`);
      doc.write(`}</style>`);
      doc.write(`<div class='inner'>${html}</div>`);
    } else {
      doc.write(html);
    }

    // You must manually set the xmlns if you intend to immediately serialize
    // the HTML document to a string as opposed to appending it to a
    // <foreignObject> in the DOM
    doc.documentElement.setAttribute('xmlns', doc.documentElement.namespaceURI);

    // Get well-formed markup
    html = (new XMLSerializer).serializeToString(doc.body);
    return html;
  }

  render_markdown(markdownSource: string, imageUrl: string, width: number, height: number, fontSize: number = 18): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const cacheKey = `${markdownSource}${imageUrl}${width}${height}`;
      if (this.renderCache[cacheKey]) {
        resolve(this.renderCache[cacheKey]);
        return;
      }
      const ctx = document.createElement('canvas') as HTMLCanvasElement;
      ctx.width = width;
      ctx.height = height;
      ctx.style.display = 'none';
      document.body.appendChild(ctx);

      const desc = markdownSource;
      const image = imageUrl;

      ctx.getContext('2d').clearRect(0, 0, width, height);
      if (image && image !== '') {
        const img = new Image();
        img.crossOrigin = 'true';
        const self = this;
        img.onload = function (ev) {
          const img2 = ev.currentTarget as HTMLImageElement;
          ctx.getContext('2d').drawImage(img2, 0, 0, img2.naturalWidth, img2.naturalHeight, 0, 0, width, height);
          if (desc && desc.trim() !== '') {
            let html = self.markdownService.compile(desc);
            html = `<div style="position: absolute; width: 90%; left: 2.5%; top: 2.5%; font-size: ${fontSize}px; color: black; text-shadow: 0 0 2px rgba(255,255,255,1), 0 0 4px rgba(255,255,255,1), 0 0 6px rgba(255,255,255,0.5), 0 0 8px rgba(255,255,255,0.5); font-weight: bold; background: white; opacity: 0.6; padding: 1%; border-radius: 1em;">` +
              html +
              '</div>';
            self.render_html_to_canvas(html, ctx, 0, 0, width, height)
              .then(value => {
                ctx.parentNode.removeChild(ctx);
                self.renderCache[cacheKey] = value;
                resolve(value);
              }, reason => {
                reject(reason);
              });
          } else {
            try {
              const value = ctx.toDataURL('PNG');
              ctx.parentNode.removeChild(ctx);
              self.renderCache[cacheKey] = value;
              resolve(value);
            } catch (e) {
// debugger;
            }
          }
        };
        img.src = this.imageUrlService.getImage(image, null);
      } else {
        let html = this.markdownService.compile(desc);
        html = `<div style="position: absolute; width: 90%; left: 5%; top: 5%; font-size: ${fontSize}px; color: black;` +
          ` text-shadow: 0 0 2px rgba(255,255,255,1), 0 0 4px rgba(255,255,255,1), 0 0 6px rgba(255,255,255,0.5),` +
          ` 0 0 8px rgba(255,255,255,0.5); font-weight: bold; background: white; opacity: 0.6; padding: 2%; border-radius: 1em;">` +
          html +
          '</div>';
        this.render_html_to_canvas(html, ctx, 0, 0, width, height)
          .then(value => {
            ctx.parentNode.removeChild(ctx);
            this.renderCache[cacheKey] = value;
            resolve(value);
          }, reason => {
            reject(reason);
          });
      }
    });
  }

  render_text(desc: string, style: string, width: number, height: number): Promise<string> {
    return new Promise<string>(resolve => {
      if (this.renderCache[desc]) {
        resolve(this.renderCache[desc]);
        return;
      }
      const ctx = document.createElement('canvas') as HTMLCanvasElement;
      ctx.width = width;
      ctx.height = height;
      ctx.style.display = 'none';
      document.body.appendChild(ctx);

      ctx.getContext('2d').clearRect(0, 0, width, height);

      this.render_html_to_canvas(desc, ctx, 0, 0, width, height, style)
        .then(value => {
          this.renderCache[desc] = value;
          ctx.parentNode.removeChild(ctx);
          resolve(value);
        }, reason => {
          // console.log(reason);
        });
    });
  }
}
