<div class="modal-header">
  <h1>Set a password</h1>
</div>
<form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="registerForm" novalidate=""
      (submit)="submitForm($event, valForm.value)">
  <div class="modal-body">
    <div>
      <p>
        In addition to using the passwordless entry mode, you can set a password for your account, in case you might
        not
        have access to your email account.
      </p>
      <div class="form-group has-feedback">
        <label class="text-muted">Password</label>
        <div class="input-group">
          <input class="form-control" id="id-password" type="password" name="password" formControlName="password"
                 autocomplete="new-password"
          />
          <div class="input-group-append">
            <span class="input-group-text" id="passwordIcon"><i class="fa fa-lock"></i></span>
          </div>
        </div>
        <app-control-validation-results [formGroup]="valForm" [validatedControl]="'password'"></app-control-validation-results>
<!--        <span class="text-danger"-->
<!--              *ngIf="valForm.get('password').hasError('required') && (valForm.get('password').dirty || valForm.get('password').touched)">This field is required</span>-->
<!--        <span class="text-danger"-->
<!--              *ngIf="valForm.get('password').hasError('pattern') && (valForm.get('password').dirty || valForm.get('password').touched)">Input should be 6-16 characters long, contain at least one upper case and one lower case character, one number and one special character <span-->
<!--          style="font-weight: bolder">! @ # $ % ^ &</span></span>-->
      </div>
      <div class="form-group has-feedback">
        <label class="text-muted">Retype Password</label>
        <div class="input-group">
          <input class="form-control" type="password" name="confirmPassword" formControlName="confirmPassword"
                 autocomplete="new-password"
          />
          <div class="input-group-append">
            <span class="input-group-text" id="passwordIcon2"><i class="fa fa-lock"></i></span>
          </div>
        </div>
        <app-control-validation-results [formGroup]="valForm" [validatedControl]="'confirmPassword'"></app-control-validation-results>
<!--        <span class="text-danger"-->
<!--              *ngIf="valForm.get('confirmPassword').hasError('equalTo')">Password does Not match</span>-->
      </div>
    </div>
<!--    <div class="clearfix" *ngIf="errorMessage">-->
<!--      <ul>-->
<!--        <li class="text-danger" *ngFor="let error of errorMessage">{{ error.description }}</li>-->
<!--      </ul>-->
<!--    </div>-->
  </div>
  <div class="modal-footer">
    <button class="btn btn-block btn-primary mt-lg" type="submit" [disabled]="submitted || !valForm.value">Update password</button>
  </div>
</form>
