import {Injectable} from '@angular/core';
import {SettingsService} from './settings/settings.service';
import {LinkBase} from '../objects/link-base';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LinkHandlerService {

  lastTs: number;

  constructor(
    private settingsService: SettingsService
  ) {
    if (!this.lastTs || this.lastTs < Date.now() - 5000) {
      this.lastTs = Date.now();
    }
  }

  prepare(name: string, shortKey: string): string {
    name = name.trim();
    name = name.replace(/ /g, '-');
    name = name.replace(/_/g, '-');
    name = name.replace(/[^a-zA-Z0-9äÄöÖüÜß]/g, '-');
    name = name.substr(0, 39);
    // return this.codec.encodeKey(name) + '_' + shortKey;
    return name + '_' + shortKey;
  }

  parse(path: string): string {
    path = path.replace(/.*_/, '');
    return path;
  }

  construct(base: string, main?: LinkBase, sub?: LinkBase): string {
    let area = '';
    if (base === 'stage') {
      area = `/${base}`;
      base = 'exhibition';
    }
    let url = `${this.settingsService.app.clientBase}${base}`;
    if (this.settingsService.app.isTest) {
      url = `${this.settingsService.app.clientBase}${base}`;
    } else if (this.settingsService.app.isBeta) {
      url = `//${environment.betaServer}/${base}`;
    } else if (main.domain && main.domain.trim() !== '') {
      const domain = main.domain.split(',')[0];
      if (domain.match(/local\.test/) || domain.match(/ube-asus/)) {
        // url = `//${domain}${this.settingsService.app.clientPort}/${base}`;
      } else {
        url = `//${domain}/${base}`;
      }
    }
    if (main) {
      url += `/${this.prepare(main.name, main.shortKey)}`;
    }
    if (area) {
      url += area;
    }
    if (sub) {
      url += `/${this.prepare(sub.name, sub.shortKey)}`;
    }
    return url + `?noCache=${this.lastTs}`;
  }

  constructBase(main: LinkBase): string {
    let url = `${this.settingsService.app.clientBase}exhibition`;
    if (main.domain && main.domain.trim() !== '' && environment.production) {
      const domain = main.domain.split(',')[0];
      if (domain.match(/local\.test/) || domain.match(/ube-asus/)) {
        // url = `//${domain}${this.settingsService.app.clientPort}/${base}`;
      } else {
        url = `//${domain}/exhibition`;
      }
    }
    url += `/${this.prepare(main.name, main.shortKey)}`;
    return url;
  }

  constructExpo(main: LinkBase): string {
    const url = this.constructBase(main);
    return url + `?noCache=${this.lastTs}`;
  }

  constructExhibitor(main: LinkBase, sub: LinkBase): string {
    let url = this.constructBase(main);
    if (sub) {
      url += `/${this.prepare(sub.name, sub.shortKey)}`;
    }
    return url + `?noCache=${this.lastTs}`;
  }

  constructStage(main: LinkBase, sub: LinkBase): string {
    let url = this.constructBase(main);
    if (sub) {
      url += `/stage/${this.prepare(sub.name, sub.shortKey)}`;
    }
    return url + `?noCache=${this.lastTs}`;
  }
}
