import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  Display,
  DisplayListDto
} from '../virtual-expo-api';

@Injectable({
  providedIn: 'root'
})
export class ImageUrlService {
  private dateTag: string;

  constructor() {
    this.dateTag = Date.now().toString();
  }

  private static getUrlOrRaw(url: string): string {
    if (url.startsWith('http') || url.startsWith('data')) {
      return url;
    }
    return environment.cdnURL + '/uploads/' + url;
  }

  private parseYoutubeId(url: string): string {
    if (url) {
      let media = url;
      if (media.length > 11) {
        let isId = media.match(/^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/);
        if (isId && isId.length > 3) {
          media = isId[3];
        }
        if (media.length > 11) {
          isId = media.match(/([A-Za-z0-9_\-]{11})/);
          if (isId && isId.length > 1) {
            media = isId[1];
          }
        }
      }
      return media;
    }
    return null;
  }

  getImageLink(display: Display): string {
    let uri = '';
    const url = display.image;
    if (!url || url.trim() === '' || url.trim() === 'new image') {
      return '';
    }
    if ((!display || display.displayType.toString() !== '1') && (url.startsWith('http') || url.startsWith('data'))) {
      uri = url;
    } else {
      if (display.displayType.toString() === '0') {
        return environment.cdnURL + '/uploads/' + display.image + '?ts=' + display.rowVersion;
      } else if (display.displayType === 1) {
        const tmp = this.parseYoutubeId(url);
        if (tmp) {
          uri = 'https://img.youtube.com/vi/' + tmp + '/sddefault.jpg';
        } else {
          uri = environment.cdnURL + '/empty.jpg';
        }
      } else if (display.displayType === 3) {
        if (display.image && display.image !== '' && display.image !== 'DELETED') {
          return environment.cdnURL + '/uploads/' + display.image + '?ts=' + display.rowVersion;
        } else {
          uri = `https://videodelivery.net/${display.source}/thumbnails/thumbnail.jpg`;
          if (display.sourceAdditional && display.sourceAdditional !== '') {
            uri += `?time=${display.sourceAdditional}`;
          }
        }
      } else if (display.displayType === 10) {
        uri = environment.cdnURL + '/uploads/' + url + '?ts=' + Date.now().toString();
      } else {
        uri = environment.cdnURL + '/uploads/' + url + '?ts=' + Date.now().toString();
      }
    }
    return uri;
  }

  getImage(url: string, display?: Display): string {
    if (!url && !display) {
      return environment.cdnURL + '/empty.jpg';
    }
    if ((!display || display.displayType.toString() !== '1') && url && (url.startsWith('http') || url.startsWith('data'))) {
      return url;
    }
    if (display) {
      if (display.displayType.toString() === '0') {
        return environment.cdnURL + '/uploads/' + display.image + '?ts=' + display.rowVersion;
      } else if (display.displayType.toString() === '1') {
        // const tmp = this.parseYoutubeId(url);
        const tmp = url;
        if (tmp) {
          return 'https://img.youtube.com/vi/' + tmp + '/0.jpg';
        } else {
          return environment.cdnURL + '/empty.jpg';
        }
      } else if (display.displayType.toString() === '21') {
        const tmp = display.source;
        if (tmp) {
          return 'https://img.youtube.com/vi/' + tmp + '/0.jpg';
        } else {
          return environment.cdnURL + '/empty.jpg';
        }
      } else if (display.displayType.toString() === '2') {
        if (display.source) {
          if (display.source.startsWith('http')) {
            return display.source;
          } else {
            return 'https://i.vimeocdn.com/video/' + display.source + '.jpg';
          }
        } else {
          return environment.cdnURL + '/empty.jpg';
        }
      } else if (display.displayType.toString() === '22') {
        if (display.source) {
          if (display.source.startsWith('http')) {
            return display.source;
          } else {
            return 'https://i.vimeocdn.com/video/' + display.source + '.jpg';
          }
        } else {
          return environment.cdnURL + '/empty.jpg';
        }
      } else if (display.displayType.toString() === '3') {
        if (display.image && display.image !== '' && display.image !== 'DELETED') {
          return environment.cdnURL + '/uploads/' + display.image + '?ts=' + display.rowVersion;
        } else {
          let link = `https://videodelivery.net/${display.source}/thumbnails/thumbnail.jpg`;
          if (display.sourceAdditional && display.sourceAdditional !== '') {
            link += `?time=${display.sourceAdditional}`;
          }
          return link;
        }
      }
    }
    if (!url) {
      return environment.cdnURL + '/empty.jpg';
    }
    return environment.cdnURL + '/uploads/' + url;
  }

  getImageList(url: string, display?: DisplayListDto): string {
    if (!url && !display) {
      return environment.cdnURL + '/empty.jpg';
    }
    if ((!display || display.displayType.toString() !== '1') && url && (url.startsWith('http') || url.startsWith('data'))) {
      return url;
    }
    if (display) {
      if (display.displayType.toString() === '0') {
        return environment.cdnURL + '/uploads/' + display.image + '?ts=' + display.rowVersion;
      } else if (display.displayType === 1 && url && url.trim() !== '') {
        const tmp = this.parseYoutubeId(url);
        if (tmp) {
          return 'https://img.youtube.com/vi/' + tmp + '/sddefault.jpg';
        } else {
          return environment.cdnURL + '/empty.jpg';
        }
      } else if (display.displayType === 2 && display.source && display.source.trim() !== '') {
        return 'https://i.vimeocdn.com/video/' + display.source + '.jpg';
      } else if (display.displayType === 3 && display.source && display.source.trim() !== '') {
        if (display.image && display.image !== '' && display.image !== 'DELETED') {
          return environment.cdnURL + '/uploads/' + display.image + '?ts=' + display.rowVersion;
        } else {
          let link = `https://videodelivery.net/${display.source}/thumbnails/thumbnail.jpg`;
          if (display.sourceAdditional && display.sourceAdditional !== '') {
            link += `?time=${display.sourceAdditional}`;
          }
          return link;
        }
      } else if (display.displayType === 10) {
        if (display.image && display.image !== '' && display.image !== 'DELETED') {
          return environment.cdnURL + '/uploads/' + display.image + '?ts=' + display.rowVersion;
        } else {
          return environment.cdnURL + '/assets/pdf-placeholder.jpg';
        }
      }
    }
    if (!url || url.trim() === '') {
      return environment.cdnURL + '/empty.jpg';
    }
    return environment.cdnURL + '/uploads/' + url;
  }

  refreshDateTag() {
    this.dateTag = Date.now().toString();
  }

  getUrl(url: string): string {
    if (!url) {
      return environment.cdnURL + '/empty.jpg';
    }
    return ImageUrlService.getUrlOrRaw(url);
  }

  getLogo(logoBase: LogoBase): string {
    if (logoBase) {
      if (logoBase.logoSquare && logoBase.logoSquare !== '') {
        return ImageUrlService.getUrlOrRaw(logoBase.logoSquare);
      }
      if (logoBase.logo && logoBase.logo !== '') {
        return ImageUrlService.getUrlOrRaw(logoBase.logo);
      }
    }
    return environment.cdnURL + '/empty.jpg';
  }

  getLogoWide(logoBase: LogoBase): string {
    if (logoBase) {
      if (logoBase.logoWide && logoBase.logoWide !== '') {
        return ImageUrlService.getUrlOrRaw(logoBase.logoWide);
      }
      return this.getLogo(logoBase);
    }
    return environment.cdnURL + '/empty.jpg';
  }
}

declare interface LogoBase {
  logo?: string;
  logoSquare?: string;
  logoWide?: string;
}
