/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CategoryDto } from './categoryDto';
import { ExhibitorGroupReferenceDto } from './exhibitorGroupReferenceDto';
import { ExpoExhibitorListItemDto } from './expoExhibitorListItemDto';
import { ExpoListItemDto } from './expoListItemDto';
import { VideoChatMode } from './videoChatMode';
import { ExpoDesignDto } from './expoDesignDto';
import { BoothDetailItemDto } from './boothDetailItemDto';
import { AccessMode } from './accessMode';
import { ExpoDetailItemDtoAllOf } from './expoDetailItemDtoAllOf';


export interface ExpoDetailItemDto { 
    id?: string;
    shortKey?: string;
    name?: string;
    description?: string;
    descriptionLong?: string;
    intro?: string;
    dateStart?: Date;
    dateEnd?: Date;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    exhibitors?: Array<ExpoExhibitorListItemDto>;
    categories?: Array<CategoryDto>;
    exhibitorGroups?: Array<ExhibitorGroupReferenceDto>;
    chatActive?: boolean;
    schedulingActive?: boolean;
    worldActive?: boolean;
    isPreview?: boolean;
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    accessMode?: AccessMode;
    videoChatMode?: VideoChatMode;
    randomChatNames?: boolean;
    groupingEnabled?: boolean;
    exhibitorInfoVisibility?: boolean;
    liveStreamEnabled?: boolean;
    externalLinksDirectOpen?: boolean;
    mediaLinksSkipZoom?: boolean;
    domain?: string;
    expoDesign?: ExpoDesignDto;
    rowVersion?: number;
    textChatEnabled?: boolean;
    videoChatEnabled?: boolean;
    boothCount?: number;
    booths?: Array<BoothDetailItemDto>;
    tagsAvailable?: boolean;
    tagsEnabled?: boolean;
    tagsExhibitorEditable?: boolean;
    boothPresentationEnabled?: boolean;
    tags?: Array<string>;
    timeZone?: string;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoDetailItemDto.
 */
export namespace ExpoDetailItemDto {
    /**
     * All properties of ExpoDetailItemDto.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        description = 'description',
        descriptionLong = 'descriptionLong',
        intro = 'intro',
        dateStart = 'dateStart',
        dateEnd = 'dateEnd',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        exhibitors = 'exhibitors',
        categories = 'categories',
        exhibitorGroups = 'exhibitorGroups',
        chatActive = 'chatActive',
        schedulingActive = 'schedulingActive',
        worldActive = 'worldActive',
        isPreview = 'isPreview',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        accessMode = 'accessMode',
        videoChatMode = 'videoChatMode',
        randomChatNames = 'randomChatNames',
        groupingEnabled = 'groupingEnabled',
        exhibitorInfoVisibility = 'exhibitorInfoVisibility',
        liveStreamEnabled = 'liveStreamEnabled',
        externalLinksDirectOpen = 'externalLinksDirectOpen',
        mediaLinksSkipZoom = 'mediaLinksSkipZoom',
        domain = 'domain',
        expoDesign = 'expoDesign',
        rowVersion = 'rowVersion',
        textChatEnabled = 'textChatEnabled',
        videoChatEnabled = 'videoChatEnabled',
        boothCount = 'boothCount',
        booths = 'booths',
        tagsAvailable = 'tagsAvailable',
        tagsEnabled = 'tagsEnabled',
        tagsExhibitorEditable = 'tagsExhibitorEditable',
        boothPresentationEnabled = 'boothPresentationEnabled',
        tags = 'tags',
        timeZone = 'timeZone'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoDetailItemDto.
    */
    export const ModelValidators: {[K in keyof ExpoDetailItemDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        shortKey: [
        ],
        name: [
        ],
        description: [
        ],
        descriptionLong: [
        ],
        intro: [
        ],
        dateStart: [
        ],
        dateEnd: [
        ],
        logo: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        exhibitors: [
        ],
        categories: [
        ],
        exhibitorGroups: [
        ],
        chatActive: [
        ],
        schedulingActive: [
        ],
        worldActive: [
        ],
        isPreview: [
        ],
        googleAnalyticsId: [
        ],
        matomoId: [
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        accessMode: [
        ],
        videoChatMode: [
        ],
        randomChatNames: [
        ],
        groupingEnabled: [
        ],
        exhibitorInfoVisibility: [
        ],
        liveStreamEnabled: [
        ],
        externalLinksDirectOpen: [
        ],
        mediaLinksSkipZoom: [
        ],
        domain: [
        ],
        expoDesign: [
        ],
        rowVersion: [
        ],
        textChatEnabled: [
        ],
        videoChatEnabled: [
        ],
        boothCount: [
        ],
        booths: [
        ],
        tagsAvailable: [
        ],
        tagsEnabled: [
        ],
        tagsExhibitorEditable: [
        ],
        boothPresentationEnabled: [
        ],
        tags: [
        ],
        timeZone: [
        ],
    };

    /**
    * The FormControlFactory for ExpoDetailItemDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoDetailItemDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoDetailItemDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoDetailItemDto = {
            id: null,
            shortKey: null,
            name: null,
            description: null,
            descriptionLong: null,
            intro: null,
            dateStart: null,
            dateEnd: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            exhibitors: null,
            categories: null,
            exhibitorGroups: null,
            chatActive: null,
            schedulingActive: null,
            worldActive: null,
            isPreview: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            accessMode: null,
            videoChatMode: null,
            randomChatNames: null,
            groupingEnabled: null,
            exhibitorInfoVisibility: null,
            liveStreamEnabled: null,
            externalLinksDirectOpen: null,
            mediaLinksSkipZoom: null,
            domain: null,
            expoDesign: null,
            rowVersion: null,
            textChatEnabled: null,
            videoChatEnabled: null,
            boothCount: null,
            booths: null,
            tagsAvailable: null,
            tagsEnabled: null,
            tagsExhibitorEditable: null,
            boothPresentationEnabled: null,
            tags: null,
            timeZone: null,
          }
        ) {
            super(model, ExpoDetailItemDto.ModelValidators);
        }
    }

}


