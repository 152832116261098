<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
  <!-- START navbar header-->
  <div class="navbar-header">
    <a class="navbar-brand" href="/">
      <div class="brand-logo">
        <img class="img-fluid" [src]="settings.app.logoSmall" [alt]="settings.app.logoAlt" style="max-height: 2em;"/>
      </div>
      <div class="brand-logo-collapsed">
        <img class="img-fluid" [src]="settings.app.logoSquare" [alt]="settings.app.logoAlt"/>
      </div>
    </a>
  </div>
  <!-- END navbar header-->
  <!-- START Nav wrapper-->
  <ul class="navbar-nav mr-auto flex-row">
    <!-- START Left navbar-->
    <li class="nav-item">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize="" (click)="toggleCollapsedSidebar()"
         *ngIf="!isCollapsedText()">
        <em class="fas fa-bars"></em>
      </a>
      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a class="nav-link sidebar-toggle d-md-none"
         (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
        <em class="fas fa-bars"></em>
      </a>
    </li>
    <!-- START lock screen-->
    <li *ngIf="this.auth" class="nav-item" ngbDropdown>
      <a class="nav-link" style="cursor: pointer" ngbDropdownToggle id="navbarDropdown1" role="button">
        {{ givenName }}
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
        <a class="dropdown-item" ngbDropdownItem href="#" (click)="signOut($event)"><em class="fas fa-sign-out-alt"></em>
          Logout</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" ngbDropdownItem href="#" (click)="setPassword($event)">Set password</a>
      </div>
    </li>

    <!-- END lock screen-->
  </ul>
  <!-- END Left navbar-->
  <!-- START Right Navbar-->
  <ul class="nav navbar-nav navbar-right">
    <li style="margin-right: 1em;">
      <a href="#" (click)="signOut($event)"><i class="fa fa-power-off fa-2x text-danger"> </i></a>
    </li>
    <!--      <li class="visible-lg">-->
    <!--        <a #fsbutton (click)="toggleFullScreen($event)" [ngClass]="{'fa-compress': screenFull(), 'fa-expand': !screenFull()}">-->
    <!--          <em class="fa fa-expand"></em>-->
    <!--        </a>-->
    <!--      </li>-->
  </ul>
  <!-- END Right Navbar-->
</nav>
<!-- END Top Navbar-->
