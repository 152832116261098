<div class="wrapper">
    <div class="abs-center wd-xl">
        <!-- START panel-->
        <div class="text-center mb-xl">
            <div class="mb-lg">
                <em class="fa fa-wrench fa-5x text-muted"></em>
            </div>
            <div class="text-lg mb-lg">500</div>
            <p class="lead m0">Oh! Something went wrong :(</p>
            <p>Don't worry, we're now checking this.</p>
            <p>In the meantime, please try one of those links below or come back in a moment</p>
        </div>
        <ul class="list-inline text-center text-sm mb-xl">
            <li><a class="text-muted" [routerLink]="'/home'">Go to App</a>
            </li>
            <li class="text-muted">|</li>
            <li><a class="text-muted" [routerLink]="'/pwdless'">Login</a>
            </li>
<!--            <li class="text-muted">|</li>-->
<!--            <li><a class="text-muted" [routerLink]="'/register'">Register</a>-->
<!--            </li>-->
        </ul>
        <div class="p-lg text-center">
            <span>&copy;</span>
            <span>{{ settings.app.year }}</span>
            <span>-</span>
            <span>{{ settings.app.name }}</span>
            <br/>
            <span>{{ settings.app.description }}</span>
        </div>
    </div>
</div>
