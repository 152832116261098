import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BoothCalendarService,
  BoothDetailItemDto,
  CalendarEntryBoothDto,
  ExhibitorReferenceDto,
  ExhibitorService,
  ExpoDetailItemDto,
  FeaturedMode,
  IntegrationPartnerDto, IntegrationStartDto,
  NotificationMessageDto,
  OauthClientService,
  StreamType,
  StreamUpdateDto, WebexRoomDto
} from '../../../virtual-expo-api';
import {NgbDateStruct, NgbModal, NgbModalRef, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {TypedFormGroup} from '../../../virtual-expo-api/formgroup';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {NgAddToCalendarService} from '../../../modules/ngAddToCalendar/service/ng-add-to-calendar.service';
import {ToasterService} from '../../../core/toaster.service';
import {ConfirmationDialogService} from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import {LinkHandlerService} from '../../../core/link-handler.service';
import {ICalendarEvent} from '@trademe/ng-add-to-calendar';
import {CalendarTypeEnum} from '../../../modules/ngAddToCalendar/model/calendar-type.enum';
import {InputBoxComponent} from '../input-box/input-box.component';
import {environment} from '../../../../environments/environment';
import {SettingsService} from '../../../core/settings/settings.service';
import {NgxSpinnerService} from 'ngx-spinner';

const moment = require('moment-timezone');

@Component({
  selector: 'app-exhibitor-presentation-edit',
  templateUrl: './exhibitor-presentation-edit.component.html',
  styleUrls: ['./exhibitor-presentation-edit.component.scss']
})
export class ExhibitorPresentationEditComponent implements OnInit {
  @Input() expo: ExpoDetailItemDto;
  @Input() exhibitor: BoothDetailItemDto;
  @Input() booths: Array<BoothDetailItemDto>;

  @Output() calendarEdit: EventEmitter<CalendarEntryBoothDto> = new EventEmitter<CalendarEntryBoothDto>();
  @Output() calendarDelete: EventEmitter<CalendarEntryBoothDto> = new EventEmitter<CalendarEntryBoothDto>();
  @Output() boothUpdate: EventEmitter<BoothDetailItemDto> = new EventEmitter<BoothDetailItemDto>();

  calendarItem: CalendarEntryBoothDto;

  modalRef: NgbModalRef;
  valForm: TypedFormGroup<CalendarEntryBoothDto>;
  errorMessage: any = '';

  startDate: NgbDateStruct;
  startTime: NgbTimeStruct;

  properties = CalendarEntryBoothDto.Properties;
  errorMap = {
    'prefix.value.required': 'Value is required',
  };

  saving: boolean;
  loaded: boolean;

  boothLink: string;
  downloadICal: SafeUrl;
  downloadGoogle: SafeUrl;
  downloadOutlook: SafeUrl;
  downloadOutlookLive: SafeUrl;

  isLoaded = false;

  dateMode: boolean;
  featured: boolean;
  availableExhibitors: Array<ExhibitorReferenceDto>;

  rooms: Array<WebexRoomDto> = new Array<WebexRoomDto>();
  roomsLoaded: boolean;
  private integrationId: string;
  roomsError: boolean;

  constructor(
    private sanitizer: DomSanitizer
    , private spinnerService: NgxSpinnerService
    , private oauthClientService: OauthClientService
    , private settingsService: SettingsService
    , private addToCalendarService: NgAddToCalendarService
    , private boothCalendarService: BoothCalendarService
    , private alertService: ToasterService
    , private modalService: NgbModal
    , private confirmationDialogService: ConfirmationDialogService
    , private exhibitorService: ExhibitorService
    , private linkHandlerService: LinkHandlerService
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.boothLink = this.linkHandlerService.constructExhibitor(this.expo, this.exhibitor);

    let startDate = new Date();
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours() + 1, 0, 0);
    const emptyItem: CalendarEntryBoothDto = {
      id: -1,
      expoId: this.expo.id,
      exhibitorId: this.exhibitor.id,
      duration: 15,
      start: startDate,
      label: '',
      description: '',
      streamType: StreamType.NUMBER_0,
      featured: FeaturedMode.NUMBER_0
    };

    if (!this.calendarItem) {
      this.calendarItem = emptyItem;
    } else {
      this.calendarItem = {...emptyItem, ...this.calendarItem};
    }

    const dt = moment.tz(this.calendarItem.start, this.expo.timeZone).toDate();

    this.startDate = {
      day: dt.getDate(),
      month: dt.getMonth() + 1,
      year: dt.getFullYear()
    };

    this.startTime = {
      hour: dt.getHours(),
      minute: dt.getMinutes(),
      second: 0
    };

    const factory = new CalendarEntryBoothDto.FormControlFactory(this.calendarItem);
    this.valForm = new TypedFormGroup<CalendarEntryBoothDto>({
        label: factory.createFormControl<string>('label'),
        description: factory.createFormControl<string>('description'),
        duration: factory.createFormControl<number>('duration'),
        start: factory.createFormControl<Date>('start'),
        streamType: factory.createFormControl<number>('streamType'),
        stream: factory.createFormControl<number>('stream'),
        streamAdditional: factory.createFormControl<number>('streamAdditional'),
        streamAdditional2: factory.createFormControl<number>('streamAdditional2'),
        featured: factory.createFormControl('featured'),
        exhibitorId: factory.createFormControl('exhibitorId'),
        expoId: factory.createFormControl('expoId'),
        id: factory.createFormControl('id')
      },
      {updateOn: 'blur'});

    this.featured = this.calendarItem.featured > 0;

    if (this.calendarItem && this.calendarItem.id > 0) {
      const newEvent: ICalendarEvent = {
        title: this.calendarItem.label,
        start: new Date(this.calendarItem.start),
        duration: this.calendarItem.duration,
        description: this.calendarItem.description,
        url: this.boothLink,
      };

      this.downloadICal = this.sanitizer.bypassSecurityTrustUrl(
        this.addToCalendarService.getHrefFor(CalendarTypeEnum.iCalendar, newEvent)
      );
      this.downloadGoogle = this.sanitizer.bypassSecurityTrustUrl(
        this.addToCalendarService.getHrefFor(CalendarTypeEnum.google, newEvent)
      );
      this.downloadOutlook = this.sanitizer.bypassSecurityTrustUrl(
        this.addToCalendarService.getHrefFor(CalendarTypeEnum.outlook, newEvent)
      );
      this.downloadOutlookLive = this.sanitizer.bypassSecurityTrustUrl(
        this.addToCalendarService.getHrefFor(CalendarTypeEnum.outlookLive, newEvent)
      );
    }

    this.valForm.reset(this.calendarItem);

    this.isLoaded = true;
  }

  submitForm($ev: MouseEvent) {
    $ev.preventDefault();
    this.saving = true;

    for (const c in this.valForm.controls) {
      if (c) {
        this.valForm.controls[c].markAsTouched();
      }
    }

    if (this.valForm.valid) {
      const saveData: CalendarEntryBoothDto = {...this.calendarItem, ...this.valForm.value};

      if (saveData.stream) {
        saveData.stream = saveData.stream.replace(/ /g, '');
      }

      if (saveData.streamAdditional) {
        saveData.streamAdditional = saveData.streamAdditional.replace(/ /g, '');
      }

      if (saveData.streamAdditional2) {
        saveData.streamAdditional2 = saveData.streamAdditional2.replace(/ /g, '');
      }

      saveData.start = moment.tz({
        year: this.startDate.year,
        month: this.startDate.month - 1,
        day: this.startDate.day,
        hour: this.startTime.hour,
        minute: this.startTime.minute
      }, this.expo.timeZone).toDate();

      this.boothCalendarService.boothCalendarPost(this.expo.id, this.exhibitor.id, saveData)
        .subscribe(value => {
          this.saving = false;
          this.alertService.saved();
          this.calendarEdit.emit(value);
          this.modalRef.close();
        }, error => {
          this.saving = false;
          this.errorMessage = error;
        });
    } else {
      this.saving = false;
      this.errorMessage = this.valForm.errors;
    }
  }

  cancelEditor($event) {
    $event.preventDefault();
    this.modalRef.close();
  }

  deleteEntry($event: MouseEvent) {
    $event.preventDefault();

    this.confirmationDialogService.confirm('Delete entry', 'Really delete this calendar entry?')
      .then(value => {
        if (value) {
          this.boothCalendarService.boothCalendarDelete(this.expo.id, this.exhibitor.id, this.calendarItem.id)
            .subscribe(() => {
              this.alertService.deleted();
              this.calendarDelete.emit(this.calendarItem);
              this.modalRef.close();
            }, error => {
              this.errorMessage = error;
            });
        }
      }, () => {
      });
  }

  sendAnnouncement($event: MouseEvent) {
    $event.preventDefault();

    this.boothCalendarService.boothCalendarNotify(this.calendarItem.id)
      .subscribe(() => {
        this.alertService.success('Announcement sent');
      });
  }

  sendCustomAnnouncement($event: MouseEvent) {
    $event.preventDefault();

    const modalRef = this.modalService.open(InputBoxComponent);
    const modalComponent = modalRef.componentInstance as InputBoxComponent;
    modalComponent.modalRef = modalRef;
    // const instance = <InputBoxComponent>modalRef.componentInstance;
    modalRef.result.then((result) => {
      const saveData: NotificationMessageDto = {
        entryId: this.calendarItem.id,
        message: result
      };
      this.boothCalendarService.boothCalendarNotifyMessage(saveData)
        .subscribe(() => {
          this.alertService.success('Announcement sent');
        });
    }, () => {
    });
  }

  activeEvent($event: MouseEvent) {
    $event.preventDefault();

    this.boothCalendarService.boothCalendarActivate(this.calendarItem.id)
      .subscribe(() => {
        this.exhibitor.current = this.calendarItem.id;
        this.boothUpdate.emit(this.exhibitor);
        this.alertService.success('Activated');
      });
  }

  deactivateEvent($event: MouseEvent) {
    $event.preventDefault();

    this.boothCalendarService.boothCalendarDeactivate(this.expo.id, this.exhibitor.id)
      .subscribe(() => {
        this.exhibitor.current = null;
        this.boothUpdate.emit(this.exhibitor);
        this.alertService.warn('Deactivated');
      });
  }

  updateEvent($event: MouseEvent) {
    $event.preventDefault();

    const streamData: StreamUpdateDto = this.valForm.value;

    this.boothCalendarService.boothCalendarUpdateStream(this.calendarItem.id, streamData)
      .subscribe(() => {
        this.exhibitor.current = this.calendarItem.id;
        this.boothUpdate.emit(this.exhibitor);
        this.alertService.success('Activated');
      });
  }

  changeFeatured($event: boolean) {
    this.valForm.get('featured').setValue($event ? 1 : 0);
  }

  changeCurrentExhibitor($event: Event) {

  }

  chooseWebExRoom($event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
    }

    this.spinnerService.show();
    if (this.exhibitor) {
      this.oauthClientService.oauthClientGetExhibitorIntegrations(this.expo.id, this.exhibitor.id)
        .subscribe((integrations) => {
          let found = false;
          integrations.forEach((integration) => {
            if (integration.id === environment.webexIntegrationPartnerId && integration.integrations && integration.integrations.length > 0) {
              found = true;
              this.selectWebExRoom(integration.integrations[0].id, 0);
            }
          });
          if (!found) {
            this.createWebExIntegration(environment.webexIntegrationPartnerId);
          }
        });
    } else {
      this.oauthClientService.oauthClientGetExpoIntegrations(this.expo.id)
        .subscribe((integrations) => {
          let found = false;
          integrations.forEach((integration) => {
            if (integration.id === environment.webexIntegrationPartnerId && integration.integrations && integration.integrations.length > 0) {
              found = true;
              this.selectWebExRoom(integration.integrations[0].id, 0);
            }
          });
          if (!found) {
            this.createWebExIntegration(environment.webexIntegrationPartnerId);
          }
        });
    }
  }

  private selectWebExRoom(integrationId: string, retryCount: number) {
    if (retryCount > 2) {
      this.valForm.get('stream').setValue(null);
      this.valForm.get('streamAdditional').setValue(null);
      this.valForm.get('streamAdditional2').setValue(null);
      this.rooms.length = 0;
      this.roomsError = true;
      return;
    }
    this.roomsError = false;
    this.rooms.length = 0;
    this.integrationId = integrationId;
    if (integrationId) {
      this.valForm.get('streamAdditional2').setValue(integrationId);
      this.oauthClientService.oauthClientGetRooms(integrationId)
        .subscribe((rooms) => {
          rooms.forEach(value => {
            this.rooms.push(value);
          });
          this.roomsLoaded = true;
          if (this.rooms.length === 0) {
            this.alertService.warn('No WebEx rooms found');
          }
          this.spinnerService.hide();
        }, error => {
          if (error.status === 401) {
            this.oauthClientService.oauthClientRefreshToken(integrationId)
              .subscribe(value => {
                if (retryCount === 0) {
                  this.selectWebExRoom(integrationId, ++retryCount);
                } else {
                  this.createWebExIntegration(environment.webexIntegrationPartnerId);
                }
              }, refreshError => {
                this.alertService.error('Error getting rooms');
              });
          } else {
            this.alertService.error('Error getting rooms');
          }
          this.spinnerService.hide();
        });
    }
  }

  private createWebExIntegration(integrationId) {
    const intTest: IntegrationStartDto = {
      exhibitorId: this.exhibitor ? this.exhibitor.id : null,
      expoId: this.expo.id,
      id: integrationId,
      server: location.host
    };
    this.spinnerService.hide();
    this.oauthClientService.oauthClientStartIntegration(intTest)
      .subscribe(value => {
        window.addEventListener('message', this.messageEventListener.bind(this));

        window.open(value, 'targetWindow',
          `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=500,
                                    height=500`);
      });
  }

  private messageEventListener(event: MessageEvent) {
    window.removeEventListener('message', this.messageEventListener.bind(this));
    this.chooseWebExRoom();
  }

  useWebExRoom($event: MouseEvent, room: WebexRoomDto) {
    $event.preventDefault();

    this.valForm.get('stream').setValue(room.title);
    this.valForm.get('streamAdditional').setValue(room.id);
    this.valForm.get('streamAdditional2').setValue(this.integrationId);
  }

  clearIntegration($event: MouseEvent) {
    $event.preventDefault();

    this.confirmationDialogService.confirm('Really delete?', 'If you have more than one WebEx meeting ' +
      ' scheduled, removing this integration can (probably will) break things ! Please check all previously created events !' +
      ' This delete is immediate !')
      .then(value => {
        if (value) {
          this.spinnerService.show();
          this.roomsLoaded = false;
          this.roomsError = false;
          this.oauthClientService.oauthClientRemove(this.valForm.value.streamAdditional2)
            .subscribe(value1 => {
              this.valForm.get('stream').setValue(null);
              this.valForm.get('streamAdditional').setValue(null);
              this.valForm.get('streamAdditional2').setValue(null);
              this.rooms.length = 0;
              this.spinnerService.hide();
              this.alertService.warn('The WebEx integration was deleted, please create a new one if you still want to use WebEx !');
            }, error => {
              this.valForm.get('stream').setValue(null);
              this.valForm.get('streamAdditional').setValue(null);
              this.valForm.get('streamAdditional2').setValue(null);
              this.rooms.length = 0;
              this.spinnerService.hide();
              this.alertService.warn('The WebEx integration was deleted, please create a new one if you still want to use WebEx !');
            });
        }
      });
  }
}
