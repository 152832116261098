import {Component, OnInit, Injector, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';

declare var $: any;

import {MenuService} from '../../core/menu/menu.service';
import {SettingsService} from '../../core/settings/settings.service';
import {AuthService} from '../../core/authService/authService';
import {MenuItem} from '../../objects/menu-item';
import {SetPasswordComponent} from '../../routes/pages/set-password/set-password.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SetUserPasswordComponent} from '../../expo/expo-manage/components/set-user-password/set-user-password.component';
import {GetUserTokenComponent} from '../../expo/expo-manage/components/get-user-token/get-user-token.component';
import {AuthClientComponent} from '../../modules/integrations/auth-client/auth-client.component';
import {IntegrationStartDto, OauthClientService} from '../../virtual-expo-api';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  menuItems: Array<MenuItem>;
  router: Router;
  sbclickEvent = 'click.sidebar-toggle';
  $doc: any = null;

  constructor(
    private menu: MenuService
    , public settings: SettingsService
    , private injector: Injector
    , private auth: AuthService
    , private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.auth.refreshToken()
      .subscribe(value => {
        this.refreshMenu();
      });

    this.router = this.injector.get(Router);

    this.router.events.subscribe((val) => {
      // close any submenu opened when route changes
      this.removeFloatingNav();
      // scroll view to top
      window.scrollTo(0, 0);
      // close sidebar on route change
      this.settings.layout.asideToggled = false;
    });

    // enable sidebar autoclose from extenal clicks
    this.anyClickClose();

    this.auth.onTokenRefresh.subscribe(value => {
      this.refreshMenu();
    });
  }

  refreshMenu() {
    this.menuItems = this.menu.getMenu();
  }

  anyClickClose() {
    // this.$doc = $(document).on(this.sbclickEvent, (e) => {
    //     if (!$(e.target).parents('.aside').length) {
    //         this.settings.layout.asideToggled = false;
    //     }
    // });
  }

  ngOnDestroy() {
    // if (this.$doc)
    //     this.$doc.off(this.sbclickEvent);
  }

  toggleSubmenuClick(event, item) {
    if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {
      event.preventDefault();
    }
    if (item) {
      item.opened = !item.opened;
    }
    // if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {
    //     event.preventDefault();
    //
    //     let target = $(event.target || event.srcElement || event.currentTarget);
    //     let ul, anchor = target;
    //
    //     // find the UL
    //     if (!target.is('a')) {
    //         anchor = target.parent('a').first();
    //     }
    //     ul = anchor.next();
    //
    //     // hide other submenus
    //     let parentNav = ul.parents('.sidebar-subnav');
    //     $('.sidebar-subnav').each((idx, el) => {
    //         let $el = $(el);
    //         // if element is not a parent or self ul
    //         if (!$el.is(parentNav) && !$el.is(ul)) {
    //             this.closeMenu($el);
    //         }
    //     });
    //
    //     // bort if not UL to process
    //     if (!ul.length) {
    //         return;
    //     }
    //
    //     // any child menu should start closed
    //     ul.find('.sidebar-subnav').each((idx, el) => {
    //         this.closeMenu($(el));
    //     });
    //
    //     // toggle UL height
    //     if (parseInt(ul.height(), 0)) {
    //         this.closeMenu(ul);
    //     }
    //     else {
    //         // expand menu
    //         ul.on('transitionend', () => {
    //             ul.height('auto').off('transitionend');
    //         }).height(ul[0].scrollHeight);
    //         // add class to manage animation
    //         ul.addClass('opening');
    //     }
    //
    // }

  }

  // Close menu collapsing height
  closeMenu(elem) {
    elem.height(elem[0].scrollHeight); // set height
    elem.height(0); // and move to zero to collapse
    elem.removeClass('opening');
  }

  toggleSubmenuHover(event) {
    // let self = this;
    // if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
    //     event.preventDefault();
    //
    //     this.removeFloatingNav();
    //
    //     let target = $(event.target || event.srcElement || event.currentTarget);
    //     let ul, anchor = target;
    //     // find the UL
    //     if (!target.is('a')) {
    //         anchor = target.parent('a');
    //     }
    //     ul = anchor.next();
    //
    //     if (!ul.length) {
    //         return; // if not submenu return
    //     }
    //
    //     let $aside = $('.aside');
    //     let $asideInner = $aside.children('.aside-inner'); // for top offset calculation
    //     let $sidebar = $asideInner.children('.sidebar');
    //     let mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
    //     let itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();
    //
    //     let floatingNav = ul.clone().appendTo($aside);
    //     let vwHeight = $(window).height();
    //
    //     // let itemTop = anchor.position().top || anchor.offset().top;
    //
    //     floatingNav
    //         .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
    //         .addClass('nav-floating')
    //         .css({
    //             position: this.settings.layout.isFixed ? 'fixed' : 'absolute',
    //             top: itemTop,
    //             bottom: (floatingNav.outerHeight(true) + itemTop > vwHeight) ? 0 : 'auto'
    //         });
    //
    //     floatingNav
    //         .on('mouseleave', () => { floatingNav.remove(); })
    //         .find('a').on('click', function(e) {
    //             e.preventDefault(); // prevents page reload on click
    //             // get the exact route path to navigate
    //             let routeTo = $(this).attr('route');
    //             if (routeTo) self.router.navigate([routeTo]);
    //         });
    //
    //     this.listenForExternalClicks();
    //
    // }

  }

  listenForExternalClicks() {
    // let $doc = $(document).on('click.sidebar', (e) => {
    //     if (!$(e.target).parents('.aside').length) {
    //         this.removeFloatingNav();
    //         $doc.off('click.sidebar');
    //     }
    // });
  }

  removeFloatingNav() {
    // $('.nav-floating').remove();
  }

  isSidebarCollapsed() {
    return this.settings.layout.isCollapsed;
  }

  isSidebarCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  isEnabledHover() {
    return this.settings.layout.asideHover;
  }

  hasRole(validRoles: Array<string>) {
    if (!validRoles || validRoles.length === 0) {
      return true;
    }
    if (!this.auth.isLoggedIn()) {
      this.router.navigate(['pwdless']);
      return false;
    }
    let result = false;

    // decode the token to get its payload
    const tokenPayload = this.auth.getTokenData();

    const userRoles: string[] = tokenPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    if (userRoles && userRoles.length > 0) {
      if (Array.isArray(userRoles)) {
        userRoles.forEach(function (value) {
          if (validRoles.indexOf(value) >= 0) {
            result = true;
            return;
          }
        });
      } else if (validRoles.indexOf(userRoles) >= 0) {
        result = true;
      }
    }
    return result;
  }

  setPassword($event: MouseEvent) {
    $event.preventDefault();

    this.modalService.open(SetPasswordComponent, {size: 'sm'});
  }

  setUserPassword($event: MouseEvent) {
    $event.preventDefault();

    this.modalService.open(SetUserPasswordComponent, {size: 'sm'});
  }

  getUserToken($event: MouseEvent) {
    $event.preventDefault();

    this.modalService.open(GetUserTokenComponent, {size: 'sm'});
  }
}
