<div class="modal-header">
  <h4 class="modal-title float-left" *ngIf="calendarItem.id > 0">
    Edit presentation
  </h4>
  <h4 class="modal-title float-left" *ngIf="!(calendarItem.id > 0)">
    Create presentation
  </h4>
  <div class="btn-group-xs action-button-group" style="margin-left: 8em;" *ngIf="calendarItem.id > 0">
    <button class="btn btn-success" (click)="activeEvent($event)"
            *ngIf="exhibitor && exhibitor.current !== calendarItem.id">Activate
    </button>
    <button class="btn btn-success" (click)="updateEvent($event)"
            *ngIf="exhibitor && exhibitor.current === calendarItem.id">Change stream
    </button>
    <button class="btn btn-warning" (click)="deactivateEvent($event) "
            *ngIf="exhibitor && exhibitor.current === calendarItem.id">Deactivate
    </button>
    <button class="btn btn-default-outline" style="width: 12em;" disabled
            *ngIf="exhibitor && exhibitor.current !== calendarItem.id">Deactivate
    </button>
    <button class="btn btn-primary" (click)="sendAnnouncement($event)">Send announcement</button>
    <button class="btn btn-secondary" (click)="sendCustomAnnouncement($event)">Custom
      announcement
    </button>
    <a class="btn btn-outline-success" [href]="boothLink" target="_blank" rel="noopener">Open booth</a>
  </div>
  <button type="button" class="close float-right" aria-label="Close" (click)="modalRef.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="expoForm" novalidate=""
        *ngIf="calendarItem && isLoaded">
    <div class="row">
      <div class="col-6">
        <div class="form-group has-feedback">
          <label for="label">Label *</label>
          <input class="form-control" id="label" type="text" name="label" placeholder="Enter label"
                 autocomplete="off"
                 [required]="valForm.isValidatorRegistered(properties.label, 'required')"
                 formControlName="label"/>
          <app-control-validation-results [validatedControl]="properties.label"
                                          [formGroup]="valForm"></app-control-validation-results>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group has-feedback">
              <label for="exhibitorId">Exhibitor</label>
              <select class="form-control" id="exhibitorId" name="exhibitorId" formControlName="exhibitorId"
                      style="width: 100%;"
                      (change)="changeCurrentExhibitor($event)">
                <option [value]="oneStage.id" [selected]="oneStage.id === valForm.value.exhibitorId"
                        *ngFor="let oneStage of booths | sequenceSort" [innerText]="oneStage.name"></option>
              </select>
              <app-control-validation-results [validatedControl]="properties.exhibitorId"
                                              [formGroup]="valForm"></app-control-validation-results>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group has-feedback">
              <label for="streamType">Stream type</label>
              <select class="form-control" id="streamType" name="streamType" formControlName="streamType"
                      [required]="valForm.isValidatorRegistered(properties.streamType, 'required')">
                <option value="0">none</option>
                <option value="1">Youtube</option>
                <option value="2">Youtube live stream</option>
                <option value="3">Zoom</option>
                <option value="11">Vimeo</option>
                <option value="12">Vimeo live stream</option>
                <option value="20">Cisco WebEx Room</option>
                <option value="21">Website</option>
<!--                <option value="22">Cisco WebEx Meeting</option>-->
                <option value="23">Cisco WebEx Call</option>
              </select>
              <app-control-validation-results [validatedControl]="properties.streamType"
                                              [formGroup]="valForm"></app-control-validation-results>
            </div>
          </div>
          <div class="col-6"
               *ngIf="valForm.value && valForm.value.streamType && valForm.value.streamType.toString() !== '21' && valForm.value.streamType.toString() !== '22' && valForm.value.streamType.toString() !== '23' && valForm.value.streamType.toString() !== '20'">
            <div class="form-group has-feedback">
              <label for="stream">Stream Id</label>
              <input class="form-control" id="stream" type="text" name="stream" placeholder="Enter stream"
                     prevent-spaces
                     autocomplete="off"
                     [required]="valForm.isValidatorRegistered(properties.stream, 'required')"
                     formControlName="stream"/>
              <app-control-validation-results [validatedControl]="properties.stream"
                                              [formGroup]="valForm"></app-control-validation-results>
            </div>
          </div>
          <div class="col-6"
               *ngIf="valForm.value && valForm.value.streamType && valForm.value.streamType.toString() === '21'">
            <app-form-string-input label="Website url" placeholder="Enter website url"
                                   [formGroup]="valForm" fieldName="stream"></app-form-string-input>
          </div>
          <ng-container
            *ngIf="valForm.value && valForm.value.streamType && valForm.value.streamType.toString() === '3'">
            <div class="col-6">
              <div class="form-group has-feedback">
                <label for="streamAdditional2">Cleartext zoom password</label>
                <input class="form-control" id="streamAdditional2" type="text" name="streamAdditional2" prevent-spaces
                       placeholder="Zoom password clear text"
                       autocomplete="off"
                       [required]="valForm.isValidatorRegistered(properties.streamAdditional2, 'required')"
                       formControlName="streamAdditional2"/>
                <app-control-validation-results [validatedControl]="properties.streamAdditional2"
                                                [formGroup]="valForm"></app-control-validation-results>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group has-feedback">
                <label for="streamAdditional">Encrypted Zoom password</label>
                <input class="form-control" id="streamAdditional" type="text" name="streamAdditional" prevent-spaces
                       placeholder="Zoom password encrypted"
                       autocomplete="off"
                       [required]="valForm.isValidatorRegistered(properties.streamAdditional, 'required')"
                       formControlName="streamAdditional"/>
                <app-control-validation-results [validatedControl]="properties.streamAdditional"
                                                [formGroup]="valForm"></app-control-validation-results>
              </div>
              needed to open external Zoom client
            </div>
          </ng-container>
          <ng-container
            *ngIf="valForm.value && valForm.value.streamType && valForm.value.streamType.toString() === '20'">
            <div class="col-4">
              <div class="form-group has-feedback">
                <label for="webexRoomId">WebEx Room</label>
                <input class="form-control" id="webexRoomId" type="text" name="webexRoomId" placeholder="WebEx Room Id"
                       prevent-spaces readonly="readonly"
                       autocomplete="off" formControlName="stream"/>
              </div>
            </div>
            <div class="col-2">
              <button class="btn btn-xs btn-success" (click)="chooseWebExRoom($event)">Choose</button>
              <button class="btn btn-xs btn-danger" (click)="clearIntegration($event)"
                      *ngIf="valForm.value.streamAdditional2">Remove integration
              </button>
            </div>
            <div *ngIf="roomsLoaded">
              <div *ngIf="roomsError">
                Error loading rooms list
              </div>
              <ul *ngIf="!roomsError && rooms.length > 0">
                <li *ngFor="let room of rooms" (click)="useWebExRoom($event, room)">
                  {{ room.title }}
                </li>
              </ul>
              <div *ngIf="!roomsError && rooms.length === 0">
                No rooms found
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="valForm.value && valForm.value.streamType && valForm.value.streamType.toString() === '23'">
            <div class="col-4">
              <div class="form-group has-feedback">
                <label for="webexRoomId">WebEx Room</label>
                <input class="form-control" id="webexSipId" type="text" name="webexSipId" placeholder="WebEx SIP Id"
                       prevent-spaces
                       autocomplete="off" formControlName="stream"/>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="valForm.value && valForm.value.streamType && valForm.value.streamType.toString() === '12'">
            <div class="col-4">
              <div class="form-group has-feedback">
                <label for="vimeoEvent">Vimeo event id</label>
                <input class="form-control" id="vimeoEvent" type="text" name="vimeoEvent" placeholder="Vimeo event id"
                       prevent-spaces
                       autocomplete="off" formControlName="streamAdditional"/>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-7">
            <div class="form-group has-feedback">
              <label for="startDate">Stage presentation start time</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i class="fa fa-edit" (click)="d.toggle()"></i>
                  </div>
                </div>
                <input class="form-control" id="startDate" type="text" name="startDate" readonly="readonly"
                       placeholder="Enter start date" autocomplete="off" ngbDatepicker #d="ngbDatepicker"
                       (click)="d.toggle()"
                       [(ngModel)]="startDate" [ngModelOptions]="{standalone: true}"/>
              </div>
            </div>
            <div class="form-group has-feedback">
              <label [for]="properties.duration">Duration</label> &nbsp;
              <input type="number" [formControlName]="properties.duration" [id]="properties.duration"
                     style="width: 5em;">
              &nbsp; min.
            </div>
          </div>
          <div class="col-5">
            <ngb-timepicker #d [(ngModel)]="startTime" [ngModelOptions]="{standalone: true}" [hourStep]="1"
                            [minuteStep]="15"></ngb-timepicker>
          </div>
        </div>
      </div>
    </div>
    <app-form-markdown-input [formGroup]="valForm" fieldName="description" label="Description"
                             [class]="'item-group'"
                             placeholder="Enter description"
    ></app-form-markdown-input>
  </form>
</div>
<div class="modal-footer">
  <div class="container-fluid">
    <div class="row" *ngIf="calendarItem && isLoaded">
      <div class="col-md-2">
        <button class="btn btn-warning" (click)="cancelEditor($event)">Cancel</button>
      </div>
      <div class="col-md-2">
        <button class="btn btn-danger" (click)="deleteEntry($event)">Delete</button>
      </div>
      <div class="col-md-4">
        <div class="calendar-download" *ngIf="calendarItem.id > 0">
          {{ 'calendar.download.label' | translate }}
          <a [href]="downloadICal" title="ICal" target="_blank">
            <button class="btn btn-xs btn-outline-info mr-1">
              ICal
            </button>
          </a>
          <a [href]="downloadGoogle" title="ICS" target="_blank">
            <button class="btn btn-xs btn-outline-info mr-1">
              Google
            </button>
          </a>
          <a [href]="downloadOutlook" title="Outlook" target="_blank">
            <button class="btn btn-xs btn-outline-info">
              Outlook
            </button>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <button class="btn btn-success float-right" (click)="submitForm($event)"
                [disabled]="saving || !valForm.valid" [title]="!valForm.valid ? 'Invalid / missing data' : ''"
                *ngIf="!calendarItem.id || calendarItem.id === 0">Save
        </button>
        <button class="btn btn-success float-right" (click)="submitForm($event)"
                [disabled]="saving || !valForm.valid" [title]="!valForm.valid ? 'Invalid / missing data' : ''"
                *ngIf="calendarItem.id && calendarItem.id !== 0">Update
        </button>
      </div>
    </div>
  </div>
</div>
