import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pending-changes',
  templateUrl: './pending-changes.component.html',
  styleUrls: ['./pending-changes.component.scss']
})
export class PendingChangesComponent implements OnInit {
  public onClose: Subject<boolean> = new Subject<boolean>();
  modalRef: NgbModalRef;
  message: string;

  constructor() {

  }

  public ngOnInit(): void {
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this.modalRef.close();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.modalRef.close();
  }
}
