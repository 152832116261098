import {Component, OnInit} from '@angular/core';
import {SettingsService} from '../../core/settings/settings.service';
import {VersionCheckService} from '../../core/version-check.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: '[app-footer]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public settings: SettingsService, public versionCheckService: VersionCheckService) {
  }

  ngOnInit() {

  }

  getBuildDate(): string {
    return environment.timeStamp;
    // return this.versionCheckService.buildDate().toLocaleString();
  }
}
