/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ApplicationUser } from './applicationUser';
import { Expo } from './expo';


export interface ExpoContact { 
    expoId?: string;
    expo?: Expo;
    userId?: string;
    user?: ApplicationUser;
    responsibility?: string;
    showOnWebsite?: boolean;
    sequence?: number;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoContact.
 */
export namespace ExpoContact {
    /**
     * All properties of ExpoContact.
     */
    export enum Properties {
        expoId = 'expoId',
        expo = 'expo',
        userId = 'userId',
        user = 'user',
        responsibility = 'responsibility',
        showOnWebsite = 'showOnWebsite',
        sequence = 'sequence'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoContact.
    */
    export const ModelValidators: {[K in keyof ExpoContact]: [string, ValidatorFn][]} = {
        expoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        expo: [
        ],
        userId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        user: [
        ],
        responsibility: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        showOnWebsite: [
        ],
        sequence: [
        ],
    };

    /**
    * The FormControlFactory for ExpoContact.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoContact> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoContact.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoContact = {
            expoId: null,
            expo: null,
            userId: null,
            user: null,
            responsibility: null,
            showOnWebsite: null,
            sequence: null,
          }
        ) {
            super(model, ExpoContact.ModelValidators);
        }
    }

}


