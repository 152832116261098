import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {EasymdeOptions} from 'ngx-easymde/src/config';

@Component({
  selector: 'app-form-markdown-input',
  templateUrl: './form-markdown-input.component.html',
  styleUrls: ['./form-markdown-input.component.scss']
})
export class FormMarkdownInputComponent implements OnInit, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() fieldName: string;

  @Input() label = '';
  @Input() help = '';

  @Input() placeholder = '';

  @Input() class: string;

  @Input() detectChanges: boolean;
  @Input() fieldValue: string;
  @Input() fieldLabel: string;

  @ViewChild('input') input: ElementRef;

  markdownSource: string;

  options: EasymdeOptions = {
    hideIcons: ['side-by-side', 'fullscreen'],
    spellChecker: false
  };

  constructor() { }

  ngOnInit(): void {
    this.markdownSource = this.formGroup.get(this.fieldName).value ?? '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.detectChanges) {
      if (changes && changes.fieldValue && changes.fieldLabel) {
        this.markdownSource = changes.fieldValue.currentValue ?? '';
      }
    }
  }

  updateForm($event: string) {
    this.formGroup.get(this.fieldName).setValue($event);
  }
}
