import { Component, OnInit } from '@angular/core';
import {SettingsService} from '../../../core/settings/settings.service';

@Component({
  selector: 'app-account-footer',
  templateUrl: './account-footer.component.html',
  styleUrls: ['./account-footer.component.scss']
})
export class AccountFooterComponent implements OnInit {

  constructor(public settings: SettingsService) { }

  ngOnInit() {
  }

}
