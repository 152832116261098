<div class="form-group has-feedback" [formGroup]="formGroup">
  <label [for]="fieldName">{{ label }}
    <span class="small" [innerHTML]="subLabel" *ngIf="subLabel && subLabel.trim() !== ''"></span>
    <app-help *ngIf="help && help.trim() !== ''"
              [details]="help"></app-help>
  </label>
  <input class="form-control" [id]="fieldName" type="number" [name]="fieldName"
         [placeholder]="placeholder"
         [formControlName]="fieldName"/>
  <app-control-validation-results [formGroup]="formGroup"
                                  [validatedControl]="fieldName"></app-control-validation-results>
</div>
