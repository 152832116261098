/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { CalendarEntrySessionDto } from '../model/calendarEntrySessionDto';
import { CalendarEntryStageDto } from '../model/calendarEntryStageDto';
import { ExpoEventsDto } from '../model/expoEventsDto';
import { ExpoUpdateDto } from '../model/expoUpdateDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for calendarDelete.
 */
export namespace CalendarDelete {
    /**
     * Parameter map for calendarDelete.
     */
    export interface PartialParamMap {
      /**
       * The identifier.
       */
      id: number;
    }

    /**
     * Enumeration of all parameters for calendarDelete.
     */
    export enum Parameters {
      /**
       * The identifier.
       */
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of calendarDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof CalendarDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for calendarDeleteSession.
 */
export namespace CalendarDeleteSession {
    /**
     * Parameter map for calendarDeleteSession.
     */
    export interface PartialParamMap {
      /**
       * The identifier.
       */
      id: number;
    }

    /**
     * Enumeration of all parameters for calendarDeleteSession.
     */
    export enum Parameters {
      /**
       * The identifier.
       */
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of calendarDeleteSession
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof CalendarDeleteSession.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for calendarGetCalendar.
 */
export namespace CalendarGetCalendar {
    /**
     * Parameter map for calendarGetCalendar.
     */
    export interface PartialParamMap {
      /**
       * The expo identifier.
       */
      expoId: string;
    }

    /**
     * Enumeration of all parameters for calendarGetCalendar.
     */
    export enum Parameters {
      /**
       * The expo identifier.
       */
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of calendarGetCalendar
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof CalendarGetCalendar.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for calendarGetCalendarEntry.
 */
export namespace CalendarGetCalendarEntry {
    /**
     * Parameter map for calendarGetCalendarEntry.
     */
    export interface PartialParamMap {
      expoId: string;
      id: string;
    }

    /**
     * Enumeration of all parameters for calendarGetCalendarEntry.
     */
    export enum Parameters {
      expoId = 'expoId',
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of calendarGetCalendarEntry
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof CalendarGetCalendarEntry.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for calendarGetMyExpos.
 */
export namespace CalendarGetMyExpos {
    /**
     * Parameter map for calendarGetMyExpos.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for calendarGetMyExpos.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of calendarGetMyExpos
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof CalendarGetMyExpos.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for calendarGetSessions.
 */
export namespace CalendarGetSessions {
    /**
     * Parameter map for calendarGetSessions.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for calendarGetSessions.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of calendarGetSessions
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof CalendarGetSessions.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for calendarOpenSession.
 */
export namespace CalendarOpenSession {
    /**
     * Parameter map for calendarOpenSession.
     */
    export interface PartialParamMap {
      id: number;
    }

    /**
     * Enumeration of all parameters for calendarOpenSession.
     */
    export enum Parameters {
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of calendarOpenSession
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof CalendarOpenSession.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for calendarPost.
 */
export namespace CalendarPost {
    /**
     * Parameter map for calendarPost.
     */
    export interface PartialParamMap {
      /**
       * The calendar entry dto.
       */
      CalendarEntryStageDto: CalendarEntryStageDto;
    }

    /**
     * Enumeration of all parameters for calendarPost.
     */
    export enum Parameters {
      /**
       * The calendar entry dto.
       */
      CalendarEntryStageDto = 'CalendarEntryStageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of calendarPost
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof CalendarPost.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for calendarPostSession.
 */
export namespace CalendarPostSession {
    /**
     * Parameter map for calendarPostSession.
     */
    export interface PartialParamMap {
      /**
       * The calendar entry dto.
       */
      CalendarEntrySessionDto: CalendarEntrySessionDto;
    }

    /**
     * Enumeration of all parameters for calendarPostSession.
     */
    export enum Parameters {
      /**
       * The calendar entry dto.
       */
      CalendarEntrySessionDto = 'CalendarEntrySessionDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of calendarPostSession
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof CalendarPostSession.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}



@Injectable({
  providedIn: 'root'
})
export class CalendarService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   * Deletes the specified identifier. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public calendarDeleteByMap(
    map: CalendarDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public calendarDeleteByMap(
    map: CalendarDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public calendarDeleteByMap(
    map: CalendarDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public calendarDeleteByMap(
    map: CalendarDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.calendarDelete(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Deletes the specified identifier.
     * 
     * @param id The identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public calendarDelete(id: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public calendarDelete(id: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public calendarDelete(id: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public calendarDelete(id: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling calendarDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete(`${this.configuration.basePath}/api/Calendar/${encodeURIComponent(String(id))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Calendar_Delete']) {
                this.cancelMap['Calendar_Delete'].next();
            }
            this.cancelMap['Calendar_Delete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Calendar_Delete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Calendar_Delete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Calendar_Delete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Calendar_Delete']) {
            this.cancelMap['Calendar_Delete'].complete();
            delete this.cancelMap['Calendar_Delete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Calendar_Delete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Calendar_Delete')));
        }
        return handle;
    }


  /**
   * Deletes the specified identifier. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public calendarDeleteSessionByMap(
    map: CalendarDeleteSession.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public calendarDeleteSessionByMap(
    map: CalendarDeleteSession.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public calendarDeleteSessionByMap(
    map: CalendarDeleteSession.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public calendarDeleteSessionByMap(
    map: CalendarDeleteSession.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.calendarDeleteSession(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Deletes the specified identifier.
     * 
     * @param id The identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public calendarDeleteSession(id: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public calendarDeleteSession(id: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public calendarDeleteSession(id: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public calendarDeleteSession(id: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling calendarDeleteSession.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete(`${this.configuration.basePath}/api/Calendar/sessions/${encodeURIComponent(String(id))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Calendar_DeleteSession']) {
                this.cancelMap['Calendar_DeleteSession'].next();
            }
            this.cancelMap['Calendar_DeleteSession'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Calendar_DeleteSession']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Calendar_DeleteSession']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Calendar_DeleteSession', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Calendar_DeleteSession']) {
            this.cancelMap['Calendar_DeleteSession'].complete();
            delete this.cancelMap['Calendar_DeleteSession'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Calendar_DeleteSession',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Calendar_DeleteSession')));
        }
        return handle;
    }


  /**
   * Gets the calendar. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public calendarGetCalendarByMap(
    map: CalendarGetCalendar.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoEventsDto>;
  public calendarGetCalendarByMap(
    map: CalendarGetCalendar.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoEventsDto>>;
  public calendarGetCalendarByMap(
    map: CalendarGetCalendar.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoEventsDto>>;
  public calendarGetCalendarByMap(
    map: CalendarGetCalendar.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.calendarGetCalendar(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the calendar.
     * 
     * @param expoId The expo identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public calendarGetCalendar(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoEventsDto>;
    public calendarGetCalendar(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoEventsDto>>;
    public calendarGetCalendar(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoEventsDto>>;
    public calendarGetCalendar(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling calendarGetCalendar.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<ExpoEventsDto>(`${this.configuration.basePath}/api/Calendar/${encodeURIComponent(String(expoId))}/stages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Calendar_GetCalendar']) {
                this.cancelMap['Calendar_GetCalendar'].next();
            }
            this.cancelMap['Calendar_GetCalendar'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Calendar_GetCalendar']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Calendar_GetCalendar']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Calendar_GetCalendar', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Calendar_GetCalendar']) {
            this.cancelMap['Calendar_GetCalendar'].complete();
            delete this.cancelMap['Calendar_GetCalendar'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Calendar_GetCalendar',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Calendar_GetCalendar')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public calendarGetCalendarEntryByMap(
    map: CalendarGetCalendarEntry.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarEntryStageDto>;
  public calendarGetCalendarEntryByMap(
    map: CalendarGetCalendarEntry.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryStageDto>>;
  public calendarGetCalendarEntryByMap(
    map: CalendarGetCalendarEntry.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryStageDto>>;
  public calendarGetCalendarEntryByMap(
    map: CalendarGetCalendarEntry.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.calendarGetCalendarEntry(
      map.expoId,
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public calendarGetCalendarEntry(expoId: string, id: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarEntryStageDto>;
    public calendarGetCalendarEntry(expoId: string, id: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryStageDto>>;
    public calendarGetCalendarEntry(expoId: string, id: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryStageDto>>;
    public calendarGetCalendarEntry(expoId: string, id: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling calendarGetCalendarEntry.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling calendarGetCalendarEntry.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<CalendarEntryStageDto>(`${this.configuration.basePath}/api/Calendar/${encodeURIComponent(String(expoId))}/stages/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Calendar_GetCalendarEntry']) {
                this.cancelMap['Calendar_GetCalendarEntry'].next();
            }
            this.cancelMap['Calendar_GetCalendarEntry'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Calendar_GetCalendarEntry']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Calendar_GetCalendarEntry']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Calendar_GetCalendarEntry', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Calendar_GetCalendarEntry']) {
            this.cancelMap['Calendar_GetCalendarEntry'].complete();
            delete this.cancelMap['Calendar_GetCalendarEntry'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Calendar_GetCalendarEntry',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Calendar_GetCalendarEntry')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public calendarGetMyExposByMap(
    map: CalendarGetMyExpos.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoUpdateDto>>;
  public calendarGetMyExposByMap(
    map: CalendarGetMyExpos.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoUpdateDto>>>;
  public calendarGetMyExposByMap(
    map: CalendarGetMyExpos.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoUpdateDto>>>;
  public calendarGetMyExposByMap(
    map: CalendarGetMyExpos.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.calendarGetMyExpos(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public calendarGetMyExpos(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoUpdateDto>>;
    public calendarGetMyExpos(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoUpdateDto>>>;
    public calendarGetMyExpos(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoUpdateDto>>>;
    public calendarGetMyExpos(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoUpdateDto>>(`${this.configuration.basePath}/api/Calendar/my`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Calendar_GetMyExpos']) {
                this.cancelMap['Calendar_GetMyExpos'].next();
            }
            this.cancelMap['Calendar_GetMyExpos'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Calendar_GetMyExpos']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Calendar_GetMyExpos']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Calendar_GetMyExpos', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Calendar_GetMyExpos']) {
            this.cancelMap['Calendar_GetMyExpos'].complete();
            delete this.cancelMap['Calendar_GetMyExpos'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Calendar_GetMyExpos',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Calendar_GetMyExpos')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public calendarGetSessionsByMap(
    map: CalendarGetSessions.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<CalendarEntrySessionDto>>;
  public calendarGetSessionsByMap(
    map: CalendarGetSessions.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarEntrySessionDto>>>;
  public calendarGetSessionsByMap(
    map: CalendarGetSessions.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarEntrySessionDto>>>;
  public calendarGetSessionsByMap(
    map: CalendarGetSessions.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.calendarGetSessions(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public calendarGetSessions(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<CalendarEntrySessionDto>>;
    public calendarGetSessions(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarEntrySessionDto>>>;
    public calendarGetSessions(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarEntrySessionDto>>>;
    public calendarGetSessions(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling calendarGetSessions.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<CalendarEntrySessionDto>>(`${this.configuration.basePath}/api/Calendar/sessions/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Calendar_GetSessions']) {
                this.cancelMap['Calendar_GetSessions'].next();
            }
            this.cancelMap['Calendar_GetSessions'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Calendar_GetSessions']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Calendar_GetSessions']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Calendar_GetSessions', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Calendar_GetSessions']) {
            this.cancelMap['Calendar_GetSessions'].complete();
            delete this.cancelMap['Calendar_GetSessions'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Calendar_GetSessions',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Calendar_GetSessions')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public calendarOpenSessionByMap(
    map: CalendarOpenSession.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public calendarOpenSessionByMap(
    map: CalendarOpenSession.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public calendarOpenSessionByMap(
    map: CalendarOpenSession.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public calendarOpenSessionByMap(
    map: CalendarOpenSession.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.calendarOpenSession(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public calendarOpenSession(id: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public calendarOpenSession(id: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public calendarOpenSession(id: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public calendarOpenSession(id: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling calendarOpenSession.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/Calendar/sessionopen/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Calendar_OpenSession']) {
                this.cancelMap['Calendar_OpenSession'].next();
            }
            this.cancelMap['Calendar_OpenSession'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Calendar_OpenSession']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Calendar_OpenSession']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Calendar_OpenSession', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Calendar_OpenSession']) {
            this.cancelMap['Calendar_OpenSession'].complete();
            delete this.cancelMap['Calendar_OpenSession'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Calendar_OpenSession',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Calendar_OpenSession')));
        }
        return handle;
    }


  /**
   * Posts the specified calendar entry dto. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public calendarPostByMap(
    map: CalendarPost.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarEntryStageDto>;
  public calendarPostByMap(
    map: CalendarPost.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryStageDto>>;
  public calendarPostByMap(
    map: CalendarPost.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryStageDto>>;
  public calendarPostByMap(
    map: CalendarPost.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.calendarPost(
      map.CalendarEntryStageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Posts the specified calendar entry dto.
     * 
     * @param CalendarEntryStageDto The calendar entry dto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public calendarPost(CalendarEntryStageDto: CalendarEntryStageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarEntryStageDto>;
    public calendarPost(CalendarEntryStageDto: CalendarEntryStageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryStageDto>>;
    public calendarPost(CalendarEntryStageDto: CalendarEntryStageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryStageDto>>;
    public calendarPost(CalendarEntryStageDto: CalendarEntryStageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (CalendarEntryStageDto === null || CalendarEntryStageDto === undefined) {
            throw new Error('Required parameter CalendarEntryStageDto was null or undefined when calling calendarPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<CalendarEntryStageDto>(`${this.configuration.basePath}/api/Calendar`,
            CalendarEntryStageDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Calendar_Post']) {
                this.cancelMap['Calendar_Post'].next();
            }
            this.cancelMap['Calendar_Post'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Calendar_Post']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Calendar_Post']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Calendar_Post', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Calendar_Post']) {
            this.cancelMap['Calendar_Post'].complete();
            delete this.cancelMap['Calendar_Post'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Calendar_Post',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Calendar_Post')));
        }
        return handle;
    }


  /**
   * Posts the specified calendar entry dto. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public calendarPostSessionByMap(
    map: CalendarPostSession.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarEntrySessionDto>;
  public calendarPostSessionByMap(
    map: CalendarPostSession.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntrySessionDto>>;
  public calendarPostSessionByMap(
    map: CalendarPostSession.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntrySessionDto>>;
  public calendarPostSessionByMap(
    map: CalendarPostSession.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.calendarPostSession(
      map.CalendarEntrySessionDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Posts the specified calendar entry dto.
     * 
     * @param CalendarEntrySessionDto The calendar entry dto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public calendarPostSession(CalendarEntrySessionDto: CalendarEntrySessionDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarEntrySessionDto>;
    public calendarPostSession(CalendarEntrySessionDto: CalendarEntrySessionDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntrySessionDto>>;
    public calendarPostSession(CalendarEntrySessionDto: CalendarEntrySessionDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntrySessionDto>>;
    public calendarPostSession(CalendarEntrySessionDto: CalendarEntrySessionDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (CalendarEntrySessionDto === null || CalendarEntrySessionDto === undefined) {
            throw new Error('Required parameter CalendarEntrySessionDto was null or undefined when calling calendarPostSession.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<CalendarEntrySessionDto>(`${this.configuration.basePath}/api/Calendar/sessions`,
            CalendarEntrySessionDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Calendar_PostSession']) {
                this.cancelMap['Calendar_PostSession'].next();
            }
            this.cancelMap['Calendar_PostSession'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Calendar_PostSession']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Calendar_PostSession']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Calendar_PostSession', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Calendar_PostSession']) {
            this.cancelMap['Calendar_PostSession'].complete();
            delete this.cancelMap['Calendar_PostSession'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Calendar_PostSession',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Calendar_PostSession')));
        }
        return handle;
    }

  }

export namespace CalendarService {
  export enum Operations {
    Calendar_Delete = 'Calendar_Delete',
    Calendar_DeleteSession = 'Calendar_DeleteSession',
    Calendar_GetCalendar = 'Calendar_GetCalendar',
    Calendar_GetCalendarEntry = 'Calendar_GetCalendarEntry',
    Calendar_GetMyExpos = 'Calendar_GetMyExpos',
    Calendar_GetSessions = 'Calendar_GetSessions',
    Calendar_OpenSession = 'Calendar_OpenSession',
    Calendar_Post = 'Calendar_Post',
    Calendar_PostSession = 'Calendar_PostSession'
  }
}
