/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { AvailabilityDto } from '../model/availabilityDto';
import { BookSlotDto } from '../model/bookSlotDto';
import { BookingResultState } from '../model/bookingResultState';
import { CalendarAvailabilityDto } from '../model/calendarAvailabilityDto';
import { CalendarBookedSlotAdminDto } from '../model/calendarBookedSlotAdminDto';
import { CalendarBookedSlotDto } from '../model/calendarBookedSlotDto';
import { CalendarBookedSlotUpdateDto } from '../model/calendarBookedSlotUpdateDto';
import { UserListDto } from '../model/userListDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for talkBookingBookSlot.
 */
export namespace TalkBookingBookSlot {
    /**
     * Parameter map for talkBookingBookSlot.
     */
    export interface PartialParamMap {
      expoId: string;
      BookSlotDto: BookSlotDto;
    }

    /**
     * Enumeration of all parameters for talkBookingBookSlot.
     */
    export enum Parameters {
      expoId = 'expoId',
      BookSlotDto = 'BookSlotDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of talkBookingBookSlot
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TalkBookingBookSlot.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for talkBookingDeleteAvailability.
 */
export namespace TalkBookingDeleteAvailability {
    /**
     * Parameter map for talkBookingDeleteAvailability.
     */
    export interface PartialParamMap {
      id: string;
    }

    /**
     * Enumeration of all parameters for talkBookingDeleteAvailability.
     */
    export enum Parameters {
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of talkBookingDeleteAvailability
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TalkBookingDeleteAvailability.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for talkBookingGetAllAvailability.
 */
export namespace TalkBookingGetAllAvailability {
    /**
     * Parameter map for talkBookingGetAllAvailability.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for talkBookingGetAllAvailability.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of talkBookingGetAllAvailability
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TalkBookingGetAllAvailability.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for talkBookingGetAvailability.
 */
export namespace TalkBookingGetAvailability {
    /**
     * Parameter map for talkBookingGetAvailability.
     */
    export interface PartialParamMap {
      expoId: string;
      id: number;
    }

    /**
     * Enumeration of all parameters for talkBookingGetAvailability.
     */
    export enum Parameters {
      expoId = 'expoId',
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of talkBookingGetAvailability
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TalkBookingGetAvailability.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for talkBookingGetAvailableSlots.
 */
export namespace TalkBookingGetAvailableSlots {
    /**
     * Parameter map for talkBookingGetAvailableSlots.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for talkBookingGetAvailableSlots.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of talkBookingGetAvailableSlots
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TalkBookingGetAvailableSlots.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for talkBookingGetBookedSlot.
 */
export namespace TalkBookingGetBookedSlot {
    /**
     * Parameter map for talkBookingGetBookedSlot.
     */
    export interface PartialParamMap {
      id: number;
    }

    /**
     * Enumeration of all parameters for talkBookingGetBookedSlot.
     */
    export enum Parameters {
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of talkBookingGetBookedSlot
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TalkBookingGetBookedSlot.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for talkBookingGetBookedSlots.
 */
export namespace TalkBookingGetBookedSlots {
    /**
     * Parameter map for talkBookingGetBookedSlots.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for talkBookingGetBookedSlots.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of talkBookingGetBookedSlots
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TalkBookingGetBookedSlots.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for talkBookingGetCallId.
 */
export namespace TalkBookingGetCallId {
    /**
     * Parameter map for talkBookingGetCallId.
     */
    export interface PartialParamMap {
      id: number;
    }

    /**
     * Enumeration of all parameters for talkBookingGetCallId.
     */
    export enum Parameters {
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of talkBookingGetCallId
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TalkBookingGetCallId.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for talkBookingGetChatManagers.
 */
export namespace TalkBookingGetChatManagers {
    /**
     * Parameter map for talkBookingGetChatManagers.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for talkBookingGetChatManagers.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of talkBookingGetChatManagers
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TalkBookingGetChatManagers.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for talkBookingPostAvailability.
 */
export namespace TalkBookingPostAvailability {
    /**
     * Parameter map for talkBookingPostAvailability.
     */
    export interface PartialParamMap {
      expoId: string;
      CalendarAvailabilityDto: CalendarAvailabilityDto;
    }

    /**
     * Enumeration of all parameters for talkBookingPostAvailability.
     */
    export enum Parameters {
      expoId = 'expoId',
      CalendarAvailabilityDto = 'CalendarAvailabilityDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of talkBookingPostAvailability
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TalkBookingPostAvailability.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for talkBookingUpdateBooking.
 */
export namespace TalkBookingUpdateBooking {
    /**
     * Parameter map for talkBookingUpdateBooking.
     */
    export interface PartialParamMap {
      CalendarBookedSlotUpdateDto: CalendarBookedSlotUpdateDto;
      id?: string;
    }

    /**
     * Enumeration of all parameters for talkBookingUpdateBooking.
     */
    export enum Parameters {
      CalendarBookedSlotUpdateDto = 'CalendarBookedSlotUpdateDto',
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of talkBookingUpdateBooking
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TalkBookingUpdateBooking.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class TalkBookingService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public talkBookingBookSlotByMap(
    map: TalkBookingBookSlot.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<BookingResultState>;
  public talkBookingBookSlotByMap(
    map: TalkBookingBookSlot.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<BookingResultState>>;
  public talkBookingBookSlotByMap(
    map: TalkBookingBookSlot.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<BookingResultState>>;
  public talkBookingBookSlotByMap(
    map: TalkBookingBookSlot.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.talkBookingBookSlot(
      map.expoId,
      map.BookSlotDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param BookSlotDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public talkBookingBookSlot(expoId: string, BookSlotDto: BookSlotDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<BookingResultState>;
    public talkBookingBookSlot(expoId: string, BookSlotDto: BookSlotDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<BookingResultState>>;
    public talkBookingBookSlot(expoId: string, BookSlotDto: BookSlotDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<BookingResultState>>;
    public talkBookingBookSlot(expoId: string, BookSlotDto: BookSlotDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling talkBookingBookSlot.');
        }
        if (BookSlotDto === null || BookSlotDto === undefined) {
            throw new Error('Required parameter BookSlotDto was null or undefined when calling talkBookingBookSlot.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<BookingResultState>(`${this.configuration.basePath}/api/TalkBooking/book/${encodeURIComponent(String(expoId))}`,
            BookSlotDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['TalkBooking_BookSlot']) {
                this.cancelMap['TalkBooking_BookSlot'].next();
            }
            this.cancelMap['TalkBooking_BookSlot'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['TalkBooking_BookSlot']) {
                handle = handle.pipe(takeUntil(this.cancelMap['TalkBooking_BookSlot']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('TalkBooking_BookSlot', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['TalkBooking_BookSlot']) {
            this.cancelMap['TalkBooking_BookSlot'].complete();
            delete this.cancelMap['TalkBooking_BookSlot'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'TalkBooking_BookSlot',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'TalkBooking_BookSlot')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public talkBookingDeleteAvailabilityByMap(
    map: TalkBookingDeleteAvailability.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public talkBookingDeleteAvailabilityByMap(
    map: TalkBookingDeleteAvailability.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public talkBookingDeleteAvailabilityByMap(
    map: TalkBookingDeleteAvailability.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public talkBookingDeleteAvailabilityByMap(
    map: TalkBookingDeleteAvailability.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.talkBookingDeleteAvailability(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public talkBookingDeleteAvailability(id: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public talkBookingDeleteAvailability(id: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public talkBookingDeleteAvailability(id: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public talkBookingDeleteAvailability(id: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling talkBookingDeleteAvailability.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<boolean>(`${this.configuration.basePath}/api/TalkBooking/delete/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['TalkBooking_DeleteAvailability']) {
                this.cancelMap['TalkBooking_DeleteAvailability'].next();
            }
            this.cancelMap['TalkBooking_DeleteAvailability'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['TalkBooking_DeleteAvailability']) {
                handle = handle.pipe(takeUntil(this.cancelMap['TalkBooking_DeleteAvailability']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('TalkBooking_DeleteAvailability', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['TalkBooking_DeleteAvailability']) {
            this.cancelMap['TalkBooking_DeleteAvailability'].complete();
            delete this.cancelMap['TalkBooking_DeleteAvailability'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'TalkBooking_DeleteAvailability',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'TalkBooking_DeleteAvailability')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public talkBookingGetAllAvailabilityByMap(
    map: TalkBookingGetAllAvailability.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<CalendarAvailabilityDto>>;
  public talkBookingGetAllAvailabilityByMap(
    map: TalkBookingGetAllAvailability.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarAvailabilityDto>>>;
  public talkBookingGetAllAvailabilityByMap(
    map: TalkBookingGetAllAvailability.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarAvailabilityDto>>>;
  public talkBookingGetAllAvailabilityByMap(
    map: TalkBookingGetAllAvailability.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.talkBookingGetAllAvailability(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public talkBookingGetAllAvailability(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<CalendarAvailabilityDto>>;
    public talkBookingGetAllAvailability(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarAvailabilityDto>>>;
    public talkBookingGetAllAvailability(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarAvailabilityDto>>>;
    public talkBookingGetAllAvailability(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling talkBookingGetAllAvailability.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<CalendarAvailabilityDto>>(`${this.configuration.basePath}/api/TalkBooking/availability/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['TalkBooking_GetAllAvailability']) {
                this.cancelMap['TalkBooking_GetAllAvailability'].next();
            }
            this.cancelMap['TalkBooking_GetAllAvailability'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['TalkBooking_GetAllAvailability']) {
                handle = handle.pipe(takeUntil(this.cancelMap['TalkBooking_GetAllAvailability']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('TalkBooking_GetAllAvailability', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['TalkBooking_GetAllAvailability']) {
            this.cancelMap['TalkBooking_GetAllAvailability'].complete();
            delete this.cancelMap['TalkBooking_GetAllAvailability'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'TalkBooking_GetAllAvailability',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'TalkBooking_GetAllAvailability')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public talkBookingGetAvailabilityByMap(
    map: TalkBookingGetAvailability.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarAvailabilityDto>;
  public talkBookingGetAvailabilityByMap(
    map: TalkBookingGetAvailability.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarAvailabilityDto>>;
  public talkBookingGetAvailabilityByMap(
    map: TalkBookingGetAvailability.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarAvailabilityDto>>;
  public talkBookingGetAvailabilityByMap(
    map: TalkBookingGetAvailability.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.talkBookingGetAvailability(
      map.expoId,
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public talkBookingGetAvailability(expoId: string, id: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarAvailabilityDto>;
    public talkBookingGetAvailability(expoId: string, id: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarAvailabilityDto>>;
    public talkBookingGetAvailability(expoId: string, id: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarAvailabilityDto>>;
    public talkBookingGetAvailability(expoId: string, id: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling talkBookingGetAvailability.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling talkBookingGetAvailability.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<CalendarAvailabilityDto>(`${this.configuration.basePath}/api/TalkBooking/availability/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['TalkBooking_GetAvailability']) {
                this.cancelMap['TalkBooking_GetAvailability'].next();
            }
            this.cancelMap['TalkBooking_GetAvailability'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['TalkBooking_GetAvailability']) {
                handle = handle.pipe(takeUntil(this.cancelMap['TalkBooking_GetAvailability']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('TalkBooking_GetAvailability', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['TalkBooking_GetAvailability']) {
            this.cancelMap['TalkBooking_GetAvailability'].complete();
            delete this.cancelMap['TalkBooking_GetAvailability'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'TalkBooking_GetAvailability',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'TalkBooking_GetAvailability')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public talkBookingGetAvailableSlotsByMap(
    map: TalkBookingGetAvailableSlots.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<AvailabilityDto>>;
  public talkBookingGetAvailableSlotsByMap(
    map: TalkBookingGetAvailableSlots.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<AvailabilityDto>>>;
  public talkBookingGetAvailableSlotsByMap(
    map: TalkBookingGetAvailableSlots.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<AvailabilityDto>>>;
  public talkBookingGetAvailableSlotsByMap(
    map: TalkBookingGetAvailableSlots.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.talkBookingGetAvailableSlots(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public talkBookingGetAvailableSlots(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<AvailabilityDto>>;
    public talkBookingGetAvailableSlots(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<AvailabilityDto>>>;
    public talkBookingGetAvailableSlots(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<AvailabilityDto>>>;
    public talkBookingGetAvailableSlots(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling talkBookingGetAvailableSlots.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<AvailabilityDto>>(`${this.configuration.basePath}/api/TalkBooking/free/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['TalkBooking_GetAvailableSlots']) {
                this.cancelMap['TalkBooking_GetAvailableSlots'].next();
            }
            this.cancelMap['TalkBooking_GetAvailableSlots'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['TalkBooking_GetAvailableSlots']) {
                handle = handle.pipe(takeUntil(this.cancelMap['TalkBooking_GetAvailableSlots']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('TalkBooking_GetAvailableSlots', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['TalkBooking_GetAvailableSlots']) {
            this.cancelMap['TalkBooking_GetAvailableSlots'].complete();
            delete this.cancelMap['TalkBooking_GetAvailableSlots'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'TalkBooking_GetAvailableSlots',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'TalkBooking_GetAvailableSlots')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public talkBookingGetBookedSlotByMap(
    map: TalkBookingGetBookedSlot.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarBookedSlotAdminDto>;
  public talkBookingGetBookedSlotByMap(
    map: TalkBookingGetBookedSlot.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarBookedSlotAdminDto>>;
  public talkBookingGetBookedSlotByMap(
    map: TalkBookingGetBookedSlot.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarBookedSlotAdminDto>>;
  public talkBookingGetBookedSlotByMap(
    map: TalkBookingGetBookedSlot.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.talkBookingGetBookedSlot(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public talkBookingGetBookedSlot(id: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarBookedSlotAdminDto>;
    public talkBookingGetBookedSlot(id: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarBookedSlotAdminDto>>;
    public talkBookingGetBookedSlot(id: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarBookedSlotAdminDto>>;
    public talkBookingGetBookedSlot(id: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling talkBookingGetBookedSlot.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<CalendarBookedSlotAdminDto>(`${this.configuration.basePath}/api/TalkBooking/booked/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['TalkBooking_GetBookedSlot']) {
                this.cancelMap['TalkBooking_GetBookedSlot'].next();
            }
            this.cancelMap['TalkBooking_GetBookedSlot'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['TalkBooking_GetBookedSlot']) {
                handle = handle.pipe(takeUntil(this.cancelMap['TalkBooking_GetBookedSlot']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('TalkBooking_GetBookedSlot', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['TalkBooking_GetBookedSlot']) {
            this.cancelMap['TalkBooking_GetBookedSlot'].complete();
            delete this.cancelMap['TalkBooking_GetBookedSlot'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'TalkBooking_GetBookedSlot',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'TalkBooking_GetBookedSlot')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public talkBookingGetBookedSlotsByMap(
    map: TalkBookingGetBookedSlots.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<CalendarBookedSlotDto>>;
  public talkBookingGetBookedSlotsByMap(
    map: TalkBookingGetBookedSlots.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarBookedSlotDto>>>;
  public talkBookingGetBookedSlotsByMap(
    map: TalkBookingGetBookedSlots.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarBookedSlotDto>>>;
  public talkBookingGetBookedSlotsByMap(
    map: TalkBookingGetBookedSlots.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.talkBookingGetBookedSlots(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public talkBookingGetBookedSlots(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<CalendarBookedSlotDto>>;
    public talkBookingGetBookedSlots(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarBookedSlotDto>>>;
    public talkBookingGetBookedSlots(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarBookedSlotDto>>>;
    public talkBookingGetBookedSlots(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<CalendarBookedSlotDto>>(`${this.configuration.basePath}/api/TalkBooking/booked`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['TalkBooking_GetBookedSlots']) {
                this.cancelMap['TalkBooking_GetBookedSlots'].next();
            }
            this.cancelMap['TalkBooking_GetBookedSlots'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['TalkBooking_GetBookedSlots']) {
                handle = handle.pipe(takeUntil(this.cancelMap['TalkBooking_GetBookedSlots']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('TalkBooking_GetBookedSlots', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['TalkBooking_GetBookedSlots']) {
            this.cancelMap['TalkBooking_GetBookedSlots'].complete();
            delete this.cancelMap['TalkBooking_GetBookedSlots'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'TalkBooking_GetBookedSlots',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'TalkBooking_GetBookedSlots')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public talkBookingGetCallIdByMap(
    map: TalkBookingGetCallId.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public talkBookingGetCallIdByMap(
    map: TalkBookingGetCallId.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public talkBookingGetCallIdByMap(
    map: TalkBookingGetCallId.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public talkBookingGetCallIdByMap(
    map: TalkBookingGetCallId.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.talkBookingGetCallId(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public talkBookingGetCallId(id: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public talkBookingGetCallId(id: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public talkBookingGetCallId(id: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public talkBookingGetCallId(id: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling talkBookingGetCallId.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/TalkBooking/call/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['TalkBooking_GetCallId']) {
                this.cancelMap['TalkBooking_GetCallId'].next();
            }
            this.cancelMap['TalkBooking_GetCallId'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['TalkBooking_GetCallId']) {
                handle = handle.pipe(takeUntil(this.cancelMap['TalkBooking_GetCallId']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('TalkBooking_GetCallId', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['TalkBooking_GetCallId']) {
            this.cancelMap['TalkBooking_GetCallId'].complete();
            delete this.cancelMap['TalkBooking_GetCallId'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'TalkBooking_GetCallId',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'TalkBooking_GetCallId')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public talkBookingGetChatManagersByMap(
    map: TalkBookingGetChatManagers.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<UserListDto>>;
  public talkBookingGetChatManagersByMap(
    map: TalkBookingGetChatManagers.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<UserListDto>>>;
  public talkBookingGetChatManagersByMap(
    map: TalkBookingGetChatManagers.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<UserListDto>>>;
  public talkBookingGetChatManagersByMap(
    map: TalkBookingGetChatManagers.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.talkBookingGetChatManagers(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public talkBookingGetChatManagers(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<UserListDto>>;
    public talkBookingGetChatManagers(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<UserListDto>>>;
    public talkBookingGetChatManagers(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<UserListDto>>>;
    public talkBookingGetChatManagers(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling talkBookingGetChatManagers.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling talkBookingGetChatManagers.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<UserListDto>>(`${this.configuration.basePath}/api/TalkBooking/chat-managers/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['TalkBooking_GetChatManagers']) {
                this.cancelMap['TalkBooking_GetChatManagers'].next();
            }
            this.cancelMap['TalkBooking_GetChatManagers'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['TalkBooking_GetChatManagers']) {
                handle = handle.pipe(takeUntil(this.cancelMap['TalkBooking_GetChatManagers']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('TalkBooking_GetChatManagers', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['TalkBooking_GetChatManagers']) {
            this.cancelMap['TalkBooking_GetChatManagers'].complete();
            delete this.cancelMap['TalkBooking_GetChatManagers'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'TalkBooking_GetChatManagers',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'TalkBooking_GetChatManagers')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public talkBookingPostAvailabilityByMap(
    map: TalkBookingPostAvailability.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarAvailabilityDto>;
  public talkBookingPostAvailabilityByMap(
    map: TalkBookingPostAvailability.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarAvailabilityDto>>;
  public talkBookingPostAvailabilityByMap(
    map: TalkBookingPostAvailability.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarAvailabilityDto>>;
  public talkBookingPostAvailabilityByMap(
    map: TalkBookingPostAvailability.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.talkBookingPostAvailability(
      map.expoId,
      map.CalendarAvailabilityDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param CalendarAvailabilityDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public talkBookingPostAvailability(expoId: string, CalendarAvailabilityDto: CalendarAvailabilityDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarAvailabilityDto>;
    public talkBookingPostAvailability(expoId: string, CalendarAvailabilityDto: CalendarAvailabilityDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarAvailabilityDto>>;
    public talkBookingPostAvailability(expoId: string, CalendarAvailabilityDto: CalendarAvailabilityDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarAvailabilityDto>>;
    public talkBookingPostAvailability(expoId: string, CalendarAvailabilityDto: CalendarAvailabilityDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling talkBookingPostAvailability.');
        }
        if (CalendarAvailabilityDto === null || CalendarAvailabilityDto === undefined) {
            throw new Error('Required parameter CalendarAvailabilityDto was null or undefined when calling talkBookingPostAvailability.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<CalendarAvailabilityDto>(`${this.configuration.basePath}/api/TalkBooking/availability/${encodeURIComponent(String(expoId))}`,
            CalendarAvailabilityDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['TalkBooking_PostAvailability']) {
                this.cancelMap['TalkBooking_PostAvailability'].next();
            }
            this.cancelMap['TalkBooking_PostAvailability'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['TalkBooking_PostAvailability']) {
                handle = handle.pipe(takeUntil(this.cancelMap['TalkBooking_PostAvailability']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('TalkBooking_PostAvailability', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['TalkBooking_PostAvailability']) {
            this.cancelMap['TalkBooking_PostAvailability'].complete();
            delete this.cancelMap['TalkBooking_PostAvailability'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'TalkBooking_PostAvailability',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'TalkBooking_PostAvailability')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public talkBookingUpdateBookingByMap(
    map: TalkBookingUpdateBooking.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarBookedSlotDto>;
  public talkBookingUpdateBookingByMap(
    map: TalkBookingUpdateBooking.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarBookedSlotDto>>;
  public talkBookingUpdateBookingByMap(
    map: TalkBookingUpdateBooking.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarBookedSlotDto>>;
  public talkBookingUpdateBookingByMap(
    map: TalkBookingUpdateBooking.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.talkBookingUpdateBooking(
      map.CalendarBookedSlotUpdateDto,
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param CalendarBookedSlotUpdateDto 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public talkBookingUpdateBooking(CalendarBookedSlotUpdateDto: CalendarBookedSlotUpdateDto, id?: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarBookedSlotDto>;
    public talkBookingUpdateBooking(CalendarBookedSlotUpdateDto: CalendarBookedSlotUpdateDto, id?: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarBookedSlotDto>>;
    public talkBookingUpdateBooking(CalendarBookedSlotUpdateDto: CalendarBookedSlotUpdateDto, id?: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarBookedSlotDto>>;
    public talkBookingUpdateBooking(CalendarBookedSlotUpdateDto: CalendarBookedSlotUpdateDto, id?: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (CalendarBookedSlotUpdateDto === null || CalendarBookedSlotUpdateDto === undefined) {
            throw new Error('Required parameter CalendarBookedSlotUpdateDto was null or undefined when calling talkBookingUpdateBooking.');
        }

        //let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<CalendarBookedSlotDto>(`${this.configuration.basePath}/api/TalkBooking/update`,
            CalendarBookedSlotUpdateDto,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['TalkBooking_UpdateBooking']) {
                this.cancelMap['TalkBooking_UpdateBooking'].next();
            }
            this.cancelMap['TalkBooking_UpdateBooking'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['TalkBooking_UpdateBooking']) {
                handle = handle.pipe(takeUntil(this.cancelMap['TalkBooking_UpdateBooking']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('TalkBooking_UpdateBooking', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['TalkBooking_UpdateBooking']) {
            this.cancelMap['TalkBooking_UpdateBooking'].complete();
            delete this.cancelMap['TalkBooking_UpdateBooking'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'TalkBooking_UpdateBooking',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'TalkBooking_UpdateBooking')));
        }
        return handle;
    }

  }

export namespace TalkBookingService {
  export enum Operations {
    TalkBooking_BookSlot = 'TalkBooking_BookSlot',
    TalkBooking_DeleteAvailability = 'TalkBooking_DeleteAvailability',
    TalkBooking_GetAllAvailability = 'TalkBooking_GetAllAvailability',
    TalkBooking_GetAvailability = 'TalkBooking_GetAvailability',
    TalkBooking_GetAvailableSlots = 'TalkBooking_GetAvailableSlots',
    TalkBooking_GetBookedSlot = 'TalkBooking_GetBookedSlot',
    TalkBooking_GetBookedSlots = 'TalkBooking_GetBookedSlots',
    TalkBooking_GetCallId = 'TalkBooking_GetCallId',
    TalkBooking_GetChatManagers = 'TalkBooking_GetChatManagers',
    TalkBooking_PostAvailability = 'TalkBooking_PostAvailability',
    TalkBooking_UpdateBooking = 'TalkBooking_UpdateBooking'
  }
}
