import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BoothSize} from '../../../virtual-expo-api';

@Component({
  selector: 'app-stage-sizes',
  templateUrl: './stage-sizes.component.html',
  styleUrls: ['./stage-sizes.component.scss']
})
export class StageSizesComponent implements OnInit {
  @Input() sizes: string;
  @Input() allowedSizes: string;
  @Input() has3d: boolean;
  @Output() sizesChanged: EventEmitter<string> = new EventEmitter<string>();

  availableSizes: Array<{ size: BoothSize, label: string, selected: boolean }> = new Array<{ size: BoothSize, label: string, selected: boolean }>();

  constructor() {
    this.availableSizes.push({size: BoothSize.NUMBER_0, label: 'XS', selected: false});
    this.availableSizes.push({size: BoothSize.NUMBER_1, label: 'Small', selected: false});
    this.availableSizes.push({size: BoothSize.NUMBER_2, label: 'Medium', selected: false});
    this.availableSizes.push({size: BoothSize.NUMBER_3, label: 'Large', selected: false});
    this.availableSizes.push({size: BoothSize.NUMBER_10, label: 'XL', selected: false});
    this.availableSizes.push({size: BoothSize.NUMBER_11, label: 'XXL (3D Expo only!)', selected: false});
  }

  ngOnInit(): void {
    if (this.allowedSizes && this.allowedSizes !== '') {
      const tmpSizes: Array<{ size: BoothSize, label: string, selected: boolean }> = new Array<{ size: BoothSize, label: string, selected: boolean }>();
      this.allowedSizes.split(',').forEach(value => {
        const tmp = parseInt(value, 10) as BoothSize;
        this.availableSizes.forEach((value1, index) => {
          if (value1.size === tmp) {
            tmpSizes.push(value1);
          }
        });
      });
      this.availableSizes = tmpSizes;
    }
    if (!this.sizes) {
      this.sizes = '';
    }
    this.sizes.split(',').forEach(value => {
      const tmp = parseInt(value, 10) as BoothSize;
      this.availableSizes.forEach(value1 => {
        if (value1.size === tmp) {
          value1.selected = true;
        }
      });
    });
  }

  updatedSize(current: { size: BoothSize; label: string; selected: boolean }) {
    // current.selected = !current.selected;

    let result = '';
    this.availableSizes.forEach(value => {
      if (value.selected) {
        if (result !== '') {
          result += ',';
        }
        result += value.size.toFixed(0);
      }
    });

    this.sizesChanged.emit(result);
  }
}
