  <div class="form-group has-feedback">
    <label [for]="fieldName" *ngIf="label && label.trim() !== ''">{{ label }}
      <app-help *ngIf="help && help.trim() !== ''"
                [details]="help"></app-help>
    </label>
    <ui-switch [ngModel]="myValue" (change)="onChange($event)"
               [reverse]="reverse" [color]="foreColor" [defaultBgColor]="backColor" [uncheckedTextColor]="textColor"
               [checkedLabel]="checkedLabel" [uncheckedLabel]="uncheckedLabel"
    ></ui-switch>
    <app-help *ngIf="help && help.trim() !== '' && (!label || label.trim() === '')"
              [details]="help"></app-help>
  </div>
<!--</ng-container>-->
