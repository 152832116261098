import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; // this is needed!
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {HttpClientModule, HttpClient, HttpErrorResponse} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {LayoutModule} from './layout/layout.module';
import {SharedModule} from './shared/shared.module';
import {RoutesModule} from './routes/routes.module';
import {environment} from '../environments/environment';
import {FormsModule} from '@angular/forms';
import {ValuePipe} from './core/helpers/objectKeyIterator';
import {ConfirmationDialogComponent} from './shared/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogService} from './shared/confirmation-dialog/confirmation-dialog.service';
import {MarkdownModule, MarkedOptions, MarkedRenderer} from 'ngx-markdown';
import {LinkHandlerService} from './core/link-handler.service';
import {ToastrModule} from 'ngx-toastr';
import {NgxSpinnerModule} from 'ngx-spinner';
import * as Sentry from '@sentry/browser';
import {InputBoxComponent} from './expo/common/input-box/input-box.component';
import {PendingChangesComponent} from './expo/common/pending-changes/pending-changes.component';
import {ApiModule, Configuration, ConfigurationParameters} from './virtual-expo-api';
import {AuthClientComponent} from './modules/integrations/auth-client/auth-client.component';
import {EasymdeModule} from 'ngx-easymde';

let ignoreError = false;

if (environment.sentryEnabled) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.production ? 'production' : 'development',
    release: environment.timeStamp,
    autoSessionTracking: false,
    tracesSampleRate: 0.01,
    beforeBreadcrumb: (response, hint) => {
      if (response &&
        response.category &&
        response.category === 'xhr' &&
        response.data &&
        response.data.status_code &&
        response.data.status_code === 401 || 404
      ) {
        ignoreError = true;
      } else {
        ignoreError = false;
      }
      return response;
    },
    beforeSend: (event, hint) => {
      if (ignoreError) {
        return null;
      }
      return event;
    },
    // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
    // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
    // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
    integrations: [new Sentry.Integrations.TryCatch({
      XMLHttpRequest: false,
    })],
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  extractError(error) {
    if (environment.sentryEnabled) {
      // Try to unwrap zone.js error.
      // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
      if (error && error.ngOriginalError) {
        error = error.ngOriginalError;
      }

      // We can handle messages and Error objects directly.
      if (typeof error === 'string' || error instanceof Error) {
        return error;
      }

      // If it's http module error, extract as much information from it as we can.
      if (error instanceof HttpErrorResponse) {
        // The `error` property of http exception can be either an `Error` object, which we can use directly...
        if (error.error instanceof Error) {
          return error.error;
        }

        // ... or an`ErrorEvent`, which can provide us with the message but no stack...
        if (error.error instanceof ErrorEvent) {
          return error.error.message;
        }

        // ...or the request body itself, which we can use as a message instead.
        if (typeof error.error === 'string') {
          return `Server returned code ${error.status} with body "${error.error}"`;
        }

        // If we don't have any detailed information, fallback to the request message itself.
        return error.message;
      }
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  handleError(error) {
    const extractedError = this.extractError(error) || 'Handled unknown error';
    if (environment.sentryEnabled) {
      // Capture handled exception and send it to Sentry.
      const eventId = Sentry.captureException(extractedError);

      // When in development mode, log the error to console for immediate feedback.
      if (!environment.production) {
        console.error(extractedError);
      }

      // Optionally show user dialog to provide details on what happened.
      // Sentry.showReportDialog({ eventId });
    } else {
      // debugger;
      throw error;
    }
  }
}

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
    basePath: environment.API_BASE_PATH
  };
  return new Configuration(params);
}

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.link = (href, title1, text) => {
    const url = `<a href='${href}' title='${title1}' target='_blank'>${text}</a>`;
    return url;
  };

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ValuePipe,
    ConfirmationDialogComponent,
    InputBoxComponent,
    PendingChangesComponent,
    AuthClientComponent
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    FormsModule,
    CoreModule,
    LayoutModule,
    ToastrModule.forRoot(),
    SharedModule.forRoot(),
    RoutesModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    EasymdeModule.forRoot({
      options: {
        autosave: {enabled: false, delay: 10000},
        renderingConfig: {codeSyntaxHighlighting: false},
        hideIcons: ['side-by-side', 'fullscreen']
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxSpinnerModule,
    ApiModule.forRoot(apiConfigFactory)
  ],
  providers: [
    ConfirmationDialogService,
    LinkHandlerService,
    {provide: ErrorHandler, useClass: SentryErrorHandler}
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmationDialogComponent
  ]
})
export class AppModule {
}
