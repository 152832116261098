import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SettingsService} from '../../../../core/settings/settings.service';
import {Router} from '@angular/router';
import {AccountService, BoothDetailItemDto, UserPasswordSetDto} from '../../../../virtual-expo-api';
import {ToasterService} from '../../../../core/toaster.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EqualValidator} from '../../../../shared/directives/equal-validator.directive';
import {TypedFormGroup} from '../../../../virtual-expo-api/formgroup';

@Component({
  selector: 'app-set-user-password',
  templateUrl: './set-user-password.component.html',
  styleUrls: ['./set-user-password.component.scss']
})
export class SetUserPasswordComponent implements OnInit {

  valForm: TypedFormGroup<any>;
  user: UserPasswordSetDto;
  errorMessage: string;

  submitted: boolean;

  constructor(public settings: SettingsService, fb: FormBuilder, private router: Router, private accountService: AccountService
    , private toasterService: ToasterService, private activeModal: NgbActiveModal) {

    // const password = new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,10}$')]));
    const pattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{6,16}';
    // const password = new FormControl('', Validators.compose([Validators.required, Validators.pattern(pattern)]));
    // const certainPassword = new FormControl('', Validators.compose([Validators.required, Validators.pattern(pattern)]));

    const factory = new UserPasswordSetDto.FormControlFactory(this.user as UserPasswordSetDto);
    this.valForm = new TypedFormGroup<UserPasswordSetDto>({
      email: factory.createFormControl<string>('email'),
      password: factory.createFormControl<string>('password'),
      confirmPassword: factory.createFormControl<string>('confirmPassword')
    });
    // this.valForm.setValidators(EqualValidator('password', 'confirmPassword'))
  }

  submitForm($ev, value: any) {
    this.submitted = true;
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      if (c) {
        this.valForm.controls[c].markAsTouched();
      }
    }

    if (this.valForm.valid) {
      this.accountService.accountSetUserPassword(this.valForm.value)
        .subscribe(value1 => {
            this.toasterService.success('Password set');
            this.activeModal.close();
          },
          error => {
            this.submitted = false;
            this.toasterService.error(error.error);
            this.errorMessage = error.error;
          });
    } else {
      this.submitted = false;
    }
  }

  ngOnInit() {
  }

}
