import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss']
})
export class StorageComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() documentUrl: SafeResourceUrl;
  @Input() documentId: string;
  @Output() documentUploaded: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('fileInput', {static: true}) fileInput: ElementRef;

  instanceId = '';
  hasImage = false;

  glob: StorageComponent;
  maxFileSize: number;

  constructor(
    private sanitizer: DomSanitizer
  ) {
    this.instanceId = '_' + Math.random().toString(36).substr(2, 9);
    this.maxFileSize = Math.floor(environment.fileUploadSize / 1024 / 1024);
  }

  ngOnInit() {
  }


  ngOnChanges() {
    // dummy
  }

  startRead(evt) {
    const file = this.fileInput.nativeElement.files[0];
    if (file) {
      if (file.size > environment.fileUploadSize) {
        alert(`Sorry, this file is too big. Max file size is ${this.formatBytes(environment.fileUploadSize)}`);
      } else {
        if (file.type.match('.*pdf.*')) {
          this.getAsPdf(file);
        } else {
          alert('Not an pdf file');
        }
      }
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getAsPdf(readFile) {
    const reader = new FileReader();
    reader.onload = (event) => {

      const glob = this;
      if (reader.result) {
        glob.documentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result as string);
        glob.documentUploaded.emit(reader.result as string);
      }
    };
    reader.readAsDataURL(readFile);
  };
}
