/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface TicketSaleRequestDto { 
    expoId: string;
    email: string;
    name: string;
    price?: number;
    vendor?: string;
}

/**
 * Namespace for property- and property-value-enumerations of TicketSaleRequestDto.
 */
export namespace TicketSaleRequestDto {
    /**
     * All properties of TicketSaleRequestDto.
     */
    export enum Properties {
        expoId = 'expoId',
        email = 'email',
        name = 'name',
        price = 'price',
        vendor = 'vendor'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of TicketSaleRequestDto.
    */
    export const ModelValidators: {[K in keyof TicketSaleRequestDto]: [string, ValidatorFn][]} = {
        expoId: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(36)],
        ],
        email: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(6)],
                ['maxlength', Validators.maxLength(100)],
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(3)],
                ['maxlength', Validators.maxLength(100)],
        ],
        price: [
        ],
        vendor: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
    };

    /**
    * The FormControlFactory for TicketSaleRequestDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<TicketSaleRequestDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for TicketSaleRequestDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: TicketSaleRequestDto = {
            expoId: null,
            email: null,
            name: null,
            price: null,
            vendor: null,
          }
        ) {
            super(model, TicketSaleRequestDto.ModelValidators);
        }
    }

}


