/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface IdentityUserOfString { 
    id?: string;
    userName?: string;
    normalizedUserName?: string;
    email?: string;
    normalizedEmail?: string;
    emailConfirmed?: boolean;
    passwordHash?: string;
    securityStamp?: string;
    concurrencyStamp?: string;
    phoneNumber?: string;
    phoneNumberConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    lockoutEnd?: Date;
    lockoutEnabled?: boolean;
    accessFailedCount?: number;
}

/**
 * Namespace for property- and property-value-enumerations of IdentityUserOfString.
 */
export namespace IdentityUserOfString {
    /**
     * All properties of IdentityUserOfString.
     */
    export enum Properties {
        id = 'id',
        userName = 'userName',
        normalizedUserName = 'normalizedUserName',
        email = 'email',
        normalizedEmail = 'normalizedEmail',
        emailConfirmed = 'emailConfirmed',
        passwordHash = 'passwordHash',
        securityStamp = 'securityStamp',
        concurrencyStamp = 'concurrencyStamp',
        phoneNumber = 'phoneNumber',
        phoneNumberConfirmed = 'phoneNumberConfirmed',
        twoFactorEnabled = 'twoFactorEnabled',
        lockoutEnd = 'lockoutEnd',
        lockoutEnabled = 'lockoutEnabled',
        accessFailedCount = 'accessFailedCount'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of IdentityUserOfString.
    */
    export const ModelValidators: {[K in keyof IdentityUserOfString]: [string, ValidatorFn][]} = {
        id: [
        ],
        userName: [
        ],
        normalizedUserName: [
        ],
        email: [
        ],
        normalizedEmail: [
        ],
        emailConfirmed: [
        ],
        passwordHash: [
        ],
        securityStamp: [
        ],
        concurrencyStamp: [
        ],
        phoneNumber: [
        ],
        phoneNumberConfirmed: [
        ],
        twoFactorEnabled: [
        ],
        lockoutEnd: [
        ],
        lockoutEnabled: [
        ],
        accessFailedCount: [
        ],
    };

    /**
    * The FormControlFactory for IdentityUserOfString.
    */
    export class FormControlFactory extends BaseFormControlFactory<IdentityUserOfString> {

        /**
         * Constructor.
         *
         * @param model An existing model for IdentityUserOfString.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: IdentityUserOfString = {
            id: null,
            userName: null,
            normalizedUserName: null,
            email: null,
            normalizedEmail: null,
            emailConfirmed: null,
            passwordHash: null,
            securityStamp: null,
            concurrencyStamp: null,
            phoneNumber: null,
            phoneNumberConfirmed: null,
            twoFactorEnabled: null,
            lockoutEnd: null,
            lockoutEnabled: null,
            accessFailedCount: null,
          }
        ) {
            super(model, IdentityUserOfString.ModelValidators);
        }
    }

}


