/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type BookingState = 0 | 1 | 2 | 3 | 4 | 5 | 100 | 101;

export const BookingState = {
    NUMBER_0: 0 as BookingState,
    NUMBER_1: 1 as BookingState,
    NUMBER_2: 2 as BookingState,
    NUMBER_3: 3 as BookingState,
    NUMBER_4: 4 as BookingState,
    NUMBER_5: 5 as BookingState,
    NUMBER_100: 100 as BookingState,
    NUMBER_101: 101 as BookingState
};

