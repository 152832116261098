import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'values', pure: false})
export class ValuePipe implements PipeTransform {
  transform(value: any, args?: any[]): any[] {
    const keyArr: any[] = Object.keys(value);
    const dataArr = [];

    keyArr.forEach((key: any) => {
      dataArr.push(value[key]);
    });

    return dataArr;
  }
}
