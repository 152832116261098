import {LayoutComponent} from '../layout/layout.component';
import {LoginComponent} from './pages/login/login.component';
import {LockComponent} from './pages/lock/lock.component';
import {MaintenanceComponent} from './pages/maintenance/maintenance.component';
import {Error404Component} from './pages/error404/error404.component';
import {Error500Component} from './pages/error500/error500.component';
import {AuthGuard} from '../core/authGuard/authGuard';
import {AccountLayoutComponent} from './pages/account-layout/account-layout.component';
import {CallbackComponent} from './pages/callback/callback.component';
import {PwdLessComponent} from './pages/pwd-less/pwd-less.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {TokenComponent} from './pages/token/token.component';

export const routes = [

  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'enter',
        loadChildren: () => import('../expo/enter/enter.module').then(m => m.EnterModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'calendar',
        loadChildren: () => import('../expo/calendar/expo-calendar.module').then(m => m.ExpoCalendarModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'director',
        loadChildren: () => import('../expo/director/director.module').then(m => m.DirectorModule),
        canActivate: [AuthGuard]
      },
      // {path: 'chat', loadChildren: '../expo/chat/chat.module#ChatModule', canActivate: [AuthGuard]},
      {
        path: 'webchat',
        loadChildren: () => import('../expo/web-chat/web-chat.module').then(m => m.WebChatModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'user',
        redirectTo: 'user/profile',
        pathMatch: 'full'
      },
      {
        path: 'user/profile',
        loadChildren: () => import('../expo/user/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin/expo',
        loadChildren: () => import('../expo/expo-manage/expo-manage.module').then(m => m.ExpoManageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin/content',
        loadChildren: () => import('../expo/content/content.module').then(m => m.ContentModule),
        canActivate: [AuthGuard]
      },

      {
        path: 'exhibitor',
        loadChildren: () => import('../expo/exhibitor-manage/exhibitor-manage.module').then(m => m.ExhibitorManageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'organizer',
        loadChildren: () => import('../expo/organizer/organizer.module').then(m => m.OrganizerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'presentation',
        loadChildren: () => import('../expo/presentation/presentation.module').then(m => m.PresentationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'info',
        loadChildren: () => import('../info/info.module').then(m => m.InfoModule)
      }
    ]
  },
  {
    path: '',
    component: AccountLayoutComponent,
    children: [
      {path: 'login', component: LoginComponent},
      {path: 'pwdless', component: PwdLessComponent},
      {path: 'token', component: TokenComponent},
      // {path: 'setpassword', component: SetPasswordComponent},
      {path: 'callback/:email/:token', component: CallbackComponent},
      {path: 'profile', component: ProfileComponent},
    ]
  },
  {
    path: 'world',
    loadChildren: () => import('../expo/world/world.module').then(m => m.WorldModule)
  },
  // Not lazy-loaded routes
  {path: 'lock', component: LockComponent},
  {path: 'maintenance', component: MaintenanceComponent},
  {path: '404', component: Error404Component},
  {path: '500', component: Error500Component},
  {path: '**', redirectTo: 'home'}

];
