/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { AccessMode } from '../model/accessMode';
import { AgoraTokenResultDto } from '../model/agoraTokenResultDto';
import { AvailableBookingResult } from '../model/availableBookingResult';
import { BoockingCheckDto } from '../model/boockingCheckDto';
import { BookSlotDto } from '../model/bookSlotDto';
import { BookingResultState } from '../model/bookingResultState';
import { CallDetails } from '../model/callDetails';
import { ExpoBookedListDto } from '../model/expoBookedListDto';
import { ExpoExhibitorListItemDto } from '../model/expoExhibitorListItemDto';
import { ExpoListItemDto } from '../model/expoListItemDto';
import { ExpoOverviewDto } from '../model/expoOverviewDto';
import { ExpoSimpleListItemDto } from '../model/expoSimpleListItemDto';
import { ExpoStageEventDto } from '../model/expoStageEventDto';
import { LoginResultDto } from '../model/loginResultDto';
import { MessageExhibitorDto } from '../model/messageExhibitorDto';
import { PretixLoginResult } from '../model/pretixLoginResult';
import { PretixRequestDto } from '../model/pretixRequestDto';
import { PwdLessCallbackDto } from '../model/pwdLessCallbackDto';
import { PwdLessLoginDto } from '../model/pwdLessLoginDto';
import { SessionPassword } from '../model/sessionPassword';
import { SignInDto } from '../model/signInDto';
import { SurveyResult } from '../model/surveyResult';
import { TrackDownloadDto } from '../model/trackDownloadDto';
import { TrackingType } from '../model/trackingType';
import { VHost } from '../model/vHost';
import { VisitorProfileDetailUpdateDto } from '../model/visitorProfileDetailUpdateDto';
import { VisitorProfileDto } from '../model/visitorProfileDto';
import { YoutubeChannel } from '../model/youtubeChannel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for webAgoraLookup.
 */
export namespace WebAgoraLookup {
    /**
     * Parameter map for webAgoraLookup.
     */
    export interface PartialParamMap {
      expoId: string;
      groupId: string;
      uid: string;
    }

    /**
     * Enumeration of all parameters for webAgoraLookup.
     */
    export enum Parameters {
      expoId = 'expoId',
      groupId = 'groupId',
      uid = 'uid'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webAgoraLookup
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebAgoraLookup.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      groupId: [
              ['required', Validators.required],
      ],
      uid: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webAgoraToken.
 */
export namespace WebAgoraToken {
    /**
     * Parameter map for webAgoraToken.
     */
    export interface PartialParamMap {
      expoId: string;
      groupId: string;
    }

    /**
     * Enumeration of all parameters for webAgoraToken.
     */
    export enum Parameters {
      expoId = 'expoId',
      groupId = 'groupId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webAgoraToken
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebAgoraToken.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      groupId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webAnonToken.
 */
export namespace WebAnonToken {
    /**
     * Parameter map for webAnonToken.
     */
    export interface PartialParamMap {
      /**
       * The track download dto.
       */
      SignInDto: SignInDto;
    }

    /**
     * Enumeration of all parameters for webAnonToken.
     */
    export enum Parameters {
      /**
       * The track download dto.
       */
      SignInDto = 'SignInDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webAnonToken
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebAnonToken.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webBookSlot.
 */
export namespace WebBookSlot {
    /**
     * Parameter map for webBookSlot.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      BookSlotDto: BookSlotDto;
    }

    /**
     * Enumeration of all parameters for webBookSlot.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      BookSlotDto = 'BookSlotDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webBookSlot
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebBookSlot.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webDownload.
 */
export namespace WebDownload {
    /**
     * Parameter map for webDownload.
     */
    export interface PartialParamMap {
      displayId: string;
    }

    /**
     * Enumeration of all parameters for webDownload.
     */
    export enum Parameters {
      displayId = 'displayId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webDownload
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebDownload.PartialParamMap]?: [string, ValidatorFn][]} = {
      displayId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webGetAvailableSlots.
 */
export namespace WebGetAvailableSlots {
    /**
     * Parameter map for webGetAvailableSlots.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      BoockingCheckDto: BoockingCheckDto;
    }

    /**
     * Enumeration of all parameters for webGetAvailableSlots.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      BoockingCheckDto = 'BoockingCheckDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetAvailableSlots
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetAvailableSlots.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webGetCallId.
 */
export namespace WebGetCallId {
    /**
     * Parameter map for webGetCallId.
     */
    export interface PartialParamMap {
      id: string;
    }

    /**
     * Enumeration of all parameters for webGetCallId.
     */
    export enum Parameters {
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetCallId
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetCallId.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webGetChatId.
 */
export namespace WebGetChatId {
    /**
     * Parameter map for webGetChatId.
     */
    export interface PartialParamMap {
      partnerId: string;
    }

    /**
     * Enumeration of all parameters for webGetChatId.
     */
    export enum Parameters {
      partnerId = 'partnerId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetChatId
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetChatId.PartialParamMap]?: [string, ValidatorFn][]} = {
      partnerId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webGetExhibitor.
 */
export namespace WebGetExhibitor {
    /**
     * Parameter map for webGetExhibitor.
     */
    export interface PartialParamMap {
      /**
       * The short key expo.
       */
      shortKeyExpo: string;
      /**
       * The short key exhibitor.
       */
      shortKeyExhibitor: string;
      noCache?: boolean;
    }

    /**
     * Enumeration of all parameters for webGetExhibitor.
     */
    export enum Parameters {
      /**
       * The short key expo.
       */
      shortKeyExpo = 'shortKeyExpo',
      /**
       * The short key exhibitor.
       */
      shortKeyExhibitor = 'shortKeyExhibitor',
      noCache = 'noCache'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetExhibitor
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetExhibitor.PartialParamMap]?: [string, ValidatorFn][]} = {
      shortKeyExpo: [
              ['required', Validators.required],
      ],
      shortKeyExhibitor: [
              ['required', Validators.required],
      ],
      noCache: [
      ],
    };
}

/**
 * Namespace for webGetExpo.
 */
export namespace WebGetExpo {
    /**
     * Parameter map for webGetExpo.
     */
    export interface PartialParamMap {
      /**
       * The identifier.
       */
      shortKey: string;
      noCache?: boolean;
    }

    /**
     * Enumeration of all parameters for webGetExpo.
     */
    export enum Parameters {
      /**
       * The identifier.
       */
      shortKey = 'shortKey',
      noCache = 'noCache'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetExpo
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetExpo.PartialParamMap]?: [string, ValidatorFn][]} = {
      shortKey: [
              ['required', Validators.required],
      ],
      noCache: [
      ],
    };
}

/**
 * Namespace for webGetExpoAccess.
 */
export namespace WebGetExpoAccess {
    /**
     * Parameter map for webGetExpoAccess.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for webGetExpoAccess.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetExpoAccess
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetExpoAccess.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webGetExpoById.
 */
export namespace WebGetExpoById {
    /**
     * Parameter map for webGetExpoById.
     */
    export interface PartialParamMap {
      /**
       * The identifier.
       */
      id: string;
      noCache?: boolean;
    }

    /**
     * Enumeration of all parameters for webGetExpoById.
     */
    export enum Parameters {
      /**
       * The identifier.
       */
      id = 'id',
      noCache = 'noCache'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetExpoById
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetExpoById.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
      noCache: [
      ],
    };
}

/**
 * Namespace for webGetExpoList.
 */
export namespace WebGetExpoList {
    /**
     * Parameter map for webGetExpoList.
     */
    export interface PartialParamMap {
      noCache?: boolean;
    }

    /**
     * Enumeration of all parameters for webGetExpoList.
     */
    export enum Parameters {
      noCache = 'noCache'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetExpoList
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetExpoList.PartialParamMap]?: [string, ValidatorFn][]} = {
      noCache: [
      ],
    };
}

/**
 * Namespace for webGetExpoOverview.
 */
export namespace WebGetExpoOverview {
    /**
     * Parameter map for webGetExpoOverview.
     */
    export interface PartialParamMap {
      /**
       * The identifier.
       */
      shortKey: string;
      noCache?: boolean;
    }

    /**
     * Enumeration of all parameters for webGetExpoOverview.
     */
    export enum Parameters {
      /**
       * The identifier.
       */
      shortKey = 'shortKey',
      noCache = 'noCache'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetExpoOverview
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetExpoOverview.PartialParamMap]?: [string, ValidatorFn][]} = {
      shortKey: [
              ['required', Validators.required],
      ],
      noCache: [
      ],
    };
}

/**
 * Namespace for webGetProfile.
 */
export namespace WebGetProfile {
    /**
     * Parameter map for webGetProfile.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for webGetProfile.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetProfile
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetProfile.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webGetSitemap.
 */
export namespace WebGetSitemap {
    /**
     * Parameter map for webGetSitemap.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for webGetSitemap.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetSitemap
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetSitemap.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webGetStage.
 */
export namespace WebGetStage {
    /**
     * Parameter map for webGetStage.
     */
    export interface PartialParamMap {
      /**
       * The short key expo.
       */
      shortKeyExpo: string;
      /**
       * The short key stage.
       */
      shortKeyStage: string;
      noCache?: boolean;
    }

    /**
     * Enumeration of all parameters for webGetStage.
     */
    export enum Parameters {
      /**
       * The short key expo.
       */
      shortKeyExpo = 'shortKeyExpo',
      /**
       * The short key stage.
       */
      shortKeyStage = 'shortKeyStage',
      noCache = 'noCache'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetStage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetStage.PartialParamMap]?: [string, ValidatorFn][]} = {
      shortKeyExpo: [
              ['required', Validators.required],
      ],
      shortKeyStage: [
              ['required', Validators.required],
      ],
      noCache: [
      ],
    };
}

/**
 * Namespace for webGetStage2.
 */
export namespace WebGetStage2 {
    /**
     * Parameter map for webGetStage2.
     */
    export interface PartialParamMap {
      /**
       * The short key expo.
       */
      shortKeyExpo: string;
      /**
       * The short key stage.
       */
      shortKeyStage: string;
      entry: string;
      noCache?: boolean;
    }

    /**
     * Enumeration of all parameters for webGetStage2.
     */
    export enum Parameters {
      /**
       * The short key expo.
       */
      shortKeyExpo = 'shortKeyExpo',
      /**
       * The short key stage.
       */
      shortKeyStage = 'shortKeyStage',
      entry = 'entry',
      noCache = 'noCache'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetStage2
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetStage2.PartialParamMap]?: [string, ValidatorFn][]} = {
      shortKeyExpo: [
              ['required', Validators.required],
      ],
      shortKeyStage: [
              ['required', Validators.required],
      ],
      entry: [
              ['required', Validators.required],
      ],
      noCache: [
      ],
    };
}

/**
 * Namespace for webGetVHosts.
 */
export namespace WebGetVHosts {
    /**
     * Parameter map for webGetVHosts.
     */
    export interface PartialParamMap {
      noCache?: boolean;
    }

    /**
     * Enumeration of all parameters for webGetVHosts.
     */
    export enum Parameters {
      noCache = 'noCache'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetVHosts
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetVHosts.PartialParamMap]?: [string, ValidatorFn][]} = {
      noCache: [
      ],
    };
}

/**
 * Namespace for webGetYoutubePlaylist.
 */
export namespace WebGetYoutubePlaylist {
    /**
     * Parameter map for webGetYoutubePlaylist.
     */
    export interface PartialParamMap {
      id: string;
      noCache?: boolean;
    }

    /**
     * Enumeration of all parameters for webGetYoutubePlaylist.
     */
    export enum Parameters {
      id = 'id',
      noCache = 'noCache'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGetYoutubePlaylist
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGetYoutubePlaylist.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
      noCache: [
      ],
    };
}

/**
 * Namespace for webGrantExpoAccess.
 */
export namespace WebGrantExpoAccess {
    /**
     * Parameter map for webGrantExpoAccess.
     */
    export interface PartialParamMap {
      /**
       * The model.
       */
      ExpoBookedListDto: ExpoBookedListDto;
    }

    /**
     * Enumeration of all parameters for webGrantExpoAccess.
     */
    export enum Parameters {
      /**
       * The model.
       */
      ExpoBookedListDto = 'ExpoBookedListDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webGrantExpoAccess
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebGrantExpoAccess.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webOpenSession.
 */
export namespace WebOpenSession {
    /**
     * Parameter map for webOpenSession.
     */
    export interface PartialParamMap {
      roomId: number;
      SessionPassword: SessionPassword;
    }

    /**
     * Enumeration of all parameters for webOpenSession.
     */
    export enum Parameters {
      roomId = 'roomId',
      SessionPassword = 'SessionPassword'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webOpenSession
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebOpenSession.PartialParamMap]?: [string, ValidatorFn][]} = {
      roomId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webPostSurveyResult.
 */
export namespace WebPostSurveyResult {
    /**
     * Parameter map for webPostSurveyResult.
     */
    export interface PartialParamMap {
      SurveyResult: SurveyResult;
    }

    /**
     * Enumeration of all parameters for webPostSurveyResult.
     */
    export enum Parameters {
      SurveyResult = 'SurveyResult'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webPostSurveyResult
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebPostSurveyResult.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webPwdLessCallback.
 */
export namespace WebPwdLessCallback {
    /**
     * Parameter map for webPwdLessCallback.
     */
    export interface PartialParamMap {
      PwdLessCallbackDto: PwdLessCallbackDto;
    }

    /**
     * Enumeration of all parameters for webPwdLessCallback.
     */
    export enum Parameters {
      PwdLessCallbackDto = 'PwdLessCallbackDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webPwdLessCallback
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebPwdLessCallback.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webPwdLessProfileUpdate.
 */
export namespace WebPwdLessProfileUpdate {
    /**
     * Parameter map for webPwdLessProfileUpdate.
     */
    export interface PartialParamMap {
      VisitorProfileDetailUpdateDto: VisitorProfileDetailUpdateDto;
    }

    /**
     * Enumeration of all parameters for webPwdLessProfileUpdate.
     */
    export enum Parameters {
      VisitorProfileDetailUpdateDto = 'VisitorProfileDetailUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webPwdLessProfileUpdate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebPwdLessProfileUpdate.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webPwdLessStart.
 */
export namespace WebPwdLessStart {
    /**
     * Parameter map for webPwdLessStart.
     */
    export interface PartialParamMap {
      PwdLessLoginDto: PwdLessLoginDto;
    }

    /**
     * Enumeration of all parameters for webPwdLessStart.
     */
    export enum Parameters {
      PwdLessLoginDto = 'PwdLessLoginDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webPwdLessStart
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebPwdLessStart.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webPwdLessTotpCallback.
 */
export namespace WebPwdLessTotpCallback {
    /**
     * Parameter map for webPwdLessTotpCallback.
     */
    export interface PartialParamMap {
      PwdLessCallbackDto: PwdLessCallbackDto;
    }

    /**
     * Enumeration of all parameters for webPwdLessTotpCallback.
     */
    export enum Parameters {
      PwdLessCallbackDto = 'PwdLessCallbackDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webPwdLessTotpCallback
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebPwdLessTotpCallback.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webRefresh.
 */
export namespace WebRefresh {
    /**
     * Parameter map for webRefresh.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for webRefresh.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webRefresh
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebRefresh.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webRegisterPretix.
 */
export namespace WebRegisterPretix {
    /**
     * Parameter map for webRegisterPretix.
     */
    export interface PartialParamMap {
      /**
       * The data.
       */
      PretixRequestDto: PretixRequestDto;
    }

    /**
     * Enumeration of all parameters for webRegisterPretix.
     */
    export enum Parameters {
      /**
       * The data.
       */
      PretixRequestDto = 'PretixRequestDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webRegisterPretix
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebRegisterPretix.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webSendOfflineMessage.
 */
export namespace WebSendOfflineMessage {
    /**
     * Parameter map for webSendOfflineMessage.
     */
    export interface PartialParamMap {
      MessageExhibitorDto: MessageExhibitorDto;
    }

    /**
     * Enumeration of all parameters for webSendOfflineMessage.
     */
    export enum Parameters {
      MessageExhibitorDto = 'MessageExhibitorDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webSendOfflineMessage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebSendOfflineMessage.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webSignIn.
 */
export namespace WebSignIn {
    /**
     * Parameter map for webSignIn.
     */
    export interface PartialParamMap {
      /**
       * The track download dto.
       */
      SignInDto: SignInDto;
    }

    /**
     * Enumeration of all parameters for webSignIn.
     */
    export enum Parameters {
      /**
       * The track download dto.
       */
      SignInDto = 'SignInDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webSignIn
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebSignIn.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webTrackContact.
 */
export namespace WebTrackContact {
    /**
     * Parameter map for webTrackContact.
     */
    export interface PartialParamMap {
      /**
       * The track download dto.
       */
      TrackDownloadDto: TrackDownloadDto;
    }

    /**
     * Enumeration of all parameters for webTrackContact.
     */
    export enum Parameters {
      /**
       * The track download dto.
       */
      TrackDownloadDto = 'TrackDownloadDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webTrackContact
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebTrackContact.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webTrackDownload.
 */
export namespace WebTrackDownload {
    /**
     * Parameter map for webTrackDownload.
     */
    export interface PartialParamMap {
      ExpoId?: string;
      ExhibitorId?: string;
      DisplayId?: string;
      Name?: string;
      EmailAddress?: string;
      PermitContact?: boolean;
      Url?: string;
      TrackingType?: TrackingType;
      AccessMode?: AccessMode;
    }

    /**
     * Enumeration of all parameters for webTrackDownload.
     */
    export enum Parameters {
      ExpoId = 'ExpoId',
      ExhibitorId = 'ExhibitorId',
      DisplayId = 'DisplayId',
      Name = 'Name',
      EmailAddress = 'EmailAddress',
      PermitContact = 'PermitContact',
      Url = 'Url',
      TrackingType = 'TrackingType',
      AccessMode = 'AccessMode'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webTrackDownload
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebTrackDownload.PartialParamMap]?: [string, ValidatorFn][]} = {
      ExpoId: [
              ['minlength', Validators.minLength(36)],
              ['maxlength', Validators.maxLength(36)],
      ],
      ExhibitorId: [
              ['minlength', Validators.minLength(36)],
              ['maxlength', Validators.maxLength(36)],
      ],
      DisplayId: [
      ],
      Name: [
              ['minlength', Validators.minLength(6)],
              ['maxlength', Validators.maxLength(100)],
      ],
      EmailAddress: [
              ['minlength', Validators.minLength(6)],
              ['maxlength', Validators.maxLength(100)],
      ],
      PermitContact: [
      ],
      Url: [
      ],
      TrackingType: [
      ],
      AccessMode: [
      ],
    };
}

/**
 * Namespace for webTrackDownloadPost.
 */
export namespace WebTrackDownloadPost {
    /**
     * Parameter map for webTrackDownloadPost.
     */
    export interface PartialParamMap {
      /**
       * The track download dto.
       */
      TrackDownloadDto: TrackDownloadDto;
    }

    /**
     * Enumeration of all parameters for webTrackDownloadPost.
     */
    export enum Parameters {
      /**
       * The track download dto.
       */
      TrackDownloadDto = 'TrackDownloadDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webTrackDownloadPost
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebTrackDownloadPost.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for webWebexBoothClient.
 */
export namespace WebWebexBoothClient {
    /**
     * Parameter map for webWebexBoothClient.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: number;
    }

    /**
     * Enumeration of all parameters for webWebexBoothClient.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webWebexBoothClient
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebWebexBoothClient.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webWebexBoothSipClient.
 */
export namespace WebWebexBoothSipClient {
    /**
     * Parameter map for webWebexBoothSipClient.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: number;
    }

    /**
     * Enumeration of all parameters for webWebexBoothSipClient.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webWebexBoothSipClient
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebWebexBoothSipClient.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webWebexClient.
 */
export namespace WebWebexClient {
    /**
     * Parameter map for webWebexClient.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      entryId: number;
    }

    /**
     * Enumeration of all parameters for webWebexClient.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webWebexClient
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebWebexClient.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webZoomBoothClient.
 */
export namespace WebZoomBoothClient {
    /**
     * Parameter map for webZoomBoothClient.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: number;
    }

    /**
     * Enumeration of all parameters for webZoomBoothClient.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webZoomBoothClient
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebZoomBoothClient.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for webZoomClient.
 */
export namespace WebZoomClient {
    /**
     * Parameter map for webZoomClient.
     */
    export interface PartialParamMap {
      expoId: string;
      stageId: string;
      entryId: number;
    }

    /**
     * Enumeration of all parameters for webZoomClient.
     */
    export enum Parameters {
      expoId = 'expoId',
      stageId = 'stageId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of webZoomClient
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof WebZoomClient.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      stageId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class WebService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webAgoraLookupByMap(
    map: WebAgoraLookup.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public webAgoraLookupByMap(
    map: WebAgoraLookup.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public webAgoraLookupByMap(
    map: WebAgoraLookup.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public webAgoraLookupByMap(
    map: WebAgoraLookup.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webAgoraLookup(
      map.expoId,
      map.groupId,
      map.uid,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param groupId 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webAgoraLookup(expoId: string, groupId: string, uid: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public webAgoraLookup(expoId: string, groupId: string, uid: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public webAgoraLookup(expoId: string, groupId: string, uid: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public webAgoraLookup(expoId: string, groupId: string, uid: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling webAgoraLookup.');
        }
        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling webAgoraLookup.');
        }
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling webAgoraLookup.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/web/agora/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(groupId))}/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_AgoraLookup']) {
                this.cancelMap['Web_AgoraLookup'].next();
            }
            this.cancelMap['Web_AgoraLookup'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_AgoraLookup']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_AgoraLookup']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_AgoraLookup', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_AgoraLookup']) {
            this.cancelMap['Web_AgoraLookup'].complete();
            delete this.cancelMap['Web_AgoraLookup'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_AgoraLookup',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_AgoraLookup')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webAgoraTokenByMap(
    map: WebAgoraToken.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<AgoraTokenResultDto>;
  public webAgoraTokenByMap(
    map: WebAgoraToken.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<AgoraTokenResultDto>>;
  public webAgoraTokenByMap(
    map: WebAgoraToken.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<AgoraTokenResultDto>>;
  public webAgoraTokenByMap(
    map: WebAgoraToken.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webAgoraToken(
      map.expoId,
      map.groupId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param groupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webAgoraToken(expoId: string, groupId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<AgoraTokenResultDto>;
    public webAgoraToken(expoId: string, groupId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<AgoraTokenResultDto>>;
    public webAgoraToken(expoId: string, groupId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<AgoraTokenResultDto>>;
    public webAgoraToken(expoId: string, groupId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling webAgoraToken.');
        }
        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling webAgoraToken.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<AgoraTokenResultDto>(`${this.configuration.basePath}/api/web/agora/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(groupId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_AgoraToken']) {
                this.cancelMap['Web_AgoraToken'].next();
            }
            this.cancelMap['Web_AgoraToken'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_AgoraToken']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_AgoraToken']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_AgoraToken', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_AgoraToken']) {
            this.cancelMap['Web_AgoraToken'].complete();
            delete this.cancelMap['Web_AgoraToken'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_AgoraToken',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_AgoraToken')));
        }
        return handle;
    }


  /**
   * Signs the in. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webAnonTokenByMap(
    map: WebAnonToken.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public webAnonTokenByMap(
    map: WebAnonToken.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public webAnonTokenByMap(
    map: WebAnonToken.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public webAnonTokenByMap(
    map: WebAnonToken.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webAnonToken(
      map.SignInDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Signs the in.
     * 
     * @param SignInDto The track download dto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webAnonToken(SignInDto: SignInDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public webAnonToken(SignInDto: SignInDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public webAnonToken(SignInDto: SignInDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public webAnonToken(SignInDto: SignInDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (SignInDto === null || SignInDto === undefined) {
            throw new Error('Required parameter SignInDto was null or undefined when calling webAnonToken.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<string>(`${this.configuration.basePath}/api/web/anon`,
            SignInDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_AnonToken']) {
                this.cancelMap['Web_AnonToken'].next();
            }
            this.cancelMap['Web_AnonToken'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_AnonToken']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_AnonToken']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_AnonToken', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_AnonToken']) {
            this.cancelMap['Web_AnonToken'].complete();
            delete this.cancelMap['Web_AnonToken'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_AnonToken',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_AnonToken')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webBookSlotByMap(
    map: WebBookSlot.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<BookingResultState>;
  public webBookSlotByMap(
    map: WebBookSlot.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<BookingResultState>>;
  public webBookSlotByMap(
    map: WebBookSlot.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<BookingResultState>>;
  public webBookSlotByMap(
    map: WebBookSlot.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webBookSlot(
      map.expoId,
      map.exhibitorId,
      map.BookSlotDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param BookSlotDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webBookSlot(expoId: string, exhibitorId: string, BookSlotDto: BookSlotDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<BookingResultState>;
    public webBookSlot(expoId: string, exhibitorId: string, BookSlotDto: BookSlotDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<BookingResultState>>;
    public webBookSlot(expoId: string, exhibitorId: string, BookSlotDto: BookSlotDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<BookingResultState>>;
    public webBookSlot(expoId: string, exhibitorId: string, BookSlotDto: BookSlotDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling webBookSlot.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling webBookSlot.');
        }
        if (BookSlotDto === null || BookSlotDto === undefined) {
            throw new Error('Required parameter BookSlotDto was null or undefined when calling webBookSlot.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<BookingResultState>(`${this.configuration.basePath}/api/web/book/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            BookSlotDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_BookSlot']) {
                this.cancelMap['Web_BookSlot'].next();
            }
            this.cancelMap['Web_BookSlot'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_BookSlot']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_BookSlot']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_BookSlot', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_BookSlot']) {
            this.cancelMap['Web_BookSlot'].complete();
            delete this.cancelMap['Web_BookSlot'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_BookSlot',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_BookSlot')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webDownloadByMap(
    map: WebDownload.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public webDownloadByMap(
    map: WebDownload.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public webDownloadByMap(
    map: WebDownload.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public webDownloadByMap(
    map: WebDownload.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webDownload(
      map.displayId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param displayId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webDownload(displayId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public webDownload(displayId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public webDownload(displayId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public webDownload(displayId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (displayId === null || displayId === undefined) {
            throw new Error('Required parameter displayId was null or undefined when calling webDownload.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/web/pdf/${encodeURIComponent(String(displayId))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_Download']) {
                this.cancelMap['Web_Download'].next();
            }
            this.cancelMap['Web_Download'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_Download']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_Download']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_Download', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_Download']) {
            this.cancelMap['Web_Download'].complete();
            delete this.cancelMap['Web_Download'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_Download',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_Download')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetAvailableSlotsByMap(
    map: WebGetAvailableSlots.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<AvailableBookingResult>;
  public webGetAvailableSlotsByMap(
    map: WebGetAvailableSlots.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<AvailableBookingResult>>;
  public webGetAvailableSlotsByMap(
    map: WebGetAvailableSlots.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<AvailableBookingResult>>;
  public webGetAvailableSlotsByMap(
    map: WebGetAvailableSlots.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetAvailableSlots(
      map.expoId,
      map.exhibitorId,
      map.BoockingCheckDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param BoockingCheckDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetAvailableSlots(expoId: string, exhibitorId: string, BoockingCheckDto: BoockingCheckDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<AvailableBookingResult>;
    public webGetAvailableSlots(expoId: string, exhibitorId: string, BoockingCheckDto: BoockingCheckDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<AvailableBookingResult>>;
    public webGetAvailableSlots(expoId: string, exhibitorId: string, BoockingCheckDto: BoockingCheckDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<AvailableBookingResult>>;
    public webGetAvailableSlots(expoId: string, exhibitorId: string, BoockingCheckDto: BoockingCheckDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling webGetAvailableSlots.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling webGetAvailableSlots.');
        }
        if (BoockingCheckDto === null || BoockingCheckDto === undefined) {
            throw new Error('Required parameter BoockingCheckDto was null or undefined when calling webGetAvailableSlots.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<AvailableBookingResult>(`${this.configuration.basePath}/api/web/free/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            BoockingCheckDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetAvailableSlots']) {
                this.cancelMap['Web_GetAvailableSlots'].next();
            }
            this.cancelMap['Web_GetAvailableSlots'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetAvailableSlots']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetAvailableSlots']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetAvailableSlots', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetAvailableSlots']) {
            this.cancelMap['Web_GetAvailableSlots'].complete();
            delete this.cancelMap['Web_GetAvailableSlots'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetAvailableSlots',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetAvailableSlots')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetCallIdByMap(
    map: WebGetCallId.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CallDetails>;
  public webGetCallIdByMap(
    map: WebGetCallId.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CallDetails>>;
  public webGetCallIdByMap(
    map: WebGetCallId.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CallDetails>>;
  public webGetCallIdByMap(
    map: WebGetCallId.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetCallId(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetCallId(id: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CallDetails>;
    public webGetCallId(id: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CallDetails>>;
    public webGetCallId(id: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CallDetails>>;
    public webGetCallId(id: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling webGetCallId.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<CallDetails>(`${this.configuration.basePath}/api/web/call/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetCallId']) {
                this.cancelMap['Web_GetCallId'].next();
            }
            this.cancelMap['Web_GetCallId'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetCallId']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetCallId']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetCallId', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetCallId']) {
            this.cancelMap['Web_GetCallId'].complete();
            delete this.cancelMap['Web_GetCallId'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetCallId',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetCallId')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetChatIdByMap(
    map: WebGetChatId.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public webGetChatIdByMap(
    map: WebGetChatId.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public webGetChatIdByMap(
    map: WebGetChatId.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public webGetChatIdByMap(
    map: WebGetChatId.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetChatId(
      map.partnerId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param partnerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetChatId(partnerId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public webGetChatId(partnerId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public webGetChatId(partnerId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public webGetChatId(partnerId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling webGetChatId.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/web/chat/${encodeURIComponent(String(partnerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetChatId']) {
                this.cancelMap['Web_GetChatId'].next();
            }
            this.cancelMap['Web_GetChatId'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetChatId']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetChatId']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetChatId', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetChatId']) {
            this.cancelMap['Web_GetChatId'].complete();
            delete this.cancelMap['Web_GetChatId'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetChatId',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetChatId')));
        }
        return handle;
    }


  /**
   * Gets the exhibitor. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetExhibitorByMap(
    map: WebGetExhibitor.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoExhibitorListItemDto>;
  public webGetExhibitorByMap(
    map: WebGetExhibitor.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoExhibitorListItemDto>>;
  public webGetExhibitorByMap(
    map: WebGetExhibitor.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoExhibitorListItemDto>>;
  public webGetExhibitorByMap(
    map: WebGetExhibitor.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetExhibitor(
      map.shortKeyExpo,
      map.shortKeyExhibitor,
      map.noCache,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the exhibitor.
     * 
     * @param shortKeyExpo The short key expo.
     * @param shortKeyExhibitor The short key exhibitor.
     * @param noCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetExhibitor(shortKeyExpo: string, shortKeyExhibitor: string, noCache?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoExhibitorListItemDto>;
    public webGetExhibitor(shortKeyExpo: string, shortKeyExhibitor: string, noCache?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoExhibitorListItemDto>>;
    public webGetExhibitor(shortKeyExpo: string, shortKeyExhibitor: string, noCache?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoExhibitorListItemDto>>;
    public webGetExhibitor(shortKeyExpo: string, shortKeyExhibitor: string, noCache?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (shortKeyExpo === null || shortKeyExpo === undefined) {
            throw new Error('Required parameter shortKeyExpo was null or undefined when calling webGetExhibitor.');
        }
        if (shortKeyExhibitor === null || shortKeyExhibitor === undefined) {
            throw new Error('Required parameter shortKeyExhibitor was null or undefined when calling webGetExhibitor.');
        }

        //let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (noCache !== undefined && noCache !== null) {
            queryParameters = queryParameters.set('noCache', <any>noCache);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<ExpoExhibitorListItemDto>(`${this.configuration.basePath}/api/web/exhibitor/${encodeURIComponent(String(shortKeyExpo))}/${encodeURIComponent(String(shortKeyExhibitor))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetExhibitor']) {
                this.cancelMap['Web_GetExhibitor'].next();
            }
            this.cancelMap['Web_GetExhibitor'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetExhibitor']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetExhibitor']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetExhibitor', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetExhibitor']) {
            this.cancelMap['Web_GetExhibitor'].complete();
            delete this.cancelMap['Web_GetExhibitor'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetExhibitor',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetExhibitor')));
        }
        return handle;
    }


  /**
   * Gets the expo. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetExpoByMap(
    map: WebGetExpo.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoListItemDto>;
  public webGetExpoByMap(
    map: WebGetExpo.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoListItemDto>>;
  public webGetExpoByMap(
    map: WebGetExpo.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoListItemDto>>;
  public webGetExpoByMap(
    map: WebGetExpo.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetExpo(
      map.shortKey,
      map.noCache,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the expo.
     * 
     * @param shortKey The identifier.
     * @param noCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetExpo(shortKey: string, noCache?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoListItemDto>;
    public webGetExpo(shortKey: string, noCache?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoListItemDto>>;
    public webGetExpo(shortKey: string, noCache?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoListItemDto>>;
    public webGetExpo(shortKey: string, noCache?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (shortKey === null || shortKey === undefined) {
            throw new Error('Required parameter shortKey was null or undefined when calling webGetExpo.');
        }

        //let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (noCache !== undefined && noCache !== null) {
            queryParameters = queryParameters.set('noCache', <any>noCache);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<ExpoListItemDto>(`${this.configuration.basePath}/api/web/expo/${encodeURIComponent(String(shortKey))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetExpo']) {
                this.cancelMap['Web_GetExpo'].next();
            }
            this.cancelMap['Web_GetExpo'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetExpo']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetExpo']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetExpo', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetExpo']) {
            this.cancelMap['Web_GetExpo'].complete();
            delete this.cancelMap['Web_GetExpo'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetExpo',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetExpo')));
        }
        return handle;
    }


  /**
   * Gets my expo access. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetExpoAccessByMap(
    map: WebGetExpoAccess.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<{ [key: string]: ExpoBookedListDto; }>;
  public webGetExpoAccessByMap(
    map: WebGetExpoAccess.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<{ [key: string]: ExpoBookedListDto; }>>;
  public webGetExpoAccessByMap(
    map: WebGetExpoAccess.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<{ [key: string]: ExpoBookedListDto; }>>;
  public webGetExpoAccessByMap(
    map: WebGetExpoAccess.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetExpoAccess(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets my expo access.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetExpoAccess(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<{ [key: string]: ExpoBookedListDto; }>;
    public webGetExpoAccess(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<{ [key: string]: ExpoBookedListDto; }>>;
    public webGetExpoAccess(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<{ [key: string]: ExpoBookedListDto; }>>;
    public webGetExpoAccess(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<{ [key: string]: ExpoBookedListDto; }>(`${this.configuration.basePath}/api/web/access`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetExpoAccess']) {
                this.cancelMap['Web_GetExpoAccess'].next();
            }
            this.cancelMap['Web_GetExpoAccess'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetExpoAccess']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetExpoAccess']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetExpoAccess', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetExpoAccess']) {
            this.cancelMap['Web_GetExpoAccess'].complete();
            delete this.cancelMap['Web_GetExpoAccess'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetExpoAccess',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetExpoAccess')));
        }
        return handle;
    }


  /**
   * Gets the expo. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetExpoByIdByMap(
    map: WebGetExpoById.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoListItemDto>;
  public webGetExpoByIdByMap(
    map: WebGetExpoById.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoListItemDto>>;
  public webGetExpoByIdByMap(
    map: WebGetExpoById.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoListItemDto>>;
  public webGetExpoByIdByMap(
    map: WebGetExpoById.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetExpoById(
      map.id,
      map.noCache,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the expo.
     * 
     * @param id The identifier.
     * @param noCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetExpoById(id: string, noCache?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoListItemDto>;
    public webGetExpoById(id: string, noCache?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoListItemDto>>;
    public webGetExpoById(id: string, noCache?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoListItemDto>>;
    public webGetExpoById(id: string, noCache?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling webGetExpoById.');
        }

        //let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (noCache !== undefined && noCache !== null) {
            queryParameters = queryParameters.set('noCache', <any>noCache);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<ExpoListItemDto>(`${this.configuration.basePath}/api/web/expoById/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetExpoById']) {
                this.cancelMap['Web_GetExpoById'].next();
            }
            this.cancelMap['Web_GetExpoById'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetExpoById']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetExpoById']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetExpoById', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetExpoById']) {
            this.cancelMap['Web_GetExpoById'].complete();
            delete this.cancelMap['Web_GetExpoById'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetExpoById',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetExpoById')));
        }
        return handle;
    }


  /**
   * Gets the expo list. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetExpoListByMap(
    map: WebGetExpoList.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoSimpleListItemDto>>;
  public webGetExpoListByMap(
    map: WebGetExpoList.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoSimpleListItemDto>>>;
  public webGetExpoListByMap(
    map: WebGetExpoList.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoSimpleListItemDto>>>;
  public webGetExpoListByMap(
    map: WebGetExpoList.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetExpoList(
      map.noCache,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the expo list.
     * 
     * @param noCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetExpoList(noCache?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoSimpleListItemDto>>;
    public webGetExpoList(noCache?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoSimpleListItemDto>>>;
    public webGetExpoList(noCache?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoSimpleListItemDto>>>;
    public webGetExpoList(noCache?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        //let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (noCache !== undefined && noCache !== null) {
            queryParameters = queryParameters.set('noCache', <any>noCache);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoSimpleListItemDto>>(`${this.configuration.basePath}/api/web/expo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetExpoList']) {
                this.cancelMap['Web_GetExpoList'].next();
            }
            this.cancelMap['Web_GetExpoList'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetExpoList']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetExpoList']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetExpoList', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetExpoList']) {
            this.cancelMap['Web_GetExpoList'].complete();
            delete this.cancelMap['Web_GetExpoList'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetExpoList',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetExpoList')));
        }
        return handle;
    }


  /**
   * Gets the expo. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetExpoOverviewByMap(
    map: WebGetExpoOverview.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoOverviewDto>;
  public webGetExpoOverviewByMap(
    map: WebGetExpoOverview.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoOverviewDto>>;
  public webGetExpoOverviewByMap(
    map: WebGetExpoOverview.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoOverviewDto>>;
  public webGetExpoOverviewByMap(
    map: WebGetExpoOverview.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetExpoOverview(
      map.shortKey,
      map.noCache,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the expo.
     * 
     * @param shortKey The identifier.
     * @param noCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetExpoOverview(shortKey: string, noCache?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoOverviewDto>;
    public webGetExpoOverview(shortKey: string, noCache?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoOverviewDto>>;
    public webGetExpoOverview(shortKey: string, noCache?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoOverviewDto>>;
    public webGetExpoOverview(shortKey: string, noCache?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (shortKey === null || shortKey === undefined) {
            throw new Error('Required parameter shortKey was null or undefined when calling webGetExpoOverview.');
        }

        //let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (noCache !== undefined && noCache !== null) {
            queryParameters = queryParameters.set('noCache', <any>noCache);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<ExpoOverviewDto>(`${this.configuration.basePath}/api/web/expo/overview/${encodeURIComponent(String(shortKey))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetExpoOverview']) {
                this.cancelMap['Web_GetExpoOverview'].next();
            }
            this.cancelMap['Web_GetExpoOverview'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetExpoOverview']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetExpoOverview']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetExpoOverview', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetExpoOverview']) {
            this.cancelMap['Web_GetExpoOverview'].complete();
            delete this.cancelMap['Web_GetExpoOverview'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetExpoOverview',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetExpoOverview')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetProfileByMap(
    map: WebGetProfile.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<VisitorProfileDto>;
  public webGetProfileByMap(
    map: WebGetProfile.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<VisitorProfileDto>>;
  public webGetProfileByMap(
    map: WebGetProfile.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<VisitorProfileDto>>;
  public webGetProfileByMap(
    map: WebGetProfile.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetProfile(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetProfile(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<VisitorProfileDto>;
    public webGetProfile(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<VisitorProfileDto>>;
    public webGetProfile(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<VisitorProfileDto>>;
    public webGetProfile(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<VisitorProfileDto>(`${this.configuration.basePath}/api/web/pwdless/get`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetProfile']) {
                this.cancelMap['Web_GetProfile'].next();
            }
            this.cancelMap['Web_GetProfile'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetProfile']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetProfile']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetProfile', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetProfile']) {
            this.cancelMap['Web_GetProfile'].complete();
            delete this.cancelMap['Web_GetProfile'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetProfile',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetProfile')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetSitemapByMap(
    map: WebGetSitemap.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<any>;
  public webGetSitemapByMap(
    map: WebGetSitemap.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<any>>;
  public webGetSitemapByMap(
    map: WebGetSitemap.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<any>>;
  public webGetSitemapByMap(
    map: WebGetSitemap.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetSitemap(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetSitemap(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<any>;
    public webGetSitemap(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<any>>;
    public webGetSitemap(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<any>>;
    public webGetSitemap(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<any>(`${this.configuration.basePath}/api/web/sitemap`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetSitemap']) {
                this.cancelMap['Web_GetSitemap'].next();
            }
            this.cancelMap['Web_GetSitemap'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetSitemap']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetSitemap']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetSitemap', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetSitemap']) {
            this.cancelMap['Web_GetSitemap'].complete();
            delete this.cancelMap['Web_GetSitemap'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetSitemap',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetSitemap')));
        }
        return handle;
    }


  /**
   * Gets the stage. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetStageByMap(
    map: WebGetStage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoStageEventDto>;
  public webGetStageByMap(
    map: WebGetStage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoStageEventDto>>;
  public webGetStageByMap(
    map: WebGetStage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoStageEventDto>>;
  public webGetStageByMap(
    map: WebGetStage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetStage(
      map.shortKeyExpo,
      map.shortKeyStage,
      map.noCache,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the stage.
     * 
     * @param shortKeyExpo The short key expo.
     * @param shortKeyStage The short key stage.
     * @param noCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetStage(shortKeyExpo: string, shortKeyStage: string, noCache?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoStageEventDto>;
    public webGetStage(shortKeyExpo: string, shortKeyStage: string, noCache?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoStageEventDto>>;
    public webGetStage(shortKeyExpo: string, shortKeyStage: string, noCache?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoStageEventDto>>;
    public webGetStage(shortKeyExpo: string, shortKeyStage: string, noCache?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (shortKeyExpo === null || shortKeyExpo === undefined) {
            throw new Error('Required parameter shortKeyExpo was null or undefined when calling webGetStage.');
        }
        if (shortKeyStage === null || shortKeyStage === undefined) {
            throw new Error('Required parameter shortKeyStage was null or undefined when calling webGetStage.');
        }

        //let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (noCache !== undefined && noCache !== null) {
            queryParameters = queryParameters.set('noCache', <any>noCache);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<ExpoStageEventDto>(`${this.configuration.basePath}/api/web/stage/${encodeURIComponent(String(shortKeyExpo))}/${encodeURIComponent(String(shortKeyStage))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetStage']) {
                this.cancelMap['Web_GetStage'].next();
            }
            this.cancelMap['Web_GetStage'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetStage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetStage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetStage', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetStage']) {
            this.cancelMap['Web_GetStage'].complete();
            delete this.cancelMap['Web_GetStage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetStage',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetStage')));
        }
        return handle;
    }


  /**
   * Gets the stage. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetStage2ByMap(
    map: WebGetStage2.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoStageEventDto>;
  public webGetStage2ByMap(
    map: WebGetStage2.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoStageEventDto>>;
  public webGetStage2ByMap(
    map: WebGetStage2.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoStageEventDto>>;
  public webGetStage2ByMap(
    map: WebGetStage2.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetStage2(
      map.shortKeyExpo,
      map.shortKeyStage,
      map.entry,
      map.noCache,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the stage.
     * 
     * @param shortKeyExpo The short key expo.
     * @param shortKeyStage The short key stage.
     * @param entry 
     * @param noCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetStage2(shortKeyExpo: string, shortKeyStage: string, entry: string, noCache?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoStageEventDto>;
    public webGetStage2(shortKeyExpo: string, shortKeyStage: string, entry: string, noCache?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoStageEventDto>>;
    public webGetStage2(shortKeyExpo: string, shortKeyStage: string, entry: string, noCache?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoStageEventDto>>;
    public webGetStage2(shortKeyExpo: string, shortKeyStage: string, entry: string, noCache?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (shortKeyExpo === null || shortKeyExpo === undefined) {
            throw new Error('Required parameter shortKeyExpo was null or undefined when calling webGetStage2.');
        }
        if (shortKeyStage === null || shortKeyStage === undefined) {
            throw new Error('Required parameter shortKeyStage was null or undefined when calling webGetStage2.');
        }
        if (entry === null || entry === undefined) {
            throw new Error('Required parameter entry was null or undefined when calling webGetStage2.');
        }

        //let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (noCache !== undefined && noCache !== null) {
            queryParameters = queryParameters.set('noCache', <any>noCache);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<ExpoStageEventDto>(`${this.configuration.basePath}/api/web/stage2/${encodeURIComponent(String(shortKeyExpo))}/${encodeURIComponent(String(shortKeyStage))}/${encodeURIComponent(String(entry))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetStage2']) {
                this.cancelMap['Web_GetStage2'].next();
            }
            this.cancelMap['Web_GetStage2'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetStage2']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetStage2']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetStage2', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetStage2']) {
            this.cancelMap['Web_GetStage2'].complete();
            delete this.cancelMap['Web_GetStage2'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetStage2',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetStage2')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetVHostsByMap(
    map: WebGetVHosts.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<VHost>>;
  public webGetVHostsByMap(
    map: WebGetVHosts.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<VHost>>>;
  public webGetVHostsByMap(
    map: WebGetVHosts.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<VHost>>>;
  public webGetVHostsByMap(
    map: WebGetVHosts.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetVHosts(
      map.noCache,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param noCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetVHosts(noCache?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<VHost>>;
    public webGetVHosts(noCache?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<VHost>>>;
    public webGetVHosts(noCache?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<VHost>>>;
    public webGetVHosts(noCache?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        //let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (noCache !== undefined && noCache !== null) {
            queryParameters = queryParameters.set('noCache', <any>noCache);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<VHost>>(`${this.configuration.basePath}/api/web/vhosts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetVHosts']) {
                this.cancelMap['Web_GetVHosts'].next();
            }
            this.cancelMap['Web_GetVHosts'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetVHosts']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetVHosts']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetVHosts', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetVHosts']) {
            this.cancelMap['Web_GetVHosts'].complete();
            delete this.cancelMap['Web_GetVHosts'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetVHosts',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetVHosts')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGetYoutubePlaylistByMap(
    map: WebGetYoutubePlaylist.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<YoutubeChannel>;
  public webGetYoutubePlaylistByMap(
    map: WebGetYoutubePlaylist.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<YoutubeChannel>>;
  public webGetYoutubePlaylistByMap(
    map: WebGetYoutubePlaylist.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<YoutubeChannel>>;
  public webGetYoutubePlaylistByMap(
    map: WebGetYoutubePlaylist.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGetYoutubePlaylist(
      map.id,
      map.noCache,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param noCache 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGetYoutubePlaylist(id: string, noCache?: boolean, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<YoutubeChannel>;
    public webGetYoutubePlaylist(id: string, noCache?: boolean, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<YoutubeChannel>>;
    public webGetYoutubePlaylist(id: string, noCache?: boolean, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<YoutubeChannel>>;
    public webGetYoutubePlaylist(id: string, noCache?: boolean, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling webGetYoutubePlaylist.');
        }

        //let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (noCache !== undefined && noCache !== null) {
            queryParameters = queryParameters.set('noCache', <any>noCache);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<YoutubeChannel>(`${this.configuration.basePath}/api/web/playlist/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GetYoutubePlaylist']) {
                this.cancelMap['Web_GetYoutubePlaylist'].next();
            }
            this.cancelMap['Web_GetYoutubePlaylist'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GetYoutubePlaylist']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GetYoutubePlaylist']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GetYoutubePlaylist', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GetYoutubePlaylist']) {
            this.cancelMap['Web_GetYoutubePlaylist'].complete();
            delete this.cancelMap['Web_GetYoutubePlaylist'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GetYoutubePlaylist',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GetYoutubePlaylist')));
        }
        return handle;
    }


  /**
   * Grants the expo access. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webGrantExpoAccessByMap(
    map: WebGrantExpoAccess.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<{ [key: string]: ExpoBookedListDto; }>;
  public webGrantExpoAccessByMap(
    map: WebGrantExpoAccess.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<{ [key: string]: ExpoBookedListDto; }>>;
  public webGrantExpoAccessByMap(
    map: WebGrantExpoAccess.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<{ [key: string]: ExpoBookedListDto; }>>;
  public webGrantExpoAccessByMap(
    map: WebGrantExpoAccess.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webGrantExpoAccess(
      map.ExpoBookedListDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Grants the expo access.
     * 
     * @param ExpoBookedListDto The model.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webGrantExpoAccess(ExpoBookedListDto: ExpoBookedListDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<{ [key: string]: ExpoBookedListDto; }>;
    public webGrantExpoAccess(ExpoBookedListDto: ExpoBookedListDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<{ [key: string]: ExpoBookedListDto; }>>;
    public webGrantExpoAccess(ExpoBookedListDto: ExpoBookedListDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<{ [key: string]: ExpoBookedListDto; }>>;
    public webGrantExpoAccess(ExpoBookedListDto: ExpoBookedListDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ExpoBookedListDto === null || ExpoBookedListDto === undefined) {
            throw new Error('Required parameter ExpoBookedListDto was null or undefined when calling webGrantExpoAccess.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<{ [key: string]: ExpoBookedListDto; }>(`${this.configuration.basePath}/api/web/access`,
            ExpoBookedListDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_GrantExpoAccess']) {
                this.cancelMap['Web_GrantExpoAccess'].next();
            }
            this.cancelMap['Web_GrantExpoAccess'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_GrantExpoAccess']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_GrantExpoAccess']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_GrantExpoAccess', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_GrantExpoAccess']) {
            this.cancelMap['Web_GrantExpoAccess'].complete();
            delete this.cancelMap['Web_GrantExpoAccess'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_GrantExpoAccess',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_GrantExpoAccess')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webOpenSessionByMap(
    map: WebOpenSession.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public webOpenSessionByMap(
    map: WebOpenSession.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public webOpenSessionByMap(
    map: WebOpenSession.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public webOpenSessionByMap(
    map: WebOpenSession.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webOpenSession(
      map.roomId,
      map.SessionPassword,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param roomId 
     * @param SessionPassword 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webOpenSession(roomId: number, SessionPassword: SessionPassword, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public webOpenSession(roomId: number, SessionPassword: SessionPassword, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public webOpenSession(roomId: number, SessionPassword: SessionPassword, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public webOpenSession(roomId: number, SessionPassword: SessionPassword, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (roomId === null || roomId === undefined) {
            throw new Error('Required parameter roomId was null or undefined when calling webOpenSession.');
        }
        if (SessionPassword === null || SessionPassword === undefined) {
            throw new Error('Required parameter SessionPassword was null or undefined when calling webOpenSession.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<string>(`${this.configuration.basePath}/api/web/conf/${encodeURIComponent(String(roomId))}`,
            SessionPassword,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_OpenSession']) {
                this.cancelMap['Web_OpenSession'].next();
            }
            this.cancelMap['Web_OpenSession'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_OpenSession']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_OpenSession']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_OpenSession', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_OpenSession']) {
            this.cancelMap['Web_OpenSession'].complete();
            delete this.cancelMap['Web_OpenSession'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_OpenSession',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_OpenSession')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webPostSurveyResultByMap(
    map: WebPostSurveyResult.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public webPostSurveyResultByMap(
    map: WebPostSurveyResult.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public webPostSurveyResultByMap(
    map: WebPostSurveyResult.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public webPostSurveyResultByMap(
    map: WebPostSurveyResult.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webPostSurveyResult(
      map.SurveyResult,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param SurveyResult 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webPostSurveyResult(SurveyResult: SurveyResult, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public webPostSurveyResult(SurveyResult: SurveyResult, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public webPostSurveyResult(SurveyResult: SurveyResult, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public webPostSurveyResult(SurveyResult: SurveyResult, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (SurveyResult === null || SurveyResult === undefined) {
            throw new Error('Required parameter SurveyResult was null or undefined when calling webPostSurveyResult.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/web/survey`,
            SurveyResult,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_PostSurveyResult']) {
                this.cancelMap['Web_PostSurveyResult'].next();
            }
            this.cancelMap['Web_PostSurveyResult'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_PostSurveyResult']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_PostSurveyResult']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_PostSurveyResult', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_PostSurveyResult']) {
            this.cancelMap['Web_PostSurveyResult'].complete();
            delete this.cancelMap['Web_PostSurveyResult'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_PostSurveyResult',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_PostSurveyResult')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webPwdLessCallbackByMap(
    map: WebPwdLessCallback.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
  public webPwdLessCallbackByMap(
    map: WebPwdLessCallback.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
  public webPwdLessCallbackByMap(
    map: WebPwdLessCallback.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
  public webPwdLessCallbackByMap(
    map: WebPwdLessCallback.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webPwdLessCallback(
      map.PwdLessCallbackDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param PwdLessCallbackDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webPwdLessCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
    public webPwdLessCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
    public webPwdLessCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
    public webPwdLessCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (PwdLessCallbackDto === null || PwdLessCallbackDto === undefined) {
            throw new Error('Required parameter PwdLessCallbackDto was null or undefined when calling webPwdLessCallback.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<LoginResultDto>(`${this.configuration.basePath}/api/web/pwdless/callback`,
            PwdLessCallbackDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_PwdLessCallback']) {
                this.cancelMap['Web_PwdLessCallback'].next();
            }
            this.cancelMap['Web_PwdLessCallback'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_PwdLessCallback']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_PwdLessCallback']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_PwdLessCallback', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_PwdLessCallback']) {
            this.cancelMap['Web_PwdLessCallback'].complete();
            delete this.cancelMap['Web_PwdLessCallback'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_PwdLessCallback',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_PwdLessCallback')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webPwdLessProfileUpdateByMap(
    map: WebPwdLessProfileUpdate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
  public webPwdLessProfileUpdateByMap(
    map: WebPwdLessProfileUpdate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
  public webPwdLessProfileUpdateByMap(
    map: WebPwdLessProfileUpdate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
  public webPwdLessProfileUpdateByMap(
    map: WebPwdLessProfileUpdate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webPwdLessProfileUpdate(
      map.VisitorProfileDetailUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param VisitorProfileDetailUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webPwdLessProfileUpdate(VisitorProfileDetailUpdateDto: VisitorProfileDetailUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
    public webPwdLessProfileUpdate(VisitorProfileDetailUpdateDto: VisitorProfileDetailUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
    public webPwdLessProfileUpdate(VisitorProfileDetailUpdateDto: VisitorProfileDetailUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
    public webPwdLessProfileUpdate(VisitorProfileDetailUpdateDto: VisitorProfileDetailUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (VisitorProfileDetailUpdateDto === null || VisitorProfileDetailUpdateDto === undefined) {
            throw new Error('Required parameter VisitorProfileDetailUpdateDto was null or undefined when calling webPwdLessProfileUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<LoginResultDto>(`${this.configuration.basePath}/api/web/pwdless/update`,
            VisitorProfileDetailUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_PwdLessProfileUpdate']) {
                this.cancelMap['Web_PwdLessProfileUpdate'].next();
            }
            this.cancelMap['Web_PwdLessProfileUpdate'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_PwdLessProfileUpdate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_PwdLessProfileUpdate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_PwdLessProfileUpdate', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_PwdLessProfileUpdate']) {
            this.cancelMap['Web_PwdLessProfileUpdate'].complete();
            delete this.cancelMap['Web_PwdLessProfileUpdate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_PwdLessProfileUpdate',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_PwdLessProfileUpdate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webPwdLessStartByMap(
    map: WebPwdLessStart.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public webPwdLessStartByMap(
    map: WebPwdLessStart.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public webPwdLessStartByMap(
    map: WebPwdLessStart.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public webPwdLessStartByMap(
    map: WebPwdLessStart.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webPwdLessStart(
      map.PwdLessLoginDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param PwdLessLoginDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webPwdLessStart(PwdLessLoginDto: PwdLessLoginDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public webPwdLessStart(PwdLessLoginDto: PwdLessLoginDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public webPwdLessStart(PwdLessLoginDto: PwdLessLoginDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public webPwdLessStart(PwdLessLoginDto: PwdLessLoginDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (PwdLessLoginDto === null || PwdLessLoginDto === undefined) {
            throw new Error('Required parameter PwdLessLoginDto was null or undefined when calling webPwdLessStart.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/web/pwdless`,
            PwdLessLoginDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_PwdLessStart']) {
                this.cancelMap['Web_PwdLessStart'].next();
            }
            this.cancelMap['Web_PwdLessStart'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_PwdLessStart']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_PwdLessStart']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_PwdLessStart', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_PwdLessStart']) {
            this.cancelMap['Web_PwdLessStart'].complete();
            delete this.cancelMap['Web_PwdLessStart'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_PwdLessStart',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_PwdLessStart')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webPwdLessTotpCallbackByMap(
    map: WebPwdLessTotpCallback.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
  public webPwdLessTotpCallbackByMap(
    map: WebPwdLessTotpCallback.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
  public webPwdLessTotpCallbackByMap(
    map: WebPwdLessTotpCallback.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
  public webPwdLessTotpCallbackByMap(
    map: WebPwdLessTotpCallback.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webPwdLessTotpCallback(
      map.PwdLessCallbackDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param PwdLessCallbackDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webPwdLessTotpCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
    public webPwdLessTotpCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
    public webPwdLessTotpCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
    public webPwdLessTotpCallback(PwdLessCallbackDto: PwdLessCallbackDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (PwdLessCallbackDto === null || PwdLessCallbackDto === undefined) {
            throw new Error('Required parameter PwdLessCallbackDto was null or undefined when calling webPwdLessTotpCallback.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<LoginResultDto>(`${this.configuration.basePath}/api/web/pwdless/totpcallback`,
            PwdLessCallbackDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_PwdLessTotpCallback']) {
                this.cancelMap['Web_PwdLessTotpCallback'].next();
            }
            this.cancelMap['Web_PwdLessTotpCallback'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_PwdLessTotpCallback']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_PwdLessTotpCallback']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_PwdLessTotpCallback', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_PwdLessTotpCallback']) {
            this.cancelMap['Web_PwdLessTotpCallback'].complete();
            delete this.cancelMap['Web_PwdLessTotpCallback'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_PwdLessTotpCallback',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_PwdLessTotpCallback')));
        }
        return handle;
    }


  /**
   * Refreshes this instance. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webRefreshByMap(
    map: WebRefresh.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
  public webRefreshByMap(
    map: WebRefresh.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
  public webRefreshByMap(
    map: WebRefresh.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
  public webRefreshByMap(
    map: WebRefresh.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webRefresh(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Refreshes this instance.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webRefresh(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
    public webRefresh(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
    public webRefresh(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
    public webRefresh(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.post<LoginResultDto>(`${this.configuration.basePath}/api/web/refresh`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_Refresh']) {
                this.cancelMap['Web_Refresh'].next();
            }
            this.cancelMap['Web_Refresh'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_Refresh']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_Refresh']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_Refresh', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_Refresh']) {
            this.cancelMap['Web_Refresh'].complete();
            delete this.cancelMap['Web_Refresh'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_Refresh',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_Refresh')));
        }
        return handle;
    }


  /**
   * Registers the ticket sale. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webRegisterPretixByMap(
    map: WebRegisterPretix.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<PretixLoginResult>;
  public webRegisterPretixByMap(
    map: WebRegisterPretix.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<PretixLoginResult>>;
  public webRegisterPretixByMap(
    map: WebRegisterPretix.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<PretixLoginResult>>;
  public webRegisterPretixByMap(
    map: WebRegisterPretix.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webRegisterPretix(
      map.PretixRequestDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Registers the ticket sale.
     * 
     * @param PretixRequestDto The data.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webRegisterPretix(PretixRequestDto: PretixRequestDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<PretixLoginResult>;
    public webRegisterPretix(PretixRequestDto: PretixRequestDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<PretixLoginResult>>;
    public webRegisterPretix(PretixRequestDto: PretixRequestDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<PretixLoginResult>>;
    public webRegisterPretix(PretixRequestDto: PretixRequestDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (PretixRequestDto === null || PretixRequestDto === undefined) {
            throw new Error('Required parameter PretixRequestDto was null or undefined when calling webRegisterPretix.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<PretixLoginResult>(`${this.configuration.basePath}/api/web/pretix`,
            PretixRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_RegisterPretix']) {
                this.cancelMap['Web_RegisterPretix'].next();
            }
            this.cancelMap['Web_RegisterPretix'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_RegisterPretix']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_RegisterPretix']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_RegisterPretix', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_RegisterPretix']) {
            this.cancelMap['Web_RegisterPretix'].complete();
            delete this.cancelMap['Web_RegisterPretix'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_RegisterPretix',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_RegisterPretix')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webSendOfflineMessageByMap(
    map: WebSendOfflineMessage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<any>;
  public webSendOfflineMessageByMap(
    map: WebSendOfflineMessage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<any>>;
  public webSendOfflineMessageByMap(
    map: WebSendOfflineMessage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<any>>;
  public webSendOfflineMessageByMap(
    map: WebSendOfflineMessage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webSendOfflineMessage(
      map.MessageExhibitorDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param MessageExhibitorDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webSendOfflineMessage(MessageExhibitorDto: MessageExhibitorDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<any>;
    public webSendOfflineMessage(MessageExhibitorDto: MessageExhibitorDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<any>>;
    public webSendOfflineMessage(MessageExhibitorDto: MessageExhibitorDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<any>>;
    public webSendOfflineMessage(MessageExhibitorDto: MessageExhibitorDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (MessageExhibitorDto === null || MessageExhibitorDto === undefined) {
            throw new Error('Required parameter MessageExhibitorDto was null or undefined when calling webSendOfflineMessage.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<any>(`${this.configuration.basePath}/api/web/message`,
            MessageExhibitorDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_SendOfflineMessage']) {
                this.cancelMap['Web_SendOfflineMessage'].next();
            }
            this.cancelMap['Web_SendOfflineMessage'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_SendOfflineMessage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_SendOfflineMessage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_SendOfflineMessage', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_SendOfflineMessage']) {
            this.cancelMap['Web_SendOfflineMessage'].complete();
            delete this.cancelMap['Web_SendOfflineMessage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_SendOfflineMessage',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_SendOfflineMessage')));
        }
        return handle;
    }


  /**
   * Signs the in. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webSignInByMap(
    map: WebSignIn.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public webSignInByMap(
    map: WebSignIn.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public webSignInByMap(
    map: WebSignIn.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public webSignInByMap(
    map: WebSignIn.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webSignIn(
      map.SignInDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Signs the in.
     * 
     * @param SignInDto The track download dto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webSignIn(SignInDto: SignInDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public webSignIn(SignInDto: SignInDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public webSignIn(SignInDto: SignInDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public webSignIn(SignInDto: SignInDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (SignInDto === null || SignInDto === undefined) {
            throw new Error('Required parameter SignInDto was null or undefined when calling webSignIn.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<string>(`${this.configuration.basePath}/api/web/signin`,
            SignInDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_SignIn']) {
                this.cancelMap['Web_SignIn'].next();
            }
            this.cancelMap['Web_SignIn'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_SignIn']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_SignIn']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_SignIn', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_SignIn']) {
            this.cancelMap['Web_SignIn'].complete();
            delete this.cancelMap['Web_SignIn'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_SignIn',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_SignIn')));
        }
        return handle;
    }


  /**
   * Tracks the download. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webTrackContactByMap(
    map: WebTrackContact.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public webTrackContactByMap(
    map: WebTrackContact.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public webTrackContactByMap(
    map: WebTrackContact.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public webTrackContactByMap(
    map: WebTrackContact.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webTrackContact(
      map.TrackDownloadDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Tracks the download.
     * 
     * @param TrackDownloadDto The track download dto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webTrackContact(TrackDownloadDto: TrackDownloadDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public webTrackContact(TrackDownloadDto: TrackDownloadDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public webTrackContact(TrackDownloadDto: TrackDownloadDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public webTrackContact(TrackDownloadDto: TrackDownloadDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (TrackDownloadDto === null || TrackDownloadDto === undefined) {
            throw new Error('Required parameter TrackDownloadDto was null or undefined when calling webTrackContact.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/web/contact`,
            TrackDownloadDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_TrackContact']) {
                this.cancelMap['Web_TrackContact'].next();
            }
            this.cancelMap['Web_TrackContact'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_TrackContact']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_TrackContact']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_TrackContact', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_TrackContact']) {
            this.cancelMap['Web_TrackContact'].complete();
            delete this.cancelMap['Web_TrackContact'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_TrackContact',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_TrackContact')));
        }
        return handle;
    }


  /**
   * Tracks the download. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webTrackDownloadByMap(
    map: WebTrackDownload.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<any>;
  public webTrackDownloadByMap(
    map: WebTrackDownload.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<any>>;
  public webTrackDownloadByMap(
    map: WebTrackDownload.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<any>>;
  public webTrackDownloadByMap(
    map: WebTrackDownload.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webTrackDownload(
      map.ExpoId,
      map.ExhibitorId,
      map.DisplayId,
      map.Name,
      map.EmailAddress,
      map.PermitContact,
      map.Url,
      map.TrackingType,
      map.AccessMode,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Tracks the download.
     * 
     * @param ExpoId 
     * @param ExhibitorId 
     * @param DisplayId 
     * @param Name 
     * @param EmailAddress 
     * @param PermitContact 
     * @param Url 
     * @param TrackingType 
     * @param AccessMode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webTrackDownload(ExpoId?: string, ExhibitorId?: string, DisplayId?: string, Name?: string, EmailAddress?: string, PermitContact?: boolean, Url?: string, TrackingType?: TrackingType, AccessMode?: AccessMode, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<any>;
    public webTrackDownload(ExpoId?: string, ExhibitorId?: string, DisplayId?: string, Name?: string, EmailAddress?: string, PermitContact?: boolean, Url?: string, TrackingType?: TrackingType, AccessMode?: AccessMode, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<any>>;
    public webTrackDownload(ExpoId?: string, ExhibitorId?: string, DisplayId?: string, Name?: string, EmailAddress?: string, PermitContact?: boolean, Url?: string, TrackingType?: TrackingType, AccessMode?: AccessMode, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<any>>;
    public webTrackDownload(ExpoId?: string, ExhibitorId?: string, DisplayId?: string, Name?: string, EmailAddress?: string, PermitContact?: boolean, Url?: string, TrackingType?: TrackingType, AccessMode?: AccessMode, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        //let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let queryParameters = new HttpParams();
        if (ExpoId !== undefined && ExpoId !== null) {
            queryParameters = queryParameters.set('ExpoId', <any>ExpoId);
        }
        if (ExhibitorId !== undefined && ExhibitorId !== null) {
            queryParameters = queryParameters.set('ExhibitorId', <any>ExhibitorId);
        }
        if (DisplayId !== undefined && DisplayId !== null) {
            queryParameters = queryParameters.set('DisplayId', <any>DisplayId);
        }
        if (Name !== undefined && Name !== null) {
            queryParameters = queryParameters.set('Name', <any>Name);
        }
        if (EmailAddress !== undefined && EmailAddress !== null) {
            queryParameters = queryParameters.set('EmailAddress', <any>EmailAddress);
        }
        if (PermitContact !== undefined && PermitContact !== null) {
            queryParameters = queryParameters.set('PermitContact', <any>PermitContact);
        }
        if (Url !== undefined && Url !== null) {
            queryParameters = queryParameters.set('Url', <any>Url);
        }
        if (TrackingType !== undefined && TrackingType !== null) {
            queryParameters = queryParameters.set('TrackingType', <any>TrackingType);
        }
        if (AccessMode !== undefined && AccessMode !== null) {
            queryParameters = queryParameters.set('AccessMode', <any>AccessMode);
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<any>(`${this.configuration.basePath}/api/web/download`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_TrackDownload']) {
                this.cancelMap['Web_TrackDownload'].next();
            }
            this.cancelMap['Web_TrackDownload'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_TrackDownload']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_TrackDownload']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_TrackDownload', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_TrackDownload']) {
            this.cancelMap['Web_TrackDownload'].complete();
            delete this.cancelMap['Web_TrackDownload'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_TrackDownload',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_TrackDownload')));
        }
        return handle;
    }


  /**
   * Tracks the download post. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webTrackDownloadPostByMap(
    map: WebTrackDownloadPost.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public webTrackDownloadPostByMap(
    map: WebTrackDownloadPost.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public webTrackDownloadPostByMap(
    map: WebTrackDownloadPost.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public webTrackDownloadPostByMap(
    map: WebTrackDownloadPost.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webTrackDownloadPost(
      map.TrackDownloadDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Tracks the download post.
     * 
     * @param TrackDownloadDto The track download dto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webTrackDownloadPost(TrackDownloadDto: TrackDownloadDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public webTrackDownloadPost(TrackDownloadDto: TrackDownloadDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public webTrackDownloadPost(TrackDownloadDto: TrackDownloadDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public webTrackDownloadPost(TrackDownloadDto: TrackDownloadDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (TrackDownloadDto === null || TrackDownloadDto === undefined) {
            throw new Error('Required parameter TrackDownloadDto was null or undefined when calling webTrackDownloadPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/web/download`,
            TrackDownloadDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_TrackDownloadPost']) {
                this.cancelMap['Web_TrackDownloadPost'].next();
            }
            this.cancelMap['Web_TrackDownloadPost'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_TrackDownloadPost']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_TrackDownloadPost']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_TrackDownloadPost', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_TrackDownloadPost']) {
            this.cancelMap['Web_TrackDownloadPost'].complete();
            delete this.cancelMap['Web_TrackDownloadPost'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_TrackDownloadPost',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_TrackDownloadPost')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webWebexBoothClientByMap(
    map: WebWebexBoothClient.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public webWebexBoothClientByMap(
    map: WebWebexBoothClient.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public webWebexBoothClientByMap(
    map: WebWebexBoothClient.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public webWebexBoothClientByMap(
    map: WebWebexBoothClient.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webWebexBoothClient(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webWebexBoothClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public webWebexBoothClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public webWebexBoothClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public webWebexBoothClient(expoId: string, exhibitorId: string, entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling webWebexBoothClient.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling webWebexBoothClient.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling webWebexBoothClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/web/webex-booth/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_WebexBoothClient']) {
                this.cancelMap['Web_WebexBoothClient'].next();
            }
            this.cancelMap['Web_WebexBoothClient'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_WebexBoothClient']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_WebexBoothClient']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_WebexBoothClient', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_WebexBoothClient']) {
            this.cancelMap['Web_WebexBoothClient'].complete();
            delete this.cancelMap['Web_WebexBoothClient'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_WebexBoothClient',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_WebexBoothClient')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webWebexBoothSipClientByMap(
    map: WebWebexBoothSipClient.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public webWebexBoothSipClientByMap(
    map: WebWebexBoothSipClient.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public webWebexBoothSipClientByMap(
    map: WebWebexBoothSipClient.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public webWebexBoothSipClientByMap(
    map: WebWebexBoothSipClient.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webWebexBoothSipClient(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webWebexBoothSipClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public webWebexBoothSipClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public webWebexBoothSipClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public webWebexBoothSipClient(expoId: string, exhibitorId: string, entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling webWebexBoothSipClient.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling webWebexBoothSipClient.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling webWebexBoothSipClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/web/webex-sip/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_WebexBoothSipClient']) {
                this.cancelMap['Web_WebexBoothSipClient'].next();
            }
            this.cancelMap['Web_WebexBoothSipClient'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_WebexBoothSipClient']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_WebexBoothSipClient']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_WebexBoothSipClient', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_WebexBoothSipClient']) {
            this.cancelMap['Web_WebexBoothSipClient'].complete();
            delete this.cancelMap['Web_WebexBoothSipClient'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_WebexBoothSipClient',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_WebexBoothSipClient')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webWebexClientByMap(
    map: WebWebexClient.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public webWebexClientByMap(
    map: WebWebexClient.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public webWebexClientByMap(
    map: WebWebexClient.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public webWebexClientByMap(
    map: WebWebexClient.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webWebexClient(
      map.expoId,
      map.stageId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webWebexClient(expoId: string, stageId: string, entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public webWebexClient(expoId: string, stageId: string, entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public webWebexClient(expoId: string, stageId: string, entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public webWebexClient(expoId: string, stageId: string, entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling webWebexClient.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling webWebexClient.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling webWebexClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/web/webex/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(stageId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_WebexClient']) {
                this.cancelMap['Web_WebexClient'].next();
            }
            this.cancelMap['Web_WebexClient'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_WebexClient']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_WebexClient']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_WebexClient', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_WebexClient']) {
            this.cancelMap['Web_WebexClient'].complete();
            delete this.cancelMap['Web_WebexClient'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_WebexClient',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_WebexClient')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webZoomBoothClientByMap(
    map: WebZoomBoothClient.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public webZoomBoothClientByMap(
    map: WebZoomBoothClient.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public webZoomBoothClientByMap(
    map: WebZoomBoothClient.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public webZoomBoothClientByMap(
    map: WebZoomBoothClient.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webZoomBoothClient(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webZoomBoothClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public webZoomBoothClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public webZoomBoothClient(expoId: string, exhibitorId: string, entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public webZoomBoothClient(expoId: string, exhibitorId: string, entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling webZoomBoothClient.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling webZoomBoothClient.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling webZoomBoothClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/web/zoom-booth/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_ZoomBoothClient']) {
                this.cancelMap['Web_ZoomBoothClient'].next();
            }
            this.cancelMap['Web_ZoomBoothClient'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_ZoomBoothClient']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_ZoomBoothClient']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_ZoomBoothClient', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_ZoomBoothClient']) {
            this.cancelMap['Web_ZoomBoothClient'].complete();
            delete this.cancelMap['Web_ZoomBoothClient'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_ZoomBoothClient',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_ZoomBoothClient')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public webZoomClientByMap(
    map: WebZoomClient.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public webZoomClientByMap(
    map: WebZoomClient.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public webZoomClientByMap(
    map: WebZoomClient.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public webZoomClientByMap(
    map: WebZoomClient.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.webZoomClient(
      map.expoId,
      map.stageId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param stageId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public webZoomClient(expoId: string, stageId: string, entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public webZoomClient(expoId: string, stageId: string, entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public webZoomClient(expoId: string, stageId: string, entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public webZoomClient(expoId: string, stageId: string, entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling webZoomClient.');
        }
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling webZoomClient.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling webZoomClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/web/zoom/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(stageId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Web_ZoomClient']) {
                this.cancelMap['Web_ZoomClient'].next();
            }
            this.cancelMap['Web_ZoomClient'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Web_ZoomClient']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Web_ZoomClient']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Web_ZoomClient', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Web_ZoomClient']) {
            this.cancelMap['Web_ZoomClient'].complete();
            delete this.cancelMap['Web_ZoomClient'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Web_ZoomClient',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Web_ZoomClient')));
        }
        return handle;
    }

  }

export namespace WebService {
  export enum Operations {
    Web_AgoraLookup = 'Web_AgoraLookup',
    Web_AgoraToken = 'Web_AgoraToken',
    Web_AnonToken = 'Web_AnonToken',
    Web_BookSlot = 'Web_BookSlot',
    Web_Download = 'Web_Download',
    Web_GetAvailableSlots = 'Web_GetAvailableSlots',
    Web_GetCallId = 'Web_GetCallId',
    Web_GetChatId = 'Web_GetChatId',
    Web_GetExhibitor = 'Web_GetExhibitor',
    Web_GetExpo = 'Web_GetExpo',
    Web_GetExpoAccess = 'Web_GetExpoAccess',
    Web_GetExpoById = 'Web_GetExpoById',
    Web_GetExpoList = 'Web_GetExpoList',
    Web_GetExpoOverview = 'Web_GetExpoOverview',
    Web_GetProfile = 'Web_GetProfile',
    Web_GetSitemap = 'Web_GetSitemap',
    Web_GetStage = 'Web_GetStage',
    Web_GetStage2 = 'Web_GetStage2',
    Web_GetVHosts = 'Web_GetVHosts',
    Web_GetYoutubePlaylist = 'Web_GetYoutubePlaylist',
    Web_GrantExpoAccess = 'Web_GrantExpoAccess',
    Web_OpenSession = 'Web_OpenSession',
    Web_PostSurveyResult = 'Web_PostSurveyResult',
    Web_PwdLessCallback = 'Web_PwdLessCallback',
    Web_PwdLessProfileUpdate = 'Web_PwdLessProfileUpdate',
    Web_PwdLessStart = 'Web_PwdLessStart',
    Web_PwdLessTotpCallback = 'Web_PwdLessTotpCallback',
    Web_Refresh = 'Web_Refresh',
    Web_RegisterPretix = 'Web_RegisterPretix',
    Web_SendOfflineMessage = 'Web_SendOfflineMessage',
    Web_SignIn = 'Web_SignIn',
    Web_TrackContact = 'Web_TrackContact',
    Web_TrackDownload = 'Web_TrackDownload',
    Web_TrackDownloadPost = 'Web_TrackDownloadPost',
    Web_WebexBoothClient = 'Web_WebexBoothClient',
    Web_WebexBoothSipClient = 'Web_WebexBoothSipClient',
    Web_WebexClient = 'Web_WebexClient',
    Web_ZoomBoothClient = 'Web_ZoomBoothClient',
    Web_ZoomClient = 'Web_ZoomClient'
  }
}
