import {Injectable} from '@angular/core';
import {ActiveToast, ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastr: ToastrService) {
  }

  toast(type: string, title: string, message: string): void {
    if (type === 'success') {
      this.toastr.success(message, title);
    } else if (type === 'err' || type === 'error') {
      this.toastr.error(message, title);
    } else {
      this.toastr.info(message, title);
    }
  }

  success(message: string = '') {
    this.toastr.success(message, 'Success');
  }

  warn(message: string) {
    this.toastr.warning(message, 'Warning');
  }

  error(message: string) {
    this.toastr.error(message, 'Error');
  }

  errorStatic(message: string): ActiveToast<any> {
    return this.toastr.error(message, 'Error', {disableTimeOut: true});
  }

  saved() {
    this.success('Successfully saved');
  }

  deleted() {
    this.warn('Successfully deleted');
  }
}
