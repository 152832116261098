<ng-container *ngIf="!submitted">
  <div class="row">
    <div class="col-6">
      <div class="card card-default">
        <div class="card-header" style="height: 5em;">
          <h2 class="text-center pv">Passwordless login.</h2>
        </div>
        <div class="card-body">
          <div style="height: 12em;">
            <p>
              Passwordless login means that you won't have to choose a password.
            </p>
            <p>
              If you already have an account, you will log in by clicking a link in an email we will send you.
            </p>
            <p>
              If you are a new user, you will receive an email with a link, too, and by clicking that link you will
              create
              your
              account.
            </p>
          </div>
          <form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="pwdLessForm" novalidate=""
                (submit)="submitForm($event)">
            <div class="form-group has-feedback">
              <div class="input-group">
                <input class="form-control" id="email" type="email" name="email" placeholder="Enter email"
                       autocomplete="email" formControlName="email" required="" aria-describedby="emailIcon"/>
                <div class="input-group-append">
                  <span class="input-group-text" id="emailIcon"><i class="fa fa-envelope"></i></span>
                </div>
              </div>
              <app-control-validation-results [formGroup]="valForm"
                                              [validatedControl]="'email'"></app-control-validation-results>
            </div>
            <button class="btn btn-block btn-success mt-lg" type="submit">Passwordless login</button>
          </form>
          <h4 style="margin-top: 1em;">The login link and token in the email are only valid once, and only for 15 minutes!</h4>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card card-default">
        <div class="card-header" style="height: 5em;">
          <h2 class="text-center pv">Login using email and password</h2>
        </div>
        <div class="card-body">
          <div style="height: 12em;">
            <p>If you have a password, you can log in here.</p>
            <p>
              After initially logging in using the passwordless system you can create a password.
              To do so, click on your name in the top menu bar, and select "Set password"
            </p>
          </div>
          <form [formGroup]="valForm3" class="form-validate mb-lg" role="form" name="loginForm" novalidate=""
                (submit)="submitLoginForm($event, valForm.value)">
            <div class="form-group has-feedback">
              <div class="input-group">
                <input class="form-control" id="exampleInputEmail1" type="email" name="email" placeholder="Enter email"
                       autocomplete="email" formControlName="email" required=""/>
                <div class="input-group-append">
                  <span class="input-group-text"><i class="fa fa-envelope"></i></span>
                </div>
              </div>
              <app-control-validation-results [formGroup]="valForm3"
                                              [validatedControl]="'email'"></app-control-validation-results>
            </div>
            <div class="form-group has-feedback">
              <div class="input-group">
                <input class="form-control" id="exampleInputPassword1" type="password" name="password"
                       placeholder="Password"
                       formControlName="password" required="" autocomplete="current-password"/>
                <div class="input-group-append">
                  <span class="input-group-text"><i class="fa fa-lock"></i></span>
                </div>
              </div>
              <app-control-validation-results [formGroup]="valForm3"
                                              [validatedControl]="'password'"></app-control-validation-results>
            </div>
            <div class="clearfix">
              <span class="text-danger" *ngIf="errorMessageLogin">{{ errorMessageLogin }}</span>
            </div>
            <button class="btn btn-block btn-success mt-lg" type="submit">Login</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="submitted">
  <h2 class="text-center pv">Passwordless login.</h2>
  <p>
    Please check your emails for an email with a token and a login link.<br/>
    You can enter the token below, or click the link in the email to log in.
  </p>
  <form [formGroup]="valForm2" class="form-validate mb-lg" role="form" name="loginForm2" novalidate=""
        (submit)="submitToken($event)">
    <div class="form-group has-feedback">
      <div class="input-group">
        <input class="form-control" id="token" type="string" name="token" placeholder="Enter token"
               autocomplete="token" formControlName="token" required="required"/>
      </div>
      <span class="text-danger"
            *ngIf="valForm2.controls['token'].hasError('required') && (valForm2.controls['token'].dirty || valForm2.controls['token'].touched)">This field is required</span>
      <span class="text-danger" *ngIf="errorMessage && errorMessage !== ''" [innerText]="errorMessage"></span>
    </div>
    <button class="btn btn-block btn-success mt-lg" type="submit">Confirm token</button>
  </form>
  <p>
    Clicking that link or entering the token will log you in if you are a registered user,
    or create a new account for you if you are a new user.
  </p>
  <button class="btn btn-block btn-secondary mt-lg" (click)="backToLogin($event)">Back to login</button>
</ng-container>
