<label [for]="fieldName">
  {{label}}
  <app-help *ngIf="help && help !== ''"
            [details]="help"></app-help>
  <br>
</label>
<div class="form-group has-feedback" [formGroup]="formGroup" [ngClass]="class">
  <easymde [(ngModel)]="markdownSource" [ngModelOptions]="{standalone: true}" (onChange)="updateForm($event)"
           [options]="options"></easymde>
</div>
