/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { ExhibitorDto } from '../model/exhibitorDto';
import { ExhibitorReferenceDto } from '../model/exhibitorReferenceDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for exhibitorDeleteExhibitor.
 */
export namespace ExhibitorDeleteExhibitor {
    /**
     * Parameter map for exhibitorDeleteExhibitor.
     */
    export interface PartialParamMap {
      /**
       * The identifier.
       */
      id: string;
    }

    /**
     * Enumeration of all parameters for exhibitorDeleteExhibitor.
     */
    export enum Parameters {
      /**
       * The identifier.
       */
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of exhibitorDeleteExhibitor
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExhibitorDeleteExhibitor.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for exhibitorGetExhibitor.
 */
export namespace ExhibitorGetExhibitor {
    /**
     * Parameter map for exhibitorGetExhibitor.
     */
    export interface PartialParamMap {
      /**
       * The identifier.
       */
      id: string;
    }

    /**
     * Enumeration of all parameters for exhibitorGetExhibitor.
     */
    export enum Parameters {
      /**
       * The identifier.
       */
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of exhibitorGetExhibitor
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExhibitorGetExhibitor.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for exhibitorGetExhibitorShortList.
 */
export namespace ExhibitorGetExhibitorShortList {
    /**
     * Parameter map for exhibitorGetExhibitorShortList.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for exhibitorGetExhibitorShortList.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of exhibitorGetExhibitorShortList
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExhibitorGetExhibitorShortList.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for exhibitorGetExhibitors.
 */
export namespace ExhibitorGetExhibitors {
    /**
     * Parameter map for exhibitorGetExhibitors.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for exhibitorGetExhibitors.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of exhibitorGetExhibitors
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExhibitorGetExhibitors.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for exhibitorGetExhibitorsByExpo.
 */
export namespace ExhibitorGetExhibitorsByExpo {
    /**
     * Parameter map for exhibitorGetExhibitorsByExpo.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for exhibitorGetExhibitorsByExpo.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of exhibitorGetExhibitorsByExpo
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExhibitorGetExhibitorsByExpo.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for exhibitorPostExhibitor.
 */
export namespace ExhibitorPostExhibitor {
    /**
     * Parameter map for exhibitorPostExhibitor.
     */
    export interface PartialParamMap {
      /**
       * The exhibitor dto.
       */
      ExhibitorDto: ExhibitorDto;
    }

    /**
     * Enumeration of all parameters for exhibitorPostExhibitor.
     */
    export enum Parameters {
      /**
       * The exhibitor dto.
       */
      ExhibitorDto = 'ExhibitorDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of exhibitorPostExhibitor
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ExhibitorPostExhibitor.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}



@Injectable({
  providedIn: 'root'
})
export class ExhibitorService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   * Deletes the exhibitor. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public exhibitorDeleteExhibitorByMap(
    map: ExhibitorDeleteExhibitor.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExhibitorDto>;
  public exhibitorDeleteExhibitorByMap(
    map: ExhibitorDeleteExhibitor.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExhibitorDto>>;
  public exhibitorDeleteExhibitorByMap(
    map: ExhibitorDeleteExhibitor.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExhibitorDto>>;
  public exhibitorDeleteExhibitorByMap(
    map: ExhibitorDeleteExhibitor.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.exhibitorDeleteExhibitor(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Deletes the exhibitor.
     * 
     * @param id The identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public exhibitorDeleteExhibitor(id: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExhibitorDto>;
    public exhibitorDeleteExhibitor(id: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExhibitorDto>>;
    public exhibitorDeleteExhibitor(id: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExhibitorDto>>;
    public exhibitorDeleteExhibitor(id: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling exhibitorDeleteExhibitor.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete<ExhibitorDto>(`${this.configuration.basePath}/api/Exhibitor/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Exhibitor_DeleteExhibitor']) {
                this.cancelMap['Exhibitor_DeleteExhibitor'].next();
            }
            this.cancelMap['Exhibitor_DeleteExhibitor'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Exhibitor_DeleteExhibitor']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Exhibitor_DeleteExhibitor']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Exhibitor_DeleteExhibitor', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Exhibitor_DeleteExhibitor']) {
            this.cancelMap['Exhibitor_DeleteExhibitor'].complete();
            delete this.cancelMap['Exhibitor_DeleteExhibitor'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Exhibitor_DeleteExhibitor',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Exhibitor_DeleteExhibitor')));
        }
        return handle;
    }


  /**
   * Gets the exhibitor. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public exhibitorGetExhibitorByMap(
    map: ExhibitorGetExhibitor.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExhibitorDto>;
  public exhibitorGetExhibitorByMap(
    map: ExhibitorGetExhibitor.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExhibitorDto>>;
  public exhibitorGetExhibitorByMap(
    map: ExhibitorGetExhibitor.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExhibitorDto>>;
  public exhibitorGetExhibitorByMap(
    map: ExhibitorGetExhibitor.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.exhibitorGetExhibitor(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the exhibitor.
     * 
     * @param id The identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public exhibitorGetExhibitor(id: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExhibitorDto>;
    public exhibitorGetExhibitor(id: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExhibitorDto>>;
    public exhibitorGetExhibitor(id: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExhibitorDto>>;
    public exhibitorGetExhibitor(id: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling exhibitorGetExhibitor.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<ExhibitorDto>(`${this.configuration.basePath}/api/Exhibitor/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Exhibitor_GetExhibitor']) {
                this.cancelMap['Exhibitor_GetExhibitor'].next();
            }
            this.cancelMap['Exhibitor_GetExhibitor'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Exhibitor_GetExhibitor']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Exhibitor_GetExhibitor']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Exhibitor_GetExhibitor', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Exhibitor_GetExhibitor']) {
            this.cancelMap['Exhibitor_GetExhibitor'].complete();
            delete this.cancelMap['Exhibitor_GetExhibitor'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Exhibitor_GetExhibitor',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Exhibitor_GetExhibitor')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public exhibitorGetExhibitorShortListByMap(
    map: ExhibitorGetExhibitorShortList.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExhibitorReferenceDto>>;
  public exhibitorGetExhibitorShortListByMap(
    map: ExhibitorGetExhibitorShortList.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExhibitorReferenceDto>>>;
  public exhibitorGetExhibitorShortListByMap(
    map: ExhibitorGetExhibitorShortList.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExhibitorReferenceDto>>>;
  public exhibitorGetExhibitorShortListByMap(
    map: ExhibitorGetExhibitorShortList.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.exhibitorGetExhibitorShortList(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public exhibitorGetExhibitorShortList(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExhibitorReferenceDto>>;
    public exhibitorGetExhibitorShortList(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExhibitorReferenceDto>>>;
    public exhibitorGetExhibitorShortList(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExhibitorReferenceDto>>>;
    public exhibitorGetExhibitorShortList(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling exhibitorGetExhibitorShortList.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExhibitorReferenceDto>>(`${this.configuration.basePath}/api/Exhibitor/shortList/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Exhibitor_GetExhibitorShortList']) {
                this.cancelMap['Exhibitor_GetExhibitorShortList'].next();
            }
            this.cancelMap['Exhibitor_GetExhibitorShortList'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Exhibitor_GetExhibitorShortList']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Exhibitor_GetExhibitorShortList']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Exhibitor_GetExhibitorShortList', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Exhibitor_GetExhibitorShortList']) {
            this.cancelMap['Exhibitor_GetExhibitorShortList'].complete();
            delete this.cancelMap['Exhibitor_GetExhibitorShortList'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Exhibitor_GetExhibitorShortList',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Exhibitor_GetExhibitorShortList')));
        }
        return handle;
    }


  /**
   * Gets the exhibitors. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public exhibitorGetExhibitorsByMap(
    map: ExhibitorGetExhibitors.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExhibitorDto>>;
  public exhibitorGetExhibitorsByMap(
    map: ExhibitorGetExhibitors.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExhibitorDto>>>;
  public exhibitorGetExhibitorsByMap(
    map: ExhibitorGetExhibitors.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExhibitorDto>>>;
  public exhibitorGetExhibitorsByMap(
    map: ExhibitorGetExhibitors.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.exhibitorGetExhibitors(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the exhibitors.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public exhibitorGetExhibitors(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExhibitorDto>>;
    public exhibitorGetExhibitors(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExhibitorDto>>>;
    public exhibitorGetExhibitors(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExhibitorDto>>>;
    public exhibitorGetExhibitors(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExhibitorDto>>(`${this.configuration.basePath}/api/Exhibitor`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Exhibitor_GetExhibitors']) {
                this.cancelMap['Exhibitor_GetExhibitors'].next();
            }
            this.cancelMap['Exhibitor_GetExhibitors'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Exhibitor_GetExhibitors']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Exhibitor_GetExhibitors']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Exhibitor_GetExhibitors', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Exhibitor_GetExhibitors']) {
            this.cancelMap['Exhibitor_GetExhibitors'].complete();
            delete this.cancelMap['Exhibitor_GetExhibitors'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Exhibitor_GetExhibitors',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Exhibitor_GetExhibitors')));
        }
        return handle;
    }


  /**
   * Gets the exhibitors. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public exhibitorGetExhibitorsByExpoByMap(
    map: ExhibitorGetExhibitorsByExpo.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExhibitorDto>>;
  public exhibitorGetExhibitorsByExpoByMap(
    map: ExhibitorGetExhibitorsByExpo.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExhibitorDto>>>;
  public exhibitorGetExhibitorsByExpoByMap(
    map: ExhibitorGetExhibitorsByExpo.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExhibitorDto>>>;
  public exhibitorGetExhibitorsByExpoByMap(
    map: ExhibitorGetExhibitorsByExpo.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.exhibitorGetExhibitorsByExpo(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the exhibitors.
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public exhibitorGetExhibitorsByExpo(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExhibitorDto>>;
    public exhibitorGetExhibitorsByExpo(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExhibitorDto>>>;
    public exhibitorGetExhibitorsByExpo(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExhibitorDto>>>;
    public exhibitorGetExhibitorsByExpo(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling exhibitorGetExhibitorsByExpo.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExhibitorDto>>(`${this.configuration.basePath}/api/Exhibitor/expo/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Exhibitor_GetExhibitorsByExpo']) {
                this.cancelMap['Exhibitor_GetExhibitorsByExpo'].next();
            }
            this.cancelMap['Exhibitor_GetExhibitorsByExpo'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Exhibitor_GetExhibitorsByExpo']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Exhibitor_GetExhibitorsByExpo']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Exhibitor_GetExhibitorsByExpo', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Exhibitor_GetExhibitorsByExpo']) {
            this.cancelMap['Exhibitor_GetExhibitorsByExpo'].complete();
            delete this.cancelMap['Exhibitor_GetExhibitorsByExpo'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Exhibitor_GetExhibitorsByExpo',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Exhibitor_GetExhibitorsByExpo')));
        }
        return handle;
    }


  /**
   * Posts the exhibitor. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public exhibitorPostExhibitorByMap(
    map: ExhibitorPostExhibitor.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExhibitorDto>;
  public exhibitorPostExhibitorByMap(
    map: ExhibitorPostExhibitor.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExhibitorDto>>;
  public exhibitorPostExhibitorByMap(
    map: ExhibitorPostExhibitor.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExhibitorDto>>;
  public exhibitorPostExhibitorByMap(
    map: ExhibitorPostExhibitor.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.exhibitorPostExhibitor(
      map.ExhibitorDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Posts the exhibitor.
     * 
     * @param ExhibitorDto The exhibitor dto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public exhibitorPostExhibitor(ExhibitorDto: ExhibitorDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExhibitorDto>;
    public exhibitorPostExhibitor(ExhibitorDto: ExhibitorDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExhibitorDto>>;
    public exhibitorPostExhibitor(ExhibitorDto: ExhibitorDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExhibitorDto>>;
    public exhibitorPostExhibitor(ExhibitorDto: ExhibitorDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ExhibitorDto === null || ExhibitorDto === undefined) {
            throw new Error('Required parameter ExhibitorDto was null or undefined when calling exhibitorPostExhibitor.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<ExhibitorDto>(`${this.configuration.basePath}/api/Exhibitor`,
            ExhibitorDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Exhibitor_PostExhibitor']) {
                this.cancelMap['Exhibitor_PostExhibitor'].next();
            }
            this.cancelMap['Exhibitor_PostExhibitor'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Exhibitor_PostExhibitor']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Exhibitor_PostExhibitor']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Exhibitor_PostExhibitor', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Exhibitor_PostExhibitor']) {
            this.cancelMap['Exhibitor_PostExhibitor'].complete();
            delete this.cancelMap['Exhibitor_PostExhibitor'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Exhibitor_PostExhibitor',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Exhibitor_PostExhibitor')));
        }
        return handle;
    }

  }

export namespace ExhibitorService {
  export enum Operations {
    Exhibitor_DeleteExhibitor = 'Exhibitor_DeleteExhibitor',
    Exhibitor_GetExhibitor = 'Exhibitor_GetExhibitor',
    Exhibitor_GetExhibitorShortList = 'Exhibitor_GetExhibitorShortList',
    Exhibitor_GetExhibitors = 'Exhibitor_GetExhibitors',
    Exhibitor_GetExhibitorsByExpo = 'Exhibitor_GetExhibitorsByExpo',
    Exhibitor_PostExhibitor = 'Exhibitor_PostExhibitor'
  }
}
