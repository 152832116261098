/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface PretixLoginResult { 
    name?: string;
    email?: string;
    token?: string;
    success?: boolean;
    message?: string;
}

/**
 * Namespace for property- and property-value-enumerations of PretixLoginResult.
 */
export namespace PretixLoginResult {
    /**
     * All properties of PretixLoginResult.
     */
    export enum Properties {
        name = 'name',
        email = 'email',
        token = 'token',
        success = 'success',
        message = 'message'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of PretixLoginResult.
    */
    export const ModelValidators: {[K in keyof PretixLoginResult]: [string, ValidatorFn][]} = {
        name: [
        ],
        email: [
        ],
        token: [
        ],
        success: [
        ],
        message: [
        ],
    };

    /**
    * The FormControlFactory for PretixLoginResult.
    */
    export class FormControlFactory extends BaseFormControlFactory<PretixLoginResult> {

        /**
         * Constructor.
         *
         * @param model An existing model for PretixLoginResult.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: PretixLoginResult = {
            name: null,
            email: null,
            token: null,
            success: null,
            message: null,
          }
        ) {
            super(model, PretixLoginResult.ModelValidators);
        }
    }

}


