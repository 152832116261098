<div>
  <h2 class="text-center pv">Passwordless login.</h2>
  <div style="width: 250px; margin: auto;">
    <p>
      Please check your emails for an email with a token and a login link.<br/>
      You can enter the token below, or click the link in the email to log in.
    </p>
    <form [formGroup]="valForm2" class="form-validate mb-lg" role="form" name="loginForm2" novalidate=""
          (submit)="submitToken($event)">
      <div class="form-group has-feedback">
        <div class="input-group">
          <input class="form-control" id="email" type="email" name="email" placeholder="Enter email"
                 autocomplete="email" formControlName="email" required="" aria-describedby="emailIcon"/>
          <div class="input-group-append">
            <span class="input-group-text" id="emailIcon"><i class="fa fa-envelope"></i></span>
          </div>
        </div>
        <app-control-validation-results [formGroup]="valForm2"
                                        [validatedControl]="'email'"></app-control-validation-results>
      </div>
      <div class="form-group has-feedback">
        <div class="input-group">
          <input class="form-control" id="token" type="string" name="token" placeholder="Enter token"
                 autocomplete="token" formControlName="token" required="required"/>
        </div>
        <app-control-validation-results [formGroup]="valForm2"
                                        [validatedControl]="'token'"></app-control-validation-results>
      </div>
      <div class="clearfix">
        <span class="text-danger" *ngIf="errorMessage">{{ errorMessage }}</span>
      </div>
      <button class="btn btn-block btn-success mt-lg" type="submit">Confirm token</button>
    </form>
    <p>
      Problem using your login token?<br>
      Return to login page to start again.
    </p>
    <a class="btn btn-block btn-primary mt-lg" [routerLink]="['pwdless']">Back to start</a>
  </div>
</div>
