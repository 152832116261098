/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { VisitorProfileDto } from './visitorProfileDto';
import { ContactProfileDto } from './contactProfileDto';
import { ExhibitorBagDto } from './exhibitorBagDto';
import { InventoryItemDto } from './inventoryItemDto';


export interface ExpoBagDto { 
    id?: string;
    name?: string;
    description?: string;
    dateStart?: string;
    dateEnd?: string;
    logo?: string;
    exhibitors?: Array<ExhibitorBagDto>;
    profile?: VisitorProfileDto;
    contacts?: Array<ContactProfileDto>;
    inventory?: Array<InventoryItemDto>;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoBagDto.
 */
export namespace ExpoBagDto {
    /**
     * All properties of ExpoBagDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        description = 'description',
        dateStart = 'dateStart',
        dateEnd = 'dateEnd',
        logo = 'logo',
        exhibitors = 'exhibitors',
        profile = 'profile',
        contacts = 'contacts',
        inventory = 'inventory'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoBagDto.
    */
    export const ModelValidators: {[K in keyof ExpoBagDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
        ],
        description: [
        ],
        dateStart: [
        ],
        dateEnd: [
        ],
        logo: [
        ],
        exhibitors: [
        ],
        profile: [
        ],
        contacts: [
        ],
        inventory: [
        ],
    };

    /**
    * The FormControlFactory for ExpoBagDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoBagDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoBagDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoBagDto = {
            id: null,
            name: null,
            description: null,
            dateStart: null,
            dateEnd: null,
            logo: null,
            exhibitors: null,
            profile: null,
            contacts: null,
            inventory: null,
          }
        ) {
            super(model, ExpoBagDto.ModelValidators);
        }
    }

}


