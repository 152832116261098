export * from './account.service';
import { AccountService } from './account.service';
export * from './booth.service';
import { BoothService } from './booth.service';
export * from './boothAvailability.service';
import { BoothAvailabilityService } from './boothAvailability.service';
export * from './boothCalendar.service';
import { BoothCalendarService } from './boothCalendar.service';
export * from './calendar.service';
import { CalendarService } from './calendar.service';
export * from './chat.service';
import { ChatService } from './chat.service';
export * from './contact.service';
import { ContactService } from './contact.service';
export * from './content.service';
import { ContentService } from './content.service';
export * from './exhibitor.service';
import { ExhibitorService } from './exhibitor.service';
export * from './expo.service';
import { ExpoService } from './expo.service';
export * from './oauthClient.service';
import { OauthClientService } from './oauthClient.service';
export * from './place.service';
import { PlaceService } from './place.service';
export * from './report.service';
import { ReportService } from './report.service';
export * from './stage.service';
import { StageService } from './stage.service';
export * from './statistic.service';
import { StatisticService } from './statistic.service';
export * from './storage.service';
import { StorageService } from './storage.service';
export * from './talkBooking.service';
import { TalkBookingService } from './talkBooking.service';
export * from './ticket.service';
import { TicketService } from './ticket.service';
export * from './visitor.service';
import { VisitorService } from './visitor.service';
export * from './web.service';
import { WebService } from './web.service';
export const APIS = [AccountService, BoothService, BoothAvailabilityService, BoothCalendarService, CalendarService, ChatService, ContactService, ContentService, ExhibitorService, ExpoService, OauthClientService, PlaceService, ReportService, StageService, StatisticService, StorageService, TalkBookingService, TicketService, VisitorService, WebService];
