/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { BoothTypeDto } from '../model/boothTypeDto';
import { Display } from '../model/display';
import { ExhibitorDisplayUpdateDto } from '../model/exhibitorDisplayUpdateDto';
import { ExhibitorLinkUpdateDto } from '../model/exhibitorLinkUpdateDto';
import { ExhibitorSelfUpdateDto } from '../model/exhibitorSelfUpdateDto';
import { ExpoDetailItemDto } from '../model/expoDetailItemDto';
import { ExpoExhibitor } from '../model/expoExhibitor';
import { ExpoExhibitorSelfUpdateDto } from '../model/expoExhibitorSelfUpdateDto';
import { ExpoListItemExhibitorsDto } from '../model/expoListItemExhibitorsDto';
import { ExpoUpdateDto } from '../model/expoUpdateDto';
import { InfoSlide } from '../model/infoSlide';
import { InfoSlideUpdateDto } from '../model/infoSlideUpdateDto';
import { Link } from '../model/link';
import { LivestreamSettingsDto } from '../model/livestreamSettingsDto';
import { SortUpdateDto } from '../model/sortUpdateDto';
import { StockMediaDto } from '../model/stockMediaDto';
import { VisitorListDto } from '../model/visitorListDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for boothChangeActiveDisplay.
 */
export namespace BoothChangeActiveDisplay {
    /**
     * Parameter map for boothChangeActiveDisplay.
     */
    export interface PartialParamMap {
      ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothChangeActiveDisplay.
     */
    export enum Parameters {
      ExhibitorDisplayUpdateDto = 'ExhibitorDisplayUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothChangeActiveDisplay
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothChangeActiveDisplay.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothChangeActiveInfoSlide.
 */
export namespace BoothChangeActiveInfoSlide {
    /**
     * Parameter map for boothChangeActiveInfoSlide.
     */
    export interface PartialParamMap {
      InfoSlideUpdateDto: InfoSlideUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothChangeActiveInfoSlide.
     */
    export enum Parameters {
      InfoSlideUpdateDto = 'InfoSlideUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothChangeActiveInfoSlide
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothChangeActiveInfoSlide.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothChangeActiveLink.
 */
export namespace BoothChangeActiveLink {
    /**
     * Parameter map for boothChangeActiveLink.
     */
    export interface PartialParamMap {
      ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothChangeActiveLink.
     */
    export enum Parameters {
      ExhibitorLinkUpdateDto = 'ExhibitorLinkUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothChangeActiveLink
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothChangeActiveLink.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothDeleteDisplay.
 */
export namespace BoothDeleteDisplay {
    /**
     * Parameter map for boothDeleteDisplay.
     */
    export interface PartialParamMap {
      ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothDeleteDisplay.
     */
    export enum Parameters {
      ExhibitorDisplayUpdateDto = 'ExhibitorDisplayUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothDeleteDisplay
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothDeleteDisplay.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothDeleteInfoSlide.
 */
export namespace BoothDeleteInfoSlide {
    /**
     * Parameter map for boothDeleteInfoSlide.
     */
    export interface PartialParamMap {
      InfoSlideUpdateDto: InfoSlideUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothDeleteInfoSlide.
     */
    export enum Parameters {
      InfoSlideUpdateDto = 'InfoSlideUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothDeleteInfoSlide
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothDeleteInfoSlide.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothDeleteLink.
 */
export namespace BoothDeleteLink {
    /**
     * Parameter map for boothDeleteLink.
     */
    export interface PartialParamMap {
      /**
       * The link update dto.
       */
      ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothDeleteLink.
     */
    export enum Parameters {
      /**
       * The link update dto.
       */
      ExhibitorLinkUpdateDto = 'ExhibitorLinkUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothDeleteLink
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothDeleteLink.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothDisplaySort.
 */
export namespace BoothDisplaySort {
    /**
     * Parameter map for boothDisplaySort.
     */
    export interface PartialParamMap {
      /**
       * The sort update.
       */
      SortUpdateDto: SortUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothDisplaySort.
     */
    export enum Parameters {
      /**
       * The sort update.
       */
      SortUpdateDto = 'SortUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothDisplaySort
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothDisplaySort.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothGetAvatars.
 */
export namespace BoothGetAvatars {
    /**
     * Parameter map for boothGetAvatars.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for boothGetAvatars.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothGetAvatars
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothGetAvatars.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothGetBooth.
 */
export namespace BoothGetBooth {
    /**
     * Parameter map for boothGetBooth.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for boothGetBooth.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothGetBooth
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothGetBooth.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothGetBoothIcons.
 */
export namespace BoothGetBoothIcons {
    /**
     * Parameter map for boothGetBoothIcons.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for boothGetBoothIcons.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothGetBoothIcons
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothGetBoothIcons.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothGetBoothTypes.
 */
export namespace BoothGetBoothTypes {
    /**
     * Parameter map for boothGetBoothTypes.
     */
    export interface PartialParamMap {
      expoId: string;
    }

    /**
     * Enumeration of all parameters for boothGetBoothTypes.
     */
    export enum Parameters {
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothGetBoothTypes
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothGetBoothTypes.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothGetBooths.
 */
export namespace BoothGetBooths {
    /**
     * Parameter map for boothGetBooths.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for boothGetBooths.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothGetBooths
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothGetBooths.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothGetChatBooths.
 */
export namespace BoothGetChatBooths {
    /**
     * Parameter map for boothGetChatBooths.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for boothGetChatBooths.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothGetChatBooths
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothGetChatBooths.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothGetExpo.
 */
export namespace BoothGetExpo {
    /**
     * Parameter map for boothGetExpo.
     */
    export interface PartialParamMap {
      id: string;
    }

    /**
     * Enumeration of all parameters for boothGetExpo.
     */
    export enum Parameters {
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothGetExpo
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothGetExpo.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothGetMyBooths.
 */
export namespace BoothGetMyBooths {
    /**
     * Parameter map for boothGetMyBooths.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for boothGetMyBooths.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothGetMyBooths
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothGetMyBooths.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothGetMyBooths2.
 */
export namespace BoothGetMyBooths2 {
    /**
     * Parameter map for boothGetMyBooths2.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for boothGetMyBooths2.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothGetMyBooths2
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothGetMyBooths2.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothGetMyExpos.
 */
export namespace BoothGetMyExpos {
    /**
     * Parameter map for boothGetMyExpos.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for boothGetMyExpos.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothGetMyExpos
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothGetMyExpos.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothGetVisitors.
 */
export namespace BoothGetVisitors {
    /**
     * Parameter map for boothGetVisitors.
     */
    export interface PartialParamMap {
      /**
       * The expo identifier.
       */
      expoId: string;
      /**
       * The exhibitor identifier.
       */
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for boothGetVisitors.
     */
    export enum Parameters {
      /**
       * The expo identifier.
       */
      expoId = 'expoId',
      /**
       * The exhibitor identifier.
       */
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothGetVisitors
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothGetVisitors.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothGetYoutubePlaylistPreviewImage.
 */
export namespace BoothGetYoutubePlaylistPreviewImage {
    /**
     * Parameter map for boothGetYoutubePlaylistPreviewImage.
     */
    export interface PartialParamMap {
      playlistId: string;
    }

    /**
     * Enumeration of all parameters for boothGetYoutubePlaylistPreviewImage.
     */
    export enum Parameters {
      playlistId = 'playlistId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothGetYoutubePlaylistPreviewImage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothGetYoutubePlaylistPreviewImage.PartialParamMap]?: [string, ValidatorFn][]} = {
      playlistId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothInfoSlideSort.
 */
export namespace BoothInfoSlideSort {
    /**
     * Parameter map for boothInfoSlideSort.
     */
    export interface PartialParamMap {
      /**
       * The sort update.
       */
      SortUpdateDto: SortUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothInfoSlideSort.
     */
    export enum Parameters {
      /**
       * The sort update.
       */
      SortUpdateDto = 'SortUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothInfoSlideSort
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothInfoSlideSort.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothLinkSort.
 */
export namespace BoothLinkSort {
    /**
     * Parameter map for boothLinkSort.
     */
    export interface PartialParamMap {
      /**
       * The sort update.
       */
      SortUpdateDto: SortUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothLinkSort.
     */
    export enum Parameters {
      /**
       * The sort update.
       */
      SortUpdateDto = 'SortUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothLinkSort
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothLinkSort.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothPostDisplay.
 */
export namespace BoothPostDisplay {
    /**
     * Parameter map for boothPostDisplay.
     */
    export interface PartialParamMap {
      ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothPostDisplay.
     */
    export enum Parameters {
      ExhibitorDisplayUpdateDto = 'ExhibitorDisplayUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothPostDisplay
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothPostDisplay.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothPostExhibitor.
 */
export namespace BoothPostExhibitor {
    /**
     * Parameter map for boothPostExhibitor.
     */
    export interface PartialParamMap {
      /**
       * The exhibitor self update dto.
       */
      ExhibitorSelfUpdateDto: ExhibitorSelfUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothPostExhibitor.
     */
    export enum Parameters {
      /**
       * The exhibitor self update dto.
       */
      ExhibitorSelfUpdateDto = 'ExhibitorSelfUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothPostExhibitor
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothPostExhibitor.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothPostExpoExhibitor.
 */
export namespace BoothPostExpoExhibitor {
    /**
     * Parameter map for boothPostExpoExhibitor.
     */
    export interface PartialParamMap {
      ExpoExhibitorSelfUpdateDto: ExpoExhibitorSelfUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothPostExpoExhibitor.
     */
    export enum Parameters {
      ExpoExhibitorSelfUpdateDto = 'ExpoExhibitorSelfUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothPostExpoExhibitor
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothPostExpoExhibitor.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothPostInfoSlide.
 */
export namespace BoothPostInfoSlide {
    /**
     * Parameter map for boothPostInfoSlide.
     */
    export interface PartialParamMap {
      InfoSlideUpdateDto: InfoSlideUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothPostInfoSlide.
     */
    export enum Parameters {
      InfoSlideUpdateDto = 'InfoSlideUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothPostInfoSlide
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothPostInfoSlide.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothPostLink.
 */
export namespace BoothPostLink {
    /**
     * Parameter map for boothPostLink.
     */
    export interface PartialParamMap {
      ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto;
    }

    /**
     * Enumeration of all parameters for boothPostLink.
     */
    export enum Parameters {
      ExhibitorLinkUpdateDto = 'ExhibitorLinkUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothPostLink
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothPostLink.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothStartLivestream.
 */
export namespace BoothStartLivestream {
    /**
     * Parameter map for boothStartLivestream.
     */
    export interface PartialParamMap {
      LivestreamSettingsDto: LivestreamSettingsDto;
    }

    /**
     * Enumeration of all parameters for boothStartLivestream.
     */
    export enum Parameters {
      LivestreamSettingsDto = 'LivestreamSettingsDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothStartLivestream
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothStartLivestream.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for boothStopLivestream.
 */
export namespace BoothStopLivestream {
    /**
     * Parameter map for boothStopLivestream.
     */
    export interface PartialParamMap {
      LivestreamSettingsDto: LivestreamSettingsDto;
    }

    /**
     * Enumeration of all parameters for boothStopLivestream.
     */
    export enum Parameters {
      LivestreamSettingsDto = 'LivestreamSettingsDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothStopLivestream
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothStopLivestream.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}



@Injectable({
  providedIn: 'root'
})
export class BoothService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothChangeActiveDisplayByMap(
    map: BoothChangeActiveDisplay.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public boothChangeActiveDisplayByMap(
    map: BoothChangeActiveDisplay.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public boothChangeActiveDisplayByMap(
    map: BoothChangeActiveDisplay.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public boothChangeActiveDisplayByMap(
    map: BoothChangeActiveDisplay.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothChangeActiveDisplay(
      map.ExhibitorDisplayUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ExhibitorDisplayUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothChangeActiveDisplay(ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public boothChangeActiveDisplay(ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public boothChangeActiveDisplay(ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public boothChangeActiveDisplay(ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ExhibitorDisplayUpdateDto === null || ExhibitorDisplayUpdateDto === undefined) {
            throw new Error('Required parameter ExhibitorDisplayUpdateDto was null or undefined when calling boothChangeActiveDisplay.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/Booth/activateDisplay`,
            ExhibitorDisplayUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_ChangeActiveDisplay']) {
                this.cancelMap['Booth_ChangeActiveDisplay'].next();
            }
            this.cancelMap['Booth_ChangeActiveDisplay'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_ChangeActiveDisplay']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_ChangeActiveDisplay']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_ChangeActiveDisplay', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_ChangeActiveDisplay']) {
            this.cancelMap['Booth_ChangeActiveDisplay'].complete();
            delete this.cancelMap['Booth_ChangeActiveDisplay'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_ChangeActiveDisplay',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_ChangeActiveDisplay')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothChangeActiveInfoSlideByMap(
    map: BoothChangeActiveInfoSlide.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public boothChangeActiveInfoSlideByMap(
    map: BoothChangeActiveInfoSlide.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public boothChangeActiveInfoSlideByMap(
    map: BoothChangeActiveInfoSlide.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public boothChangeActiveInfoSlideByMap(
    map: BoothChangeActiveInfoSlide.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothChangeActiveInfoSlide(
      map.InfoSlideUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param InfoSlideUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothChangeActiveInfoSlide(InfoSlideUpdateDto: InfoSlideUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public boothChangeActiveInfoSlide(InfoSlideUpdateDto: InfoSlideUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public boothChangeActiveInfoSlide(InfoSlideUpdateDto: InfoSlideUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public boothChangeActiveInfoSlide(InfoSlideUpdateDto: InfoSlideUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (InfoSlideUpdateDto === null || InfoSlideUpdateDto === undefined) {
            throw new Error('Required parameter InfoSlideUpdateDto was null or undefined when calling boothChangeActiveInfoSlide.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/Booth/activateInfoSlide`,
            InfoSlideUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_ChangeActiveInfoSlide']) {
                this.cancelMap['Booth_ChangeActiveInfoSlide'].next();
            }
            this.cancelMap['Booth_ChangeActiveInfoSlide'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_ChangeActiveInfoSlide']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_ChangeActiveInfoSlide']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_ChangeActiveInfoSlide', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_ChangeActiveInfoSlide']) {
            this.cancelMap['Booth_ChangeActiveInfoSlide'].complete();
            delete this.cancelMap['Booth_ChangeActiveInfoSlide'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_ChangeActiveInfoSlide',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_ChangeActiveInfoSlide')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothChangeActiveLinkByMap(
    map: BoothChangeActiveLink.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public boothChangeActiveLinkByMap(
    map: BoothChangeActiveLink.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public boothChangeActiveLinkByMap(
    map: BoothChangeActiveLink.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public boothChangeActiveLinkByMap(
    map: BoothChangeActiveLink.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothChangeActiveLink(
      map.ExhibitorLinkUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ExhibitorLinkUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothChangeActiveLink(ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public boothChangeActiveLink(ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public boothChangeActiveLink(ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public boothChangeActiveLink(ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ExhibitorLinkUpdateDto === null || ExhibitorLinkUpdateDto === undefined) {
            throw new Error('Required parameter ExhibitorLinkUpdateDto was null or undefined when calling boothChangeActiveLink.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/Booth/activateLink`,
            ExhibitorLinkUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_ChangeActiveLink']) {
                this.cancelMap['Booth_ChangeActiveLink'].next();
            }
            this.cancelMap['Booth_ChangeActiveLink'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_ChangeActiveLink']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_ChangeActiveLink']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_ChangeActiveLink', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_ChangeActiveLink']) {
            this.cancelMap['Booth_ChangeActiveLink'].complete();
            delete this.cancelMap['Booth_ChangeActiveLink'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_ChangeActiveLink',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_ChangeActiveLink')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothDeleteDisplayByMap(
    map: BoothDeleteDisplay.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public boothDeleteDisplayByMap(
    map: BoothDeleteDisplay.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public boothDeleteDisplayByMap(
    map: BoothDeleteDisplay.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public boothDeleteDisplayByMap(
    map: BoothDeleteDisplay.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothDeleteDisplay(
      map.ExhibitorDisplayUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ExhibitorDisplayUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothDeleteDisplay(ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public boothDeleteDisplay(ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public boothDeleteDisplay(ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public boothDeleteDisplay(ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ExhibitorDisplayUpdateDto === null || ExhibitorDisplayUpdateDto === undefined) {
            throw new Error('Required parameter ExhibitorDisplayUpdateDto was null or undefined when calling boothDeleteDisplay.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/Booth/displaydelete`,
            ExhibitorDisplayUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_DeleteDisplay']) {
                this.cancelMap['Booth_DeleteDisplay'].next();
            }
            this.cancelMap['Booth_DeleteDisplay'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_DeleteDisplay']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_DeleteDisplay']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_DeleteDisplay', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_DeleteDisplay']) {
            this.cancelMap['Booth_DeleteDisplay'].complete();
            delete this.cancelMap['Booth_DeleteDisplay'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_DeleteDisplay',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_DeleteDisplay')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothDeleteInfoSlideByMap(
    map: BoothDeleteInfoSlide.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public boothDeleteInfoSlideByMap(
    map: BoothDeleteInfoSlide.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public boothDeleteInfoSlideByMap(
    map: BoothDeleteInfoSlide.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public boothDeleteInfoSlideByMap(
    map: BoothDeleteInfoSlide.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothDeleteInfoSlide(
      map.InfoSlideUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param InfoSlideUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothDeleteInfoSlide(InfoSlideUpdateDto: InfoSlideUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public boothDeleteInfoSlide(InfoSlideUpdateDto: InfoSlideUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public boothDeleteInfoSlide(InfoSlideUpdateDto: InfoSlideUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public boothDeleteInfoSlide(InfoSlideUpdateDto: InfoSlideUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (InfoSlideUpdateDto === null || InfoSlideUpdateDto === undefined) {
            throw new Error('Required parameter InfoSlideUpdateDto was null or undefined when calling boothDeleteInfoSlide.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/Booth/infoSlidedelete`,
            InfoSlideUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_DeleteInfoSlide']) {
                this.cancelMap['Booth_DeleteInfoSlide'].next();
            }
            this.cancelMap['Booth_DeleteInfoSlide'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_DeleteInfoSlide']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_DeleteInfoSlide']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_DeleteInfoSlide', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_DeleteInfoSlide']) {
            this.cancelMap['Booth_DeleteInfoSlide'].complete();
            delete this.cancelMap['Booth_DeleteInfoSlide'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_DeleteInfoSlide',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_DeleteInfoSlide')));
        }
        return handle;
    }


  /**
   * Deletes the link. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothDeleteLinkByMap(
    map: BoothDeleteLink.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<boolean>;
  public boothDeleteLinkByMap(
    map: BoothDeleteLink.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
  public boothDeleteLinkByMap(
    map: BoothDeleteLink.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
  public boothDeleteLinkByMap(
    map: BoothDeleteLink.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothDeleteLink(
      map.ExhibitorLinkUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Deletes the link.
     * 
     * @param ExhibitorLinkUpdateDto The link update dto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothDeleteLink(ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<boolean>;
    public boothDeleteLink(ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<boolean>>;
    public boothDeleteLink(ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<boolean>>;
    public boothDeleteLink(ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ExhibitorLinkUpdateDto === null || ExhibitorLinkUpdateDto === undefined) {
            throw new Error('Required parameter ExhibitorLinkUpdateDto was null or undefined when calling boothDeleteLink.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<boolean>(`${this.configuration.basePath}/api/Booth/linkdelete`,
            ExhibitorLinkUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_DeleteLink']) {
                this.cancelMap['Booth_DeleteLink'].next();
            }
            this.cancelMap['Booth_DeleteLink'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_DeleteLink']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_DeleteLink']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_DeleteLink', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_DeleteLink']) {
            this.cancelMap['Booth_DeleteLink'].complete();
            delete this.cancelMap['Booth_DeleteLink'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_DeleteLink',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_DeleteLink')));
        }
        return handle;
    }


  /**
   * Displays the sort. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothDisplaySortByMap(
    map: BoothDisplaySort.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<Display>>;
  public boothDisplaySortByMap(
    map: BoothDisplaySort.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<Display>>>;
  public boothDisplaySortByMap(
    map: BoothDisplaySort.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<Display>>>;
  public boothDisplaySortByMap(
    map: BoothDisplaySort.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothDisplaySort(
      map.SortUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Displays the sort.
     * 
     * @param SortUpdateDto The sort update.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothDisplaySort(SortUpdateDto: SortUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<Display>>;
    public boothDisplaySort(SortUpdateDto: SortUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<Display>>>;
    public boothDisplaySort(SortUpdateDto: SortUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<Display>>>;
    public boothDisplaySort(SortUpdateDto: SortUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (SortUpdateDto === null || SortUpdateDto === undefined) {
            throw new Error('Required parameter SortUpdateDto was null or undefined when calling boothDisplaySort.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<Array<Display>>(`${this.configuration.basePath}/api/Booth/displaysort`,
            SortUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_DisplaySort']) {
                this.cancelMap['Booth_DisplaySort'].next();
            }
            this.cancelMap['Booth_DisplaySort'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_DisplaySort']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_DisplaySort']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_DisplaySort', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_DisplaySort']) {
            this.cancelMap['Booth_DisplaySort'].complete();
            delete this.cancelMap['Booth_DisplaySort'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_DisplaySort',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_DisplaySort')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothGetAvatarsByMap(
    map: BoothGetAvatars.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
  public boothGetAvatarsByMap(
    map: BoothGetAvatars.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
  public boothGetAvatarsByMap(
    map: BoothGetAvatars.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
  public boothGetAvatarsByMap(
    map: BoothGetAvatars.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothGetAvatars(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothGetAvatars(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
    public boothGetAvatars(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
    public boothGetAvatars(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
    public boothGetAvatars(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<StockMediaDto>>(`${this.configuration.basePath}/api/Booth/avatars`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_GetAvatars']) {
                this.cancelMap['Booth_GetAvatars'].next();
            }
            this.cancelMap['Booth_GetAvatars'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_GetAvatars']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_GetAvatars']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_GetAvatars', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_GetAvatars']) {
            this.cancelMap['Booth_GetAvatars'].complete();
            delete this.cancelMap['Booth_GetAvatars'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_GetAvatars',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_GetAvatars')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothGetBoothByMap(
    map: BoothGetBooth.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoExhibitor>;
  public boothGetBoothByMap(
    map: BoothGetBooth.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoExhibitor>>;
  public boothGetBoothByMap(
    map: BoothGetBooth.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoExhibitor>>;
  public boothGetBoothByMap(
    map: BoothGetBooth.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothGetBooth(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothGetBooth(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoExhibitor>;
    public boothGetBooth(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoExhibitor>>;
    public boothGetBooth(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoExhibitor>>;
    public boothGetBooth(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothGetBooth.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling boothGetBooth.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<ExpoExhibitor>(`${this.configuration.basePath}/api/Booth/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_GetBooth']) {
                this.cancelMap['Booth_GetBooth'].next();
            }
            this.cancelMap['Booth_GetBooth'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_GetBooth']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_GetBooth']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_GetBooth', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_GetBooth']) {
            this.cancelMap['Booth_GetBooth'].complete();
            delete this.cancelMap['Booth_GetBooth'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_GetBooth',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_GetBooth')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothGetBoothIconsByMap(
    map: BoothGetBoothIcons.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
  public boothGetBoothIconsByMap(
    map: BoothGetBoothIcons.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
  public boothGetBoothIconsByMap(
    map: BoothGetBoothIcons.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
  public boothGetBoothIconsByMap(
    map: BoothGetBoothIcons.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothGetBoothIcons(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothGetBoothIcons(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
    public boothGetBoothIcons(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
    public boothGetBoothIcons(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
    public boothGetBoothIcons(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<StockMediaDto>>(`${this.configuration.basePath}/api/Booth/boothicons`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_GetBoothIcons']) {
                this.cancelMap['Booth_GetBoothIcons'].next();
            }
            this.cancelMap['Booth_GetBoothIcons'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_GetBoothIcons']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_GetBoothIcons']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_GetBoothIcons', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_GetBoothIcons']) {
            this.cancelMap['Booth_GetBoothIcons'].complete();
            delete this.cancelMap['Booth_GetBoothIcons'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_GetBoothIcons',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_GetBoothIcons')));
        }
        return handle;
    }


  /**
   * Gets the booth types. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothGetBoothTypesByMap(
    map: BoothGetBoothTypes.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<BoothTypeDto>>;
  public boothGetBoothTypesByMap(
    map: BoothGetBoothTypes.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<BoothTypeDto>>>;
  public boothGetBoothTypesByMap(
    map: BoothGetBoothTypes.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<BoothTypeDto>>>;
  public boothGetBoothTypesByMap(
    map: BoothGetBoothTypes.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothGetBoothTypes(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the booth types.
     * 
     * @param expoId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothGetBoothTypes(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<BoothTypeDto>>;
    public boothGetBoothTypes(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<BoothTypeDto>>>;
    public boothGetBoothTypes(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<BoothTypeDto>>>;
    public boothGetBoothTypes(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothGetBoothTypes.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<BoothTypeDto>>(`${this.configuration.basePath}/api/Booth/boothTypes/${encodeURIComponent(String(expoId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_GetBoothTypes']) {
                this.cancelMap['Booth_GetBoothTypes'].next();
            }
            this.cancelMap['Booth_GetBoothTypes'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_GetBoothTypes']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_GetBoothTypes']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_GetBoothTypes', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_GetBoothTypes']) {
            this.cancelMap['Booth_GetBoothTypes'].complete();
            delete this.cancelMap['Booth_GetBoothTypes'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_GetBoothTypes',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_GetBoothTypes')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothGetBoothsByMap(
    map: BoothGetBooths.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoListItemExhibitorsDto>>;
  public boothGetBoothsByMap(
    map: BoothGetBooths.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoListItemExhibitorsDto>>>;
  public boothGetBoothsByMap(
    map: BoothGetBooths.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoListItemExhibitorsDto>>>;
  public boothGetBoothsByMap(
    map: BoothGetBooths.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothGetBooths(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothGetBooths(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoListItemExhibitorsDto>>;
    public boothGetBooths(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoListItemExhibitorsDto>>>;
    public boothGetBooths(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoListItemExhibitorsDto>>>;
    public boothGetBooths(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoListItemExhibitorsDto>>(`${this.configuration.basePath}/api/Booth`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_GetBooths']) {
                this.cancelMap['Booth_GetBooths'].next();
            }
            this.cancelMap['Booth_GetBooths'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_GetBooths']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_GetBooths']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_GetBooths', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_GetBooths']) {
            this.cancelMap['Booth_GetBooths'].complete();
            delete this.cancelMap['Booth_GetBooths'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_GetBooths',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_GetBooths')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothGetChatBoothsByMap(
    map: BoothGetChatBooths.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoDetailItemDto>>;
  public boothGetChatBoothsByMap(
    map: BoothGetChatBooths.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoDetailItemDto>>>;
  public boothGetChatBoothsByMap(
    map: BoothGetChatBooths.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoDetailItemDto>>>;
  public boothGetChatBoothsByMap(
    map: BoothGetChatBooths.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothGetChatBooths(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothGetChatBooths(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoDetailItemDto>>;
    public boothGetChatBooths(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoDetailItemDto>>>;
    public boothGetChatBooths(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoDetailItemDto>>>;
    public boothGetChatBooths(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothGetChatBooths.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling boothGetChatBooths.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoDetailItemDto>>(`${this.configuration.basePath}/api/Booth/chat/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_GetChatBooths']) {
                this.cancelMap['Booth_GetChatBooths'].next();
            }
            this.cancelMap['Booth_GetChatBooths'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_GetChatBooths']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_GetChatBooths']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_GetChatBooths', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_GetChatBooths']) {
            this.cancelMap['Booth_GetChatBooths'].complete();
            delete this.cancelMap['Booth_GetChatBooths'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_GetChatBooths',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_GetChatBooths')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothGetExpoByMap(
    map: BoothGetExpo.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoUpdateDto>;
  public boothGetExpoByMap(
    map: BoothGetExpo.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoUpdateDto>>;
  public boothGetExpoByMap(
    map: BoothGetExpo.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoUpdateDto>>;
  public boothGetExpoByMap(
    map: BoothGetExpo.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothGetExpo(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothGetExpo(id: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoUpdateDto>;
    public boothGetExpo(id: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoUpdateDto>>;
    public boothGetExpo(id: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoUpdateDto>>;
    public boothGetExpo(id: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling boothGetExpo.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<ExpoUpdateDto>(`${this.configuration.basePath}/api/Booth/get-expo/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_GetExpo']) {
                this.cancelMap['Booth_GetExpo'].next();
            }
            this.cancelMap['Booth_GetExpo'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_GetExpo']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_GetExpo']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_GetExpo', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_GetExpo']) {
            this.cancelMap['Booth_GetExpo'].complete();
            delete this.cancelMap['Booth_GetExpo'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_GetExpo',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_GetExpo')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothGetMyBoothsByMap(
    map: BoothGetMyBooths.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoDetailItemDto>>;
  public boothGetMyBoothsByMap(
    map: BoothGetMyBooths.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoDetailItemDto>>>;
  public boothGetMyBoothsByMap(
    map: BoothGetMyBooths.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoDetailItemDto>>>;
  public boothGetMyBoothsByMap(
    map: BoothGetMyBooths.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothGetMyBooths(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothGetMyBooths(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoDetailItemDto>>;
    public boothGetMyBooths(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoDetailItemDto>>>;
    public boothGetMyBooths(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoDetailItemDto>>>;
    public boothGetMyBooths(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoDetailItemDto>>(`${this.configuration.basePath}/api/Booth/my`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_GetMyBooths']) {
                this.cancelMap['Booth_GetMyBooths'].next();
            }
            this.cancelMap['Booth_GetMyBooths'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_GetMyBooths']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_GetMyBooths']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_GetMyBooths', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_GetMyBooths']) {
            this.cancelMap['Booth_GetMyBooths'].complete();
            delete this.cancelMap['Booth_GetMyBooths'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_GetMyBooths',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_GetMyBooths')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothGetMyBooths2ByMap(
    map: BoothGetMyBooths2.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoDetailItemDto>>;
  public boothGetMyBooths2ByMap(
    map: BoothGetMyBooths2.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoDetailItemDto>>>;
  public boothGetMyBooths2ByMap(
    map: BoothGetMyBooths2.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoDetailItemDto>>>;
  public boothGetMyBooths2ByMap(
    map: BoothGetMyBooths2.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothGetMyBooths2(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothGetMyBooths2(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoDetailItemDto>>;
    public boothGetMyBooths2(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoDetailItemDto>>>;
    public boothGetMyBooths2(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoDetailItemDto>>>;
    public boothGetMyBooths2(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothGetMyBooths2.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling boothGetMyBooths2.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoDetailItemDto>>(`${this.configuration.basePath}/api/Booth/my/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_GetMyBooths2']) {
                this.cancelMap['Booth_GetMyBooths2'].next();
            }
            this.cancelMap['Booth_GetMyBooths2'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_GetMyBooths2']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_GetMyBooths2']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_GetMyBooths2', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_GetMyBooths2']) {
            this.cancelMap['Booth_GetMyBooths2'].complete();
            delete this.cancelMap['Booth_GetMyBooths2'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_GetMyBooths2',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_GetMyBooths2')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothGetMyExposByMap(
    map: BoothGetMyExpos.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<ExpoUpdateDto>>;
  public boothGetMyExposByMap(
    map: BoothGetMyExpos.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoUpdateDto>>>;
  public boothGetMyExposByMap(
    map: BoothGetMyExpos.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoUpdateDto>>>;
  public boothGetMyExposByMap(
    map: BoothGetMyExpos.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothGetMyExpos(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothGetMyExpos(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<ExpoUpdateDto>>;
    public boothGetMyExpos(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<ExpoUpdateDto>>>;
    public boothGetMyExpos(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<ExpoUpdateDto>>>;
    public boothGetMyExpos(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<ExpoUpdateDto>>(`${this.configuration.basePath}/api/Booth/my-expos`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_GetMyExpos']) {
                this.cancelMap['Booth_GetMyExpos'].next();
            }
            this.cancelMap['Booth_GetMyExpos'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_GetMyExpos']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_GetMyExpos']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_GetMyExpos', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_GetMyExpos']) {
            this.cancelMap['Booth_GetMyExpos'].complete();
            delete this.cancelMap['Booth_GetMyExpos'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_GetMyExpos',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_GetMyExpos')));
        }
        return handle;
    }


  /**
   * Gets the visitors. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothGetVisitorsByMap(
    map: BoothGetVisitors.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<VisitorListDto>>;
  public boothGetVisitorsByMap(
    map: BoothGetVisitors.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<VisitorListDto>>>;
  public boothGetVisitorsByMap(
    map: BoothGetVisitors.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<VisitorListDto>>>;
  public boothGetVisitorsByMap(
    map: BoothGetVisitors.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothGetVisitors(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the visitors.
     * 
     * @param expoId The expo identifier.
     * @param exhibitorId The exhibitor identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothGetVisitors(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<VisitorListDto>>;
    public boothGetVisitors(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<VisitorListDto>>>;
    public boothGetVisitors(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<VisitorListDto>>>;
    public boothGetVisitors(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothGetVisitors.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling boothGetVisitors.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<VisitorListDto>>(`${this.configuration.basePath}/api/Booth/visitors/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_GetVisitors']) {
                this.cancelMap['Booth_GetVisitors'].next();
            }
            this.cancelMap['Booth_GetVisitors'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_GetVisitors']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_GetVisitors']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_GetVisitors', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_GetVisitors']) {
            this.cancelMap['Booth_GetVisitors'].complete();
            delete this.cancelMap['Booth_GetVisitors'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_GetVisitors',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_GetVisitors')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothGetYoutubePlaylistPreviewImageByMap(
    map: BoothGetYoutubePlaylistPreviewImage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<string>;
  public boothGetYoutubePlaylistPreviewImageByMap(
    map: BoothGetYoutubePlaylistPreviewImage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
  public boothGetYoutubePlaylistPreviewImageByMap(
    map: BoothGetYoutubePlaylistPreviewImage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
  public boothGetYoutubePlaylistPreviewImageByMap(
    map: BoothGetYoutubePlaylistPreviewImage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothGetYoutubePlaylistPreviewImage(
      map.playlistId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param playlistId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothGetYoutubePlaylistPreviewImage(playlistId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<string>;
    public boothGetYoutubePlaylistPreviewImage(playlistId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<string>>;
    public boothGetYoutubePlaylistPreviewImage(playlistId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<string>>;
    public boothGetYoutubePlaylistPreviewImage(playlistId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (playlistId === null || playlistId === undefined) {
            throw new Error('Required parameter playlistId was null or undefined when calling boothGetYoutubePlaylistPreviewImage.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<string>(`${this.configuration.basePath}/api/Booth/youtube-preview/playlist/${encodeURIComponent(String(playlistId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_GetYoutubePlaylistPreviewImage']) {
                this.cancelMap['Booth_GetYoutubePlaylistPreviewImage'].next();
            }
            this.cancelMap['Booth_GetYoutubePlaylistPreviewImage'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_GetYoutubePlaylistPreviewImage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_GetYoutubePlaylistPreviewImage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_GetYoutubePlaylistPreviewImage', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_GetYoutubePlaylistPreviewImage']) {
            this.cancelMap['Booth_GetYoutubePlaylistPreviewImage'].complete();
            delete this.cancelMap['Booth_GetYoutubePlaylistPreviewImage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_GetYoutubePlaylistPreviewImage',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_GetYoutubePlaylistPreviewImage')));
        }
        return handle;
    }


  /**
   * InfoSlides the sort. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothInfoSlideSortByMap(
    map: BoothInfoSlideSort.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<InfoSlide>>;
  public boothInfoSlideSortByMap(
    map: BoothInfoSlideSort.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<InfoSlide>>>;
  public boothInfoSlideSortByMap(
    map: BoothInfoSlideSort.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<InfoSlide>>>;
  public boothInfoSlideSortByMap(
    map: BoothInfoSlideSort.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothInfoSlideSort(
      map.SortUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * InfoSlides the sort.
     * 
     * @param SortUpdateDto The sort update.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothInfoSlideSort(SortUpdateDto: SortUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<InfoSlide>>;
    public boothInfoSlideSort(SortUpdateDto: SortUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<InfoSlide>>>;
    public boothInfoSlideSort(SortUpdateDto: SortUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<InfoSlide>>>;
    public boothInfoSlideSort(SortUpdateDto: SortUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (SortUpdateDto === null || SortUpdateDto === undefined) {
            throw new Error('Required parameter SortUpdateDto was null or undefined when calling boothInfoSlideSort.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<Array<InfoSlide>>(`${this.configuration.basePath}/api/Booth/infoSlidesort`,
            SortUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_InfoSlideSort']) {
                this.cancelMap['Booth_InfoSlideSort'].next();
            }
            this.cancelMap['Booth_InfoSlideSort'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_InfoSlideSort']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_InfoSlideSort']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_InfoSlideSort', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_InfoSlideSort']) {
            this.cancelMap['Booth_InfoSlideSort'].complete();
            delete this.cancelMap['Booth_InfoSlideSort'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_InfoSlideSort',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_InfoSlideSort')));
        }
        return handle;
    }


  /**
   * Links the sort. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothLinkSortByMap(
    map: BoothLinkSort.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<Link>>;
  public boothLinkSortByMap(
    map: BoothLinkSort.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<Link>>>;
  public boothLinkSortByMap(
    map: BoothLinkSort.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<Link>>>;
  public boothLinkSortByMap(
    map: BoothLinkSort.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothLinkSort(
      map.SortUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Links the sort.
     * 
     * @param SortUpdateDto The sort update.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothLinkSort(SortUpdateDto: SortUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<Link>>;
    public boothLinkSort(SortUpdateDto: SortUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<Link>>>;
    public boothLinkSort(SortUpdateDto: SortUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<Link>>>;
    public boothLinkSort(SortUpdateDto: SortUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (SortUpdateDto === null || SortUpdateDto === undefined) {
            throw new Error('Required parameter SortUpdateDto was null or undefined when calling boothLinkSort.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<Array<Link>>(`${this.configuration.basePath}/api/Booth/linksort`,
            SortUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_LinkSort']) {
                this.cancelMap['Booth_LinkSort'].next();
            }
            this.cancelMap['Booth_LinkSort'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_LinkSort']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_LinkSort']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_LinkSort', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_LinkSort']) {
            this.cancelMap['Booth_LinkSort'].complete();
            delete this.cancelMap['Booth_LinkSort'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_LinkSort',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_LinkSort')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothPostDisplayByMap(
    map: BoothPostDisplay.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Display>;
  public boothPostDisplayByMap(
    map: BoothPostDisplay.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Display>>;
  public boothPostDisplayByMap(
    map: BoothPostDisplay.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Display>>;
  public boothPostDisplayByMap(
    map: BoothPostDisplay.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothPostDisplay(
      map.ExhibitorDisplayUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ExhibitorDisplayUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothPostDisplay(ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Display>;
    public boothPostDisplay(ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Display>>;
    public boothPostDisplay(ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Display>>;
    public boothPostDisplay(ExhibitorDisplayUpdateDto: ExhibitorDisplayUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ExhibitorDisplayUpdateDto === null || ExhibitorDisplayUpdateDto === undefined) {
            throw new Error('Required parameter ExhibitorDisplayUpdateDto was null or undefined when calling boothPostDisplay.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<Display>(`${this.configuration.basePath}/api/Booth/display`,
            ExhibitorDisplayUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_PostDisplay']) {
                this.cancelMap['Booth_PostDisplay'].next();
            }
            this.cancelMap['Booth_PostDisplay'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_PostDisplay']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_PostDisplay']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_PostDisplay', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_PostDisplay']) {
            this.cancelMap['Booth_PostDisplay'].complete();
            delete this.cancelMap['Booth_PostDisplay'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_PostDisplay',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_PostDisplay')));
        }
        return handle;
    }


  /**
   * Posts the exhibitor. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothPostExhibitorByMap(
    map: BoothPostExhibitor.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoExhibitorSelfUpdateDto>;
  public boothPostExhibitorByMap(
    map: BoothPostExhibitor.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoExhibitorSelfUpdateDto>>;
  public boothPostExhibitorByMap(
    map: BoothPostExhibitor.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoExhibitorSelfUpdateDto>>;
  public boothPostExhibitorByMap(
    map: BoothPostExhibitor.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothPostExhibitor(
      map.ExhibitorSelfUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Posts the exhibitor.
     * 
     * @param ExhibitorSelfUpdateDto The exhibitor self update dto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothPostExhibitor(ExhibitorSelfUpdateDto: ExhibitorSelfUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoExhibitorSelfUpdateDto>;
    public boothPostExhibitor(ExhibitorSelfUpdateDto: ExhibitorSelfUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoExhibitorSelfUpdateDto>>;
    public boothPostExhibitor(ExhibitorSelfUpdateDto: ExhibitorSelfUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoExhibitorSelfUpdateDto>>;
    public boothPostExhibitor(ExhibitorSelfUpdateDto: ExhibitorSelfUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ExhibitorSelfUpdateDto === null || ExhibitorSelfUpdateDto === undefined) {
            throw new Error('Required parameter ExhibitorSelfUpdateDto was null or undefined when calling boothPostExhibitor.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<ExpoExhibitorSelfUpdateDto>(`${this.configuration.basePath}/api/Booth`,
            ExhibitorSelfUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_PostExhibitor']) {
                this.cancelMap['Booth_PostExhibitor'].next();
            }
            this.cancelMap['Booth_PostExhibitor'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_PostExhibitor']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_PostExhibitor']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_PostExhibitor', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_PostExhibitor']) {
            this.cancelMap['Booth_PostExhibitor'].complete();
            delete this.cancelMap['Booth_PostExhibitor'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_PostExhibitor',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_PostExhibitor')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothPostExpoExhibitorByMap(
    map: BoothPostExpoExhibitor.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<ExpoExhibitorSelfUpdateDto>;
  public boothPostExpoExhibitorByMap(
    map: BoothPostExpoExhibitor.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoExhibitorSelfUpdateDto>>;
  public boothPostExpoExhibitorByMap(
    map: BoothPostExpoExhibitor.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoExhibitorSelfUpdateDto>>;
  public boothPostExpoExhibitorByMap(
    map: BoothPostExpoExhibitor.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothPostExpoExhibitor(
      map.ExpoExhibitorSelfUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ExpoExhibitorSelfUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothPostExpoExhibitor(ExpoExhibitorSelfUpdateDto: ExpoExhibitorSelfUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<ExpoExhibitorSelfUpdateDto>;
    public boothPostExpoExhibitor(ExpoExhibitorSelfUpdateDto: ExpoExhibitorSelfUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<ExpoExhibitorSelfUpdateDto>>;
    public boothPostExpoExhibitor(ExpoExhibitorSelfUpdateDto: ExpoExhibitorSelfUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<ExpoExhibitorSelfUpdateDto>>;
    public boothPostExpoExhibitor(ExpoExhibitorSelfUpdateDto: ExpoExhibitorSelfUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ExpoExhibitorSelfUpdateDto === null || ExpoExhibitorSelfUpdateDto === undefined) {
            throw new Error('Required parameter ExpoExhibitorSelfUpdateDto was null or undefined when calling boothPostExpoExhibitor.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<ExpoExhibitorSelfUpdateDto>(`${this.configuration.basePath}/api/Booth/update`,
            ExpoExhibitorSelfUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_PostExpoExhibitor']) {
                this.cancelMap['Booth_PostExpoExhibitor'].next();
            }
            this.cancelMap['Booth_PostExpoExhibitor'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_PostExpoExhibitor']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_PostExpoExhibitor']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_PostExpoExhibitor', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_PostExpoExhibitor']) {
            this.cancelMap['Booth_PostExpoExhibitor'].complete();
            delete this.cancelMap['Booth_PostExpoExhibitor'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_PostExpoExhibitor',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_PostExpoExhibitor')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothPostInfoSlideByMap(
    map: BoothPostInfoSlide.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<InfoSlide>;
  public boothPostInfoSlideByMap(
    map: BoothPostInfoSlide.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<InfoSlide>>;
  public boothPostInfoSlideByMap(
    map: BoothPostInfoSlide.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<InfoSlide>>;
  public boothPostInfoSlideByMap(
    map: BoothPostInfoSlide.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothPostInfoSlide(
      map.InfoSlideUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param InfoSlideUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothPostInfoSlide(InfoSlideUpdateDto: InfoSlideUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<InfoSlide>;
    public boothPostInfoSlide(InfoSlideUpdateDto: InfoSlideUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<InfoSlide>>;
    public boothPostInfoSlide(InfoSlideUpdateDto: InfoSlideUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<InfoSlide>>;
    public boothPostInfoSlide(InfoSlideUpdateDto: InfoSlideUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (InfoSlideUpdateDto === null || InfoSlideUpdateDto === undefined) {
            throw new Error('Required parameter InfoSlideUpdateDto was null or undefined when calling boothPostInfoSlide.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<InfoSlide>(`${this.configuration.basePath}/api/Booth/infoSlide`,
            InfoSlideUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_PostInfoSlide']) {
                this.cancelMap['Booth_PostInfoSlide'].next();
            }
            this.cancelMap['Booth_PostInfoSlide'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_PostInfoSlide']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_PostInfoSlide']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_PostInfoSlide', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_PostInfoSlide']) {
            this.cancelMap['Booth_PostInfoSlide'].complete();
            delete this.cancelMap['Booth_PostInfoSlide'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_PostInfoSlide',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_PostInfoSlide')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothPostLinkByMap(
    map: BoothPostLink.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Link>;
  public boothPostLinkByMap(
    map: BoothPostLink.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Link>>;
  public boothPostLinkByMap(
    map: BoothPostLink.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Link>>;
  public boothPostLinkByMap(
    map: BoothPostLink.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothPostLink(
      map.ExhibitorLinkUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param ExhibitorLinkUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothPostLink(ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Link>;
    public boothPostLink(ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Link>>;
    public boothPostLink(ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Link>>;
    public boothPostLink(ExhibitorLinkUpdateDto: ExhibitorLinkUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (ExhibitorLinkUpdateDto === null || ExhibitorLinkUpdateDto === undefined) {
            throw new Error('Required parameter ExhibitorLinkUpdateDto was null or undefined when calling boothPostLink.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<Link>(`${this.configuration.basePath}/api/Booth/link`,
            ExhibitorLinkUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_PostLink']) {
                this.cancelMap['Booth_PostLink'].next();
            }
            this.cancelMap['Booth_PostLink'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_PostLink']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_PostLink']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_PostLink', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_PostLink']) {
            this.cancelMap['Booth_PostLink'].complete();
            delete this.cancelMap['Booth_PostLink'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_PostLink',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_PostLink')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothStartLivestreamByMap(
    map: BoothStartLivestream.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public boothStartLivestreamByMap(
    map: BoothStartLivestream.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public boothStartLivestreamByMap(
    map: BoothStartLivestream.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public boothStartLivestreamByMap(
    map: BoothStartLivestream.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothStartLivestream(
      map.LivestreamSettingsDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param LivestreamSettingsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothStartLivestream(LivestreamSettingsDto: LivestreamSettingsDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public boothStartLivestream(LivestreamSettingsDto: LivestreamSettingsDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public boothStartLivestream(LivestreamSettingsDto: LivestreamSettingsDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public boothStartLivestream(LivestreamSettingsDto: LivestreamSettingsDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (LivestreamSettingsDto === null || LivestreamSettingsDto === undefined) {
            throw new Error('Required parameter LivestreamSettingsDto was null or undefined when calling boothStartLivestream.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/Booth/stream/start`,
            LivestreamSettingsDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_StartLivestream']) {
                this.cancelMap['Booth_StartLivestream'].next();
            }
            this.cancelMap['Booth_StartLivestream'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_StartLivestream']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_StartLivestream']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_StartLivestream', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_StartLivestream']) {
            this.cancelMap['Booth_StartLivestream'].complete();
            delete this.cancelMap['Booth_StartLivestream'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_StartLivestream',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_StartLivestream')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothStopLivestreamByMap(
    map: BoothStopLivestream.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public boothStopLivestreamByMap(
    map: BoothStopLivestream.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public boothStopLivestreamByMap(
    map: BoothStopLivestream.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public boothStopLivestreamByMap(
    map: BoothStopLivestream.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothStopLivestream(
      map.LivestreamSettingsDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param LivestreamSettingsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothStopLivestream(LivestreamSettingsDto: LivestreamSettingsDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public boothStopLivestream(LivestreamSettingsDto: LivestreamSettingsDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public boothStopLivestream(LivestreamSettingsDto: LivestreamSettingsDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public boothStopLivestream(LivestreamSettingsDto: LivestreamSettingsDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (LivestreamSettingsDto === null || LivestreamSettingsDto === undefined) {
            throw new Error('Required parameter LivestreamSettingsDto was null or undefined when calling boothStopLivestream.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/Booth/stream/stop`,
            LivestreamSettingsDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Booth_StopLivestream']) {
                this.cancelMap['Booth_StopLivestream'].next();
            }
            this.cancelMap['Booth_StopLivestream'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Booth_StopLivestream']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Booth_StopLivestream']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Booth_StopLivestream', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Booth_StopLivestream']) {
            this.cancelMap['Booth_StopLivestream'].complete();
            delete this.cancelMap['Booth_StopLivestream'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Booth_StopLivestream',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Booth_StopLivestream')));
        }
        return handle;
    }

  }

export namespace BoothService {
  export enum Operations {
    Booth_ChangeActiveDisplay = 'Booth_ChangeActiveDisplay',
    Booth_ChangeActiveInfoSlide = 'Booth_ChangeActiveInfoSlide',
    Booth_ChangeActiveLink = 'Booth_ChangeActiveLink',
    Booth_DeleteDisplay = 'Booth_DeleteDisplay',
    Booth_DeleteInfoSlide = 'Booth_DeleteInfoSlide',
    Booth_DeleteLink = 'Booth_DeleteLink',
    Booth_DisplaySort = 'Booth_DisplaySort',
    Booth_GetAvatars = 'Booth_GetAvatars',
    Booth_GetBooth = 'Booth_GetBooth',
    Booth_GetBoothIcons = 'Booth_GetBoothIcons',
    Booth_GetBoothTypes = 'Booth_GetBoothTypes',
    Booth_GetBooths = 'Booth_GetBooths',
    Booth_GetChatBooths = 'Booth_GetChatBooths',
    Booth_GetExpo = 'Booth_GetExpo',
    Booth_GetMyBooths = 'Booth_GetMyBooths',
    Booth_GetMyBooths2 = 'Booth_GetMyBooths2',
    Booth_GetMyExpos = 'Booth_GetMyExpos',
    Booth_GetVisitors = 'Booth_GetVisitors',
    Booth_GetYoutubePlaylistPreviewImage = 'Booth_GetYoutubePlaylistPreviewImage',
    Booth_InfoSlideSort = 'Booth_InfoSlideSort',
    Booth_LinkSort = 'Booth_LinkSort',
    Booth_PostDisplay = 'Booth_PostDisplay',
    Booth_PostExhibitor = 'Booth_PostExhibitor',
    Booth_PostExpoExhibitor = 'Booth_PostExpoExhibitor',
    Booth_PostInfoSlide = 'Booth_PostInfoSlide',
    Booth_PostLink = 'Booth_PostLink',
    Booth_StartLivestream = 'Booth_StartLivestream',
    Booth_StopLivestream = 'Booth_StopLivestream'
  }
}
