import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SettingsService} from '../../../../core/settings/settings.service';
import {Router} from '@angular/router';
import {AccountService, TokenGetDto} from '../../../../virtual-expo-api';
import {ToasterService} from '../../../../core/toaster.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EqualValidator} from '../../../../shared/directives/equal-validator.directive';
import {TypedFormGroup} from '../../../../virtual-expo-api/formgroup';

@Component({
  selector: 'app-get-user-token',
  templateUrl: './get-user-token.component.html',
  styleUrls: ['./get-user-token.component.scss']
})
export class GetUserTokenComponent implements OnInit {

  valForm: TypedFormGroup<TokenGetDto>;
  user: TokenGetDto;
  errorMessage: string;

  submitted: boolean;
  token: string;

  constructor(public settings: SettingsService, fb: FormBuilder, private router: Router, private accountService: AccountService
    , private toasterService: ToasterService, private activeModal: NgbActiveModal) {

    const factory = new TokenGetDto.FormControlFactory(this.user as TokenGetDto);
    this.valForm = new TypedFormGroup<TokenGetDto>({
      email: factory.createFormControl<string>('email')
    });
  }

  submitForm($ev, value: any) {
    this.submitted = true;
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      if (c) {
        this.valForm.controls[c].markAsTouched();
      }
    }

    if (this.valForm.valid) {
      this.accountService.accountGetUserToken(this.valForm.value)
        .subscribe(value1 => {
            this.submitted = false;
            this.token = value1;
          },
          error => {
            this.submitted = false;
            this.toasterService.error(error.error);
            this.errorMessage = error.error;
          });
    } else {
      this.submitted = false;
    }
  }

  ngOnInit() {
  }

}
