import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit, ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-form-toggle',
  templateUrl: './form-toggle.component.html',
  styleUrls: ['./form-toggle.component.scss']
})
export class FormToggleComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() fieldName: string;
  @Input() fieldSource: any;

  @Input() label = '';
  @Input() help = '';
  @Input() checkedLabel: string;
  @Input() uncheckedLabel: string;
  @Input() reverse = false;
  @Input() inverted = false;
  @Input() warn = false;
  @Input() warnOff = false;

  @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

  foreColor = '#64bd63';
  backColor = '#efefef';
  textColor = '#000000';
  checkedTextColor = '#000000';

  constructor() {
  }

  ngOnInit(): void {
    if (this.reverse) {
      this.foreColor = '#64bd63';
      this.backColor = '#efefef';

      if (this.warn) {
        this.backColor = 'red';
        this.textColor = 'white';
      }
    } else if (this.warn) {
      this.foreColor = 'red';
      this.textColor = 'black';
    } else if (this.warnOff) {
      this.backColor = 'red';
      this.textColor = 'white';
    }
  }

  onChange($event: boolean) {
    this.myValue = $event;
  }

  get myValue(): boolean {
    if (this.inverted) {
      if (this.formGroup) {
        if (this.formGroup.get(this.fieldName)) {
          return !this.formGroup.get(this.fieldName).value;
        }
        return null;
      } else {
        return !this.fieldSource[this.fieldName];
      }
    } else {
      if (this.formGroup) {
        if (this.formGroup.get(this.fieldName)) {
          return this.formGroup.get(this.fieldName).value;
        }
        return null;
      } else {
        return this.fieldSource[this.fieldName];
      }
    }
    // return false;
  }

  set myValue(val: boolean) {
    if (this.inverted) {
      if (this.formGroup) {
        this.formGroup.get(this.fieldName).setValue(!val);
      } else {
        this.fieldSource[this.fieldName] = !val;
      }
    } else {
      if (this.formGroup) {
        this.formGroup.get(this.fieldName).setValue(val);
      } else {
        this.fieldSource[this.fieldName] = val;
      }
    }

    if (this.formGroup) {
      this.change.emit(this.formGroup.get(this.fieldName).value);
    } else {
      this.change.emit(this.fieldSource[this.fieldName]);
    }
  }

}
