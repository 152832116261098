/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ProblemDetails { 
  [key: string]: object | any;


    type?: string;
    title?: string;
    status?: number;
    detail?: string;
    instance?: string;
    extensions?: { [key: string]: object; };
}

/**
 * Namespace for property- and property-value-enumerations of ProblemDetails.
 */
export namespace ProblemDetails {
    /**
     * All properties of ProblemDetails.
     */
    export enum Properties {
        type = 'type',
        title = 'title',
        status = 'status',
        detail = 'detail',
        instance = 'instance',
        extensions = 'extensions'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ProblemDetails.
    */
    export const ModelValidators: {[K in keyof ProblemDetails]: [string, ValidatorFn][]} = {
        type: [
        ],
        title: [
        ],
        status: [
        ],
        detail: [
        ],
        instance: [
        ],
        extensions: [
        ],
    };

    /**
    * The FormControlFactory for ProblemDetails.
    */
    export class FormControlFactory extends BaseFormControlFactory<ProblemDetails> {

        /**
         * Constructor.
         *
         * @param model An existing model for ProblemDetails.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ProblemDetails = {
            type: null,
            title: null,
            status: null,
            detail: null,
            instance: null,
            extensions: null,
          }
        ) {
            super(model, ProblemDetails.ModelValidators);
        }
    }

}


