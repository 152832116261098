/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { CalendarAvailabilityDto } from '../model/calendarAvailabilityDto';
import { CalendarBookedSlotAdminDto } from '../model/calendarBookedSlotAdminDto';
import { CalendarEntryBoothDto } from '../model/calendarEntryBoothDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for boothAvailabilityGet.
 */
export namespace BoothAvailabilityGet {
    /**
     * Parameter map for boothAvailabilityGet.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
      entryId: number;
    }

    /**
     * Enumeration of all parameters for boothAvailabilityGet.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId',
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothAvailabilityGet
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothAvailabilityGet.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothAvailabilityGetAllAvailabilities.
 */
export namespace BoothAvailabilityGetAllAvailabilities {
    /**
     * Parameter map for boothAvailabilityGetAllAvailabilities.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for boothAvailabilityGetAllAvailabilities.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothAvailabilityGetAllAvailabilities
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothAvailabilityGetAllAvailabilities.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for boothAvailabilityGetAllBookings.
 */
export namespace BoothAvailabilityGetAllBookings {
    /**
     * Parameter map for boothAvailabilityGetAllBookings.
     */
    export interface PartialParamMap {
      expoId: string;
      exhibitorId: string;
    }

    /**
     * Enumeration of all parameters for boothAvailabilityGetAllBookings.
     */
    export enum Parameters {
      expoId = 'expoId',
      exhibitorId = 'exhibitorId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of boothAvailabilityGetAllBookings
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof BoothAvailabilityGetAllBookings.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      exhibitorId: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class BoothAvailabilityService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothAvailabilityGetByMap(
    map: BoothAvailabilityGet.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarEntryBoothDto>;
  public boothAvailabilityGetByMap(
    map: BoothAvailabilityGet.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryBoothDto>>;
  public boothAvailabilityGetByMap(
    map: BoothAvailabilityGet.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryBoothDto>>;
  public boothAvailabilityGetByMap(
    map: BoothAvailabilityGet.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothAvailabilityGet(
      map.expoId,
      map.exhibitorId,
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothAvailabilityGet(expoId: string, exhibitorId: string, entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarEntryBoothDto>;
    public boothAvailabilityGet(expoId: string, exhibitorId: string, entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryBoothDto>>;
    public boothAvailabilityGet(expoId: string, exhibitorId: string, entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryBoothDto>>;
    public boothAvailabilityGet(expoId: string, exhibitorId: string, entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothAvailabilityGet.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling boothAvailabilityGet.');
        }
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling boothAvailabilityGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<CalendarEntryBoothDto>(`${this.configuration.basePath}/api/BoothAvailability/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}/${encodeURIComponent(String(entryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothAvailability_Get']) {
                this.cancelMap['BoothAvailability_Get'].next();
            }
            this.cancelMap['BoothAvailability_Get'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothAvailability_Get']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothAvailability_Get']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothAvailability_Get', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothAvailability_Get']) {
            this.cancelMap['BoothAvailability_Get'].complete();
            delete this.cancelMap['BoothAvailability_Get'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothAvailability_Get',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothAvailability_Get')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothAvailabilityGetAllAvailabilitiesByMap(
    map: BoothAvailabilityGetAllAvailabilities.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<CalendarAvailabilityDto>>;
  public boothAvailabilityGetAllAvailabilitiesByMap(
    map: BoothAvailabilityGetAllAvailabilities.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarAvailabilityDto>>>;
  public boothAvailabilityGetAllAvailabilitiesByMap(
    map: BoothAvailabilityGetAllAvailabilities.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarAvailabilityDto>>>;
  public boothAvailabilityGetAllAvailabilitiesByMap(
    map: BoothAvailabilityGetAllAvailabilities.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothAvailabilityGetAllAvailabilities(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothAvailabilityGetAllAvailabilities(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<CalendarAvailabilityDto>>;
    public boothAvailabilityGetAllAvailabilities(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarAvailabilityDto>>>;
    public boothAvailabilityGetAllAvailabilities(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarAvailabilityDto>>>;
    public boothAvailabilityGetAllAvailabilities(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothAvailabilityGetAllAvailabilities.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling boothAvailabilityGetAllAvailabilities.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<CalendarAvailabilityDto>>(`${this.configuration.basePath}/api/BoothAvailability/availabilities/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothAvailability_GetAllAvailabilities']) {
                this.cancelMap['BoothAvailability_GetAllAvailabilities'].next();
            }
            this.cancelMap['BoothAvailability_GetAllAvailabilities'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothAvailability_GetAllAvailabilities']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothAvailability_GetAllAvailabilities']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothAvailability_GetAllAvailabilities', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothAvailability_GetAllAvailabilities']) {
            this.cancelMap['BoothAvailability_GetAllAvailabilities'].complete();
            delete this.cancelMap['BoothAvailability_GetAllAvailabilities'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothAvailability_GetAllAvailabilities',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothAvailability_GetAllAvailabilities')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public boothAvailabilityGetAllBookingsByMap(
    map: BoothAvailabilityGetAllBookings.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<CalendarBookedSlotAdminDto>>;
  public boothAvailabilityGetAllBookingsByMap(
    map: BoothAvailabilityGetAllBookings.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarBookedSlotAdminDto>>>;
  public boothAvailabilityGetAllBookingsByMap(
    map: BoothAvailabilityGetAllBookings.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarBookedSlotAdminDto>>>;
  public boothAvailabilityGetAllBookingsByMap(
    map: BoothAvailabilityGetAllBookings.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.boothAvailabilityGetAllBookings(
      map.expoId,
      map.exhibitorId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param expoId 
     * @param exhibitorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public boothAvailabilityGetAllBookings(expoId: string, exhibitorId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<CalendarBookedSlotAdminDto>>;
    public boothAvailabilityGetAllBookings(expoId: string, exhibitorId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<CalendarBookedSlotAdminDto>>>;
    public boothAvailabilityGetAllBookings(expoId: string, exhibitorId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<CalendarBookedSlotAdminDto>>>;
    public boothAvailabilityGetAllBookings(expoId: string, exhibitorId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling boothAvailabilityGetAllBookings.');
        }
        if (exhibitorId === null || exhibitorId === undefined) {
            throw new Error('Required parameter exhibitorId was null or undefined when calling boothAvailabilityGetAllBookings.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<CalendarBookedSlotAdminDto>>(`${this.configuration.basePath}/api/BoothAvailability/bookings/${encodeURIComponent(String(expoId))}/${encodeURIComponent(String(exhibitorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['BoothAvailability_GetAllBookings']) {
                this.cancelMap['BoothAvailability_GetAllBookings'].next();
            }
            this.cancelMap['BoothAvailability_GetAllBookings'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['BoothAvailability_GetAllBookings']) {
                handle = handle.pipe(takeUntil(this.cancelMap['BoothAvailability_GetAllBookings']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('BoothAvailability_GetAllBookings', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['BoothAvailability_GetAllBookings']) {
            this.cancelMap['BoothAvailability_GetAllBookings'].complete();
            delete this.cancelMap['BoothAvailability_GetAllBookings'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'BoothAvailability_GetAllBookings',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'BoothAvailability_GetAllBookings')));
        }
        return handle;
    }

  }

export namespace BoothAvailabilityService {
  export enum Operations {
    BoothAvailability_Get = 'BoothAvailability_Get',
    BoothAvailability_GetAllAvailabilities = 'BoothAvailability_GetAllAvailabilities',
    BoothAvailability_GetAllBookings = 'BoothAvailability_GetAllBookings'
  }
}
