import {Injectable} from '@angular/core';
import {menu} from '../../routes/menu';

declare var $: any;

@Injectable()
export class SettingsService {

  public app: any;
  public layout: any;

  public user: any;

  constructor() {
    // App Settings
    // -----------------------------------

    const url = location.href;

    const {baseUrl, basePort, isBeta, isTest} = this.mapUrl(url);

    menu[1].elink = baseUrl;
    this.app = {
      name: 'VirtualExpo',
      description: 'a product of dotEnterprise Ulli Berthold',
      logo: 'assets/ve-logo.svg',
      logoSmall: 'assets/ve-logo.svg',
      logoSquare: 'assets/ve-logo.svg',
      logoAlt: 'VirtualExpo logo',
      parentLink: 'https://dotenterprise.com',
      siteLink: 'https://virtualexpo.info',
      clientBase: baseUrl,
      clientPort: basePort,
      isBeta: isBeta,
      isTest: isTest,
      year: ((new Date()).getFullYear())
    };

    // Layout Settings
    // -----------------------------------
    this.layout = {
      isFixed: true,
      isCollapsed: false,
      isBoxed: false,
      isRTL: false,
      horizontal: false,
      isFloat: false,
      asideHover: false,
      theme: null,
      asideScrollbar: false,
      isCollapsedText: false,
      useFullLayout: false,
      hiddenFooter: false,
      offsidebarOpen: false,
      asideToggled: false,
      viewAnimation: 'ng-fadeInUp'
    };

  }

  getAppSetting(name) {
    return name ? this.app[name] : this.app;
  }

  getLayoutSetting(name) {
    return name ? this.layout[name] : this.layout;
  }

  setAppSetting(name, value) {
    if (typeof this.app[name] !== 'undefined') {
      this.app[name] = value;
    }
  }

  setLayoutSetting(name, value) {
    if (typeof this.layout[name] !== 'undefined') {
      return this.layout[name] = value;
    }
  }

  toggleLayoutSetting(name) {
    return this.setLayoutSetting(name, !this.getLayoutSetting(name));
  }

  mapUrl(url: string): { baseUrl: string, basePort: string, isBeta: boolean, isTest: boolean } {
    let baseUrl: string;

    const testEnvironment = url.match(/http[s]?\:\/\/([\w\.\-]+)\:(\d+)(\/.*)?$/);
    const isBeta: boolean = url.match(/beta\./) !== null;

    let isTest = false;

    let port = '';
    if (testEnvironment && testEnvironment.length > 1) {
      port = `:${parseInt(testEnvironment[2], 10) - 100}`;
      isTest = true;
    }

    let subdomain = url.match(/https?\:\/\/([\w-]+)\.manage\.(\w+)\.(\w+)/);
    if (subdomain) {
      baseUrl = `${subdomain[1]}.${subdomain[2]}.${subdomain[3]}`;
      baseUrl = `//${baseUrl}${port}/`;
    } else {
      subdomain = url.match(/http[s]?\:\/\/manage\.(\w+)\.(\w+)/);
      if (subdomain) {
        baseUrl = `${subdomain[1]}.${subdomain[2]}`;
        baseUrl = `//${baseUrl}${port}/`;
      } else {
        subdomain = url.match(/https?\:\/\/([\w-.]+)/);
        if (subdomain) {
          baseUrl = `${subdomain[1]}`;
          baseUrl = `//${baseUrl}${port}/`;
        }
      }
    }
    return {baseUrl: baseUrl, basePort: port, isBeta: isBeta, isTest};
  }
}
