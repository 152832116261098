/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CalendarAvailability } from './calendarAvailability';
import { BookingState } from './bookingState';


export interface CalendarBookedSlotDto { 
    rowId?: number;
    availabilityId?: number;
    availability?: CalendarAvailability;
    start?: Date;
    partnerName?: string;
    partnerEmail?: string;
    comment?: string;
    bookingState?: BookingState;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarBookedSlotDto.
 */
export namespace CalendarBookedSlotDto {
    /**
     * All properties of CalendarBookedSlotDto.
     */
    export enum Properties {
        rowId = 'rowId',
        availabilityId = 'availabilityId',
        availability = 'availability',
        start = 'start',
        partnerName = 'partnerName',
        partnerEmail = 'partnerEmail',
        comment = 'comment',
        bookingState = 'bookingState'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarBookedSlotDto.
    */
    export const ModelValidators: {[K in keyof CalendarBookedSlotDto]: [string, ValidatorFn][]} = {
        rowId: [
        ],
        availabilityId: [
        ],
        availability: [
        ],
        start: [
        ],
        partnerName: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        partnerEmail: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        comment: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        bookingState: [
        ],
    };

    /**
    * The FormControlFactory for CalendarBookedSlotDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarBookedSlotDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarBookedSlotDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarBookedSlotDto = {
            rowId: null,
            availabilityId: null,
            availability: null,
            start: null,
            partnerName: null,
            partnerEmail: null,
            comment: null,
            bookingState: null,
          }
        ) {
            super(model, CalendarBookedSlotDto.ModelValidators);
        }
    }

}


