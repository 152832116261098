import {NgModule, Optional, SkipSelf} from '@angular/core';

import {SettingsService} from './settings/settings.service';
import {ThemesService} from './themes/themes.service';
import {TranslatorService} from './translator/translator.service';
import {MenuService} from './menu/menu.service';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {AuthGuard} from './authGuard/authGuard';
import {AuthService} from './authService/authService';
import {AuthInterceptor} from './authService/auth-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {VersionCheckService} from './version-check.service';
import {SoundService} from './sound-service.service';
import {CanvasRendererService} from './canvas-renderer.service';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    VersionCheckService,
    SettingsService,
    ThemesService,
    TranslatorService,
    MenuService,
    AuthGuard,
    AuthService,
    AuthInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    SoundService,
    CanvasRendererService
  ],
  declarations: [],
  exports: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
