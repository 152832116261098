import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IntegrationPartnerDto, IntegrationStartDto, OauthClientService, WebexRoomDto} from '../../../virtual-expo-api';
import {AuthService} from '../../../core/authService/authService';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogService} from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import {ToasterService} from '../../../core/toaster.service';

@Component({
  selector: 'app-linked-integrations',
  templateUrl: './linked-integrations.component.html',
  styleUrls: ['./linked-integrations.component.scss']
})
export class LinkedIntegrationsComponent implements OnInit {
  @Input() expoId;
  @Input() exhibitorId;

  integrations: Array<IntegrationPartnerDto> = new Array<IntegrationPartnerDto>();
  usedIntegrations: Array<IntegrationPartnerDto> = new Array<IntegrationPartnerDto>();
  unusedIntegrations: Array<IntegrationPartnerDto> = new Array<IntegrationPartnerDto>();

  rooms: Array<WebexRoomDto> = new Array<WebexRoomDto>();

  @ViewChild('iframe', {static: false}) iframe: ElementRef;

  constructor(
    private oauthClientService: OauthClientService
    , private authService: AuthService
    , private toasterService: ToasterService
  ) {

  }

  ngOnInit(): void {
    this.usedIntegrations.length = 0;
    this.unusedIntegrations.length = 0;
    if (this.exhibitorId) {
      this.oauthClientService.oauthClientGetExhibitorIntegrations(this.expoId, this.exhibitorId)
        .subscribe((integrations) => {
          this.integrations = integrations;
          this.integrations.forEach((integration) => {
            if (integration.integrations.length > 0) {
              this.usedIntegrations.push(integration);
            } else {
              this.unusedIntegrations.push(integration);
            }
          });
        });
    } else {
      this.oauthClientService.oauthClientGetExpoIntegrations(this.expoId)
        .subscribe((integrations) => {
          this.integrations = integrations;
          this.integrations.forEach((integration) => {
            if (integration.integrations.length > 0) {
              this.usedIntegrations.push(integration);
            } else {
              this.unusedIntegrations.push(integration);
            }
          });
        });
    }
  }

  addIntegration($event: MouseEvent) {
    $event.preventDefault();

    // this.modalService.open(AuthClientComponent);

    const intTest: IntegrationStartDto = {
      exhibitorId: null,
      expoId: '791c4ffa-c395-4e24-8e20-47fef8afed9f',
      id: 'df022c4e-d85e-4594-a161-03247f5465fd',
      server: 'ube-asus:4400'
    };
    this.oauthClientService.oauthClientStartIntegration(intTest)
      .subscribe(value => {
        window.addEventListener('message', this.messageEventListener.bind(this));

        window.open(value, 'targetWindow',
          `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=500,
                                    height=500`);
      });
  }

  private messageEventListener(event: MessageEvent) {
    window.removeEventListener('message', this.messageEventListener.bind(this));
  }

  getRooms($event: MouseEvent) {
    let integrationId: string;
    this.usedIntegrations.forEach(value => {
      if (value.name === 'Cisco WebEx' && value.integrations.length > 0) {
        integrationId = value.integrations[0].id;
      }
    });
    this.rooms.length = 0;
    if (integrationId) {
      this.oauthClientService.oauthClientGetRooms(integrationId)
        .subscribe((rooms) => {
          rooms.forEach(value => {
            this.rooms.push(value);
          });
        }, error => {
          if (error.status === 401) {
            this.oauthClientService.oauthClientRefreshToken(integrationId)
              .subscribe(value => {
                debugger;
              }, refreshError => {
                debugger;
              });
          } else {
            this.toasterService.error('Error getting rooms');
          }
        });
    }
  }

  openRoom($event: MouseEvent, room: WebexRoomDto) {
    $event.preventDefault();

    let integrationId: string;
    this.usedIntegrations.forEach(value => {
      if (value.name === 'Cisco WebEx' && value.integrations.length > 0) {
        integrationId = value.integrations[0].id;
      }
    });

    const link = `/api/oauth-client/webex/rooms/${integrationId}/${room.id}?access-token=${this.authService.getToken()}`;

    const element = this.iframe.nativeElement;
    element.src = link;

    // displayCode += `<iframe src="${link}" allowfullscreen width="100%" height="100%"`
    //   + ` sandbox="allow-forms allow-popups allow-scripts allow-same-origin" allow="microphone; camera" allowfullscreen="allowfullscreen"></iframe>`;

  }
}
