import * as moment from 'moment';
import {EventEmitter, Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {
  AccountService,
  LoginDto,
  LoginResultDto,
  PwdLessCallbackDto, PwdLessLoginDto, PwdLessProfileDto
} from '../../virtual-expo-api';
import {Observable, Subject, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class AuthService {
  onTokenRefresh = new Subject<LoginResultDto>();
  onTokenUpdate = new Subject<LoginResultDto>();
  onLogout = new Subject();
  onLogin = new Subject();

  constructor(private accountApi: AccountService) {
  }

  login(email: string, password: string): Observable<LoginResultDto> {
    const loginData: LoginDto = {email: email, password: password};
    return this.accountApi
      .accountLogin(loginData)
      .pipe(
        tap(x => {
            this.setSession(x);
            this.onLogin.next();
          }
        )
      );
  }

  private setSession(authResult: LoginResultDto) {
    const expiresAt = moment().add(authResult.lifetime, 'second');

    localStorage.setItem('id_token', authResult.token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));

    this.onTokenUpdate.next(authResult);
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    this.onLogout.next();
  }

  public isLoggedIn() {
    if (localStorage.getItem('id_token') && localStorage.getItem('expires_at')) {
      return moment().isBefore(this.getExpiration());
    }
    return false;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  public getToken(): string {
    const token = localStorage.getItem('id_token');
    return token;
  }

  public getTokenData() {
    const helper = new JwtHelperService();

    const token = localStorage.getItem('id_token');
    const decodedToken = helper.decodeToken(token);
    const expirationDate = helper.getTokenExpirationDate(token);
    // this.email = decodedToken.sub;
    // this.givenName = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
    // this.givenName = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
    const isExpired = helper.isTokenExpired(token);
    return decodedToken;
  }

  public validateToken(token: string): Observable<LoginResultDto> {
    localStorage.setItem('id_token', token);
    const helper = new JwtHelperService();
    const expirationDate = helper.getTokenExpirationDate(token);
    localStorage.setItem('expires_at', JSON.stringify(expirationDate.valueOf()));

    return this.accountApi
      .accountRefresh()
      .pipe(
        tap(x => {
            this.setSession(x);
          }
        )
      );
  }

  public startPwdLess(email: string): Observable<boolean> {
    const loginData: PwdLessLoginDto = {email: email};
    return this.accountApi
      .accountPwdLessStart(loginData);
  }

  public validatePwdLess(email: string, token: string): Observable<LoginResultDto> {
    const loginData: PwdLessCallbackDto = {email: email, token: token};
    return this.accountApi
      .accountPwdLessCallback(loginData)
      .pipe(
        tap(x => {
            this.setSession(x);
          }
        )
      );
  }

  public validateTotpPwdLess(email: string, token: string): Observable<LoginResultDto> {
    const loginData: PwdLessCallbackDto = {email: email, token: token};
    return this.accountApi
      .accountPwdLessTotpCallback(loginData)
      .pipe(
        tap(x => {
            this.setSession(x);
          }
        )
      );
  }

  public updatedPwdLessProfile(dto: PwdLessProfileDto): Observable<LoginResultDto> {
    return this.accountApi.accountPwdLessProfile(dto)
      .pipe(
        tap(x => {
            this.setSession(x);
          }
        )
      );
  }

  refreshToken(): Observable<LoginResultDto> {
    return this.accountApi
      .accountRefresh()
      .pipe(
        tap(x => {
            this.setSession(x);
            this.onTokenRefresh.next(x);
          }
        )
      );
  }

  getUserId(): string {
    const decodedToken = this.getTokenData();
    const id = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
    return id;
  }
}



