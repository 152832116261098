<ng-container *ngIf="!error">
  <h2 class="text-center pv">Passwordless login.</h2>
  <p>
    Passwordless login means that you won't have to choose a password.
  </p>
  <p>
    Validating your login link, just a moment.
  </p>
</ng-container>
<ng-container *ngIf="error">
  <h2 class="text-center pv">Passwordless login.</h2>
  <p>
    An error occurred trying to log in your account.<br/>
    Please try again.
  </p>
  <p>
    Please remember that your login link is only valid once, and only for 15 minutes!
  </p>
  <a href="/pwdless" class="btn btn-block btn-primary mt-lg" type="submit">Click to try again</a>
</ng-container>
