/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ExpoListDto } from './expoListDto';
import { StageEventsDto } from './stageEventsDto';


export interface ExpoEventsDto { 
    expo?: ExpoListDto;
    expoEvents?: Array<StageEventsDto>;
    canEditStages?: boolean;
    canEditAvailabilities?: boolean;
    canEditSessions?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoEventsDto.
 */
export namespace ExpoEventsDto {
    /**
     * All properties of ExpoEventsDto.
     */
    export enum Properties {
        expo = 'expo',
        expoEvents = 'expoEvents',
        canEditStages = 'canEditStages',
        canEditAvailabilities = 'canEditAvailabilities',
        canEditSessions = 'canEditSessions'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoEventsDto.
    */
    export const ModelValidators: {[K in keyof ExpoEventsDto]: [string, ValidatorFn][]} = {
        expo: [
        ],
        expoEvents: [
        ],
        canEditStages: [
        ],
        canEditAvailabilities: [
        ],
        canEditSessions: [
        ],
    };

    /**
    * The FormControlFactory for ExpoEventsDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoEventsDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoEventsDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoEventsDto = {
            expo: null,
            expoEvents: null,
            canEditStages: null,
            canEditAvailabilities: null,
            canEditSessions: null,
          }
        ) {
            super(model, ExpoEventsDto.ModelValidators);
        }
    }

}


