/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { LoginResultDto } from '../model/loginResultDto';
import { ProblemDetails } from '../model/problemDetails';
import { TicketSaleRequestDto } from '../model/ticketSaleRequestDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for ticketRegisterTicketSale.
 */
export namespace TicketRegisterTicketSale {
    /**
     * Parameter map for ticketRegisterTicketSale.
     */
    export interface PartialParamMap {
      /**
       * The data.
       */
      TicketSaleRequestDto: TicketSaleRequestDto;
    }

    /**
     * Enumeration of all parameters for ticketRegisterTicketSale.
     */
    export enum Parameters {
      /**
       * The data.
       */
      TicketSaleRequestDto = 'TicketSaleRequestDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of ticketRegisterTicketSale
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TicketRegisterTicketSale.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}



@Injectable({
  providedIn: 'root'
})
export class TicketService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   * Registers the ticket sale. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public ticketRegisterTicketSaleByMap(
    map: TicketRegisterTicketSale.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
  public ticketRegisterTicketSaleByMap(
    map: TicketRegisterTicketSale.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
  public ticketRegisterTicketSaleByMap(
    map: TicketRegisterTicketSale.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
  public ticketRegisterTicketSaleByMap(
    map: TicketRegisterTicketSale.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.ticketRegisterTicketSale(
      map.TicketSaleRequestDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Registers the ticket sale.
     * 
     * @param TicketSaleRequestDto The data.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public ticketRegisterTicketSale(TicketSaleRequestDto: TicketSaleRequestDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<LoginResultDto>;
    public ticketRegisterTicketSale(TicketSaleRequestDto: TicketSaleRequestDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<LoginResultDto>>;
    public ticketRegisterTicketSale(TicketSaleRequestDto: TicketSaleRequestDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<LoginResultDto>>;
    public ticketRegisterTicketSale(TicketSaleRequestDto: TicketSaleRequestDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (TicketSaleRequestDto === null || TicketSaleRequestDto === undefined) {
            throw new Error('Required parameter TicketSaleRequestDto was null or undefined when calling ticketRegisterTicketSale.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<LoginResultDto>(`${this.configuration.basePath}/api/Ticket`,
            TicketSaleRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Ticket_RegisterTicketSale']) {
                this.cancelMap['Ticket_RegisterTicketSale'].next();
            }
            this.cancelMap['Ticket_RegisterTicketSale'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Ticket_RegisterTicketSale']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Ticket_RegisterTicketSale']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Ticket_RegisterTicketSale', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Ticket_RegisterTicketSale']) {
            this.cancelMap['Ticket_RegisterTicketSale'].complete();
            delete this.cancelMap['Ticket_RegisterTicketSale'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Ticket_RegisterTicketSale',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Ticket_RegisterTicketSale')));
        }
        return handle;
    }

  }

export namespace TicketService {
  export enum Operations {
    Ticket_RegisterTicketSale = 'Ticket_RegisterTicketSale'
  }
}
