/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { YoutubeChannel } from '../model/youtubeChannel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for reportGetTutorialPlaylist.
 */
export namespace ReportGetTutorialPlaylist {
    /**
     * Parameter map for reportGetTutorialPlaylist.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for reportGetTutorialPlaylist.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of reportGetTutorialPlaylist
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ReportGetTutorialPlaylist.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for reportGetYoutubePlaylist.
 */
export namespace ReportGetYoutubePlaylist {
    /**
     * Parameter map for reportGetYoutubePlaylist.
     */
    export interface PartialParamMap {
      id: string;
    }

    /**
     * Enumeration of all parameters for reportGetYoutubePlaylist.
     */
    export enum Parameters {
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of reportGetYoutubePlaylist
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof ReportGetYoutubePlaylist.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class ReportService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public reportGetTutorialPlaylistByMap(
    map: ReportGetTutorialPlaylist.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<YoutubeChannel>;
  public reportGetTutorialPlaylistByMap(
    map: ReportGetTutorialPlaylist.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<YoutubeChannel>>;
  public reportGetTutorialPlaylistByMap(
    map: ReportGetTutorialPlaylist.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<YoutubeChannel>>;
  public reportGetTutorialPlaylistByMap(
    map: ReportGetTutorialPlaylist.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.reportGetTutorialPlaylist(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public reportGetTutorialPlaylist(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<YoutubeChannel>;
    public reportGetTutorialPlaylist(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<YoutubeChannel>>;
    public reportGetTutorialPlaylist(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<YoutubeChannel>>;
    public reportGetTutorialPlaylist(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<YoutubeChannel>(`${this.configuration.basePath}/api/Report/tutorials`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Report_GetTutorialPlaylist']) {
                this.cancelMap['Report_GetTutorialPlaylist'].next();
            }
            this.cancelMap['Report_GetTutorialPlaylist'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Report_GetTutorialPlaylist']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Report_GetTutorialPlaylist']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Report_GetTutorialPlaylist', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Report_GetTutorialPlaylist']) {
            this.cancelMap['Report_GetTutorialPlaylist'].complete();
            delete this.cancelMap['Report_GetTutorialPlaylist'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Report_GetTutorialPlaylist',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Report_GetTutorialPlaylist')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public reportGetYoutubePlaylistByMap(
    map: ReportGetYoutubePlaylist.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<YoutubeChannel>;
  public reportGetYoutubePlaylistByMap(
    map: ReportGetYoutubePlaylist.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<YoutubeChannel>>;
  public reportGetYoutubePlaylistByMap(
    map: ReportGetYoutubePlaylist.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<YoutubeChannel>>;
  public reportGetYoutubePlaylistByMap(
    map: ReportGetYoutubePlaylist.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.reportGetYoutubePlaylist(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public reportGetYoutubePlaylist(id: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<YoutubeChannel>;
    public reportGetYoutubePlaylist(id: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<YoutubeChannel>>;
    public reportGetYoutubePlaylist(id: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<YoutubeChannel>>;
    public reportGetYoutubePlaylist(id: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling reportGetYoutubePlaylist.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<YoutubeChannel>(`${this.configuration.basePath}/api/Report/playlist/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Report_GetYoutubePlaylist']) {
                this.cancelMap['Report_GetYoutubePlaylist'].next();
            }
            this.cancelMap['Report_GetYoutubePlaylist'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Report_GetYoutubePlaylist']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Report_GetYoutubePlaylist']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Report_GetYoutubePlaylist', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Report_GetYoutubePlaylist']) {
            this.cancelMap['Report_GetYoutubePlaylist'].complete();
            delete this.cancelMap['Report_GetYoutubePlaylist'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Report_GetYoutubePlaylist',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Report_GetYoutubePlaylist')));
        }
        return handle;
    }

  }

export namespace ReportService {
  export enum Operations {
    Report_GetTutorialPlaylist = 'Report_GetTutorialPlaylist',
    Report_GetYoutubePlaylist = 'Report_GetYoutubePlaylist'
  }
}
