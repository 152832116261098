/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, Subject, Subscription }                 from 'rxjs';
import { catchError, takeUntil, tap, share }                 from 'rxjs/operators';

import { CalendarEntryStageDto } from '../model/calendarEntryStageDto';
import { NotificationMessageDto } from '../model/notificationMessageDto';
import { StageDto } from '../model/stageDto';
import { StockMediaDto } from '../model/stockMediaDto';
import { StreamUpdateDto } from '../model/streamUpdateDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for stageActivate.
 */
export namespace StageActivate {
    /**
     * Parameter map for stageActivate.
     */
    export interface PartialParamMap {
      entryId: number;
    }

    /**
     * Enumeration of all parameters for stageActivate.
     */
    export enum Parameters {
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of stageActivate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StageActivate.PartialParamMap]?: [string, ValidatorFn][]} = {
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for stageDeactivate.
 */
export namespace StageDeactivate {
    /**
     * Parameter map for stageDeactivate.
     */
    export interface PartialParamMap {
      stageId: string;
    }

    /**
     * Enumeration of all parameters for stageDeactivate.
     */
    export enum Parameters {
      stageId = 'stageId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of stageDeactivate
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StageDeactivate.PartialParamMap]?: [string, ValidatorFn][]} = {
      stageId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for stageDelete.
 */
export namespace StageDelete {
    /**
     * Parameter map for stageDelete.
     */
    export interface PartialParamMap {
      /**
       * The identifier.
       */
      id: number;
    }

    /**
     * Enumeration of all parameters for stageDelete.
     */
    export enum Parameters {
      /**
       * The identifier.
       */
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of stageDelete
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StageDelete.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for stageGetActiveStages.
 */
export namespace StageGetActiveStages {
    /**
     * Parameter map for stageGetActiveStages.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for stageGetActiveStages.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of stageGetActiveStages
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StageGetActiveStages.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for stageGetBackgrounds.
 */
export namespace StageGetBackgrounds {
    /**
     * Parameter map for stageGetBackgrounds.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for stageGetBackgrounds.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of stageGetBackgrounds
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StageGetBackgrounds.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for stageGetStage.
 */
export namespace StageGetStage {
    /**
     * Parameter map for stageGetStage.
     */
    export interface PartialParamMap {
      /**
       * The expo identifier.
       */
      expoId: string;
      /**
       * The identifier.
       */
      id: string;
    }

    /**
     * Enumeration of all parameters for stageGetStage.
     */
    export enum Parameters {
      /**
       * The expo identifier.
       */
      expoId = 'expoId',
      /**
       * The identifier.
       */
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of stageGetStage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StageGetStage.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for stageGetStageForExpo.
 */
export namespace StageGetStageForExpo {
    /**
     * Parameter map for stageGetStageForExpo.
     */
    export interface PartialParamMap {
      /**
       * The expo identifier.
       */
      expoId: string;
    }

    /**
     * Enumeration of all parameters for stageGetStageForExpo.
     */
    export enum Parameters {
      /**
       * The expo identifier.
       */
      expoId = 'expoId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of stageGetStageForExpo
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StageGetStageForExpo.PartialParamMap]?: [string, ValidatorFn][]} = {
      expoId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for stageNotify.
 */
export namespace StageNotify {
    /**
     * Parameter map for stageNotify.
     */
    export interface PartialParamMap {
      entryId: number;
    }

    /**
     * Enumeration of all parameters for stageNotify.
     */
    export enum Parameters {
      entryId = 'entryId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of stageNotify
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StageNotify.PartialParamMap]?: [string, ValidatorFn][]} = {
      entryId: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for stageNotifyMessage.
 */
export namespace StageNotifyMessage {
    /**
     * Parameter map for stageNotifyMessage.
     */
    export interface PartialParamMap {
      NotificationMessageDto: NotificationMessageDto;
    }

    /**
     * Enumeration of all parameters for stageNotifyMessage.
     */
    export enum Parameters {
      NotificationMessageDto = 'NotificationMessageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of stageNotifyMessage
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StageNotifyMessage.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for stagePost.
 */
export namespace StagePost {
    /**
     * Parameter map for stagePost.
     */
    export interface PartialParamMap {
      /**
       * The stage dto.
       */
      StageDto: StageDto;
    }

    /**
     * Enumeration of all parameters for stagePost.
     */
    export enum Parameters {
      /**
       * The stage dto.
       */
      StageDto = 'StageDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of stagePost
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StagePost.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for stageUpdateStream.
 */
export namespace StageUpdateStream {
    /**
     * Parameter map for stageUpdateStream.
     */
    export interface PartialParamMap {
      entryId: number;
      StreamUpdateDto: StreamUpdateDto;
    }

    /**
     * Enumeration of all parameters for stageUpdateStream.
     */
    export enum Parameters {
      entryId = 'entryId',
      StreamUpdateDto = 'StreamUpdateDto'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of stageUpdateStream
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof StageUpdateStream.PartialParamMap]?: [string, ValidatorFn][]} = {
      entryId: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class StageService {

    protected basePath = 'http://localhost:7000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    private cancelMap: {[key: string]: Subject<any>} = {};

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public stageActivateByMap(
    map: StageActivate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public stageActivateByMap(
    map: StageActivate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public stageActivateByMap(
    map: StageActivate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public stageActivateByMap(
    map: StageActivate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.stageActivate(
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public stageActivate(entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public stageActivate(entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public stageActivate(entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public stageActivate(entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling stageActivate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/Stage/activate/${encodeURIComponent(String(entryId))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Stage_Activate']) {
                this.cancelMap['Stage_Activate'].next();
            }
            this.cancelMap['Stage_Activate'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Stage_Activate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Stage_Activate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Stage_Activate', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Stage_Activate']) {
            this.cancelMap['Stage_Activate'].complete();
            delete this.cancelMap['Stage_Activate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Stage_Activate',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Stage_Activate')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public stageDeactivateByMap(
    map: StageDeactivate.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public stageDeactivateByMap(
    map: StageDeactivate.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public stageDeactivateByMap(
    map: StageDeactivate.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public stageDeactivateByMap(
    map: StageDeactivate.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.stageDeactivate(
      map.stageId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param stageId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public stageDeactivate(stageId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public stageDeactivate(stageId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public stageDeactivate(stageId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public stageDeactivate(stageId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (stageId === null || stageId === undefined) {
            throw new Error('Required parameter stageId was null or undefined when calling stageDeactivate.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/Stage/deactivate/${encodeURIComponent(String(stageId))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Stage_Deactivate']) {
                this.cancelMap['Stage_Deactivate'].next();
            }
            this.cancelMap['Stage_Deactivate'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Stage_Deactivate']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Stage_Deactivate']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Stage_Deactivate', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Stage_Deactivate']) {
            this.cancelMap['Stage_Deactivate'].complete();
            delete this.cancelMap['Stage_Deactivate'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Stage_Deactivate',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Stage_Deactivate')));
        }
        return handle;
    }


  /**
   * Deletes the specified identifier. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public stageDeleteByMap(
    map: StageDelete.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public stageDeleteByMap(
    map: StageDelete.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public stageDeleteByMap(
    map: StageDelete.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public stageDeleteByMap(
    map: StageDelete.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.stageDelete(
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Deletes the specified identifier.
     * 
     * @param id The identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public stageDelete(id: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public stageDelete(id: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public stageDelete(id: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public stageDelete(id: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling stageDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.delete(`${this.configuration.basePath}/api/Stage/${encodeURIComponent(String(id))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Stage_Delete']) {
                this.cancelMap['Stage_Delete'].next();
            }
            this.cancelMap['Stage_Delete'] = 'delete'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Stage_Delete']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Stage_Delete']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Stage_Delete', 'delete'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Stage_Delete']) {
            this.cancelMap['Stage_Delete'].complete();
            delete this.cancelMap['Stage_Delete'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Stage_Delete',
              'delete'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Stage_Delete')));
        }
        return handle;
    }


  /**
   * Gets the active stages. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public stageGetActiveStagesByMap(
    map: StageGetActiveStages.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<StageDto>>;
  public stageGetActiveStagesByMap(
    map: StageGetActiveStages.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StageDto>>>;
  public stageGetActiveStagesByMap(
    map: StageGetActiveStages.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StageDto>>>;
  public stageGetActiveStagesByMap(
    map: StageGetActiveStages.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.stageGetActiveStages(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the active stages.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public stageGetActiveStages(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<StageDto>>;
    public stageGetActiveStages(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StageDto>>>;
    public stageGetActiveStages(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StageDto>>>;
    public stageGetActiveStages(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<StageDto>>(`${this.configuration.basePath}/api/Stage`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Stage_GetActiveStages']) {
                this.cancelMap['Stage_GetActiveStages'].next();
            }
            this.cancelMap['Stage_GetActiveStages'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Stage_GetActiveStages']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Stage_GetActiveStages']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Stage_GetActiveStages', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Stage_GetActiveStages']) {
            this.cancelMap['Stage_GetActiveStages'].complete();
            delete this.cancelMap['Stage_GetActiveStages'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Stage_GetActiveStages',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Stage_GetActiveStages')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public stageGetBackgroundsByMap(
    map: StageGetBackgrounds.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
  public stageGetBackgroundsByMap(
    map: StageGetBackgrounds.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
  public stageGetBackgroundsByMap(
    map: StageGetBackgrounds.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
  public stageGetBackgroundsByMap(
    map: StageGetBackgrounds.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.stageGetBackgrounds(
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public stageGetBackgrounds(observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<StockMediaDto>>;
    public stageGetBackgrounds(observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StockMediaDto>>>;
    public stageGetBackgrounds(observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StockMediaDto>>>;
    public stageGetBackgrounds(observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<StockMediaDto>>(`${this.configuration.basePath}/api/Stage/backgrounds`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Stage_GetBackgrounds']) {
                this.cancelMap['Stage_GetBackgrounds'].next();
            }
            this.cancelMap['Stage_GetBackgrounds'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Stage_GetBackgrounds']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Stage_GetBackgrounds']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Stage_GetBackgrounds', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Stage_GetBackgrounds']) {
            this.cancelMap['Stage_GetBackgrounds'].complete();
            delete this.cancelMap['Stage_GetBackgrounds'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Stage_GetBackgrounds',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Stage_GetBackgrounds')));
        }
        return handle;
    }


  /**
   * Gets the stage. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public stageGetStageByMap(
    map: StageGetStage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<StageDto>;
  public stageGetStageByMap(
    map: StageGetStage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<StageDto>>;
  public stageGetStageByMap(
    map: StageGetStage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<StageDto>>;
  public stageGetStageByMap(
    map: StageGetStage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.stageGetStage(
      map.expoId,
      map.id,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the stage.
     * 
     * @param expoId The expo identifier.
     * @param id The identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public stageGetStage(expoId: string, id: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<StageDto>;
    public stageGetStage(expoId: string, id: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<StageDto>>;
    public stageGetStage(expoId: string, id: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<StageDto>>;
    public stageGetStage(expoId: string, id: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling stageGetStage.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling stageGetStage.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<StageDto>(`${this.configuration.basePath}/api/Stage/${encodeURIComponent(String(expoId))}/stage/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Stage_GetStage']) {
                this.cancelMap['Stage_GetStage'].next();
            }
            this.cancelMap['Stage_GetStage'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Stage_GetStage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Stage_GetStage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Stage_GetStage', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Stage_GetStage']) {
            this.cancelMap['Stage_GetStage'].complete();
            delete this.cancelMap['Stage_GetStage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Stage_GetStage',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Stage_GetStage')));
        }
        return handle;
    }


  /**
   * Gets the one stage. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public stageGetStageForExpoByMap(
    map: StageGetStageForExpo.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Array<StageDto>>;
  public stageGetStageForExpoByMap(
    map: StageGetStageForExpo.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StageDto>>>;
  public stageGetStageForExpoByMap(
    map: StageGetStageForExpo.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StageDto>>>;
  public stageGetStageForExpoByMap(
    map: StageGetStageForExpo.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.stageGetStageForExpo(
      map.expoId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Gets the one stage.
     * 
     * @param expoId The expo identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public stageGetStageForExpo(expoId: string, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Array<StageDto>>;
    public stageGetStageForExpo(expoId: string, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Array<StageDto>>>;
    public stageGetStageForExpo(expoId: string, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Array<StageDto>>>;
    public stageGetStageForExpo(expoId: string, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (expoId === null || expoId === undefined) {
            throw new Error('Required parameter expoId was null or undefined when calling stageGetStageForExpo.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get<Array<StageDto>>(`${this.configuration.basePath}/api/Stage/${encodeURIComponent(String(expoId))}/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Stage_GetStageForExpo']) {
                this.cancelMap['Stage_GetStageForExpo'].next();
            }
            this.cancelMap['Stage_GetStageForExpo'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Stage_GetStageForExpo']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Stage_GetStageForExpo']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Stage_GetStageForExpo', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Stage_GetStageForExpo']) {
            this.cancelMap['Stage_GetStageForExpo'].complete();
            delete this.cancelMap['Stage_GetStageForExpo'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Stage_GetStageForExpo',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Stage_GetStageForExpo')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public stageNotifyByMap(
    map: StageNotify.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public stageNotifyByMap(
    map: StageNotify.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public stageNotifyByMap(
    map: StageNotify.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public stageNotifyByMap(
    map: StageNotify.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.stageNotify(
      map.entryId,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param entryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public stageNotify(entryId: number, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public stageNotify(entryId: number, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public stageNotify(entryId: number, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public stageNotify(entryId: number, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling stageNotify.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        let handle = this.httpClient.get(`${this.configuration.basePath}/api/Stage/notify/${encodeURIComponent(String(entryId))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Stage_Notify']) {
                this.cancelMap['Stage_Notify'].next();
            }
            this.cancelMap['Stage_Notify'] = 'get'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Stage_Notify']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Stage_Notify']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Stage_Notify', 'get'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Stage_Notify']) {
            this.cancelMap['Stage_Notify'].complete();
            delete this.cancelMap['Stage_Notify'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Stage_Notify',
              'get'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Stage_Notify')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public stageNotifyMessageByMap(
    map: StageNotifyMessage.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<Blob>;
  public stageNotifyMessageByMap(
    map: StageNotifyMessage.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
  public stageNotifyMessageByMap(
    map: StageNotifyMessage.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
  public stageNotifyMessageByMap(
    map: StageNotifyMessage.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.stageNotifyMessage(
      map.NotificationMessageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param NotificationMessageDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public stageNotifyMessage(NotificationMessageDto: NotificationMessageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<Blob>;
    public stageNotifyMessage(NotificationMessageDto: NotificationMessageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<Blob>>;
    public stageNotifyMessage(NotificationMessageDto: NotificationMessageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<Blob>>;
    public stageNotifyMessage(NotificationMessageDto: NotificationMessageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (NotificationMessageDto === null || NotificationMessageDto === undefined) {
            throw new Error('Required parameter NotificationMessageDto was null or undefined when calling stageNotifyMessage.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post(`${this.configuration.basePath}/api/Stage/notify`,
            NotificationMessageDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Stage_NotifyMessage']) {
                this.cancelMap['Stage_NotifyMessage'].next();
            }
            this.cancelMap['Stage_NotifyMessage'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Stage_NotifyMessage']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Stage_NotifyMessage']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Stage_NotifyMessage', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Stage_NotifyMessage']) {
            this.cancelMap['Stage_NotifyMessage'].complete();
            delete this.cancelMap['Stage_NotifyMessage'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Stage_NotifyMessage',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Stage_NotifyMessage')));
        }
        return handle;
    }


  /**
   * Posts the specified calendar entry dto. by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public stagePostByMap(
    map: StagePost.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<StageDto>;
  public stagePostByMap(
    map: StagePost.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<StageDto>>;
  public stagePostByMap(
    map: StagePost.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<StageDto>>;
  public stagePostByMap(
    map: StagePost.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.stagePost(
      map.StageDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * Posts the specified calendar entry dto.
     * 
     * @param StageDto The stage dto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public stagePost(StageDto: StageDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<StageDto>;
    public stagePost(StageDto: StageDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<StageDto>>;
    public stagePost(StageDto: StageDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<StageDto>>;
    public stagePost(StageDto: StageDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (StageDto === null || StageDto === undefined) {
            throw new Error('Required parameter StageDto was null or undefined when calling stagePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<StageDto>(`${this.configuration.basePath}/api/Stage`,
            StageDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Stage_Post']) {
                this.cancelMap['Stage_Post'].next();
            }
            this.cancelMap['Stage_Post'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Stage_Post']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Stage_Post']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Stage_Post', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Stage_Post']) {
            this.cancelMap['Stage_Post'].complete();
            delete this.cancelMap['Stage_Post'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Stage_Post',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Stage_Post')));
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
   */
  public stageUpdateStreamByMap(
    map: StageUpdateStream.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<CalendarEntryStageDto>;
  public stageUpdateStreamByMap(
    map: StageUpdateStream.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryStageDto>>;
  public stageUpdateStreamByMap(
    map: StageUpdateStream.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean,
    cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryStageDto>>;
  public stageUpdateStreamByMap(
    map: StageUpdateStream.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false,
    cancelPreviousRequest: boolean = true): Observable<any> {
    return this.stageUpdateStream(
      map.entryId,
      map.StreamUpdateDto,
      observe,
      reportProgress,
      cancelPreviousRequest
    );
  }


    /**
     * 
     * 
     * @param entryId 
     * @param StreamUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param cancelPreviousRequest set whether or not the previous request for the same operation should be cancelled if it is still running.
     */
    public stageUpdateStream(entryId: number, StreamUpdateDto: StreamUpdateDto, observe?: 'body', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<CalendarEntryStageDto>;
    public stageUpdateStream(entryId: number, StreamUpdateDto: StreamUpdateDto, observe?: 'response', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpResponse<CalendarEntryStageDto>>;
    public stageUpdateStream(entryId: number, StreamUpdateDto: StreamUpdateDto, observe?: 'events', reportProgress?: boolean, cancelPreviousRequest?: boolean): Observable<HttpEvent<CalendarEntryStageDto>>;
    public stageUpdateStream(entryId: number, StreamUpdateDto: StreamUpdateDto, observe: any = 'body', reportProgress: boolean = false, cancelPreviousRequest: boolean = true): Observable<any> {
        if (entryId === null || entryId === undefined) {
            throw new Error('Required parameter entryId was null or undefined when calling stageUpdateStream.');
        }
        if (StreamUpdateDto === null || StreamUpdateDto === undefined) {
            throw new Error('Required parameter StreamUpdateDto was null or undefined when calling stageUpdateStream.');
        }

        let headers = this.defaultHeaders;

        // authentication (jwtToken) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let handle = this.httpClient.post<CalendarEntryStageDto>(`${this.configuration.basePath}/api/Stage/updateStream/${encodeURIComponent(String(entryId))}`,
            StreamUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

        if (cancelPreviousRequest) {
            if (this.cancelMap['Stage_UpdateStream']) {
                this.cancelMap['Stage_UpdateStream'].next();
            }
            this.cancelMap['Stage_UpdateStream'] = 'post'.toUpperCase() === 'GET' ? new Subject<any>() : null;
            if(this.cancelMap['Stage_UpdateStream']) {
                handle = handle.pipe(takeUntil(this.cancelMap['Stage_UpdateStream']));
            }
        }

        if (typeof this.configuration.beforeHandler === 'function') {
          this.configuration.beforeHandler('Stage_UpdateStream', 'post'.toUpperCase());
        }
        let afterSubscription: Subscription;
        const afterHandler = (result: any = null) => {
          if (afterSubscription) {
            afterSubscription.unsubscribe();
          }
          // stop cancellation to prevent calling afterHandler on next service call
          if (cancelPreviousRequest && this.cancelMap['Stage_UpdateStream']) {
            this.cancelMap['Stage_UpdateStream'].complete();
            delete this.cancelMap['Stage_UpdateStream'];
          }
          if (typeof this.configuration.afterHandler === 'function') {
            this.configuration.afterHandler(
              'Stage_UpdateStream',
              'post'.toUpperCase(),
              result ? result : new Error('CANCELLED')
            );
          }
        };
        handle = handle.pipe(share());
        afterSubscription = handle.subscribe(
          result => afterHandler(result),
          err => afterHandler(err),
          () => afterHandler()
        );

        if (typeof this.configuration.errorHandler === 'function') {
          return handle.pipe(catchError(err => this.configuration.errorHandler(err, 'Stage_UpdateStream')));
        }
        return handle;
    }

  }

export namespace StageService {
  export enum Operations {
    Stage_Activate = 'Stage_Activate',
    Stage_Deactivate = 'Stage_Deactivate',
    Stage_Delete = 'Stage_Delete',
    Stage_GetActiveStages = 'Stage_GetActiveStages',
    Stage_GetBackgrounds = 'Stage_GetBackgrounds',
    Stage_GetStage = 'Stage_GetStage',
    Stage_GetStageForExpo = 'Stage_GetStageForExpo',
    Stage_Notify = 'Stage_Notify',
    Stage_NotifyMessage = 'Stage_NotifyMessage',
    Stage_Post = 'Stage_Post',
    Stage_UpdateStream = 'Stage_UpdateStream'
  }
}
