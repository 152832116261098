/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { InfoSlideUpdateDtoAllOf } from './infoSlideUpdateDtoAllOf';
import { InfoSlideDto } from './infoSlideDto';


export interface InfoSlideUpdateDto { 
    id?: string;
    description?: string;
    image?: string;
    link?: string;
    fontSize?: number;
    sequence?: number;
    inActive?: boolean;
    rowVersion?: number;
    imageRaw?: string;
    exhibitorId?: string;
    expoId?: string;
}

/**
 * Namespace for property- and property-value-enumerations of InfoSlideUpdateDto.
 */
export namespace InfoSlideUpdateDto {
    /**
     * All properties of InfoSlideUpdateDto.
     */
    export enum Properties {
        id = 'id',
        description = 'description',
        image = 'image',
        link = 'link',
        fontSize = 'fontSize',
        sequence = 'sequence',
        inActive = 'inActive',
        rowVersion = 'rowVersion',
        imageRaw = 'imageRaw',
        exhibitorId = 'exhibitorId',
        expoId = 'expoId'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of InfoSlideUpdateDto.
    */
    export const ModelValidators: {[K in keyof InfoSlideUpdateDto]: [string, ValidatorFn][]} = {
        id: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        description: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        image: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        link: [
        ],
        fontSize: [
        ],
        sequence: [
        ],
        inActive: [
        ],
        rowVersion: [
        ],
        imageRaw: [
        ],
        exhibitorId: [
        ],
        expoId: [
        ],
    };

    /**
    * The FormControlFactory for InfoSlideUpdateDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<InfoSlideUpdateDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for InfoSlideUpdateDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: InfoSlideUpdateDto = {
            id: null,
            description: null,
            image: null,
            link: null,
            fontSize: null,
            sequence: null,
            inActive: null,
            rowVersion: null,
            imageRaw: null,
            exhibitorId: null,
            expoId: null,
          }
        ) {
            super(model, InfoSlideUpdateDto.ModelValidators);
        }
    }

}


