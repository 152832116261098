import {Directive} from '@angular/core';

@Directive({
  selector: '[prevent-spaces]',
  host: {
    '(input)': 'onInputChange($event)'
  }
})
export class PreventSpacesDirective {

  constructor() {
  }

  onInputChange($event: any) {
    if ($event.data === ' ' || $event.data === 'Spacebar') {
      $event.target.value = $event.target.value.replace(/ /g, '');
        $event.preventDefault();
    }
  }

}
