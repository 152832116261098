import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-form-color-input',
  templateUrl: './form-color-input.component.html',
  styleUrls: ['./form-color-input.component.scss']
})
export class FormColorInputComponent implements OnInit {
  // @Input() formGroup: FormGroup;
  @Input() fieldName: string;
  @Input() dataSource: any;

  @Input() label = '';
  @Input() help = '';

  @Output() change: EventEmitter<string> = new EventEmitter<string>();
  @Input() hasAlphaChannel = true;
  alphaChannel: 'enabled' | 'disabled' | 'forced' = 'enabled';

  constructor() { }

  ngOnInit(): void {
    this.alphaChannel = this.hasAlphaChannel ? 'enabled' : 'disabled';
  }

  updateFrame(color: string) {
    this.change.emit(color);
  }
}
