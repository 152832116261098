import {Injectable} from '@angular/core';

/**
 * Service for playing sound files
 *
 * @export
 * @class SoundService
 */
@Injectable()
export class SoundService {
  private soundFiles = {
    bell: './assets/sounds/bell.wav',
    ring: './assets/sounds/ring.wav'
  };

  soundBell: HTMLAudioElement;
  soundRing: HTMLAudioElement;

  constructor() {
    this.soundBell = new Audio(this.soundFiles.bell);
    this.soundRing = new Audio(this.soundFiles.ring);
  }

  public playBell() {
    this.soundBell.play();
  }

  public playRing() {
    this.soundRing.play();
  }
}

